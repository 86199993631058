/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {

    register(`${process.env.BASE_URL}service-worker.js`, {
        ready(reg) {
            console.log('App is being served from cache by a service worker.');

            if (reg.active != null)
                reg.active.postMessage('identify');
        },

        registered(reg) {
            console.log('Service worker has been registered.');
            (Window as any).ServiceWorkerRegistration = reg;
        },

        cached() {
            console.log('Content has been cached for offline use.');
        },

        updatefound() {
            console.log('New content is downloading.');
            setTimeout(() => {
                try {
                    var event = new CustomEvent("newversiondownloading", {
                        detail: {
                            message: "",
                            info: {}
                        }
                    });
                    window.dispatchEvent(event);
                }
                catch (err) {
                    console.log(err);
                }
            }, 500);
        },

        updated(reg) {
            console.log('New content is available; Refresh...')
            if (reg.waiting) {
                reg.waiting.postMessage('force-activate');
                setTimeout(() => {
                    try {
                        var event = new CustomEvent("newversiondownloaded", {
                            detail: {
                                message: "",
                                info: {}
                            }
                        });
                        window.dispatchEvent(event);
                    }
                    catch (err) {
                        console.log(err);
                    }
                }, 500);
            }
        },

        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    })
}

