<template>
    <div class="text-center">
        <input id="datepicker" type="date" name="calendar" :value="selectedDate" :min="minDate" :max="maxDate">
        <input class="mt-3" type="text" v-model="selectedDateInput" id="dateInput" @change="InputDateChanged"/>
        <div class="w-100 mt-3 text-right">
            <button @click="SetItem(true)" class="btnViewer btnViewerDefault">{{UIText.SetDate}}</button>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Vue } from 'vue-facing-decorator';
    import * as formControls from './form/FormControls';
    import * as excelHandler from './form/ExcelHandler';
    import * as excelHandlerUtils from './form/ExcelHandler.Utils';

    @Component({ components: { } })
    export default class DateControl extends Vue {

        get ControlRanges(): formControls.GridRange[] | null { return this.$store.state.excel.ControlRanges; }
        get SelectedRangeAddress(): string | null { return this.$store.state.excel.SelectedRangeAddress; }
        get UIText(): object | null { return this.$store.state.Locale; }

        minDate: string = "1900-01-01";
        maxDate: string = "2999-12-30";
        selectedDate: string | Date = new Date().toISOString().slice(0, 10);
        selectedDateInput: string | Date = new Date().toISOString().slice(0, 10);

        @Watch("selectedDate") onSelectedDateChange() {
            this.selectedDateInput! = this.selectedDate;
        }

        @Watch("SelectedRangeAddress") async onDateChange() {
            await this.UpdateDate();
        }

        async created() {
            await this.UpdateDate();
        }

        InputDateChanged() {
            let inputVal = this.selectedDateInput;
            const inputDate = new Date(inputVal + ' 0:0:0 AM UTC').toISOString();
            if (inputDate !== "Invalid Date") {
                this.selectedDate = this.selectedDateInput;
            }
        }

        async SetItem(isUpdate: boolean) {
            if (isUpdate)
                excelHandler.SetItem(this.selectedDate, this.SelectedRangeAddress!);
        }

        async UpdateDate() {
            var vm = this;
            await Excel.run(async (context) => {
                let selectedRange = context.workbook.getSelectedRange();
                selectedRange.load('text, worksheet, rowIndex, columnIndex');
                await context.sync();
                let editControl = await excelHandlerUtils.GetCellEditControl(selectedRange.worksheet, selectedRange.rowIndex, selectedRange.columnIndex, this.ControlRanges!);
                let d: string = '';
                if (Date.parse(selectedRange.text[0][0]))
                    d = selectedRange.text[0][0];
                else
                {
                    d = new Date().toISOString().substring(0, 10);
                    if (editControl) {
                        d = editControl.MinDate ? editControl.MinDate.slice(0, 4) + '-' + editControl.MinDate.slice(4, 6) + '-' + editControl.MinDate.slice(6, 8) : "1900-01-01";
                    }
                }
                if (editControl) {
                    this.minDate = editControl.MinDate ? editControl.MinDate.slice(0, 4) + '-' + editControl.MinDate.slice(4, 6) + '-' + editControl.MinDate.slice(6, 8) : "1900-01-01";
                    this.maxDate = editControl.MaxDate ? editControl.MaxDate.slice(0, 4) + '-' + editControl.MaxDate.slice(4, 6) + '-' + editControl.MaxDate.slice(6, 8) : "2999-12-30";
                }
                Office.addin.showAsTaskpane();
                // Pass date to taskpane
                this.selectedDate = new Date(d);
                this.selectedDateInput = new Date(d).toLocaleDateString();
                await context.sync();
            });
        }
    }
</script>
