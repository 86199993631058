<template>
    <div class="empty">
        <img src="../../../public/icons/empty.svg" class="accent-svg"/>
        <h3>
            <slot></slot>
        </h3>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component
    export default class Empty extends Vue {

    }
</script>

<style scoped>
    .empty {
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 30px;
        opacity: 0.5;
    }

        .empty > img {
            width: 108px;
            height: 106px;
        }
</style>
