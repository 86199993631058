import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormParameterInput = _resolveComponent("FormParameterInput")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_ctx.isFormParameterInputOpened)
    ? (_openBlock(), _createBlock(_component_FormParameterInput, {
        key: 0,
        Parameters: _ctx.Parameters,
        SelectedParameters: "SelectedParameters",
        onCancel: _cache[0] || (_cache[0] = ($event: any) => {_ctx.isFormParameterInputOpened=false; _ctx.isBusy = false}),
        onRun: _ctx.LoadFormWithParamsAsync
      }, null, 8, ["Parameters", "onRun"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isBusy)
          ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
          : (!_ctx.isFormLoaded)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ShowParametersInput && _ctx.ShowParametersInput(...args)))
              }, "Load Form"))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_Menu, { isForm: true })
              ]))
      ]))
}