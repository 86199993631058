export const getDeepErrorMessage = (err: any): string => {
    let msg = '';
    try {
        msg = JSON.parse(err.response).deepMessage;
    } catch (e) {
        msg = '';
        console.warn('Fail get deep error message')
    }
    return msg;
}

//getBrowserLocales() returns {'en-US','es-ES'}
//getBrowserLocales({languageCodeOnly : true}) returns {'en','es'}
export function getBrowserLocales(options = {}): string[] | undefined {
    const defaultOptions = {
        languageCodeOnly: false,
    };
    const opt = {
        ...defaultOptions,
        ...options,
    };
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;
    if (!browserLocales) {
        return undefined;
    }
    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        return opt.languageCodeOnly
            ? trimmedLocale.split(/-|_/)[0]
            : trimmedLocale;
    });
}

export function parseBrowserLocale(num: number, decimalPlaces: number | undefined = undefined): string {
    var parsedNum = 'NaN';
    var locales = getBrowserLocales();
    let overrideLang = localStorage.getItem("UserLocaleOverride");
    if (overrideLang || locales && locales?.length) {
        let loc = overrideLang?.length ? overrideLang : locales![0];
        if (decimalPlaces) {
            parsedNum = num.toLocaleString(loc, { useGrouping: true, minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
        }
        else {
            parsedNum = num.toLocaleString(loc, { useGrouping: true });
        }
    }
    else {
        if (decimalPlaces) {
            parsedNum = num.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
        }
        else {
            parsedNum = num.toLocaleString();
        }
    }
    return parsedNum == 'NaN' ? '' : parsedNum;
}

export function getDefaultSeperator(): string {
    let numVal = 1.1;
    if (parseBrowserLocale(numVal, 1) === '1.1') {
        return ',';
    }
    else {
        return ';';
    }
}

export function getDecimalSeperator(): string {
    let numVal = 1.1;
    return parseBrowserLocale(numVal, 1).charAt(1);
}

export function getThousandSeperator(): string {
    let numVal = 1000.1;
    let parsedString = parseBrowserLocale(numVal, 1);
    if (parsedString.length === 7) {
        return parsedString.charAt(1);
    }
    return '';
}

export function parseToUniversalString(value: any): number {
    if (value) {
        let thousandSep = getThousandSeperator();
        let decimalSeparator = getDecimalSeperator();

        value = value.toString().replaceAll(thousandSep, '');
        if (decimalSeparator === ',') {
            value = value.toString().replace(new RegExp(decimalSeparator, 'g'), '.');
        }
        return parseFloat(value.toString())
            ? parseFloat(value.toString())
            : 0;
    }
    return 0;
}

export function removeThousandFormatter(value: string): string {
    return value.replaceAll(getThousandSeperator(), '');
}

export function downloadBlob(blob: Blob, name = 'file.txt') {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );

    // Remove link from body
    document.body.removeChild(link);
}

export function amountFormatter(value: number | string | null | undefined) {
    if (value === undefined || value === null) {
       return '';
    }
    value = +value;
    const sansDec = value.toFixed(2);
    let formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (formatted.indexOf('-') === 0) {
        formatted = formatted.substring(1);
        formatted = `(${formatted})`;
    }
    return formatted;
}

export function camelCaseToStr(camelStr:any) {
    console.log(camelStr)
    if (!camelStr) {
        return camelStr;
    }
    if (typeof camelStr !== 'string') {
        return camelStr
    }
    const result = camelStr.replace(/([A-Z])/g, ' $1').trim();
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
}
