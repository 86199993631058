<template>
    <div id="XL3Container" ref="report_container" style="width:100%;height:100%">
        <Spinner v-if="loading" />
        <Dialog v-else-if="needsLogIn"
                @close="needsLogIn=false"
                size="lg"
                title="Please sign in to your account."
                message="By clicking the sign in button you will be taken to the XL3 account sign in page to enter your credentials.">
            <template v-slot:footer>
                <button class="btnViewer" @click="needsLogIn=false">
                    {{Localizer.Localize("CANCEL")}}
                </button>
                <button class="btnViewer btnViewerDefault" @click="XL3LogIn">
                    {{Localizer.Localize("SIGN IN")}}
                </button>
            </template>
        </Dialog>
        <iframe v-else style="width:100%; height:100%; background-color:white" :src="url"></iframe>
    </div>
</template>

<script lang="ts">
    import { AuthClient } from "../../api/ApiClientBase";
    import * as api from "../../api/ApiClient";
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import Spinner from "../helpers/Spinner.vue";
    import Dialog from "../helpers/Dialog.vue";
    import { Localizer } from "../../api/Localizer";
    import XLCubedHelper from "@/api/XLCubedHelper";

    @Component({ components: { Spinner, Dialog } })
    export default class XL3 extends Vue {
        private loading: boolean = true;
        private needsLogIn: boolean = false;
        private url: string | null | undefined = null;

        private Localizer = new Localizer(this.$store);

        get Tenant(): api.Tenant { return this.$store.state.security.Tenant; }

        @Prop()
        private Path!: string | null;

        private get ReportPath(): string {
            if (this.Path != null)
                return this.Path;
            // @ts-ignore
            return this.$route.params.pathMatch;
        }

        async mounted() {
            var xl3Helper = new XLCubedHelper(this.$store, this.$route);
            var html = await xl3Helper.getFormHtml(this.ReportPath, this.Tenant.name!);
            this.url = 'data:text/html;charset=utf-8,' + encodeURI(html);
            this.loading = false;
        }


        private async XL3LogIn() {
            var browserService = new BrowserService();
            var myNewTab = browserService.openNewTab();

            var a = document.createElement('a') as any;
            a.href = this.url;

            var auth_client = new AuthClient();

            browserService.updateTabLocation("https://" + a.hostname + "/.auth/login/aad?post_login_redirect_uri=https://" + a.hostname + "/WebForm/AuthPostLogin.aspx?ret=" + AuthClient.configuration.uiBaseUrl + "/close", myNewTab);

            var y = setInterval(x => {
                if (myNewTab?.location.href == AuthClient.configuration.uiBaseUrl + "/close") {
                    console.log("new tab was used to log user in");
                    clearInterval(y);
                    myNewTab.close();

                    this.loading = false;
                    this.needsLogIn = false;
                }
            }, 2500);
        }
    }

    class BrowserService {
        public openNewTab() {
            var newTabWindow = window.open();
            return newTabWindow;
        }
        public updateTabLocation(tabLocation: string, tab: Window | null) {
            if (tab != null) {
                if (!tabLocation) {
                    tab.close();
                }
                tab.location.href = tabLocation;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
