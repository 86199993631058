export default class LogHelper {
    public static LogId: string | null = null;

    static startLog = () => {
        let id = LogHelper.uuidv4();
        LogHelper.LogId = LogHelper.uuidv4();;
        return id;
    }

    static stopLog = () => {
        LogHelper.LogId = null;
    }

    static uuidv4 = () => {
        return ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}