<template>
    <portal to="DialogsContainer">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div :class="['modal-container', containerStyle]" @click="MainClick">
                        <div class="modal-header">
                            <div style="padding-top: 10px; padding-right: 12px; padding-bottom: 6px; padding-left: 12px; width: 100%; height: 41px; ">
                                <img id="close"
                                     src="/icons/workflow/close.svg"
                                     class="theme-svg"
                                     @click="$emit('close')">
                            </div>
                        </div>

                        <div class="modal-body">
                            <div class="center" style="height: 7%; font-weight: bold; text-align:center; font-size: 22px; padding-bottom: 10px;">
                                {{ Localizer.Localize("WhatsNewHeader") }}
                            </div>

                            <div v-if="!IsHtml" class="center" style="margin-top:40px;">
                                {{ message }}
                            </div>
                            <div v-else class="whatsnewhtml html" v-html="$sanitize(message)">
                            </div>

                            <div v-if="showLearnMore" id="learnmore" class="learnmore">
                                <div class="imghelper">
                                    <img id="learnmoreimg"
                                         class="accent-svg"
                                         style="width:24px; height:24px;"
                                         src="../../../public/icons/dialog/info_whatsnew.svg">
                                </div>
                                <a id="learnmoretext" :href="thisArticle.moreDetailsLink" target="_blank">Learn More</a>
                            </div>
                            <div v-else class="learnmore">
                                <div style="height:24px;" />
                            </div>

                            <div class="ellipse">
                                <template v-for="(html, index) in listOfMessages">
                                    <div v-if="index==0" :id="`img-${index}`" class="accent-svg">
                                        <img src="../../../public/icons/dialog/ellipse.svg">
                                    </div>
                                    <div v-else :id="`img-${index}`">
                                        <img src="../../../public/icons/dialog/ellipse.svg">
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <slot name="footer">
                                <button id="backbutton" class="btnViewer btnViewerDefault" @click="PrevPageClick">
                                    BACK
                                </button>
                                <button class="btnViewer btnViewer" @click="$emit('close')">
                                    CLOSE
                                </button>
                                <button id="nextbutton" class="btnViewer btnViewerDefault" @click="NextPageClick">
                                    NEXT
                                </button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </portal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import { Localizer } from '../../api/Localizer';

    import { Portal, PortalTarget } from 'portal-vue';
    import { WhatsNewArticle } from '../../api/ApiClient';
    import { Timer } from 'ag-grid-community';

    @Component({ components: { Portal } })
    export default class WhatsNewDialog extends Vue {
        @Prop() private size!: string;
        @Prop() private icon!: string;
        @Prop() private title!: string;
        @Prop() private ishtml!: boolean;

        public message: string = "test";
        get sanitizedMessage(): string {
            return this.message; // sanitize message?
        }

        private carousel: boolean = true;
        private carouselID: any = null;
        public currentArticle: WhatsNewArticle | null = null;

        get listOfMessages() {
            return this.$store.state.whatsnew.Articles;
        }

        private Localizer = new Localizer(this.$store);

        get containerStyle() {
            return this.size;
        }

        get IsHtml() {
            return this.ishtml ?? false;
        }

        get thisArticle(): WhatsNewArticle | null {
            return this.currentArticle;
        }

        get showLearnMore(): boolean {
            if (this.currentArticle?.moreDetailsLink != null) {
                return true;
            }
            else {
                return false;
            }
        }

        ChangeLinkTarget() {
            document.getElementById('learnmoretext')?.setAttribute('href', this.currentArticle!.moreDetailsLink!);
            document.getElementById('learnmoretext')?.setAttribute('target', '_blank');
        }

        MainClick() {
            this.carousel = false;
            clearInterval(this.carouselID);
        }

        PrevPageClick() {
            this.carousel = false;
            clearInterval(this.carouselID);
            this.PrevPage();
        }
        NextPageClick() {
            this.carousel = false;
            clearInterval(this.carouselID);
            this.NextPage();
        }

        NextPage() {
            const thisIndex = this.listOfMessages.indexOf(this.currentArticle);
            let nextIndex = 0;
            if (thisIndex < this.listOfMessages.length - 1) {
                nextIndex = thisIndex + 1;
            }

            this.currentArticle = this.listOfMessages[nextIndex];
            this.message = this.listOfMessages[nextIndex].htmlText;

            const oldID = `img-${thisIndex}`;
            document.getElementById(oldID)!.className = 'blank';
            const newID = `img-${nextIndex}`;
            document.getElementById(newID)!.className = 'accent-svg';
        }

        PrevPage() {
            const thisIndex = this.listOfMessages.indexOf(this.currentArticle);
            let nextItem = thisIndex - 1;
            if (thisIndex == 0) {
                nextItem = this.listOfMessages.length - 1;
            }
            this.currentArticle = this.listOfMessages[nextItem];
            this.message = this.listOfMessages[nextItem].htmlText;

            const oldID = `img-${thisIndex}`;
            document.getElementById(oldID)!.className = 'blank';
            const newID = `img-${nextItem}`;
            document.getElementById(newID)!.className = 'accent-svg';
        }

        beforeMount() {
            this.carousel = true;
            console.log('list of messages: ', this.listOfMessages);
            this.currentArticle = this.listOfMessages[0];
            if (this.currentArticle?.htmlText != null) {
                this.message = this.currentArticle.htmlText;
            }

        }

        mounted() {
            if (this.carousel) {
                this.carouselID = setInterval(this.NextPage, 5000); // carousel
            }
            else {
            }
        }

        unmounted() {
            clearInterval(this.carouselID);
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
        height: 80vh;
    }

    .modal-container {
        width: 50%;
        margin: 0px auto;
        margin-top: 50px;
        padding: 10px 12px 0px 12px;
        transition: all 0.3s ease;
        background-color: var(--background-primary);
        box-sizing: border-box;
        position: relative;
    }

    .modal-header {
        margin: -10px -12px 0px -12px;
        border-bottom-width: 0px;
        height: 5vh;
    }

    .modal-container.sm {
        width: 800px;
        max-width: 35%;
    }

    .modal-container.md {
        width: 800px;
        max-width: 40%;
    }

    .modal-container.lg {
        width: 80%;
        max-width: 50%;
        overflow-y: auto;
        height: 80vh;
    }

    .modal-body {
        margin: 0px 40px 0px 40px;
        height: calc(100% - 107px);
    }

    .modal-footer {
        border-top-width: 0px;
        bottom: 0px;
        position: absolute;
        width: 100%;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

    #whatsnewtitle {
        text-align: center;
    }

    #close {
        float: right;
        cursor: pointer;
    }

    #backbutton {
    }

        #backbutton:disabled {
            opacity: 0.3;
        }

    #nextbutton {
    }

        #nextbutton:disabled {
            opacity: 0.3;
        }

    #learnmoreimg {
    }

    #learnmoretext {
        font-weight: 500;
        color: #555555;
        padding: 0px 10px 0px 10px;
        align-items: center;
        display: flex;
    }

    .imghelper {
        display: flex;
        align-items: center;
    }

    .ellipse {
        width: 100%;
        height: 7%;
        display: flex;
        justify-content: center;
        align-content: center;
        gap: 9px;
        margin: auto;
        padding: 1% 0px 0px 0px;
    }

    .learnmore {
        display: flex;
        height: 6%;
        align-content: center;
        justify-content: center;
        padding: 1% 0px 0px 0px;
        cursor: pointer;
    }

    .center {
        margin: 0 auto;
    }

   
</style>
<style>
    .whatsnewhtml {
        height: calc(80% - 20px);
        overflow-y: auto;
    }

    .whatsnewhtml img {
        max-width: 100%;
    }

    .html {
        margin-top: 20px;
        width: 100%;
        max-width: 100%;
        border: none !important;
    }

    
</style>
