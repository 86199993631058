import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr'
import { AuthClient } from "../api/ApiClientBase";
import * as api from "./ApiClient";
import { ActionContext } from 'vuex';
import { IExplorerStore } from '../store/ExplorerStore';

export class ExplorerNotificationsHandler {

    private lastHandledNotification: string | null;
    private context: ActionContext<IExplorerStore, any>;
    private connection: HubConnection | null;

    constructor(context: ActionContext<IExplorerStore, any>) {
        this.lastHandledNotification = null;
        this.context = context;
        this.connection = null;
    }

    async start() {
        if (this.connection != null)
            return;

        var auth_client = new AuthClient();
        await auth_client.ensureToken();
        var client1 = new api.FileSystemClient(auth_client);
        await client1.setupNotifications();

        var client = new api.SignalRClient(auth_client);
        var data = await client.getSignalRConfig();
        this.connection = new HubConnectionBuilder()
            .withUrl(data.url!, { "accessTokenFactory": () => data.token! })
            .build();

        this.connection.on('FileSystem', (messageReceived, data: string) => { this.HandleNotification(JSON.parse(data)) });
        this.connection.start();

        this.connection.onclose(() => { if (this.connection != null) this.connection.start() });
    }

    stop() {
        if (this.connection != null) {
            var con = this.connection;
            this.connection = null;
            con.stop();
        }
    }

    public async HandleNotification(notification: api.FileSystemChangeNotification) {
        if (this.context.rootState.security.Tenant.name != notification.tenant)
            return; 

        if (this.lastHandledNotification == null || this.lastHandledNotification < notification.uid!)
            this.lastHandledNotification = notification.uid!;
        else {
            console.log("Notification " + notification.uid + " already handled; last id = " + this.lastHandledNotification);
            return;
        }
        var auth_client = new AuthClient();
        await auth_client.ensureToken();
        var client = new api.FileSystemClient(auth_client);

        var id = notification.id;
        var parentId = notification.parentId;
        var path = notification.path!;
        var name = notification.objectName!;

        switch (notification.type) {
            case api.ChangeType.Create:
            case api.ChangeType.Copy:
                var parent = this.context.getters.getFsoById(parentId) as api.FileSystemObjectContent;
                var path = parent.path + "/" + parent.name + "/" + notification.objectName;
                while (path.startsWith('//')) // fix for parent being the root
                    path = path.replace("//", "/"); 
                var newContent = await client.getFile(path, null, true);
                if (parent.folderContent == null)
                    parent.folderContent = [] as api.FileSystemObjectContent[];
                parent.folderContent.push(newContent);
                break;
            case api.ChangeType.Delete:
                var parent = this.context.getters.getFsoParentById(id) as api.FileSystemObjectContent;
                for (var i = 0; i < parent.folderContent!.length; i++) {
                    if (parent.folderContent![i].id === id) {
                        parent.folderContent!.splice(i, 1);
                        break;
                    }
                }
                break;
            case api.ChangeType.Rename:
                var parent = this.context.getters.getFsoParentById(id) as api.FileSystemObjectContent;
                for (var i = 0; i < parent.folderContent!.length; i++) {
                    if (parent.folderContent![i].id === id) {
                        parent.folderContent!.splice(i, 1);
                        break;
                    }
                }
                if (parent.path !== null && parent.name !== null) {
                    var path = parent.path + "/" + parent.name + "/" + notification.objectName;
                    while (path.startsWith('//')) // fix for parent being the root
                        path = path.replace("//", "/");
                    var newContent = await client.getFile(path, null, true);
                    parent.folderContent!.push(newContent);
                }
                break;
            case api.ChangeType.Move:
                var oldparent = this.context.getters.getFsoParentById(id) as api.FileSystemObjectContent;
                for (var i = 0; i < oldparent.folderContent!.length; i++) {
                    if (oldparent.folderContent![i].id === id) {
                        oldparent.folderContent!.splice(i, 1);
                        break;
                    }
                }

                var parent = this.context.getters.getFsoById(parentId) as api.FileSystemObjectContent;
                var path = parent.path + "/" + parent.name + "/" + notification.objectName;
                while (path.startsWith('//')) // fix for parent being the root
                    path = path.replace("//", "/");
                var newContent = await client.getFile(path, null, true);
                if (parent.folderContent == null)
                    parent.folderContent = [] as api.FileSystemObjectContent[];
                parent.folderContent.push(newContent);
                break;
            case api.ChangeType.Update:
                var fso = this.context.getters.getFsoById(id) as api.FileSystemObjectContent;
                if (fso.objectType == api.FileSystemType.Folder) {
                    var parent = this.context.getters.getFsoParentById(id) as api.FileSystemObjectContent;
                    for (var i = 0; i < parent.folderContent!.length; i++) {
                        if (parent.folderContent![i].id === id) {
                            parent.folderContent!.splice(i, 1);
                            break;
                        }
                    }
                    var path = fso.path + "/" + fso.name;
                    while (path.startsWith('//')) // fix for parent being the root
                        path = path.replace("//", "/");
                    var newContent = await client.getFile(path, null, true);
                    parent.folderContent!.push(newContent);
                }
                break;
        }
    }
}