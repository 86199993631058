import { GetterTree, MutationTree, Module } from 'vuex';
import { AuthClient } from '../api/ApiClientBase';
import * as ext from '../api/Extensions';
import * as api from '../api/ApiClient';
import { getDeepErrorMessage } from '../utils/Utils';

export class PagingInfo {
    etlLogHeaderId!: number;
    skip!: number;
    take!: number;
    recordType!: string;
}

export interface IEtlStore {
    IsBusy: boolean,
    SelectedFilterDimension: string | null,
    EtlLogHeaders: api.EtlLogHeaderInfo[] | null,
    EtlLogDetails: api.DrillbackDetailInfo | null,
    EtlLogInfo: api.EtlLogInfo[] | null,
    DataMappings: api.DataMappingInfo[] | null,
    DataMaps: api.DataMapInfo[] | null,
    AllModels: api.ModelInfo[] | null,
    SelectedDataMap: api.DataMapInfo | null,
    SelectedDataMapRoleWrite: api.RoleWriteObject[] | null,
    IsAddNewDataMap: boolean | false,
    InsideDataMapDetailsView: boolean | false,
    DataSlicers: any[] | null,
    Fields: any[] | null,
    FilteredDatabaseTables: api.SimpleKeyValue[] | null,
    FieldMappings: api.ModelFieldMappingInfo[] | null,
    ModelMappings: api.ModelMappingInfo[] | null,
    RoleWritesModelMappings: api.RoleWriteObject[] | null,
    DatabaseSchemas: string[] | null,
    DatabaseTables: any[] | null,
    SelectedModelMapping: api.ModelMappingInfo | null,
    IsAddNewModelMapping: boolean | false,
    InsideModelDetailsView: boolean | false,
    IsValidated: boolean | null,
    ValidationError: string | null,
    StagingTableSchema: string,
    StagingTables: api.StagingTableInfo[] | null,
    SelectedStagingTable: api.StagingTableInfo | null,
    StagingTableChangesConfirmed: boolean
}

const EtlStore: Module<IEtlStore, any> = {
    namespaced: true,
    state: {
        IsBusy: false,
        SelectedFilterDimension: null,
        EtlLogHeaders: null,
        EtlLogDetails: null,
        EtlLogInfo: null,
        DataMappings: null,
        DataMaps: null,
        AllModels: null,
        SelectedDataMap: null,
        SelectedDataMapRoleWrite: null,
        IsAddNewDataMap: false,
        InsideDataMapDetailsView: false,
        DataSlicers: null,
        Fields: null,
        FilteredDatabaseTables: null,
        FieldMappings: null,
        DatabaseSchemas: null,
        DatabaseTables: null,
        ModelMappings: null,
        RoleWritesModelMappings: null,
        SelectedModelMapping: null,
        IsAddNewModelMapping: false,
        InsideModelDetailsView: false,
        IsValidated: null,
        ValidationError: null,
        StagingTableSchema: "client",
        StagingTables: null,
        SelectedStagingTable: null,
        StagingTableChangesConfirmed: false
    },
    getters: {
        getDataMapByName: (state) =>
            (sourceName: string): api.DataMapInfo | undefined => {
                if (!state.DataMaps) {
                    return;
                }
                return state.DataMaps.find(dataMap => dataMap.sourceName === sourceName);
            }
    },
    mutations: {
        SetIsBusy(state, isBusy: boolean) {
            state.IsBusy = isBusy;
        },
        SetSelectedFilterDimension(state: any, name: string) {
            state.SelectedFilterDimension = name;
        },
        SetDataMappings(state: any, dataMappings: api.DataMappingInfo[]) {
            state.DataMappings = dataMappings;
        },
        SetEtlLogHeaders(state: any, etlLogHeaders: api.EtlLogHeaderInfo[]) {
            state.EtlLogHeaders = etlLogHeaders;
        },
        SetEtlLogDetails(state: any, etlLogDetails: api.DrillbackDetailInfo) {
            state.EtlLogDetails = etlLogDetails;
        },
        SetEtlLogInfo(state: any, etlLogInfo: api.EtlLogInfo[]) {
            state.EtlLogInfo = etlLogInfo;
        },
        SetDataMaps(state: any, dataMaps: api.DataMapInfo[]) {
            state.DataMaps = dataMaps;
        },
        SetAllModels(state: any, models: api.ModelInfo[]) {
            state.AllModels = models;
        },
        SetSelectedDataMap(state: any, dataMap: api.DataMapInfo) {
            state.SelectedDataMap = dataMap;
        },
        SetSelectedDataMapRoleWrite(state: any, dataMapRoleWrite: api.RoleWriteObject[]) {
            state.SelectedDataMapRoleWrite = dataMapRoleWrite;
        },
        SetIsInsideDataMapDetailsView(state: any, inside: boolean) {
            state.InsideDataMapDetailsView = inside;
        },
        SetIsAddNewDataMap(state: any, is: boolean) {
            state.IsAddNewDataMap = is;
        },
        SetDataSlicers(state: any, dataSlicers: any[]) {
            state.DataSlicers = dataSlicers;
        },
        SetFields(state: any, fields: any[]) {
            state.Fields = fields;
        },
        SetSecurityProfiles(state: any, profiles: string[]) {
            state.SecurityProfiles = profiles;
        },
        SetFilteredDatabaseTables(state: any, filteredDatabaseTables: api.SimpleKeyValue[]) {
            state.FilteredDatabaseTables = filteredDatabaseTables;
        },
        SetFieldMappings(state: any, fieldMappings: api.ModelFieldMappingInfo[]) {
            state.FieldMappings = fieldMappings;
        },
        SetDatabaseSchemas(state: any, databaseSchemas: string[]) {
            state.DatabaseSchemas = databaseSchemas;
        },
        SetDatabaseTables(state: any, databaseTables: api.SimpleKeyValue[]) {
            state.DatabaseTables = databaseTables;
        },
        SetModelMappings(state: any, modelMappings: api.ModelMappingInfo[]) {
            state.ModelMappings = modelMappings;
        },
        SetRoleWriteModelMappings(state: any, roleWriteModelMappings: api.RoleWriteObject[]) {
            state.RoleWritesModelMappings = roleWriteModelMappings;
        },
        SetSelectedModelMapping(state: any, modelMapping: api.ModelMappingInfo) {
            state.SelectedModelMapping = modelMapping;
        },
        SetIsInsideModelMappingDetailsView(state: any, insideModelMappingDetailsView: boolean) {
            state.InsideModelMappingDetailsView = insideModelMappingDetailsView;
        },
        SetIsAddNewModelMapping(state: any, isAddNewModelMapping: boolean) {
            state.IsAddNewModelMapping = isAddNewModelMapping;
        },
        SetIsInsideModelDetailsView(state: any, insideModelDetailsView: boolean) {
            state.InsideModelDetailsView = insideModelDetailsView;
        },
        SetIsValidated(state: any, isValidated: boolean) {
            state.IsValidated = isValidated;
        },
        SetValidationError(state: any, validationError: string) {
            state.ValidationError = validationError;
        },
        SetStagingTables(state, tables: api.StagingTableInfo[] | null) {
            state.StagingTables = tables;
        },
        SelectStagingTable(state, table: api.StagingTableInfo | null) {
            state.SelectedStagingTable = table;
        },
        SetStagingTableChangesConfirmed(state, val: boolean) {
            state.StagingTableChangesConfirmed = val;
        }
    },
    actions: {
        async LoadEtlLogHeaders(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var etlLogHeaders = await etlClient.getEtlLogHeader();
            context.commit("SetEtlLogHeaders", etlLogHeaders);
            context.commit("SetIsBusy", false);
        },
        async LoadEtlLogDetails(context, paging: PagingInfo) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var etlLogDetails = null;
            try {
                etlLogDetails = await etlClient.getEtlLogDetails(paging.take, paging.skip, paging.etlLogHeaderId,paging.recordType);
            } catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("EtlStore_Source_details_could_not_be_loaded") + " " + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            } finally {
                context.commit("SetEtlLogDetails", etlLogDetails);
                context.commit("SetIsBusy", false);
            }

        },
        async LoadEtlLogInfo(context, etlLogHeaderId: number) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var etlLogInfo = await etlClient.getEtlLogInfo(etlLogHeaderId);
            context.commit("SetEtlLogInfo", etlLogInfo);
            context.commit("SetIsBusy", false);
        },
        async LoadModelMappingData(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            // Get all field mappings, database schemas, database tables
            // in one round trip to the database.
            // This will then be filtered and stored
            var data = etlClient.getModelMappingData();
            context.commit("SetFields", (await data).fields);
            context.commit("SetDatabaseSchemas", (await data).schemas);
            context.commit("SetDatabaseTables", (await data).databaseTables);
            context.commit("SetModelMappings", (await data).modelMappings);
            context.commit("SetIsBusy", false);
        },

        async LoadModelFieldMappingsData(context, mappingId: number) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var fieldMappings = await etlClient.getModelFieldMappings(mappingId);
            var client1 = new api.MetadataClient(auth_client);
            var models = await client1.getModels();
            context.commit("SetAllModels", models);
            context.commit("SetFieldMappings", fieldMappings);
            context.commit("SetIsBusy", false);
        },

        // Load function clear data/data slicers
        async LoadClearData(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var dataSlicers = await etlClient.getDataSlicers();
            context.commit("SetDataSlicers", dataSlicers);
            context.commit("SetIsBusy", false);
        },

        async LoadDataMaps(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var dataMaps = await etlClient.getDataMaps();
            context.commit("SetDataMaps", dataMaps)
            context.commit("SetIsBusy", false);
        },

        async LoadDataMappings(context, sourceName: string) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var dataMappings = await etlClient.getMemberMappings(sourceName);
            context.commit("SetDataMappings", dataMappings)
            context.commit("SetIsBusy", false);
        },

        async LoadDataMapRoleWrite(context, selectedSourceName: string) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var dataMapRoleWrite = await etlClient.getDataMapRoleWrite(selectedSourceName);
            context.commit("SetSelectedDataMapRoleWrite", dataMapRoleWrite);
            context.commit("SetIsBusy", false);
        },

        async LoadModelMappingRoleWrite(context, selectedSourceName: string) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var etlClient = new api.EtlClient(auth_client);
            var modelMapRoleWrite = await etlClient.getModelMappingRoleWrite(selectedSourceName);
            context.commit("SetSelectedDataMapRoleWrite", modelMapRoleWrite);
            context.commit("SetIsBusy", false);
        },

        // Context any
        // Pair - the first one in the pair is the original model mapping and the second model mapping is the changed model mapping
        async SaveModelMapping(context: any, modelMappingDetails: ext.Pair<api.ModelMappingInfo>) {
            var modelName = modelMappingDetails.edited.modelName;
            var stagingTableName = modelMappingDetails.edited.stagingTableName;
            var stagingTableSchema = modelMappingDetails.edited.stagingTableSchema;

            var modelMappings = context.state.ModelMappings;

            if (modelMappings != null &&
                modelName != null &&
                stagingTableName != null &&
                stagingTableSchema != null) {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.EtlClient(auth_client);
                try {
                    if (modelMappingDetails.original === null) {
                        var saved = await client.createModelMapping(modelMappingDetails.edited);
                    }
                    else {
                        var saved = await client.updateModelMapping(modelMappingDetails.original.mappingId!, modelMappingDetails.edited);
                    }
                    var idx = -1;
                    for (var i = 0; i < modelMappings.length; i++) {
                        if (modelMappings[i].stagingTableName == stagingTableName) {
                            idx = i;
                            break;
                        }
                    }

                    if (idx > -1)
                        modelMappings.splice(idx, 1);
                    modelMappings.push(saved);
                    modelMappings.sort((a: any, b: any) => a.modelName!.localeCompare(b.modelName!));
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_Mapping_saved_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedModelMapping", null);
                    context.commit("SetIsAddNewModelMapping", false);
                    this.dispatch("etl/LoadModelMappingData");
                    context.commit("SetIsBusy", false);

                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_Mapping_could_not_be_saved_") + " " + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
        },

        async CopyModelMappingWithFieldMappings(context: any, modelMappingData: api.ModelMappingInfo) {
            var modelName = modelMappingData.modelName;
            var stagingTableName = modelMappingData.stagingTableName;
            var stagingTableSchema = modelMappingData.stagingTableSchema;

            var modelMappings = context.state.ModelMappings;

            if (modelMappings != null &&
                modelName != null &&
                stagingTableName != null &&
                stagingTableSchema != null) {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.EtlClient(auth_client);
                try {
                    if (modelMappingData.mappingId !== null) {
                        let origMappingId: number = modelMappingData.mappingId!;
                        var saved = await client.copyModelMapping(origMappingId, modelMappingData);

                        var idx = -1;
                        for (var i = 0; i < modelMappings.length; i++) {
                            if (modelMappings[i].stagingTableName == stagingTableName) {
                                idx = i;
                                break;
                            }
                        }

                        if (idx > -1)
                            modelMappings.splice(idx, 1);
                        modelMappings.push(saved);
                        modelMappings.sort((a: any, b: any) => a.modelName!.localeCompare(b.modelName!));
                        this.dispatch("ShowToast", new api.ToastNotification({
                            message: context.rootState.Localizer.Localize("ModelsStore_Model_Mapping_saved_successfully"),
                            type: api.ToastType.Info
                        }));
                        context.commit("SetSelectedModelMapping", null);
                        context.commit("SetIsAddNewModelMapping", false);
                        this.dispatch("etl/LoadModelMappingData");
                        context.commit("SetIsBusy", false);
                    }

                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_Mapping_could_not_be_saved_") + " " + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
        },

        async SaveModelFieldMappings(context: any, saveRequest: api.ModelFieldMappingInfo[]) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.EtlClient(auth_client);
            try {
                await client.saveFieldMappings(saveRequest);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("FieldMappingView_saved_successfully"),
                    type: api.ToastType.Info
                }));
            }
            catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ModelsStore_Field_Mappings_could_not_be_saved_") + " " + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async SaveDataImportlRoleWriteMappings(context: any, saveRequest: any) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.EtlClient(auth_client);
            try {
                let roleWriteObjects: api.RoleWriteObject[] = saveRequest.roleWriteObjects
                let sourceName = saveRequest.parentSourceName;
                await client.saveModelMapRoleWrite(roleWriteObjects, sourceName, 'modelmappings');
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("RoleWrite_saved_successfully"),
                    type: api.ToastType.Info
                }));
            }
            catch (err) {
                console.log(err);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("RoleWrite_could_not_be_saved") + " " + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async SaveDataMapRoleWriteMappings(context: any, saveRequest: any) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.EtlClient(auth_client);
            try {
                let roleWriteObjects: api.RoleWriteObject[] = saveRequest.roleWriteObjects.filter((role: api.RoleWriteObject) => role.roleName !== undefined);
                let sourceName = saveRequest.parentSourceName;
                await client.saveDataMapRoleWrite(roleWriteObjects, sourceName, 'datamaps');
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("RoleWrite_saved_successfully"),
                    type: api.ToastType.Info
                }));
            }
            catch (err) {
                console.log(err);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("RoleWrite_could_not_be_saved") + " " + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async SaveClearData(context: any, saveRequest: api.DataSlicerInfo[]) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.EtlClient(auth_client);
            try {
                await client.saveClearData(saveRequest);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ClearDataView_saved_successfully"),
                    type: api.ToastType.Info
                }));
            }
            catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: "Clear Data could not be saved: " + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async SaveDataMap(context: any, saveRequest: ext.Pair<api.DataMapInfo>) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.EtlClient(auth_client);
            try {
                await client.saveDataMap(saveRequest.edited);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("Data_Map_saved_successfully"),
                    type: api.ToastType.Info
                }));
            }
            catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("DataMap_Save_Not_Successful") + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async SaveDataMappings(context: any, saveRequest: api.DataMappingInfo[]) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.EtlClient(auth_client);
            try {
                await client.saveMemberMappings(saveRequest);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("MemberMappings_saved_successfully"),
                    type: api.ToastType.Info
                }));
            }
            catch (err) {
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("MemberMappings_Save_Not_Successful") + getDeepErrorMessage(err),
                    type: api.ToastType.Error
                }));
            }
        },

        async DeleteSelectedDataMap(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.EtlClient(auth_client);
            var dataMap = context.state.SelectedDataMap;
            if (dataMap != null) {
                try {
                    await client.deleteDataMap(dataMap.sourceName!);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("Data_Map_deleted_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedDataMap", null);
                    this.dispatch("etl/LoadDataMaps");
                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("DataMap_Delete_Not_Successful") + " " + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
        },

        async DeleteSelectedModelMapping(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.EtlClient(auth_client);
            var modelMapping = context.state.SelectedModelMapping;
            if (modelMapping != null) {
                try {
                    await client.deleteModelMapping(modelMapping.mappingId!);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_Mapping_deleted_successfully"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetSelectedModelMapping", null);
                }
                catch (err) {
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ModelsStore_Model_Mapping_could_not_be_deleted") + " " + getDeepErrorMessage(err),
                        type: api.ToastType.Error
                    }));
                }
            }
            this.dispatch("etl/LoadModelMappingData");
        },

        async LoadStagingTables(context) {
            try {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.EtlClient(auth_client);
                context.commit("SetStagingTables", await client.getStagingTables());
            }
            catch (err) {
                await this.dispatch("ShowToastError", err);
                throw err;
            }
        }
    },
};

export default EtlStore;
