<template>
    <Spinner v-if="isBusy" />
    <div v-else>
        <div v-if="!showAuditTrail" style="width:100%;" class="itemGrid">
            <div>
                <div class="item" @click="ValidateSaveData()">
                    <img src="/icons/excel/form/save_data_64.png" width="52" height="52">
                    <div>{{Localizer.Localize('Save_Data')}}</div>
                </div>
                <div class="item" @click="ParametersSelectionRequested = true">
                    <img src="/icons/excel/form/select_parameters_64.png" width="52" height="52">
                    <div>{{Localizer.Localize('Parameters')}}</div>
                </div>
                <div class="item" @click="RefreshFormRequested = true">
                    <img src="/icons/excel/form/refresh_64.png" width="52" height="52">
                    <div>{{Localizer.Localize('Refresh')}}</div>
                </div>
                <div class="item" @click="ShowAuditTrail()">
                    <img src="/icons/excel/form/audit_trail_64.png" width="52" height="52">
                    <div>{{Localizer.Localize('Audit_Trail')}}</div>
                </div>
                <div class="item" @click="RowAction('add')" v-bind:class="{ disabled: SelectedControl ? !SelectedControl.AddRows || disableBtn : true }">
                    <img src="/icons/excel/form/add_row_64.png" width="52" height="52">
                    <div>{{Localizer.Localize('Add_Row')}}</div>
                </div>
                <div class="item" @click="RowAction('delete')" v-bind:class="{ disabled: SelectedControl ? !SelectedControl.DeleteRows || disableBtn : true }">
                    <img src="/icons/excel/form/delete_row_64.png" width="52" height="52">
                    <div>{{Localizer.Localize('Delete_Row')}}</div>
                </div>
                <div class="item">
                    <img src="/icons/excel/form/line_item_64.png" width="52" height="52">
                    <div>{{Localizer.Localize('Line_Item_Details')}}</div>
                </div>
            </div>
        </div>
        <div v-else class="d-flex flex-column w-100 p-2">
            <template v-for="(item,i) in auditTrail" :key="i">
                <div :class="[{'active': i == selectedAuditTrailIndex}, 'd-flex justify-content-between']" @click="selectedAuditTrailIndex = i;" v-on:dblclick="OpenAuditTrailItem()" style="cursor: pointer">
                    <div><span>{{item.timestamp.toLocaleDateString()}} {{item.timestamp.toLocaleTimeString()}}</span></div>
                    <div><span>{{item.userDisplayName}}</span></div>
                    <div><span>Save</span></div>
                </div>
            </template>
            <div class="d-flex flex-direction-row">
                <button @click="showAuditTrail=false; selectedAuditTrailIndex=-1" class="btnViewer">Cancel</button>
                <button @click="OpenAuditTrailItem()" class="btnViewer btnViewerDefault">Open</button>
            </div>
        </div>
        <!--<transition name="fade">
            <div v-if="showAlert" class="d-flex justify-content-center">
                <div class="alert alert-success alert-dismissible">
                    Completed saving data.
                    <button type="button" @click="showAlert = !showAlert" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </transition>-->
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Watch, Vue } from 'vue-facing-decorator';
    import * as formControls from '../form/FormControls';
    import * as excelHandler from '../form/ExcelHandler';
    import { AuthClient } from "../../../../api/ApiClientBase";
    import * as api from "../../../../api/ApiClient";
    import { Localizer } from "../../../../api/Localizer";

    import Spinner from '../../../helpers/Spinner.vue';

    @Component({ components: { Spinner } })
    export default class FormView extends Vue {
        private Localizer = new Localizer(this.$store);
        disableBtn: boolean = false;
        isBusy: boolean = false;
        auditTrail: api.AuditEntryId[] = [];
        showAuditTrail: boolean = false;
        selectedAuditTrailIndex: number | null = null;
        //showAlert: boolean = false;

        get FormPath(): string { return this.$store.state.excel.FormPathOrId; }
        get TenantOverride(): string { return this.$store.state.excel.TenantOverride; }
        get SelectedRangeAddress(): string | null { return this.$store.state.excel.SelectedRangeAddress; }
        set SelectedRangeAddress(selectedRangeAddress: string | null) { this.$store.commit("excel/SetSelectedRangeAddress", selectedRangeAddress); }
        get SelectedRange(): Excel.Range | null { return this.$store.state.excel.SelectedRange; }
        set SelectedRange(selectedRange: Excel.Range | null) { this.$store.commit("excel/SetSelectedRange", selectedRange); }
        get SelectedControl(): formControls.TableControl | any | null { return this.$store.state.excel.SelectedControl; }
        set SelectedControl(selectedControl: formControls.TableControl | any | null) { this.$store.commit("excel/SetSelectedControl", selectedControl); }
        get ControlRanges(): formControls.GridRange[] | any | null { return this.$store.state.excel.ControlRanges; }
        get Parameters(): api.FormParameterList | null { return this.$store.state.excel.Parameters; }
        set Parameters(params: api.FormParameterList | null) { this.$store.commit("excel/SetParameters", params); }
        get ParametersSelectionRequested(): boolean | undefined { return this.$store.state.excel.ParametersSelectionRequested; }
        set ParametersSelectionRequested(parametersSelectionRequested: boolean | undefined) { this.$store.commit("excel/SetParametersSelectionRequested", parametersSelectionRequested); }
        get RefreshFormRequested(): boolean | undefined { return this.$store.state.excel.RefreshFormRequested; }
        set RefreshFormRequested(refreshFormRequested: boolean | undefined) { this.$store.commit("excel/SetRefreshFormRequested", refreshFormRequested); }
        get SelectedParameters(): api.FormParameterCurrentValue[] | [] { return this.$store.state.excel.SelectedParameters; }
        set SelectedParameters(selectedParameters: api.FormParameterCurrentValue[] | []) { this.$store.commit("excel/SetSelectedParameters", selectedParameters); }
        get Attachments(): excelHandler.AttachmentListItem[] | [] { return this.$store.state.excel.Attachments; }
        set Attachments(attachments: excelHandler.AttachmentListItem[] | []) { this.$store.commit("excel/SetAttachments", attachments); }

        mounted() {
            console.log(this.SelectedControl);
        }

        async ShowAuditTrail() {
            let auth_client = new AuthClient();
            await auth_client.ensureToken();
            let client = new api.FormClient(auth_client);
            // get the list of audit entries for the current form
            let entries = await client.getFormAuditEntries(parseInt(this.FormPath));
            // show audit trail in addin in new view
            this.auditTrail = entries;
            this.showAuditTrail = true;
        }

        async OpenAuditTrailItem() {
            if (this.selectedAuditTrailIndex !== null && this.selectedAuditTrailIndex >= 0) {
                // get the audit highlight file
                let auth_client = new AuthClient();
                await auth_client.ensureToken();
                let client = new api.FormClient(auth_client);
                if (this.auditTrail[this.selectedAuditTrailIndex]) {
                    var fileResponse = await client.getFormAuditHighlight(parseInt(this.FormPath), this.auditTrail[this.selectedAuditTrailIndex].id!);
                    // save to a temporary file
                    // Download file to local disk
                    if (fileResponse) {
                        var myBlob = fileResponse.data;
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(<File>myBlob);
                        let date = this.auditTrail[this.selectedAuditTrailIndex].timestamp.toLocaleDateString();
                        let time = this.auditTrail[this.selectedAuditTrailIndex].timestamp.toLocaleTimeString();
                        link.download = fileResponse.fileName ? fileResponse.fileName : `AuditTrail_${date}-${time}.xlsx`;
                        link.click();
                    }
                }
            }
        }

        async RowAction(action: string) {
            let vm = this;
            this.disableBtn = true;
            switch (action) {
                case 'add':
                    this.SelectedRangeAddress = await excelHandler.AddRow(this.SelectedRangeAddress!, this.SelectedControl, this.ControlRanges);
                    await this.SelectRange();
                    break;
                case 'delete':
                    await Excel.run(async (context) => {
                        var sheet = context.workbook.worksheets.getActiveWorksheet();
                        //JBTODO: check for SelectedRange
                        var selectedRange = sheet.getRange(this.SelectedRangeAddress!);
                        selectedRange!.load('rowCount');
                        await context.sync();
                        var rangeRowCount = selectedRange!.rowCount;
                        //delete more than 1 row at a time
                        if (rangeRowCount > 1) {
                            //prompt for multiple rows
                            var dialog: any = null;
                            var proceed: any = null;
                            await Office.context.ui.displayDialogAsync(window.location.origin + "/excel/multipleRowsDeleteDialog.html", { width: 20, height: 15 },
                                async (result) => {
                                    dialog = result.value;
                                    dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
                                        if (dialog) {
                                            dialog.close();
                                            proceed = arg.message;
                                            if (proceed === 'true') {
                                                this.SelectedRangeAddress = await excelHandler.DeleteRow(this.SelectedRangeAddress!, this.SelectedControl, this.ControlRanges);
                                                await this.SelectRange();
                                            }
                                        }
                                    });
                                    dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
                                        if (arg.error === 12006) {
                                            dialog.close();
                                        }
                                    });
                                },
                            );
                        }
                        else {
                            this.SelectedRangeAddress = await excelHandler.DeleteRow(this.SelectedRangeAddress!, this.SelectedControl, this.ControlRanges);
                            await this.SelectRange();
                        }
                    });
                    break;
                default:
                    break;
            }
            this.disableBtn = false;
        }

        async SelectRange() {
            let vm = this;
            await Excel.run(async (context) => {
                this.SelectedRange = context.workbook.worksheets.getActiveWorksheet().getRange(this.SelectedRangeAddress ? this.SelectedRangeAddress : undefined);
                this.SelectedRange.select();
                await context.sync();
            })
        }

        async ValidateSaveData() {
            let vm = this;
            //Show spinner to represent saving data
            this.isBusy = true;
            //JBTODO: if (FormVM.Validate()) {
            let isDirty: boolean = false;
            await Excel.run(async (context) => {
                const wb: Excel.Workbook = context.workbook;
                wb.load('isDirty');
                await context.sync();
                console.log(`Are there any changes to the form? ${wb.isDirty ? 'yes' : 'no'}`);
                isDirty = wb.isDirty;
            });
            if (!isDirty) {
                var dialog: any = null;
                var proceed: any = null;
                await Office.context.ui.displayDialogAsync(window.location.origin + "/excel/saveDataDialog.html", { width: 20, height: 15 },
                    // Dialog callback
                    (result) => {
                        dialog = result.value;
                        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                            proceed = arg.message;
                            if (dialog) {
                                dialog.close();
                            }
                            if (proceed === 'true') {
                                this.SaveData();
                            }
                            else this.isBusy = false;
                        });
                        dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
                            if (arg.error === 12006) {
                                dialog.close();
                                this.isBusy = false;
                            }
                        });
                    },
                );
            }
            else
                await this.SaveData();
        }

        async SaveData() {
            let vm = this;
            let refreshForm = {
                saveSuccessful: false,
                attachmentsUpdated: false,
                reloadAfterSave: false
            };
            this.isBusy = true;
            //Notify that upload is being performed
            await this.$store.dispatch("ShowToast", new api.ToastNotification({
                type: api.ToastType.Info,
                message: this.Localizer.Localize('FormView_Saving_Data_Cloud')
            }));
            try {
                let attachments = this.Attachments;
                refreshForm = await excelHandler.SaveData(this.ControlRanges, attachments);
                this.isBusy = false;
                if (refreshForm.saveSuccessful) {
                    if (refreshForm.attachmentsUpdated) {
                        await this.$store.dispatch("ShowToast", new api.ToastNotification({
                            type: api.ToastType.Success,
                            message: this.Localizer.Localize('FormView_Attachements_Updated')
                        }));
                        //clear Attachments array
                        this.Attachments = [];
                    }
                    await this.$store.dispatch("ShowToast", new api.ToastNotification({
                        type: api.ToastType.Success,
                        message: this.Localizer.Localize('FormView_Data_Saved')
                    }));
                    this.RefreshFormRequested = refreshForm.reloadAfterSave;
                }
                else {
                    throw "error";
                }
            } catch (e) {
                await this.$store.dispatch("ShowToast", new api.ToastNotification({
                    type: api.ToastType.Error,
                    message: this.Localizer.Localize('FormView_Error_Saving_Data')
                }));
            }
            //this.showAlert = true;
            //setTimeout(() => {
            //    this.showAlert = false;
            //}, 2000);
            this.isBusy = false;
        }

    }
</script>

<style scoped>
    .itemGrid {
        display: grid;
        grid-column: 1/4;
        grid-row: 2;
        border-bottom: 1px solid var(--thin-border);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .item {
        display: inline-grid;
        align-self: center;
        cursor: pointer;
        -ms-flex-line-pack: center;
        align-content: center;
        justify-items: center;
        padding: 14px;
        width: 100px;
        text-align: center;
    }

        .item.disabled {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
            color: var(--theme-color);
        }

    .active {
        background-color: #51d6bc;
    }

    /*.alert {
        position: absolute;
        padding: 0.75rem 1.25rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        bottom: 5px;
        margin-bottom: 0;
        width: 95%;
        max-width: 500px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }

    .alert-dismissible .close{
        padding: .1em .3em;
    }*/
</style>
