import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "XL3Container",
  ref: "report_container",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : (_ctx.needsLogIn)
        ? (_openBlock(), _createBlock(_component_Dialog, {
            key: 1,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.needsLogIn=false)),
            size: "lg",
            title: "Please sign in to your account.",
            message: "By clicking the sign in button you will be taken to the XL3 account sign in page to enter your credentials."
          }, {
            footer: _withCtx(() => [
              _createElementVNode("button", {
                class: "btnViewer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.needsLogIn=false))
              }, _toDisplayString(_ctx.Localizer.Localize("CANCEL")), 1),
              _createElementVNode("button", {
                class: "btnViewer btnViewerDefault",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.XL3LogIn && _ctx.XL3LogIn(...args)))
              }, _toDisplayString(_ctx.Localizer.Localize("SIGN IN")), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("iframe", {
            key: 2,
            style: {"width":"100%","height":"100%","background-color":"white"},
            src: _ctx.url
          }, null, 8, _hoisted_2))
  ], 512))
}