import { createStore } from 'vuex';
import { App } from 'vue'
import router from '../routing/Router'
import { useToast } from "vue-toastification";
import dashboard from "./DashboardStore";
import security from "./SecurityStore";
import explorer from "./ExplorerStore";
import workflow from "./WorkflowStore";
import contributor from "./ContributorStore";
import usermanagement from "./UserManagementStore";
import models from "./ModelsStore";
import etl from "./EtlStore";
import reportbook from "./ReportBookStore";
import forms from "./FormsStore";
import excel from "./ExcelStore";
import journals from "./JournalsStore";
import windowstabs from "./WindowsTabsStore";
import maintenance from "./MaintenanceStore";
import audittrail from "./AuditTrailStore";
import whatsnew from "./WhatsNewStore";
import chat from "./ChatStore";
import theme from "./ThemeStore"
import matching from "./MatchingUIStore";

import * as api from "../api/ApiClient";
import * as extensions from "../api/Extensions";
import Vue from "vue";
import { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { ToastNotificationsHandler } from '../api/ToastNotificationsHandler';
import { AuthClient } from '../api/ApiClientBase';
import { Localizer } from "../api/Localizer";
import { CssFilterFromColor, SetBrowserLogo } from "../components/helpers/ThemeHelper";

import Themes from "../api/Themes";
import UIStrings from '../api/UIStrings';
import { ToastOptions } from 'vue-toastification/dist/types/types';

import * as uiIntegration from '@/api/FluenceIntegration';

const toast = useToast();

export interface IGlobalStore {
    WindowWidth: number;
    WindowHeight: number;
    NotificationHandler: ToastNotificationsHandler | null;
    Log: extensions.ToastNotificationLogItem[],
    Theme: object | null;
    Locale: object;
    Localizer: Localizer | null;
    NewVersionDownloading: boolean;
    NewVersionDownloaded: boolean;
    HomeExtensionBreadcrumbs: uiIntegration.FluenceBreadcrumbMessage[]
}

const store = createStore<IGlobalStore>({
    modules: {
        security: security,
        explorer: explorer,
        workflow: workflow,
        contributor: contributor,
        usermanagement: usermanagement,
        models: models,
        etl: etl,
        theme: theme,
        dashboard: dashboard,
        reportbook: reportbook,
        forms: forms,
        excel: excel,
        journals: journals,
        windowstabs: windowstabs,
        maintenance: maintenance,
        audittrail: audittrail,
        whatsnew: whatsnew,
        chat: chat,
        matching
    },

    state: {
        WindowWidth: 0,
        WindowHeight: 0,
        NotificationHandler: null,
        Log: new Array<extensions.ToastNotificationLogItem>(),
        Theme: null,
        Locale: UIStrings,
        Localizer: null,
        NewVersionDownloading: false,
        NewVersionDownloaded: false,
        HomeExtensionBreadcrumbs: []
    },

    mutations: {
        SetWindowWidth(state, width: number) {
            state.WindowWidth = width;
        },
        SetWindowHeight(state, height: number) {
            state.WindowHeight = height;
        },
        SetNotificationHandler(state, notificationHandler: ToastNotificationsHandler | null) {
            if (state.NotificationHandler != null)
                state.NotificationHandler.stop();
            state.NotificationHandler = notificationHandler;
            if (state.NotificationHandler != null)
                state.NotificationHandler.start();
        },
        NewNotificationHandler(state) {
            var notificationHandler = new ToastNotificationsHandler(store);
            if (state.NotificationHandler != null)
                state.NotificationHandler.stop();
            state.NotificationHandler = notificationHandler;
            state.NotificationHandler.start();
        },
        UpdateNotificationLog(state, notification: extensions.ToastNotificationLogItem) {
            state.Log.push(notification);
        },
        SetTheme(state, theme: object) {
            state.Theme = theme;
            var themeBrowserLogo: string = theme["--browser-tab-logo" as keyof typeof theme];
            SetBrowserLogo(themeBrowserLogo)
        },
        SetLocales(state, translations: object) {
            state.Locale = translations;
        },
        SetLocalizer(state, localizer: Localizer) {
            state.Localizer = localizer;
        },
        SetNewVersionDoawnloading(state, value: boolean) {
            state.NewVersionDownloading = value;
        },
        SetNewVersionDoawnloaded(state, value: boolean) {
            state.NewVersionDownloaded = value;
        }
    },

    actions: {
        async NewLocalizer(context) {
            context.commit("SetLocalizer", new Localizer(context));
        },
        async CacheCleanup(context) {
            context.commit("security/SetTenant", null);
            context.commit("security/SetUserData", null);

            context.commit("explorer/SetFileSystem", null);
            context.commit("explorer/SetFavorites", null);
            context.commit("explorer/SelectFso", null);
            context.commit("explorer/SetPasteOperationDetails", null);
            context.commit("explorer/SetPasteTargetFso", null);
            context.commit("explorer/SetNotificationHandler", null);
            context.commit("explorer/SetXL3DefaultServer", null);
            context.commit("explorer/SetXL3DefaultStandaloneServer", null);

            context.commit("contributor/SetMyActivities", null);
            context.commit("contributor/SetSelectedActivity", null);
            context.commit("contributor/SetSelectedActivityDetails", null);

            context.commit("usermanagement/SetUsers", null);
            context.commit("usermanagement/SetRoles", null);
            context.commit("usermanagement/SetSelectedUser", null);
            context.commit("usermanagement/SetSelectedRole", null);
            context.commit("usermanagement/SetUsersShort", null);

            context.commit("workflow/ClearWorkflowInstanceVariables");
            context.commit("workflow/SetAvailableDataSources", null);

            context.commit("maintenance/ClearMaintenanceInstanceVariables");

            context.commit("windowstabs/ClearTabs", null);

            context.commit("audittrail/SetSelectedAuditTrail", null);

            context.commit("chat/ResetBots", []);
            context.commit("chat/setArticles", null);
        },

        async SetCustomTheme(context, { theme, defaultTheme }: { theme: string, defaultTheme: boolean }) {
            var themeObj: { [key: string]: string; } = {};
            var defaultThemeObj = JSON.parse(JSON.stringify(Themes.ThemeLight));
            if (!defaultTheme) {
                var auth_client = new AuthClient();
                var themeClient = new api.ThemeClient(auth_client);
                var themeData: api.ThemeInfo = await themeClient.getTheme(theme);
                if (themeData != null && themeData.isCurrentTheme) {
                    if (themeData.themeCustomSettings != null && themeData.themeCustomSettings.length > 0) {
                        themeData.themeCustomSettings.forEach(setting => {
                            if (setting.elementKey != null && setting.elementKey.trim() != '')
                                themeObj[setting.elementKey!] = setting.elementKey.endsWith("-svg") ? CssFilterFromColor(setting.elementSetting!) : setting.elementSetting!;
                        });
                    }
                }
            }

            // If keys are missing from db, use default values instead
            for (const setting of Object.keys(Themes.ThemeLight)) {
                if (themeObj[setting] == null) {
                    themeObj[setting] = setting.endsWith("-svg") ? CssFilterFromColor(defaultThemeObj[setting]) : defaultThemeObj[setting];
                }
            }

            var storedTenantName = localStorage.getItem("tenantName");
            if (storedTenantName) {
                context.commit("theme/SetThemeInLocalStorage", themeObj);
            }
            context.commit("theme/SetActiveThemeSettings", themeObj);
            context.commit("theme/SetPreviewedTheme", null);
            context.commit("theme/SetPreviewedThemeSettings", null);
            context.commit("SetTheme", themeObj);
        },

        async PreviewCustomTheme(context, { theme }: { theme: any }) {
            var themeObj: { [key: string]: string; } = JSON.parse(JSON.stringify(theme));
            var defaultThemeObj = JSON.parse(JSON.stringify(Themes.ThemeLight));

            // If keys are missing from db, use default values instead
            for (const setting of Object.keys(Themes.ThemeLight)) {
                if (themeObj[setting] == null) {
                    themeObj[setting] = defaultThemeObj[setting];
                }
            }

            for (const setting of Object.keys(themeObj)) {
                if (setting.endsWith("-svg")) {
                    themeObj[setting] = CssFilterFromColor(themeObj[setting])
                }
            }

            context.commit("SetTheme", themeObj);
        },

        async RemoveCustomThemePreview(context, { previouslyAppliedThemeSettings }: { previouslyAppliedThemeSettings: any }) {
            context.commit("SetTheme", previouslyAppliedThemeSettings);
        },

        async ShowToastCustom(context, { notification, options }: { notification: extensions.ToastNotificationLogItem, options: ToastOptions }) {
            if (notification.message == null) {
                return;
            }

            var defaultOptions = {
                position: POSITION.BOTTOM_RIGHT,
                timeout: notification.type == api.ToastType.Error ? 10000 : 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                icon: true,
                rtl: false,
            }
            Object.assign(defaultOptions, options);

            switch (notification.type) {
                case api.ToastType.Info:
                    toast(notification.message, defaultOptions);
                    break;
                case api.ToastType.Success:
                    toast.success(notification.message, defaultOptions);
                    break;
                case api.ToastType.Warning:
                    toast.warning(notification.message, defaultOptions);
                    break;
                case api.ToastType.Error:
                    toast.error(notification.message, defaultOptions);
                    break;
            }
            notification.dateTime = new Date();
            await context.dispatch("AddToastNotificationToLog", notification);
        },

        async ShowToast(context, notification: extensions.ToastNotificationLogItem) {
            await context.dispatch("ShowToastCustom", { notification: notification, options: {} });
        },

        async ShowToastError(context, err: Error) {
            let notificationLogItem = new extensions.ToastNotificationLogItem({
                message: "",
                type: api.ToastType.Error,
                dateTime: new Date(),
                deepMessage: "",
                stack: ""
            });
            if (err instanceof (api.ApiException) && err.response) {
                var response = JSON.parse(err.response);
                try {
                    // response.message is a string that should be of the form:
                    //      Exception: <the exception message>
                    //      Inner Exception: <the inner exception message >
                    // Let's try to parse it as such, and grab the exception message using a regex...
                    var messages = response.message as string;
                    var regex = /(?:Exception: )(?<message>.*)(?:\r\n|\r|\nInner Exception: )/g;
                    var matches = regex.exec(messages)!;
                    var message = matches.groups!["message"];

                    notificationLogItem.trackingNumber = response.trackingNumber;
                    notificationLogItem.deepMessage = response.deepMessage;
                    notificationLogItem.stack = err.stack;
                }
                catch {
                    // try response.deepMessage instead - it is the inner exception message
                    message = (response.message ?? response.title) ?? "An unexpected error occurred.";
                }
            }
            else {
                message = err.message;
            }

            notificationLogItem.message = (err as any).prefix ? (err as any).prefix + message : message;
            context.dispatch("ShowToast", notificationLogItem);
        },

        async AddToastNotificationToLog(context, notification: api.ToastNotification) {
            context.commit('UpdateNotificationLog', notification);
        },

        async SetLocalizationLanguage(context, locale?: string) {
            if (locale && locale != "Debug") {
                var translations = await new UIStrings().getLocaleStrings(locale);
                context.commit("SetLocales", translations);
            }
            else {
                var translations = await new UIStrings().getLocaleStrings('en');
                context.commit("SetLocales", translations);
            }
        },

        async SetCustomLocalizationLanguage(context, locales: string) {
            var defaults = await new UIStrings().getLocaleStrings('en');
            var translations = JSON.parse(locales);
            debugger;
            var finalText = {
                ...defaults,
                ...translations
            }
            context.commit("SetLocales", finalText);
        }
    },

});

export default store;
