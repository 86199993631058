import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c808dc84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-header" }
const _hoisted_2 = { style: {"padding-top":"10px","padding-right":"12px","padding-bottom":"6px","padding-left":"12px","width":"100%","height":"41px"} }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal-mask",
            style: _normalizeStyle(_ctx.maskCss)
          }, [
            _createElementVNode("div", {
              class: "modal-wrapper",
              style: _normalizeStyle(_ctx.wrapperCss)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(['modal-container', _ctx.containerStyle]),
                style: _normalizeStyle(_ctx.containerCss)
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "header", {}, () => [
                      _createTextVNode(_toDisplayString(_ctx.Localizer.Localize("Modal_default_header")), 1)
                    ], true),
                    _createElementVNode("img", {
                      src: "/icons/workflow/close.svg",
                      class: _normalizeClass(["theme-svg", {md:_ctx.size==='md'}]),
                      id: "close",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    }, null, 2),
                    (_ctx.Can_OpenHelp)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: "/icons/help.svg",
                          class: _normalizeClass(["theme-svg", {md:_ctx.size==='md'}]),
                          id: "help",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.OpenHelp && _ctx.OpenHelp(...args)))
                        }, null, 2))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "body", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.Localizer.Localize("Modal_default_body")), 1)
                  ], true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "footer", {}, () => [
                    _createTextVNode(" default footer "),
                    _createElementVNode("button", {
                      class: "modal-default-button",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
                    }, _toDisplayString(_ctx.Localizer.Localize("OK")), 1),
                    _createElementVNode("button", {
                      class: "modal-default-button",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
                    }, _toDisplayString(_ctx.Localizer.Localize("OK")), 1)
                  ], true)
                ])
              ], 6)
            ], 4)
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}