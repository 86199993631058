import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"float":"left","margin":"12px"} }
const _hoisted_2 = { style: {"padding-top":"15px","white-space":"normal"} }
const _hoisted_3 = {
  key: 0,
  style: {"max-height":"300px","padding":"20px","overflow":"auto"}
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Confirm = _resolveComponent("Confirm")!

  return (_ctx.Show)
    ? (_openBlock(), _createBlock(_component_Confirm, {
        key: 0,
        onClose: _ctx.Cancel
      }, {
        icon: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              class: "icon",
              style: _normalizeStyle({width: '50px', height:'50px', content: (_ctx.icon ? ('url('+_ctx.icon+')') : 'url(/icons/info.png)')})
            }, null, 4)
          ])
        ]),
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          (_ctx.innerHtml)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", { innerHTML: _ctx.innerHtml }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          (_ctx.nullButtonText!=null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "btnViewer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.Null && _ctx.Null(...args)))
              }, _toDisplayString(_ctx.nullButtonText), 1))
            : _createCommentVNode("", true),
          (_ctx.hideCancelButton!=true)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "btnViewer",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.Cancel && _ctx.Cancel(...args)))
              }, _toDisplayString(_ctx.cancelButtonText == null ? _ctx.Localizer.Localize('CANCEL'): _ctx.cancelButtonText), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "btnViewer btnViewerDefault",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.Accept && _ctx.Accept(...args)))
          }, _toDisplayString(_ctx.acceptButtonText == null ? _ctx.Localizer.Localize('OK'):_ctx.acceptButtonText), 1)
        ]),
        _: 1
      }, 8, ["onClose"]))
    : _createCommentVNode("", true)
}