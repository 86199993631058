import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fb609ea6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "avatarcontent"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar", {medavatar: _ctx.Size==null || _ctx.Size == 'medium', bigavatar:_ctx.Size == 'large', smallavatar:_ctx.Size == 'small'}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
  }, [
    (_ctx.ImageData==null || _ctx.ImageData.length<10)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.Initials), 1))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "avatarcontent",
          src: _ctx.ImageData
        }, null, 8, _hoisted_2))
  ], 2))
}