<template>
    <div style="width:100%;">
        <h2>Settings</h2>
        <div v-if="SelectedControl.AddRows">
            <button @click="RowAction('add')">Add row</button>
            <br />
        </div>
        <div v-if="SelectedControl.DeleteRows">
            <button @click="RowAction('delete')">Remove row</button>
            <br />
        </div>
        <div>
            <button @click="RowAction('back')">Back</button>
        </div>
    </div>
</template>

<script lang="ts">
    import { Prop, Vue } from 'vue-facing-decorator';
    import * as formControls from './form/FormControls';

    export default class ControlSettings extends Vue {

        get SelectedControl(): formControls.TableControl | any | null { return this.$store.state.excel.SelectedControl; }
        set SelectedControl(selectedControl: formControls.TableControl | any | null) { this.$store.commit("excel/SetSelectedControl", selectedControl); }
        get SelectedRangeAddress(): string | null { return this.$store.state.excel.SelectedRangeAddress; }
        set SelectedRangeAddress(selectedRangeAddress: string | null) { this.$store.commit("excel/SetSelectedRangeAddress", selectedRangeAddress); }

        controlAction: string | null = null;

        mounted() {
            console.log(this.SelectedControl);
        }

        async RowAction(action: string) {
            this.controlAction = action;
            this.$emit('RowAction', action);
        }
    }
</script>

<style scoped>
</style>
