import { GetterTree, MutationTree, Module } from 'vuex';
import { AuthClient } from "../api/ApiClientBase";
import * as ext from "../api/Extensions";
import * as api from "../api/ApiClient";

export class PagingInfo {
    skip!: number;
    take!: number
}

export interface IAuditTrailStore {
    MetadataAuditLogs: api.MetadataAuditLogResult | null;
    SelectedMetadataAuditTrail: api.MetadataAuditLogInfo | null;
}

const AuditTrailStore: Module<IAuditTrailStore, any> = {
    namespaced: true,
        state: {
            MetadataAuditLogs: null,
            SelectedMetadataAuditTrail: null
    },
    mutations: {
        SetAuditTrail(state, auditLogs: api.MetadataAuditLogResult) {
            state.MetadataAuditLogs = auditLogs;
        },
        SetSelectedAuditTrail(state, trail: api.MetadataAuditLogInfo) {
            state.SelectedMetadataAuditTrail = trail;
        }
    },
    actions: {
        async LoadMetadataAuditLogs(context, paging: api.FilteredSortedPaging) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.AuditTrailClient(auth_client);
            var logs = await client.getMetadataAuditLogs(paging);
            context.commit("SetAuditTrail", logs);
        }
    },
    getters: {

    }
};
export default AuditTrailStore;