export class ChatUser {
    public name: string;
    public initials: string;
    public userName: string | null;
    public history: ChatMessage[];
    public soft: string;
    public hint: string | null = null;

    constructor(name: string, initials: string, soft: string, userName: string | null = null) {
        this.name = name;
        this.initials = initials;
        this.userName = userName;
        this.soft = soft;
        this.history = [];
    }
}

export class ChatMessage {
    public message: string;
    public user: boolean;
    public isError: boolean = false;

    constructor(message: string, user: boolean) {
        this.message = message;
        this.user = user;
    }
}