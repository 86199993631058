<template>
    <transition name="modal">
        <div class="modal-mask" v-if="isVisible">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <slot name="header">
                            Information
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="icon">
                            default icon
                        </slot>
                        <slot name="body">
                            default body
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            default footer
                            <button class="modal-default-button" @click="$emit('close')">
                                OK
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component
    export default class Confirm extends Vue {
        public isVisible = false;

        mounted() {
            // Assign isVisible true on mounted as part of vue3 migration
            // Transition root https://v3-migration.vuejs.org/breaking-changes/transition-as-root.html
            this.isVisible = true;
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        max-width: 35%;
        margin: 0 auto;
        transition: all 0.3s ease;
    }

    .modal-header {
        padding: 6px;
        padding-left: 12px;
    }

    .modal-body {
        text-align: center;
        margin: 12px;
    }

    .modal-footer {
        justify-content: center;
        border: none;
    }

    .modal-enter-from {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

        .modal-enter-from .modal-container,
        .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
</style>
