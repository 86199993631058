<template>
    <div class="htmleditor rich-text-editor">
        <f-rich-text-editor
            class="f-rich-text-editor"
            :html="editorHtmlValue"
            :translator="translate"
            :has-image="true"
            @update:html="onRichTextEditor"
        />
        <div style="text-align:right">
            <a href="#" @click.prevent="ShowPopup">
                <img src="../../../../../../public/icons/workflow/open_subprocess.svg">
            </a>
        </div>
        <Modal v-if="PopupVisible" @close="HidePopup(false)">
            <template #header>
                <span class="title">{{Localizer.Localize('Edit')}}</span>
            </template>
            <template #body>
                <div>
                    <f-rich-text-editor
                        class="f-rich-text-editor"
                        :has-image="true"
                        :html="popupValue"
                        :translator="translate"
                        @update:html="newValue => popupValue = newValue" />
                </div>
            </template>
            <template #footer>
                <div class="btnViewer" @click="HidePopup(false)">
                    {{Localizer.Localize('CANCEL')}}
                </div>
                <div class="btnViewer btnViewerDefault" @click="HidePopup(true)">
                    {{Localizer.Localize('OK')}}
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
    import Modal from '../../../../helpers/Modal.vue';
    import * as api from '@/api/ApiClient';
    import { Localizer } from "@/api/Localizer";

    @Component({ components: { Modal }, emits: [ 'update:modelValue' ] })
    export default class HtmlEditor extends Vue {

        @Prop()
        private modelValue: string | undefined;

        public editorHtmlValue: string | undefined;

        public popupValue: string | null = '';

        private PopupVisible = false;

        private Localizer = new Localizer(this.$store);

        @Watch('modelValue')
        private onModelValue(value: string) {
            if (value === this.editorHtmlValue) {
                return;
            }
            this.editorHtmlValue = value || '';
        }

        mounted() {
            this.editorHtmlValue = this.modelValue;
        }

        private ShowPopup() {
            this.popupValue = this.editorHtmlValue ?? '';
            this.PopupVisible = true;
        }

        private HidePopup(saveChanges: boolean) {
            if (saveChanges) {
                this.$emit('update:modelValue', this.popupValue);
            }
            this.PopupVisible = false;
        }

        public onRichTextEditor(newValue: string) {
            //send new value to modelValue
            this.$emit('update:modelValue', newValue);
        }

        public translate(value: string):string {
            return this.Localizer.Localize(value);
        }
}

</script>

<style>

    .rich-text-editor [contenteditable="true"] {
        max-height: 250px;
        min-height: 160px;
        overflow: auto;
    }

    .rich-text-editor .f-rich-text-editor {
        max-width: 346px;
    }

    .rich-text-editor .modal-body [contenteditable="true"] {
        max-height: 350px;
        min-height: 250px;
    }

    .rich-text-editor .modal-body .f-rich-text-editor {
        max-width: 100%;
    }

    .f-rich-text-editor {
        background: var(--background-primary);
    }
</style>
