import { AuthClient } from "./ApiClientBase";
import * as api from "./ApiClient";

export class XLCubedHelper {

    private $store: any;
    private $route: any;

    constructor($store: any, route: any) {
        this.$store = $store;
        this.$route = route;
    }

    openNewTab = async (path: string, tenantName: string) => {
        var html = await this.getFormHtml(path,tenantName);

        var newWindow = window.open();
        newWindow?.document.write(html);
        newWindow?.document.forms[0].submit();

    }

    prepareRequestOpts = async (tenant : string) => {
        var auth_client = new AuthClient();
        await auth_client.ensureToken();
        var form = new FormData();

        form.append("UserToken", AuthClient.accessToken as string);
        form.append("Tenant", tenant);
        var reqOpts: RequestInit = {
            method: "POST",
            body: form,
            window: null
        };

        return reqOpts;
    }

    getParametersForUrl = (parameters: api.FormParameterList | undefined | null) => {
        var result = "";

        if (parameters && parameters != null) {
            parameters.currentValues?.forEach((i) =>
                result = result +
                encodeURIComponent(i.name!) + "=" + encodeURIComponent(i.currentValue?.key!) + "&");
        }

        return result.trimCharacter('&');
    }

    getExcelFile = async(path : string, tenantName : string, filename : string, parameters: api.FormParameterList | undefined | null = null) =>
    {
        var url = await this.getUrl(path, tenantName);
        url += "&XL3ReportType=ExcelLive";

        if(parameters)
        {
            url += "&" + this.getParametersForUrl(parameters);
        }

        var reqOpts = await this.prepareRequestOpts(tenantName);

        var response = await fetch(url, reqOpts);

        if (response.headers.get("content-type")?.toLowerCase().startsWith("text/html")) {
            var message = await response.text();
            console.log(message);
            throw new Error("Error while downloading excel file");
        }

        var blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename + ".xlsx";
        link.click();
        URL.revokeObjectURL(link.href);

    }

    getFormHtml = async (path: string, tenantName: string, additionalUrl?: string) =>
    {
        var url = await this.getUrl(path, tenantName);
        if (additionalUrl) {
            url+=additionalUrl;
        }

        var html = "<body onload='document.forms[0].submit()'>";
        html += "<form method='post' action='" + url + "'> ";
        if (AuthClient.accessToken) {
            html += ("<input name='UserToken' type='hidden' value='" + AuthClient.accessToken + "'/>");
        }
        html += ("<input name='Tenant' type='hidden' value='" + tenantName.replace("'","''") + "'/>");
        html += "<input type='submit' style='display:none;'/> </form></body>"
        return html;
    }

    getUrl = async(path : string, tenantName : string) =>
    {

        var auth_client = new AuthClient();
        await auth_client.ensureToken();
        var client1 = new api.FileSystemClient(auth_client);
        var fs = await client1.getFile(path, 'text', false);
        var url = fs.textContent;

        if (url == null || url.trim() == '') {
            await this.$store.dispatch("explorer/GetXL3DefaultServer");
            var template = this.$store.state.explorer.XL3DefaultServer as string;

            url = template.replace("{0}", encodeURIComponent(tenantName + '/' + fs.fullPath?.replace("'","%27")));
        }
        else if (!url?.startsWith("https://")) {
            await this.$store.dispatch("explorer/GetXL3DefaultStandaloneServer");
            var template = this.$store.state.explorer.XL3DefaultStandaloneServer as string;

            url = template.replace("{0}", url!);
        }

        if (this.$store.state.Localizer) {
            url += "&lang=" + this.$store.state.Localizer.LanguageCode;
        }

        if (this.$route.query) {
            Object.entries(this.$route.query).forEach(e => {
                if (e.length > 1) {
                    var parm: string = e[0];
                    var val: string = "";

                    if (e && e[1]) {
                        // @ts-ignore
                        val = e[1].toString();
                    }
                    url += "&" + encodeURIComponent(parm) + "=" + encodeURIComponent(val);
                }
            })
        }
        return url;

    }

}

export default XLCubedHelper;
