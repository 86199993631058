import * as formControls from './FormControls';
import SubstitutionDictionary from './SubstitutionDictionary';
import * as api from "../../../../api/ApiClient";
import { AuthClient } from '../../../../api/ApiClientBase';
import * as excelHandlerUtils from './ExcelHandler.Utils';
import * as excelHandler from './ExcelHandler';

export enum UserAction {
    AddRow,
    DeleteRow,
    ChangeRow
}

export interface AttachmentListItem {
    attachmentInfo: AttachmentInfo;
    file: string;
}

//Actions can be 'add', 'update', or 'remove'
export interface AttachmentInfo {
    id: number;
    target: Excel.Range | null;
    attachmentParams: Record<string, any>;
    fileName: string;
    buildElement: string;
    action: string;
}

export async function insertSheets(file: File): Promise<boolean> {
    var base64 = await ToBase64(file);
    var result = true;
    await Excel.run(async (context) => {

        // Retrieve the workbook.
        const workbook = context.workbook;

        workbook.worksheets.load();
        await context.sync();

        const sheets = workbook.worksheets;
        var n = sheets.items.length

        // Set up the insert options.
        var options = {
            sheetNamesToInsert: [], // Insert all the worksheets from the source workbook.
            positionType: Excel.WorksheetPositionType.end, // Insert after the `relativeTo` sheet.
        };

        console.log(`Inserting retrieved sheets`);

        //NOTES:
        // Workbook and worksheets are unprotected before load, all names are scoped to workbook.
        // If deleted, the names are not replaced on insertWorksheetsFromBase64, therefore we need to delete current names so new ones are added.
        // However, the names are set to invisible once deleted, so they need to be set to visible after insertWorksheetsFromBase64 is complete and synced.
        let names = context.workbook.names;
        names.load();
        await context.sync();
        for (let name of names.items) {
            name.delete();
        }
        await context.sync();

        // Insert the workbook.
        workbook.insertWorksheetsFromBase64(base64, options);
        await context.sync();

        // Set names to visible
        names = context.workbook.names;
        names.load('items/visible');
        await context.sync();
        for (let name of names.items) {
            name.visible = true;
        }
        await context.sync();

        for (var i = 0; i < n; i++) {
            const theSheet = sheets.items[i];

            console.log(`Deleting worksheet named "${theSheet.name}"`);
            theSheet.delete();
        }
        console.log(`Worksheets added.`);
        await context.sync();

        const controlSheet = workbook.worksheets.getItem('_Fluence (2)');
        if (controlSheet) {
            controlSheet.name = '_Fluence';
            console.log(`Renaming controlsheet to ${controlSheet.name}"`)
        }
        return context.sync();
    })
        .catch(function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
            result = false;
        });
    return result && unprotectSheets();
}

export async function unprotectSheets(): Promise<boolean> {
    var result = true;
    await Excel.run(async (context) => {
        let worksheets = context.workbook.worksheets;
        // Sheets come protected, unprotect them to be able to change values
        worksheets.load('items');
        await context.sync();
        for (var i in worksheets.items) {
            worksheets.items[i].protection.unprotect();
            //console.log(worksheets.items[i].name);
        }
        return context.sync();
    })
        .catch(function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
            result = false;
        });
    return result;
}

async function ToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            var result = reader.result as string;
            var base64 = result.replace(/^data:.*;base64,/, "");
            resolve(base64);
        }
        reader.readAsDataURL(file);
    });
}

export async function enableRibbonButton(buttonId: string) {
    try {
        await Office.ribbon.requestUpdate({
            tabs: [
                {
                    id: "FluenceJSTab",
                    controls: [
                        {
                            id: buttonId,
                            enabled: true,
                        },
                    ],
                },
            ],
        });
    } catch (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    }
}

export async function disableRibbonButton(buttonId: string) {
    try {
        OfficeExtension.config.extendedErrorLogging = true;
        await Office.ribbon.requestUpdate({
            tabs: [
                {
                    id: "FluenceJSTab",
                    controls: [
                        {
                            id: buttonId,
                            enabled: false,
                        },
                    ],
                },
            ],
        });
    } catch (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    }
}

export async function onTaskpaneVisibilityModeChanged() {
    await Office.addin.onVisibilityModeChanged(function (args: Office.VisibilityModeChangedMessage) {
        if (args.visibilityMode == "Taskpane") {
            // Code that runs whenever the task pane is made visible.
            disableRibbonButton("ShowTaskpane");
        }
        else if (args.visibilityMode == "Hidden") {
            // Code that runs whenever the task pane is hidden.
            enableRibbonButton("ShowTaskpane");
        }
    });
}

/** Adds row immediately below target range */
export async function AddRow(selectedRangeAddress: string, selectedControlRange: any, controlRanges: formControls.GridRange[]): Promise<string | null> {
    var activeCellAddress: string | null = null;
    await Excel.run(async (context) => {
        //disable all event handlers
        context.runtime.load("enableEvents");
        context.runtime.enableEvents = false;

        var targetWorkSheet = context.workbook.worksheets.getActiveWorksheet();
        if (selectedControlRange instanceof formControls.TableControl) {
            selectedControlRange = <formControls.TableControl>selectedControlRange;
        } else return;
        var dataRangeName = selectedControlRange.DataRowRange;
        var dataRange = <Excel.Range>targetWorkSheet.getRange(dataRangeName);
        var targetRowRange = dataRange.getRowsBelow(1).getEntireRow();
        targetRowRange.insert(Excel.InsertShiftDirection.down);
        targetRowRange = dataRange.getRowsBelow(1).getEntireRow();
        var targetDataRowRange = dataRange.getRowsBelow(1);
        targetDataRowRange.load('rowIndex, columnIndex');
        targetRowRange.load('rowIndex');
        await context.sync();
        //Select 1st cell in row newly created
        var fieldMappingRange = targetWorkSheet.getRange(selectedControlRange.FieldMappings[0].ColumnRangeName);
        var fieldColumn = fieldMappingRange.getColumn(0);
        fieldColumn.load('columnIndex');
        await context.sync();
        var fieldColumnIndex = fieldColumn.columnIndex;
        var activeCell = targetRowRange.getCell(0, fieldColumnIndex);
        activeCell.select();
        //Update action flag
        await UpdateActionFlag(targetRowRange, UserAction.AddRow, context)
        //Check for new format range
        let hasFormatRow: boolean = false;
        if (selectedControlRange.NewRowFormatRange != null) {
            const sheetCollection = context.workbook.worksheets.load();
            await context.sync();
            for (let sheet of sheetCollection.items) {
                let formatRange = sheet.getRange(selectedControlRange.NewRowFormatRange);
                formatRange.load('columnIndex, columnCount');
                await context.sync();
                if (formatRange) {
                    let rangeToBeFormatted = sheet.getRangeByIndexes(targetDataRowRange.rowIndex, targetDataRowRange.columnIndex, 1, formatRange.columnCount);
                    rangeToBeFormatted.copyFrom(formatRange, Excel.RangeCopyType.formats);
                    await context.sync();
                    hasFormatRow = true;
                    break;
                }
            }
        }
        if (!hasFormatRow) {
            let formatRange = dataRange.getLastRow();
            let rangeToBeFormatted = dataRange.getRowsBelow(1);
            rangeToBeFormatted.copyFrom(formatRange, Excel.RangeCopyType.formats);
            await context.sync();
        }
        //Update control ranges
        for (let i = 0; i < controlRanges.length; i++) {
            if (dataRangeName === controlRanges[i].DataRowRange) {
                controlRanges[i].LastRow = controlRanges[i].Row! + controlRanges[i].RowCount!;
                controlRanges[i].RowCount! += 1;
            }
        }
        //Update data range rows
        var names = context.workbook.names;
        names.load();
        dataRange.load('address, addressLocal, rowIndex, columnIndex, rowCount, columnCount');
        await context.sync();
        // Update named ranges
        for (let i in names.items) {
            let value = names.items[i].value.replace(/\$/g, '');
            if (dataRange.address === value) {
                names.items[i].delete();
                names.add(dataRangeName, targetWorkSheet.getRangeByIndexes(dataRange.rowIndex, dataRange.columnIndex, dataRange.rowCount + 1, dataRange.columnCount));
            }
        }
        await context.sync();
        //insert default values if exist for the field
        let fieldMappings = selectedControlRange.FieldMappings;
        for (let i = 0; i < fieldMappings.length; i++) {
            fieldMappingRange = targetWorkSheet.getRange(fieldMappings[i].ColumnRangeName);
            fieldColumn = fieldMappingRange.getColumn(0);
            fieldColumn.load('columnIndex');
            await context.sync();
            var fieldColumnIndex_2 = fieldColumn.columnIndex;
            for (let j = 0; j < fieldMappings[i].Fields.length; j++) {
                var field = fieldMappings[i].Fields[j];
                activeCell = targetRowRange.getCell(0, fieldColumnIndex_2);
                activeCell.load('values, formulas, rowIndex, columnIndex, rowCount, columnCount');
                await context.sync();
                //Clear contents if not formula cell before applying default value
                if (activeCell.values[0][0] && !(activeCell.formulas[0][0].toString().slice(0,1) === "=")) {
                    activeCell.values = [[null]];
                }
                if (field.DefaultValue != null) {
                    const dictionary = await excelHandlerUtils.GetSubstitutionDictionaryFromRange(activeCell, controlRanges);
                    if (dictionary) {
                        let substitutedMatchValue = dictionary.Substitute(field.DefaultValue)
                        if (substitutedMatchValue !== '') {
                            activeCell.values = [[substitutedMatchValue]];
                        }
                        else activeCell.values = [[field.DefaultValue]];
                    }
                }
                fieldColumnIndex_2++;
            }
            activeCell = targetRowRange.getCell(0, fieldColumnIndex);
        }
        activeCell.load('address');
        context.runtime.enableEvents = true;
        await context.sync();
        activeCellAddress = activeCell.address;
    })
        .catch(function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });
    //return activeCell's address so add/remove row buttons are properly enabled/disabled in the Form tab
    return activeCellAddress;

}

/** Deletes selected rows */
export async function DeleteRow(selectedRangeAddress: string, selectedControl: any, controlRanges: formControls.GridRange[]): Promise<string | null> {
    var activeCellAddress: string | null = null;
    await Excel.run(async function (context) {
        //disable all event handlers
        context.runtime.load("enableEvents");
        context.runtime.enableEvents = false;
        var targetWorkSheet = context.workbook.worksheets.getActiveWorksheet();
        var sheet = context.workbook.worksheets.getActiveWorksheet();
        var selectedRange = <Excel.Range>sheet.getRange(selectedRangeAddress);
        var activeCell = selectedRange.getCell(0, 0);
        activeCell.load('rowIndex');
        var dataRangeName = selectedControl.DataRowRange;
        var dataRange = <Excel.Range>targetWorkSheet.getRange(dataRangeName);
        dataRange.load('columnIndex');
        //delete more than 1 row at a time
        selectedRange.load('rowIndex, rowCount');
        await context.sync();
        var targetRowRange = selectedRange.getRow(0).getEntireRow();
        var rangeRowCount = selectedRange.rowCount;
        for (let i = 0; i < rangeRowCount; i++) {
            targetRowRange = selectedRange.getRow(i).getEntireRow();
            targetRowRange.select();
            targetRowRange.rowHidden = true;
            await context.sync();
            var actionCell = targetRowRange.getCell(0, 0);
            // set first cell for row below to active after row deleted
            var fieldMappingRange = targetWorkSheet.getRange(selectedControl.FieldMappings[0].ColumnRangeName);
            var fieldColumn = fieldMappingRange.getColumn(0);
            fieldColumn.load('columnIndex');
            await context.sync();
            var fieldColumnIndex = fieldColumn.columnIndex;
            if (actionCell) {
                actionCell.load('rowIndex, values');
                await context.sync();
                activeCell = targetRowRange.getCell(1, fieldColumnIndex);
                activeCell.load('rowIndex, columnIndex');
                if (actionCell.values[0][0] === "new" || actionCell.values[0][0] === "inserted") {
                    targetRowRange.delete("Up");
                    await context.sync();
                    // updating control ranges
                    for (let i = 0; i < controlRanges.length; i++) {
                        if (dataRangeName === controlRanges[i].DataRowRange) {
                            controlRanges[i].RowCount! -= 1;
                            controlRanges[i].LastRow = controlRanges[i].Row! + controlRanges[i].RowCount! - 1;
                        }
                    }
                    //since row is deleted index must be reduced by one and selectedRange must be updated
                    i--;
                    rangeRowCount--;
                    selectedRange = <Excel.Range>sheet.getRange(selectedRangeAddress);
                    await context.sync();
                }
                else if (actionCell.values[0][0] !== "deleted") {
                    //if ActionFlag is "deleted" skip the row, for every other case update flag
                    await UpdateActionFlag(targetRowRange, UserAction.DeleteRow, context);
                }
                activeCell.select();
            }
        }
        //after going through all lines, skip all cells with actionFlag 'deleted'
        activeCell = sheet.getRangeByIndexes(activeCell.rowIndex-1, activeCell.columnIndex, 1, 1);
        activeCell.load('address');
        await context.sync();
        actionCell = activeCell.getEntireRow().getCell(0, 0);
        actionCell.load('values');
        await context.sync();
        var skip = true;
        while (skip) {
            if (actionCell.values[0][0] === "deleted") {
                activeCell = activeCell.getRowsBelow(1);
                actionCell = activeCell.getEntireRow().getCell(0, 0);
                actionCell.load('values');
                await context.sync();
            }
            else
                skip = false;
        }
        activeCell.load('address');
        activeCell.select();
        context.runtime.enableEvents = true;
        await context.sync();
        activeCellAddress = activeCell.address;
    })
        .catch (function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });
    //return activeCell's address so add/remove row buttons are properly enabled/disabled in the Form tab
    return activeCellAddress;
}

export function GetCellControlRange(selectedRange: Excel.Range, controlRanges: formControls.GridRange[]): formControls.GridRange | null
{
    for (let i = 0; i < controlRanges.length; i++) {
        if (selectedRange.columnIndex >= controlRanges[i].Column! &&
            selectedRange.columnIndex + selectedRange.columnCount - 1 <= controlRanges[i].Column! - 1 + controlRanges[i].ColumnCount! &&
            selectedRange.rowIndex >= controlRanges[i].Row! &&
            selectedRange.rowIndex + selectedRange.rowCount - 1 <= controlRanges[i].Row! - 1 + controlRanges[i].RowCount!
        ) {
            return controlRanges[i];
        }
    }
    return null;
}

export function GetCellControlRangeByWorksheet(ws: Excel.Worksheet, row: number, column: number, controlRanges: formControls.GridRange[]): formControls.GridRange {
    return controlRanges.filter(gridRange => {
        return gridRange.SheetName === ws.name && row >= gridRange.Row! && row <= gridRange.LastRow!
            && column >= gridRange.Column! && column <= gridRange.LastColumn!;
    })[0];
}

export function GetCellControlRangeByRange(cell: Excel.Range, controlRanges: formControls.GridRange[]): formControls.GridRange | null {
    return (cell == null) ? null : GetCellControlRangeByWorksheet(cell.worksheet, cell.rowIndex, cell.columnIndex, controlRanges);
}

export async function UpdateActionFlag(row: Excel.Range, action: UserAction, context: Excel.RequestContext) {
    var actionCell = row.getEntireRow().getCell(0, 0);
    if (actionCell === null)
        return;
    actionCell.load('values');
    await context.sync();
    if (action == UserAction.AddRow) {
        // user has added a new row
        // new rows are not saved to the database unless the user enters some data on the new row
        actionCell.values = [['new']];
    }
    else {
        switch (actionCell.values[0][0]) {
            case "modified":
                // if the row is deleted, change the value to deleted
                if (action == UserAction.DeleteRow)
                    actionCell.values = [["deleted"]];
                break;
            case "new":
                if (action == UserAction.ChangeRow) {
                    // user is modifying a brand new row, which means the row should now be saved to the database
                    actionCell.values = [["inserted"]];
                }
                // if deleting a new or inserted row, the row will be physically deleted so no need to update the action flag
                break;
            case "inserted":
                // changing an inserted row will not result in a change of status
                // deleting an instered row will result in a physical removal, so no need to change the status
                break;
            case "lid":
                if (action == UserAction.DeleteRow) {
                    actionCell.values = [["liddeleted"]];
                }
                else if (action == UserAction.ChangeRow) {
                    actionCell.values = [["lidmodified"]];
                }
                break;
            case "lidnew":
                if (action == UserAction.ChangeRow) {
                    actionCell.values = [["lidinserted"]];
                }
                break;
            case "lidinserted":
                break;
            case "lidmodified":
                if (action == UserAction.DeleteRow) {
                    actionCell.values = [["liddeleted"]];
                }
                break;
            default:    // empty string or missing altogether
                if (action == UserAction.DeleteRow) {
                    actionCell.values = [["deleted"]];
                }
                else if (action == UserAction.ChangeRow) {
                    actionCell.values = [["modified"]];
                }
                break;
        }
    }
    await context.sync();
}

export async function GetActionFlag(address: string): Promise<string>{
    let actionFlag: string = ''
    await Excel.run(async (context) => {
        let cell = context.workbook.worksheets.getActiveWorksheet().getRange(address).getEntireRow().getCell(0, 0);
        cell.load('values');
        await context.sync();
        actionFlag = cell.values[0][0];
    });
    return actionFlag;
};

export async function SetDate(date: Date | string, rangeAddress: string) {
    await Excel.run(async (context) => {
        var cellRange = context.workbook.worksheets.getActiveWorksheet().getRange(rangeAddress);
        if (cellRange) {
            cellRange.values = [[date]];
            await context.sync();
            console.log('changed the date value in the cell');
        }
    })
        .catch(function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });
}

export async function SetItem(item: any, rangeAddress: string) {
    await Excel.run(async (context) => {
        var cellRange = context.workbook.worksheets.getActiveWorksheet().getRange(rangeAddress);
        if (cellRange) {
            cellRange.values = [[item.toString()]];
            await context.sync();
            console.log('changed the value in the cell');
        }
    })
        .catch(function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });
}

/** Save form data back to the database  */
export async function SaveData(controlRanges: formControls.GridRange[], attachments?: excelHandler.AttachmentListItem[], password?: string): Promise<{saveSuccessful: boolean, attachmentsUpdated: boolean, reloadAfterSave: boolean}> {
    let returnObj = {
        saveSuccessful: false,
        attachmentsUpdated: false,
        reloadAfterSave: false
    }
    try {
        await updateControlSheet(password);
        //check for attachments
        var auth_client = new AuthClient();
        await auth_client.ensureToken();
        var client = new api.FormClient(auth_client);
        if (attachments && attachments.length > 0) {
            for (let att of attachments) {
                switch (att.attachmentInfo.action) {
                    case 'add':
                        try {
                            let uploadResultAdd = await client.uploadAttachment(att.attachmentInfo.id, att.attachmentInfo.fileName, att.file);
                            //replace attachment file name by id returned from server to prepare for save form
                            //file.Key.target.Value2 = uploadResult;
                            await Excel.run(async (context) => {
                                let sheets = context.workbook.worksheets.load('items/name');
                                await context.sync();
                                let sheet = sheets.items.find(sheet => sheet.name === att.attachmentInfo.target!.worksheet.name);
                                if (sheet) {
                                    let target = sheet.getRange(att.attachmentInfo.target!.address);
                                    target.load('values');
                                    await context.sync();
                                    target.values = [[uploadResultAdd]];
                                    await context.sync();
                                }
                            });
                        } catch (e) {
                            throw e;
                        }
                        break;
                    case 'update':
                        try {
                            let uploadResult = await client.uploadAttachment(att.attachmentInfo.id, att.attachmentInfo.fileName, att.file);
                            await client.updateFormAttachment(att.attachmentInfo.id, att.attachmentInfo.buildElement, uploadResult, att.attachmentInfo.attachmentParams);
                        } catch (e) {
                            throw e;
                        }
                        break;
                    case 'remove':
                        try {
                            await client.removeAttachment(att.attachmentInfo.id, att.attachmentInfo.buildElement, att.attachmentInfo.attachmentParams);
                        } catch (e) {
                            throw e;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        //perform the save
        await getDocumentAsCompressedAndSave();
        returnObj.saveSuccessful = true;
    } catch (e) {
        throw e;
    }

    if (returnObj.saveSuccessful) {
        await Excel.run(async (context) => {
            var wb: Excel.Workbook = context.workbook;
            wb.load('names');
            await context.sync();
            var names: Excel.NamedItemCollection = wb.names;
            let namedRange = names.items.find(name => name.name === "Form.ReloadAfterSave");
            returnObj.reloadAfterSave = namedRange ? (namedRange.value === "True" ? true : false) : false;
        });
        if (returnObj.reloadAfterSave) {
            //set return object with flags for reloadAfterSave, and if any changes to attachments happened for notifications
            return returnObj;
        }
        else {
            // Set updated attachments file names
            if (attachments && attachments.length > 0) {
                for (let att of attachments) {
                    if (att.attachmentInfo.action === 'add') {
                        //file.Key.target.Value2 = uploadResult;
                        await Excel.run(async (context) => {
                            context.runtime.load("enableEvents");
                            context.runtime.enableEvents = false;
                            let sheets = context.workbook.worksheets.load('items/name');
                            await context.sync();
                            for (let sheet of sheets.items) {
                                if (sheet.name === att.attachmentInfo.target!.worksheet.name) {
                                    let target = sheet.getRange(att.attachmentInfo.target!.address);
                                    target.load('values');
                                    await context.sync();
                                    target.values = [[att.attachmentInfo.fileName]];
                                    await context.sync();
                                }
                            }
                            // enable change handler again
                            context.runtime.load("enableEvents");
                            context.runtime.enableEvents = true;
                        });
                    }
                }
                returnObj.attachmentsUpdated = true;
            }
            //cleanup action column, remove deleted rows
            await AfterSaveCleanup(controlRanges);
        }
    }
    return returnObj;
}

async function AfterSaveCleanup(controlRanges: formControls.GridRange[]) {
    let sheetNames: string[] = [];
    await Excel.run(async (context) => {
        let sheets = context.workbook.worksheets;
        sheets.load('items/name')
        await context.sync();
        for (let sheet of sheets.items) {
            sheet.load('name');
            sheetNames.push(sheet.name);
        }
        await context.sync();
    }).catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
    // Iterate through all worksheets
    for (let sheetName of sheetNames) {
        // skip the control sheet
        if (sheetName == "_Fluence")
            continue;
        await CleanupSheet(sheetName, controlRanges);
    }
    // update control ranges since data may have shifted
    //SetupControlRanges();
}

async function CleanupSheet(worksheet: string, controlRanges: formControls.GridRange[]) {
    // Delete all rows marked as deleted
    // Leave rows marked as "new" alone
    // Clear action flags for all other rows
    //UnprotectSheet(worksheet);
    //Get the tablecontrols in the workbook
    for (let range of controlRanges) {
        if (range.ObjectControl instanceof formControls.TableControl) {
            await CleanupRange(worksheet, range.ObjectControl!.DataRowRange!);
        }
    }
    //if (MatrixControls != null) {
    //    foreach(var matrixControl in MatrixControls)
    //    {
    //        foreach(var dataRowRangeName in matrixControl.DataRowRanges)
    //        {
    //            CleanupRange(worksheet, dataRowRangeName);
    //        }
    //    }
    //}
    //if (PivotTableControls != null) {
    //    foreach(var ptControl in PivotTableControls)
    //    {
    //        foreach(var dataRowRangeName in ptControl.DataRowRanges)
    //        {
    //            CleanupRange(worksheet, dataRowRangeName);
    //        }
    //    }
    //}
    //ProtectSheet(worksheet);
}

/**
 *  Clean up the flag on a given range
 *  @param worksheet - Worksheet to process
 *  @param rangeName - Range to process
 *  @returns 1 to advance to the next row number, 0 to keep processing the same row number (if the current row was deleted)
 * */
async function CleanupRange(worksheet: string, rangeName: string) {
    await Excel.run(async (context) => {
        //make sure changes we're making won't trigger events
        context.runtime.load("enableEvents");
        context.runtime.enableEvents = false;
        let ws = context.workbook.worksheets.getItem(worksheet);
        let dataRowRange = ws.getRange(rangeName);
        dataRowRange.load('rowIndex, rowCount');
        await context.sync();
        let rowIndex = dataRowRange.rowIndex;
        let rowCount = dataRowRange.rowCount;
        if (dataRowRange) {
            for (let row = 0; row < rowCount; ++row) {
                let targetRowRange = dataRowRange.getRow(row).getEntireRow();
                await context.sync();
                let actionFlag = targetRowRange.getCell(0, 0);
                actionFlag.load('values');
                await context.sync();
                // if the flag is not set, continue
                if (!actionFlag)
                    continue;
                var actionFlagValue = actionFlag.values[0][0];
                if (actionFlagValue === "deleted") {
                    // delete the current row
                    targetRowRange.delete('Up');
                    await context.sync();
                    // and process again the same row number
                    row--;
                    rowCount--;
                }
                else if (actionFlagValue === "new") {
                    // new rows stay new
                }
                else {
                    // modfied or inserted - clear the flag
                    actionFlag.values = [['']];
                    await context.sync();
                }
                dataRowRange = ws.getRange(rangeName);
                await context.sync();
            }
        }
        context.runtime.load("enableEvents");
        context.runtime.enableEvents = true;
        await context.sync();
    }).catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function updateControlSheet(password?: string) {
    //Updates the control sheet with the controls that are required to save data
    await Excel.run(async (context) => {
        const wb: Excel.Workbook = context.workbook;
        const controlSheet = wb.worksheets.getItem('_Fluence');
        const controlRange = controlSheet.getUsedRange().load('rowCount, columnCount');
        const lastRowRange = controlRange.getLastRow().load('rowIndex');
        await context.sync();
        let lastRow = lastRowRange.rowIndex;
        // delete all existing control rows from the form
        if (lastRow >= 2) {
            let range: Excel.Range = controlSheet.getRangeByIndexes(1, 0, controlRange.rowCount - 1, controlRange.columnCount);
            await context.sync();
            range.delete('Up');
            await context.sync();
            console.log(`Control rows deleted`);
        }
        // now add current controls
        var currentControlRow = 1;
        //JBTODO: do password and matrix
        if (password) {
            let passwordRange = controlSheet.getRangeByIndexes(1, 1, 1, 1).getEntireRow();
            passwordRange.load('values');
            await context.sync();
            console.log(passwordRange.values);
            //passwordRange.values[0][0] = "Password";
            //controlSheet.Cells[currentControlRow, 2].Value2 = Password;
            //currentControlRow++;
        }
        //        if (MatrixControls != null) {
        //            foreach(var mc in MatrixControls)
        //    {
        //        ControlSheetAddObjectControl(controlSheet, currentControlRow++, "Matrix", mc);
        //    }
        //}
        //        ProtectSheet(controlSheet);

        return context.sync();

    })
        .catch(function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
            throw error;
        });
        //}
}

function getDocumentAsCompressedAndSave() {
    Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 /*64 KB*/ },
        async function (result) {
            if (result.status.toString() == "succeeded") {
                // If the getFileAsync call succeeded, then
                // result.value will return a valid File Object.
                var myFile = result.value;
                var sliceCount = myFile.sliceCount;
                var slicesReceived = 0, gotAllSlices = true, docdataSlices: any = [];
                console.log("File size:" + myFile.size + " #Slices: " + sliceCount);

                // Get the file slices.
                try {
                    await getSliceAsync(myFile, 0, sliceCount, gotAllSlices, docdataSlices, slicesReceived);
                } catch (e) {
                    throw e;
                }
            }
            else {
                console.log("Error:", result.error.message);
            }
        });
}

async function getSliceAsync(file: any, nextSlice: any, sliceCount: any, gotAllSlices: any, docdataSlices: any, slicesReceived: any) {
    try {
        await file.getSliceAsync(nextSlice, async function (sliceResult: any) {
            if (sliceResult.status == "succeeded") {
                if (!gotAllSlices) { // Failed to get all slices, no need to continue.
                    return;
                }

                // Got one slice, store it in a temporary array.
                // (Or you can do something else, such as
                // send it to a third-party server.)
                docdataSlices[sliceResult.value.index] = sliceResult.value.data;
                if (++slicesReceived == sliceCount) {
                    // All slices have been received.
                    file.closeAsync();
                    await onGotAllSlices(docdataSlices);
                }
                else {
                    getSliceAsync(file, ++nextSlice, sliceCount, gotAllSlices, docdataSlices, slicesReceived);
                }
            }
            else {
                gotAllSlices = false;
                file.closeAsync();
                console.log("getSliceAsync Error:", sliceResult.error.message);
                throw "getSliceAsync Error: " + sliceResult.error.message;
            }
        });
    } catch (e) {
        throw e;
    }
}

async function onGotAllSlices(docdataSlices: any) {
    var docdata: any = [];
    for (var i = 0; i < docdataSlices.length; i++) {
        docdata = docdata.concat(docdataSlices[i]);
    }

    var blob = new Blob(
        [new Uint8Array(docdata)],
        { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
    );

    var reader = new FileReader();
    var externalWorkbook;
    try {
        reader.onload = (async (event) => {
            await Excel.run(async (context) => {
                // Remove the metadata before the base64-encoded string.
                var startIndex = reader!.result!.toString().indexOf("base64,");
                externalWorkbook = reader!.result!.toString().substr(startIndex + 7);
                // start save operation
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.FormClient(auth_client);
                var response = await client.saveFormData(externalWorkbook);
                return context.sync();
            }).catch(function (error) {
                console.log("Error: " + error);
                if (error instanceof OfficeExtension.Error) {
                    console.log("Debug info: " + JSON.stringify(error.debugInfo));
                }
                throw error;
            });
        });
        reader.readAsDataURL(blob);
    } catch (e) {
        throw e;
    }
}

async function GetFormConfig(tenantOverride: string): Promise<{tenantOverride: string, idOrPath: string}> {
    // first check that the current workbook is a Fluence form
    var idOrPath: string = '';
    var tenant: string = tenantOverride;
    await Excel.run(async (context) => {
        var wb: Excel.Workbook = context.workbook;
        wb.load('names');
        await context.sync();
        var names: Excel.NamedItemCollection = wb.names;
        for (let name of names.items) {
            if (name.name === "Form.Id") {
                idOrPath = name.value;
                // if we found both id and tenant, break
                if (tenant) {
                    break;
                }
            }
            else if (name.name === "Form.Tenant") {
                tenant = name.value;
                // if we found both id and tenant, break
                if (idOrPath) {
                    break;
                }
            }
        }
    });
    if (idOrPath || tenant) {
        // Form.Id and Form.Tenant must be in the format ="x" with x being an integer
        if (idOrPath.startsWith("=\"") && idOrPath.endsWith("\"") && idOrPath.length >= 4)
            idOrPath = idOrPath.substring(2, idOrPath.length - 3);

        if (tenant.startsWith("=\"") && tenant.endsWith("\"") && tenant.length >= 4)
            tenant = tenant.substring(2, tenant.length - 3);
    };
    return {
        tenantOverride: tenant,
        idOrPath: idOrPath
    };
};
