<template>
    <div class="m-3">
        <DateControl v-if="hasDateControl"></DateControl>
        <ListControl v-if="hasListControl"></ListControl>
        <TreeControl v-if="hasTreeControl"></TreeControl>
        <AttachmentControl v-if="hasAttachmentControl"></AttachmentControl>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Vue } from 'vue-facing-decorator';
    import * as formControls from '../form/FormControls';
    import * as excelHandler from '../form/ExcelHandler';
    import DateControl from '../DateControl.vue';
    import ListControl from '../ListControl.vue';
    import TreeControl from '../TreeControl.vue';
    import AttachmentControl from '../AttachmentControl.vue';

    @Component({ components: { DateControl, ListControl, TreeControl, AttachmentControl } })
    export default class ElementView extends Vue {
        get SelectedControl(): formControls.TableControl | any | null { return this.$store.state.excel.SelectedControl; }
        get ControlRanges(): formControls.GridRange[] | null { return this.$store.state.excel.ControlRanges; }
        get SelectedRange(): Excel.Range | any | null { return this.$store.state.excel.SelectedRange; }
        get SelectedEditControl(): formControls.EditControl | null { return this.$store.state.excel.SelectedEditControl; }
        set SelectedEditControl(selectedEditControl: formControls.EditControl | null) { this.$store.commit("excel/SetSelectedEditControl", selectedEditControl); }

        hasDateControl: boolean = false;
        hasListControl: boolean = false;
        hasTreeControl: boolean = false;
        hasAttachmentControl: boolean = false;

        @Watch('SelectedRange') async onRangeChange() {
            await this.load();
        }

        async created() {
            await this.load();
        }

        async load() {
            let vm = this;
            this.hasDateControl = false;
            this.hasListControl = false;
            this.hasTreeControl = false;
            this.hasAttachmentControl = false;
            await Excel.run(async (context) => {
                if (this.SelectedControl instanceof formControls.TableControl) {
                    let selectedWorksheet = context.workbook.worksheets.getActiveWorksheet();
                    selectedWorksheet.load('name');
                    await context.sync();
                    var sheetControlRanges = this.ControlRanges!.filter((range: any) => range.SheetName === selectedWorksheet.name);
                    var controlRangeIndex: any = null;
                    var controlRange = <formControls.GridRange>excelHandler.GetCellControlRange(this.SelectedRange!, sheetControlRanges);
                    if (controlRange) {
                        controlRangeIndex = controlRange.ColumnRangeIndex;
                    }
                    var controlRangeEditControls = this.SelectedControl.EditControls!.filter((range: any) => range.RangeIndex === controlRangeIndex);
                    var editControl: any = null;
                    await context.sync();
                    for (let j = 0; j < controlRangeEditControls.length; ++j) {
                        if (this.SelectedRange.columnIndex === (sheetControlRanges[controlRangeIndex].Column! + controlRangeEditControls[j].FieldIndex)) {
                            editControl = controlRangeEditControls[j];
                        }
                    }
                    if (editControl) {
                        this.SelectedEditControl = editControl;
                        switch (editControl.ControlType) {
                            case 'Date':
                                this.hasDateControl = true;
                                break;
                            case 'List':
                                this.hasListControl = true;
                                break;
                            case 'Tree':
                                this.hasTreeControl = true;
                                break;
                            case 'Attachment':
                                this.hasAttachmentControl = true;
                                break;
                            default:
                                break;
                        }
                    }
                }
            });
        }
    }
</script>
