<template>
    <ThemeLoader>
        <div v-if="Username ===''" id="app" style="background-color: var(--background-primary);">
            <Login style="margin:0 auto; padding:50px" />
        </div>
        <div v-else-if="ServerSelectionNeeded" style="background-color: var(--background-primary);">
            <ChooseServer style="margin:0 auto; padding:50px" />
        </div>

        <div v-else id="app">
            <Header />
            <Breadcrumb />
            <Sidebar />

            <div id="main" style="background-color: var(--background-primary);">
                <ChooseTenant v-if="Tenant === null" />
                <router-view v-else />
            </div>
            <div id="PopupsContainer">
                <WindowsTab v-for="tab in Tabs" :key="tab.Key" :TabData="tab" />
            </div>
            <portal-target name="DialogsContainer" multiple />
        </div>
    </ThemeLoader>

</template>

<script lang="ts">
    import { Component, Watch, Vue as vue } from 'vue-facing-decorator';

    import 'uicomponents/dist/style.css';
    import '@vue-js-cron/light/dist/light.css';
    import 'ag-grid-community/styles/ag-grid.css';
    import 'ag-grid-community/styles/ag-theme-alpine.css';
    import '@/assets/uicomponent-theme-override.css';

    import ThemeLoader from './components/ThemeLoader.vue';
    import Header from './components/Header.vue';
    import Breadcrumb from './components/Breadcrumb.vue';
    import Sidebar from './components/Sidebar.vue';
    import Login from './components/Login.vue';
    import ChooseTenant from './components/ChooseTenant.vue';
    import ChooseServer from './components/ChooseServer.vue';
    import WindowsTab from './components/helpers/WindowsTab.vue';
    import * as api from './api/ApiClient';
    import { Localizer } from './api/Localizer';
    import { Portal, PortalTarget } from 'portal-vue';
    import { WindowsTabData } from './api/WindowsTabData';

    @Component({
        components: {
            Header, Breadcrumb, Sidebar, Login, ChooseTenant, ChooseServer, ThemeLoader, PortalTarget, WindowsTab
        }
    })
    export default class App extends vue {
        private Localizer = new Localizer(this.$store);
        get Username(): string {
            return this.$store.state.security.UserName;
        }
        get Tenants(): api.Tenant[] | undefined {
            return this.$store.state.security.Tenants;
        }
        get Tenant(): api.Tenant | undefined {
            return this.$store.state.security.Tenant;
        }
        set Tenant(tenant: api.Tenant | undefined) {
            this.$store.commit('security/SetTenant', tenant);
        }

        get ServerSelectionNeeded() {
            return this.$store.state.security.ServerSelectionNeeded;
        }

        get Tabs(): WindowsTabData[] | undefined {
            return this.$store.state.windowstabs.Tabs;
        }

        async mounted() {
            this.$nextTick(() => {
                window.addEventListener('newversiondownloaded', async () => {
                    await this.$store.dispatch('ShowToastCustom', {
                        notification: new api.ToastNotification({
                            message: this.Localizer.Localize('AppVue_New_Version_Available'),
                            type: api.ToastType.Info
                        }),
                        options: { timeout: false }
                    });
                });

                window.addEventListener('newversiondownloading', async () => {
                    await this.$store.dispatch('ShowToast', new api.ToastNotification({
                        message: this.Localizer.Localize('AppVue_New_Version_Downloading'),
                        type: api.ToastType.Info
                    }));
                });

                window.addEventListener('resize', this.onResize);
                this.onResize();
            });

            //not sure why this.$route.query doesn't always contain the proper values.
            const overrideLang = localStorage.getItem('UserLocaleOverride');
            let userLang = overrideLang == undefined ? 'en' : overrideLang;
            let queryString = window.location.search;
            const queryStringHasLang = queryString != null && queryString.indexOf('lang=') > 0;
            if (queryStringHasLang) {
                queryString = queryString.substring(queryString.indexOf('lang=') + 'lang='.length);
                if (queryString.indexOf('&') > 0) {
                    queryString = queryString.substring(0, queryString.indexOf('&'));
                }
                this.$store.dispatch('SetLocalizationLanguage', queryString);
            }
            else {
                if (overrideLang == undefined) {
                    if (navigator.languages && navigator.languages.length > 0) {
                        userLang = navigator.languages[0];
                    }
                    else {
                        userLang = navigator.language || 'en';
                    }
                }
            }
            this.$store.dispatch('SetLocalizationLanguage', userLang);
        }

        @Watch('Tenants') async OnTenantsChange(val: api.Tenant[] | undefined) {
            await this.InitializeTenant(val);
        }

        async InitializeTenant(val: api.Tenant[] | undefined) {
            if (this.Tenant == null) {
                let queryString = decodeURIComponent(window.location.search);
                if (queryString != null && queryString.indexOf('tenant=') > 0) {
                    queryString = queryString.substring(queryString.indexOf('tenant=') + 'tenant='.length);
                    if (queryString.indexOf('&') > 0) {
                        queryString = queryString.substring(0, queryString.indexOf('&'));
                    }
                    // eslint-disable-next-line no-console
                    console.log(`Tenant found. ${queryString}`);

                    for (let i = 0; i < val!.length; i++) {
                        if (val![i].name == queryString) {
                            await this.$store.dispatch('security/Login', queryString);
                            return;
                        }
                    }
                }

                const storedTenantName = localStorage.getItem('tenantName');
                if (storedTenantName !== null && this.Tenants) {
                    const tenant = this.Tenants.find(t => t.name === storedTenantName);
                    if (tenant) {
                        await this.$store.dispatch('security/SelectTenant', tenant);
                    }
                }
            }
        }

        @Watch('Tenant') OnTenantChange(val: api.Tenant | undefined) {
            if (val && val.name) {
                localStorage.setItem('tenantName', val.name);
            }
            else {
                localStorage.setItem('tenantName', '');
            }
        }

        beforeUnmount() {
            window.removeEventListener('resize', this.onResize);
        }

        onResize() {
            this.$store.commit('SetWindowWidth', window.innerWidth);
            this.$store.commit('SetWindowHeight', window.innerHeight);
        }
    }
</script>

<style>
    #app {
        font-family: proxima-nova;
        font-size: 14px;
        color: var(--foreground-primary) !important;
        padding: 0px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    #main {
        position: absolute;
        top: 86px;
        right: 0;
        bottom: 0;
        left: 60px;
        overflow-y: auto;
        scroll-behavior: smooth;
    }

    #PopupsContainer {
        position: absolute;
        top: 86px;
        right: 0;
        bottom: 0;
        left: 60px;
        pointer-events: none;
        overflow: hidden;
        z-index: 9999;
    }

        #PopupsContainer > * {
            pointer-events: all;
        }

    input, select, textarea {
        background-color: var(--background-primary);
        color: var(--foreground-primary);
        stroke: var(--thin-border);
    }

    .page-enter-active, .page-leave-active {
        transition: all .1s ease;
    }

    .page-leave-to {
        transform: translateX(-10px);
        opacity: 0;
    }

    .page-enter-from {
        transform: translateX(10px);
        opacity: 0;
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .btn {
        display: inline-block;
    }
</style>
