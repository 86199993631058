import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c08c6a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "node" }
const _hoisted_2 = {
  key: 0,
  class: "children"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeParameterNode = _resolveComponent("TreeParameterNode", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["node-main", {selected: _ctx.IsSelected}]),
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.Value_Click()), ["stop"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["expander", {expanded: _ctx.IsExpanded, collapsed: !_ctx.IsExpanded, hidden: !_ctx.HasChildren}]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Expander_Click && _ctx.Expander_Click(...args)), ["stop"]))
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass(["label", {disabled: _ctx.DisplayOnly}])
      }, _toDisplayString(_ctx.Label), 3)
    ], 2),
    _createVNode(_Transition, { name: "fso-expanded" }, {
      default: _withCtx(() => [
        (_ctx.IsExpanded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_TransitionGroup, {
                name: "fso-expanded",
                tag: "span"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Children, (child, i) => {
                    return (_openBlock(), _createBlock(_component_TreeParameterNode, {
                      key: i,
                      Definition: child
                    }, null, 8, ["Definition"]))
                  }), 128))
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}