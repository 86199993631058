import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr'
import { AuthClient } from "./ApiClientBase";
import * as api from "./ApiClient";
import { ActionContext, Store } from 'vuex';
import { IGlobalStore } from '../store/GlobalStore';

export class ToastNotificationsHandler {

    private context: Store<any>;
    private connection: HubConnection | null;

    constructor(context: Store<any>) {
        this.context = context;
        this.connection = null;
    }

    async start() {
        if (this.connection != null)
            return;

        var auth_client = new AuthClient();
        await auth_client.ensureToken();

        var client = new api.SignalRClient(auth_client);
        var data = await client.getSignalRConfig();
        this.connection = new HubConnectionBuilder()
            .withUrl(data.url!, { "accessTokenFactory": () => data.token! })
            .build();

        this.connection.on('ToastNotification', (messageReceived, data: string) => { this.HandleNotification(JSON.parse(data)) });
        this.connection.start();

        this.connection.onclose(() => { if (this.connection != null) this.connection.start() });
    }

    stop() {
        if (this.connection != null) {
            var con = this.connection;
            this.connection = null;
            con.stop();
        }
    }

    public async HandleNotification(notification: api.ToastNotification) {
        this.context.dispatch("ShowToast", notification);
    }
}