<template>
    <Spinner v-if="isBusy" />
    <div v-else>
        <span class="modalTitle">Select Item</span>
        <div class="d-flex">
            <input type="text"
                   :disabled="TreeParameterNodes===null || TreeParameterNodes.length===0  || null"
                   placeholder="Search"
                   v-model="SearchPattern" />
            <button class="ml-3 btnViewerDefault"
                    style="width: 90px; border-radius: 5px"
                    @click="updateControlLabel()">
                {{TreeControlLabel}}
            </button>
        </div>

        <div class="modalContent mt-3">
            <transition-group name="fso" tag="span">
                <TreeItem v-for="(node, i) in RootNodes"
                          :key="key(i)"
                          :Definition="node"
                          :Search="SearchPattern"
                          v-show="showSearchNodes(node)" />
            </transition-group>
        </div>

        <div class="btnViewer btnViewerDefault" @click="SetItem()">
            OK
        </div>
        <!--<button @click="buildJSONTree(JSONtree)">buildJSONTree</button>-->
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Vue } from 'vue-facing-decorator';
    import * as formControls from './form/FormControls';
    import * as excelHandler from './form/ExcelHandler';
    import * as excelHandlerUtils from './form/ExcelHandler.Utils';
    import * as api from "../../../api/ApiClient";
    import { AuthClient } from '../../../api/ApiClientBase';
    import SubstitutionDictionary from './form/SubstitutionDictionary';
    import HierarchicalTreeNode, { TreeNodeDefinition as NodeDefinition, TreeNodeValue } from "./form/HierarchicalTreeNode";
    import TreeItem from "./TreeItem.vue";
    import Spinner from '../../helpers/Spinner.vue';

    @Component({ components: { TreeItem, Spinner }})
    export default class TreeControl extends Vue {

        get ControlRanges(): formControls.GridRange[] | null { return this.$store.state.excel.ControlRanges; }
        get SelectedRange(): Excel.Range | any | null { return this.$store.state.excel.SelectedRange; }
        get SelectedRangeAddress(): string | null { return this.$store.state.excel.SelectedRangeAddress; }
        get SelectedEditControl(): formControls.EditControl | null { return this.$store.state.excel.SelectedEditControl; };
        get FormPath(): string | null { return this.$store.state.excel.FormPathOrId; }
        get TreeControlLabel(): string { return this.$store.state.excel.TreeControlLabel };
        set TreeControlLabel(treeControlLabel: string) { this.$store.commit("excel/SetTreeControlLabel", treeControlLabel); };
        get SelectedTreeNode(): TreeNodeValue { return this.$store.state.excel.SelectedTreeNode };
        set SelectedTreeNode(selectedTreeNode: TreeNodeValue) { this.$store.commit("excel/SetSelectedTreeNode", selectedTreeNode); };

        nodeList: any[] = [];
        RootNodes: NodeDefinition[] | null = null;
        isBusy: boolean = true;
        JSONtree: HierarchicalTreeNode[] = [];
        labelArr: string[] = ['Name', 'Caption', 'Key'];

        SearchPattern: string = "";

        @Watch("SelectedRangeAddress") async onItemChange() {
            await this.UpdateItem();

        }

        @Watch("TreeParameterNodes") async onTreeParameterNodesChange() {
            if (this.TreeParameterNodes) {
                this.RootNodes = this.TreeParameterNodes.filter(node => node.IsRoot);
            }
            else
                this.RootNodes = null;
        }

        showSearchNodes(node: NodeDefinition) {
            if (this.SearchPattern) {
                return node.IsMatch;
            }
            return true;
        }

        updateControlLabel() {
            let vm = this;
            this.TreeControlLabel = this.labelArr.indexOf(this.TreeControlLabel) === 2 ? this.labelArr[0] : this.labelArr[this.labelArr.indexOf(this.TreeControlLabel) + 1];
        }
        async created() {
            //Set whether to show name or caption. If Key, hide the name
            switch (this.SelectedEditControl!.DisplayField) {
                case "Caption":
                    this.TreeControlLabel = this.labelArr[1];
                    break;
                case "Name":
                    this.TreeControlLabel = this.labelArr[0];
                    break;
                case "Key":
                    this.TreeControlLabel = this.labelArr[2];
                    break;
                default:
                    this.TreeControlLabel = this.labelArr[0];
                    break;
            }
            await this.UpdateItem();
            var selectedNodeName: string = '';
            await Excel.run(async (context) => {
                var range = context.workbook.worksheets.getActiveWorksheet().getRange(this.SelectedRangeAddress!);
                await context.sync();
                if (range) {
                    range.load('values');
                    await context.sync();
                    if (range.values[0][0])
                        selectedNodeName = range.values[0][0];
                }
            })
            var selectedNode = this.TreeParameterNodes!.find(node => {
                return node.Value.caption === selectedNodeName || node.Value.name === selectedNodeName;
            });
            if (selectedNode) {
                this.SelectedTreeNode = selectedNode.Value;
            };
            this.isBusy = false;
        }

        async UpdateItem() {
            this.isBusy = true;
            var vm = this;
            var editControl: formControls.EditControl | null = new formControls.EditControl();
            var dictionary: SubstitutionDictionary<string> | null = null;
            dictionary = await excelHandlerUtils.GetSubstitutionDictionaryFromRange(null, this.ControlRanges!);
            await Excel.run(async (context) => {
                let selectedRange = context.workbook.getSelectedRange();
                selectedRange.load('text, worksheet, rowIndex, columnIndex');
                await context.sync();
                editControl = await excelHandlerUtils.GetCellEditControl(selectedRange.worksheet, selectedRange.rowIndex, selectedRange.columnIndex, this.ControlRanges!);
                Office.addin.showAsTaskpane();
                await context.sync();
            });
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            let client = new api.FormClient(auth_client);
            let response: api.QueryResult[] = [];
            if (editControl) {
                try {
                    if (editControl.SourceType === "DimensionQuery")
                        response = await client.runDimensionQuery(parseInt(this.FormPath!), editControl.SourceName!, dictionary!.ToStringArray()!);
                    if (editControl.SourceType === "RelationalQuery")
                        response = await client.runRelationalQuery(parseInt(this.FormPath!), editControl.SourceName!, dictionary!.ToStringArray()!);
                    if (response.length > 0) {
                        response.forEach(item => {
                            if (item) {
                                this.nodeList.push(item);
                            }
                        });
                        this.JSONtree = Object.assign(this.nodeList, this.JSONtree);
                    }
                } catch (e) {
                    console.log(e.toString());
                }
            }
        }

        get TreeParameterNodes(): NodeDefinition[] | null {
            if (this.nodeList) {
                var values = this.nodeList;
                var nodes = values.map(val => new NodeDefinition(val));
                for (var node of nodes) {
                    var parentNode = nodes.find(n => n.Value.key == node.Value.parentKey);
                    if (parentNode) {
                        node.Parent = parentNode;
                        parentNode.Children.push(node);
                        parentNode.Value.isLeaf = false;
                    }
                }
                return nodes;
            }

            return null;
        }

        async SetItem() {
            excelHandler.SetItem(this.SelectedTreeNode.name, this.SelectedRangeAddress!);
        }

        key(i: number): string {
            return i.toString();
        }

        buildJSONTree(childrenArr: HierarchicalTreeNode[]) {
            let vm = this;

            for (let i = 0; i < this.JSONtree.length; ++i) {
                //build tree recursively to JSON object format
                let parent = childrenArr.filter(n => {
                    return this.JSONtree[i].ParentKey == n.Key;
                })[0];
                if (parent != null) {
                    parent.AddChild(this.JSONtree[i]);
                    this.JSONtree = this.JSONtree.filter(item => item !== this.JSONtree[i]);
                    this.buildJSONTree(parent.Children!);
                    --i;
                };
                if (!this.JSONtree[i].Children) {
                    this.JSONtree[i].IsLeaf = false;
                } else this.JSONtree[i].IsLeaf = true;
            }
            console.log(this.JSONtree);
        }
    }
</script>

<style scoped>
    input, select, textarea {
        border: 1px solid var(--thin-border);
        border-radius: 3px 3px;
        padding: 5px;
        width: 100%;
    }

        input[type='checkbox'] {
            width: auto;
            margin-left: 10px;
            margin-top: 10px;
        }

    .editorContent {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 5px;
    }

    .buttonArray {
        position: relative;
        top: 50%;
        transform: translateY(-100%);
    }

        .buttonArray button {
            border: none;
            background: none;
            position: relative;
            top: 50%;
        }

            .buttonArray button[disabled] {
                opacity: 0.5;
            }

    .modalTitle {
        font-size: 22px;
        font-weight: bold;
    }

    .modalContent {
        grid-column: auto;
        overflow-y: auto;
        background-color: var(--background-primary);
        border: 1px solid var(--thin-border);
        overflow-x: auto;
        overflow-y: auto;
        height: 300px;
    }

    .modalItemWrapper {
        width: 100%;
        border-bottom: 1px solid var(--thin-border);
        padding-bottom: 0px;
        cursor: pointer;
        user-select: none;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 28px;
        height: 36px;
        position: relative;
        white-space: nowrap;
    }

        .modalItemWrapper.even {
            background-color: var(--background-even);
        }

    .modalItem .label {
        overflow: hidden;
        position: absolute;
        left: 56px;
        right: 28px;
        text-overflow: ellipsis;
    }
</style>
