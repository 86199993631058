import * as FormControls from './FormControls';
import SubstitutionDictionary from './SubstitutionDictionary';
import * as ExcelHandler from './ExcelHandler';

export async function GetStaticDictionary(): Promise<SubstitutionDictionary<string>> {
    let dictionary = new SubstitutionDictionary<string>();
    await Excel.run(async (context) => {
        var names = context.workbook.names;
        names.load();
        await context.sync();
        for (let name of names.items)
        {
            if (name.name.includes("Form.")
                || name.name.includes("Global.")
                || name.name.includes("Parameter.")
                || name.name.includes("Record."))
                //JBTODO: || (VariablesControl?.HasVariable(name.Name) ?? false))
            {
                dictionary.add(name.name, name.value);
            }
        }
    })
    return dictionary;
}

async function GetSubstitutionDictionaryFromWorksheetAndIndexesAndControlRange(ws: Excel.Worksheet, row: number, column: number, controlRange: FormControls.GridRange, controlRanges: FormControls.GridRange[]): Promise<SubstitutionDictionary<string>> {
    var dictionary = await GetStaticDictionary();
    // add position dependent variables
    if (controlRange != null) {
        // if table control, get fieldmapping associated with gridrange and then field associated with the selected cell to get the fieldname
        if (controlRange.ObjectControl instanceof FormControls.TableControl)
        {
            // check all control ranges for the current table
            for (let tableRange of controlRanges.filter(range => range.ObjectControl instanceof FormControls.TableControl)) {
                var fieldMapping = (<FormControls.TableControl>tableRange.ObjectControl).FieldMappings![tableRange.ColumnRangeIndex!];
                for (let i = 0; i < tableRange.ColumnCount! && i < fieldMapping!.Fields!.length; ++i)
                {
                    var field = fieldMapping!.Fields![i];
                    await Excel.run(async (context) => {
                        const ws = context.workbook.worksheets.getActiveWorksheet();
                        const range = ws.getRangeByIndexes(row, i + tableRange.Column!, 1, 1);
                        range.load('values');
                        await context.sync();
                        if (!dictionary.setItem(field.Name!, range.values[0][0])) {
                            dictionary.add(field.Name!, range.values[0][0]);
                        };
                    })
                        .catch(function (error) {
                            console.log("Error: " + error);
                            if (error instanceof OfficeExtension.Error) {
                                console.log("Debug info: " + JSON.stringify(error.debugInfo));
                            }
                        });
                }
            }
        }
        //else if (controlRange.ObjectControl is MatrixControl matrixControl)
        //{
        //    // if a matrix control, all metadata attributes present on the grid become substitution variables named Dimension.Attribute
        //    if (controlRange.ColumnRangeIndex >= 0) {
        //        // we are in the column metadata or the data range
        //        // add column metadata fields
        //        var metaRange = Workbook.GetNamedRange(matrixControl.ColumnMetaRowRange);
        //        var columnMetaWS = metaRange.Worksheet;
        //        for (int i = 0; i < matrixControl.ColumnFields.Length; ++i)
        //        {
        //            if (i >= metaRange.Rows.Count)
        //                break;
        //            var value = columnMetaWS.Cells[metaRange.Row + i, column].Value2?.ToString() ?? string.Empty;
        //            dictionary[matrixControl.ColumnFields[i]] = value;
        //        }
        //    }
        //    if (controlRange.RowRangeIndex >= 0) {
        //        // we are in the row metadata or the data range
        //        // add row metdata fields
        //        var metaRange = Workbook.GetNamedRange(matrixControl.RowMetaColumnRange);
        //        var rowMetaWS = metaRange.Worksheet;
        //        for (int i = 0; i < matrixControl.RowFields.Length; ++i)
        //        {
        //            if (i >= metaRange.Columns.Count)
        //                break;
        //            var value = rowMetaWS.Cells[row, metaRange.Column + i].Value2?.ToString() ?? string.Empty;
        //            dictionary[matrixControl.RowFields[i]] = value;
        //        }
        //    }
        //} else if (controlRange.ObjectControl is RecordControl recordControl)
        //{
        //    foreach(var field in recordControl.Fields)
        //    {
        //        var rangeName = string.Format("Record.{0}.{1}", recordControl.Name, field.Name);
        //        var range = Workbook.GetNamedRange(rangeName);
        //        var cell = range.Cells[1, 1]; //get the first cell value since records return single rows
        //        var value = cell.Value2 == null ? "" : cell.Value2;
        //        dictionary[field.Name] = value.ToString();
        //    }
        //}
    }
    return dictionary;
}

async function GetSubstitutionDictionaryFromWorksheetAndIndexes(ws: Excel.Worksheet, row: number, column: number, controlRanges: FormControls.GridRange[]): Promise<SubstitutionDictionary<string>> {
    let cellControlRange: FormControls.GridRange | FormControls.TableControl | null = null;
    let dictionary: SubstitutionDictionary<string> = new SubstitutionDictionary<string>();
    await Excel.run(async (context) => {
        let range = ws.getRangeByIndexes(row, column, 1, 1);
        // grab range's rowIndex, columnIndex
        range.load('rowIndex, columnIndex, rowCount, columnCount');
        await context.sync();
        cellControlRange = ExcelHandler.GetCellControlRange(range, controlRanges);
    }).catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
    if (cellControlRange) {
        dictionary = await GetSubstitutionDictionaryFromWorksheetAndIndexesAndControlRange(ws, row, column, cellControlRange, controlRanges);
    }
    return dictionary;
}

async function GetSubstitutionDictionaryFromRangeAndControlRange(range: Excel.Range | null, controlRange: FormControls.GridRange, controlRanges: FormControls.GridRange[] ): Promise<SubstitutionDictionary<string>> {
    let row: number = 0;
    let column: number = 0;
    let ws: Excel.Worksheet;
    let dictionary: SubstitutionDictionary<string> = new SubstitutionDictionary<string>();
    await Excel.run(async (context) => {
        // grab range's rowIndex, columnIndex
        if (!range!.rowIndex) {
            range!.load('rowIndex, columnIndex, rowCount, columnCount, worksheet');
        }
        await context.sync();
        row = range!.rowIndex;
        column = range!.columnIndex;
        ws = range!.worksheet;
        dictionary = await GetSubstitutionDictionaryFromWorksheetAndIndexesAndControlRange(ws, row, column, controlRange, controlRanges);
    }).catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
    return dictionary;
}

export async function GetSubstitutionDictionaryFromRange(range: Excel.Range | null, controlRanges: FormControls.GridRange[]): Promise<SubstitutionDictionary<string> | null> {
    let cellControlRange: FormControls.GridRange | FormControls.TableControl | null = null;
    let dictionary: SubstitutionDictionary<string> = new SubstitutionDictionary<string>();
    await Excel.run(async (context) => {
        if (range === null) {
            range = context.workbook.getSelectedRange();
        }
        // grab range's rowIndex, columnIndex
        range.load('rowIndex, columnIndex, rowCount, columnCount, worksheet');
        await context.sync();
        cellControlRange = ExcelHandler.GetCellControlRange(range, controlRanges);
    }).catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
    if (cellControlRange) {
        dictionary = await GetSubstitutionDictionaryFromRangeAndControlRange(range, cellControlRange, controlRanges);
    }
    return dictionary;
}

export async function GetCellEditControl(ws: Excel.Worksheet, row: number, column: number, controlRanges: FormControls.GridRange[]): Promise<FormControls.EditControl | null> {
    var controlRange = await ExcelHandler.GetCellControlRangeByWorksheet(ws, row, column, controlRanges);
    var editControl: FormControls.EditControl | null = null;
    await Excel.run(async (context) => {
        if (controlRange == null)
            return editControl = null;
        if (controlRange.ObjectControl instanceof FormControls.TableControl) {
            var tableControl = <FormControls.TableControl>controlRange.ObjectControl;
            // if there are no edit controls, there is nothing to be found
            if (tableControl.EditControls == null)
                return editControl = null;
            // otherwise search for an edit control with the current range index
            // and a field index equal to the offset of the current column from the left of the range
            return editControl = tableControl.EditControls.filter(editControl => {
                return editControl.RangeIndex == controlRange.ColumnRangeIndex
                    && editControl.FieldIndex == column - controlRange.Column!;
            })[0];
        }
    });
    return editControl;
}

export async function GetCellEditControlByRange(range: Excel.Range, controlRanges: FormControls.GridRange[]): Promise<FormControls.EditControl | null> {
    var ws: Excel.Worksheet = new Excel.Worksheet();
    var row: number = 0;
    var column: number = 0;
    await Excel.run(async (context) => {
        range.load("worksheet, rowIndex, columnIndex");
        await context.sync();
        ws = range.worksheet;
        row = range.rowIndex;
        column = range.columnIndex;
    });
    //JBTODO: add catch block
    return await GetCellEditControl(ws, row, column, controlRanges);
}