import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr'
import { AuthClient } from "../api/ApiClientBase";
import * as api from "./ApiClient";
import { ActionContext } from 'vuex';
import { IWorkflowStore } from '../store/WorkflowStore';

export class WorkflowNotificationsHandler {

    private context: ActionContext<IWorkflowStore, any> | null;
    private connection: HubConnection | null;
    public state: api.ProcessActivityExecutionState[] | null;
    public processRunId: string | null;

    constructor(context: ActionContext<IWorkflowStore, any> | null, state?: api.ProcessActivityExecutionState[] | null | undefined) {
        if (context == undefined)
            context = null;
        if (state == undefined)
            state = null;

        this.context = context;
        this.state = state;

        this.connection = null;
        this.processRunId = null;
    }


    async start() {
        if (this.connection != null)
            return;

        var auth_client = new AuthClient();
        await auth_client.ensureToken();
        var client1 = new api.FileSystemClient(auth_client);
        await client1.setupNotifications();

        var client = new api.SignalRClient(auth_client);
        var data = await client.getSignalRConfig();
        this.connection = new HubConnectionBuilder()
            .withUrl(data.url!, { "accessTokenFactory": () => data.token! })
            .build();

        var debounce = require('debounce');
        this.SetLastReceivedNotification = debounce(this.SetLastReceivedNotification, 200);

        this.connection.on('InteractiveProcessEvent', (messageReceived: any, data: string) => {
            this.HandleNotification(api.ProcessRunActivity.fromJS(JSON.parse(data)));
        });
        this.connection.on('ProcessEvent', (messageReceived: any, data: string) => {
            this.HandleProcessNotification(JSON.parse(data));
        });
        this.connection.start();

        this.connection.onclose(() => { if (this.connection != null) this.connection.start() });
    }

    stop() {
        if (this.connection != null) {
            var con = this.connection;
            this.connection = null;
            con.stop();
        }
    }

    private getState(): api.ProcessActivityExecutionState[] {
        if (this.context != null) {
            var state = this.context.state.WorkflowState;
            if (state == null) {
                state = [];
                this.context.commit("SetWorkflowState", state);
            }
            return state;
        }
        else return this.state!;
    }

    public async HandleNotification(notification: api.ProcessRunActivity) {
        if (this.processRunId != null && notification.processRunId != this.processRunId)
            return;

        var state = this.getState();

        var found = false;
        for (var i = 0; i < state.length; i++) {
            if (state[i].subProcessRunId == (notification.subProcessRunId ?? "") && state[i].nodeId == notification.nodeId && state[i].activityRunId == notification.activityRunId) {
                state[i].activityRunId = notification.activityRunId;
                state[i].activityName = notification.activityName;
                state[i].caption = notification.caption;
                state[i].calculatedDueDate = notification.dueDate;
                state[i].calculatedStatus = notification.status ?? api.ActivityExecutionResultEnum.Queued;
                state[i].percentComplete = notification.percentComplete ?? 0;
                state[i].startTime = notification.startTime;

                found = true;
                break;
            }
        }

        if (!found) {
            var newAES = new api.ProcessActivityExecutionState();
            newAES.nodeId = notification.nodeId;
            newAES.activityRunId = notification.activityRunId;
            newAES.activityName = notification.activityName;
            newAES.subProcessRunId = notification.subProcessRunId ?? "";
            newAES.calculatedDueDate = notification.dueDate;
            newAES.calculatedStatus = notification.status ?? api.ActivityExecutionResultEnum.Queued;
            newAES.percentComplete = notification.percentComplete ?? 0;
            newAES.startTime = notification.startTime;
            newAES.caption = notification.caption;
            state.push(newAES);
        }
        if (this.context != null) {
            this.context.commit("SetProcessRunId", notification.processRunId);
            this.SetLastReceivedNotification(notification);
        }
    }

    private SetLastReceivedNotification(notification: api.ProcessRunActivity) {
        if (this.context != null) {
            this.context.commit("SetLastReceivedNotification", notification);
        }
    }

    public async HandleProcessNotification(notification: any) {

        if (this.context == null || (this.processRunId != null && notification.processRunId != this.processRunId))
            return;

        if (notification.status != undefined) {
            var runNotification = notification as api.ProcessRun;
            if (notification.status == "stopped" || notification.status == "Stopped") {
                this.context.commit("SetWorkflowState", null);
                console.log("process stopped received.")
            }
            if (notification.status == "success" || notification.status == "Success") {
                this.context.commit("SetWorkflowState", null);
                this.context.dispatch("WorkflowRunFinished");
            }
        }
        else {
            var runlogNotifiation = notification as api.ProcessRunLog;
            console.log(runlogNotifiation);
        }
    }
}