export interface IKeyCollection<T> {
    add(key: string, value: string): void;
    containsKey(key: string): boolean;
    size(): number;
    getItem(key: string): string;
    setItem(key: string, value: string): void;
    removeItem(key: string): string;
    getKeys(): string[];
    values(): string[];
    Substitute(inputString: string): string;
    ToStringArray(): string[] | null;
}

export default class SubstitutionDictionary<T> implements IKeyCollection<T> {
    private items: { [index: string]: string } = {};
    private count: number = 0;

    add(key: string, value: string) {
        if (!this.items.hasOwnProperty(key)) {
            this.count++;
        }

        this.items[key] = value;
    }

    containsKey(key: string): boolean {
        return this.items.hasOwnProperty(key);
    }

    size(): number {
        return this.count;
    }

    getItem(key: string): string {
        return this.items[key];
    }

    setItem(key: string, value: string): boolean {
        if (this.containsKey(key)) {
            this.items[key] = value;
        }
        return this.containsKey(key);
    }

    removeItem(key: string): string {
        let value = this.items[key];

        delete this.items[key];
        this.count--;

        return value;
    }

    getKeys(): string[] {
        let keySet: string[] = [];

        for (let property in this.items) {
            if (this.items.hasOwnProperty(property)) {
                keySet.push(property);
            }
        }

        return keySet;
    }

    values(): string[] {
        let values: string[] = [];

        for (let property in this.items) {
            if (this.items.hasOwnProperty(property)) {
                values.push(this.items[property]);
            }
        }

        return values;
    }

    /// <summary>
    /// Perform variable substitution on the text. Variables are in the format {variablename} and the 
    /// names are case-sensitive
    /// Later variables will be substituted first which means later variables can include references 
    /// to earlier variables
    /// </summary>
    /// <param name="inputString">String with substitution variables</param>
    /// <returns>String with substitutions performed</returns>
    Substitute(inputString: string): string {
        var result = inputString;
        let keys = this.getKeys().reverse();
        let i = this.count - 1;

        for (let property of keys) {
            // if at some point we end up with an empty/null string, just return it
            if (result === '' || !result || i < 0)
                return result;

            let lookup: string = `{${property}}`;
            if (result.indexOf(lookup) === 0)
                result = result.replace(lookup, this.items[property]);
        }
        return result;
    }

    // convert the dictionary to an array of strings for serialization purposes
    ToStringArray(): string[] | null {
        if (this.count == 0)
            return null;
        var result: string[] = [];
        result.length = this.count * 2;
        let i = 0;
        for (let property in this.items) {
            result[i * 2] = property;
            result[i * 2 + 1] = this.items[property];
            ++i;
        }
        return result;
    }

    // restore the array from a list of strings
    // assumes object has just been initialized
    FromStringArray(array: string[]) {
        if (array.length === 0 || !array)
            return;
        for (let i = 0; i < array.length; i += 2)
        {
            let tuple = {
                Key: '',
                Value: '',
            };
            tuple.Key = array[i];
            // if the array has an odd number of elements, last key is assigned an empty string as value
            if (i < array.length - 1)
                tuple.Value = array[i + 1];
            else
                tuple.Value = '';
            this.add(tuple.Key, tuple.Value);
        }
    }
}