import * as api from "./ApiClient";
import store from '../store/GlobalStore'; 

export class Localizer {
    private $store: any;

    public static DebugMode: boolean = false;
    constructor($store: any) {
        this.$store = $store || store;
    }

    get Translations(): { [index: string]: any } {
        return this.$store.state.Locale;
    }
    get Locale(): string {
        return this.Translations["~"].toLowerCase();
    }
    get LanguageCode(): string {
        return this.Locale.substring(0, 2);
    }
    get CustomTranslations(): { [index: string]: any } {
        // returns { "locale": { ...all of our translations... } } for VueCronEditorBuefy component
        let result: { [index: string]: any } = {};
        result[this.LanguageCode] = this.Translations;
        return result;
    }

    Localize(key: string) {
        if (Localizer.DebugMode)
            return key;
        var result = this.$store.state.Locale[key];
        if (result == undefined)
            result = key;
        return result;
    }

    FormatLocalize(key: string) {
        if (Localizer.DebugMode)
            return key;

        var formatted = this.$store.state.Locale[key];
        if (formatted == undefined)
            return "";

        for (var i = 1; i < arguments.length; i++) {
            var regexp = new RegExp('\\{' + (i - 1) + '\\}', 'gi');
            formatted = formatted.replace(regexp, arguments[i]);
        }
        return formatted;
    }

    FormatParameterizedLocalize(key: string, args: string[]) {
        if (Localizer.DebugMode)
            return key;

        var formatted = this.$store.state.Locale[key];
        if (formatted == undefined)
            return "";
        for (var i = 0; i < args.length; i++) {
            var regexp = new RegExp('\\{' + (i) + '\\}', 'gi');
            formatted = formatted.replace(regexp, args[i]);
        }
        return formatted;
    }

    public ParseLocalizedString(localizedString: string | null | undefined) {
        if (localizedString == null || localizedString == '')
            return localizedString;
        var components = localizedString.split('|');
        var currentValue = components[0];
        var precision = 0;                  // 0 - default language, 1 - matched related language, 2 - matched country independent language
        // get the current culture name in the format lang-country/region
        var targetCultureName = this.Locale;
        if (targetCultureName.length < 2)
            return currentValue;            // invariant culture, return default value
        var targetLanguage = this.LanguageCode;
        // iterate through language codes
        for (var i = 1; i < components.length - 1; i += 2) {
            var currentCulture = components[i].toLowerCase();
            // first check for an exact match;
            if (currentCulture == targetCultureName) {
                // we have a perfect match, return it
                return components[i + 1];
            }
            // next check for a language-only component to match
            if (currentCulture == targetLanguage && precision < 2) {
                // we have a language match (i.e. looking for en-CA and found en)
                currentValue = components[i + 1];
                precision = 2;          // matched language
                continue;
            }
            if (currentCulture.substring(0, 2) == targetLanguage && precision == 0) {
                // we have a language match, but country mismatch (i.e. looking for fr-CA but found fr-FR)
                currentValue = components[i + 1];
                precision = 1;
            }
        }
        return currentValue;
    }

    public FormatFileType(val: api.FileSystemType): string {
        switch (val) {
            case api.FileSystemType.Folder:
                return this.Localize("FileSystemObject_Folder");
            case api.FileSystemType.InteractiveProcess:
                return this.Localize("FileSystemObject_Process");
            case api.FileSystemType.ReportBook:
                return this.Localize("FileSystemObject_Report_Book");
            case api.FileSystemType.DocxReport:
                return this.Localize("FileSystemObject_Word_Report");
            case api.FileSystemType.PptxReport:
                return this.Localize("FileSystemObject_Powerpoint_Report");
            case api.FileSystemType.CalcScript:
                return this.Localize("FileSystemObject_CalcScript");
            case api.FileSystemType.Generic:
                return this.Localize("FileSystemObject_Generic_File");
            default:
                return api.FileSystemType[val];

        }
    }
}

export default Localizer;
