<template>
    <div>
        <div class="context_menu_curtain" @click="Close" @contextmenu.prevent.stop="Close" />
        <ContextMenuPanel ref="context_menu">
            <template v-slot:buttons>
                <slot name="buttons">

                </slot>
            </template>
        </ContextMenuPanel>
    </div>
</template>

<script lang="ts">
    import ContextMenuPanel from './ContextMenu/ContextMenuPanel.vue';
    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component({ name: "ContextMenu", components: { ContextMenuPanel } })
    export default class ContextMenu extends Vue {
        @Prop() private TriggerEvent!: MouseEvent;

        beforeMount() {
            var event = this.TriggerEvent;
            setTimeout(() => {
                var cm = this.$refs.context_menu as any;

                var viewportOffset = cm.BoundingClientRect as DOMRect;
                var width = viewportOffset.width;
                var height = viewportOffset.height;

                var screenWidth = window.innerWidth;
                var screenHeight = window.innerHeight;

                var dx = event?.clientX || 0;
                var dy = event?.clientY || 0;
                if (dx + width + 5 > screenWidth)
                    dx -= width;
                if (dy + height + 5 > screenHeight)
                    dy = screenHeight - height - 5;

                cm.Open(dx, dy);
            }, 100);
        }

        Close() {
            this.$emit('Close');
        }
    }
</script>

<style>

</style>
