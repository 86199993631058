<template>
    <transition name="modal">
        <div v-if="isVisible" class="modal-mask" :style="maskCss">
            <div class="modal-wrapper" :style="wrapperCss">
                <div :class="['modal-container', containerStyle]" :style="containerCss">
                    <div class="modal-header">
                        <div style="padding-top: 10px; padding-right: 12px; padding-bottom: 6px; padding-left: 12px; width: 100%; height: 41px; ">
                            <slot name="header">
                                {{Localizer.Localize("Modal_default_header")}}
                            </slot>
                            <img src="/icons/workflow/close.svg" class="theme-svg" id="close" v-bind:class="{md:size==='md'}" @click="$emit('close')" />
                            <img src="/icons/help.svg" v-if="Can_OpenHelp" class="theme-svg" id="help" v-bind:class="{md:size==='md'}" @click="OpenHelp" />
                        </div>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                            {{Localizer.Localize("Modal_default_body")}}
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            default footer
                            <button class="modal-default-button" @click="$emit('close')">
                                {{Localizer.Localize("OK")}}
                            </button>
                            <button class="modal-default-button" @click="$emit('close')">
                                {{Localizer.Localize("OK")}}
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import { Localizer } from '../../api/Localizer';

    import { ChatUser, ChatMessage } from '../pages/chat/ChatUser';
    import { ChatWindowsTabData } from '../../api/WindowsTabData';

    @Component
    export default class Modal extends Vue {
        @Prop() private size!: string;
        private Localizer = new Localizer(this.$store);
        public isVisible = false;

        @Prop() private MaskCss: Object | undefined;
        @Prop() private WrapperCss: Object | undefined;
        @Prop() private ContainerCss: Object | undefined;

        @Prop() private HelpKey: string | undefined;
        @Prop() private HelpBotName: string | undefined;

        maskCss: Object = {};
        wrapperCss: Object = {};
        containerCss: Object = {};

        get containerStyle() {
            return this.size;
        }

        mounted() {
            // Assign isVisible true on mounted as part of vue3 migration
            // Transition root https://v3-migration.vuejs.org/breaking-changes/transition-as-root.html
            this.isVisible = true;

            this.maskCss = this.MaskCss ? this.MaskCss : '';
            this.wrapperCss = this.WrapperCss ? this.WrapperCss : '';
            this.containerCss = this.ContainerCss ? this.ContainerCss : '';
        }

        private get Can_OpenHelp(): boolean {
            if (this.HelpKey != null) {
                const article: object | null = this.$store.getters['chat/getHelpArticle'](this.HelpKey);
                return article != null;
            }
            return false;
        }

        private async OpenHelp() {
            const article: any | null = this.$store.getters['chat/getHelpArticle'](this.HelpKey);
            if (article == null)
                return;

            const botList = this.$store.state.chat.BotList as ChatUser[];
            const newBot = new ChatUser(this.HelpBotName + ' Help', (this.HelpBotName + 'H').substring(0,1).toUpperCase(), 'fluence');
            newBot.hint = 'https://support.fluencetech.com/api/v2/help_center/en-us/articles/' + article.zenDeskArticleId + '.json';
            newBot.history.push(
                new ChatMessage('Help Url: https://support.fluencetech.com/hc/en-us/articles/' + article.zenDeskArticleId, false)
            )
            let bot = botList.find(b => b.name == newBot.name);
            if (bot == null) {
                botList.push(newBot);
                this.$store.commit('chat/ResetBots', botList);
                bot = newBot;
            }
            this.$store.commit('chat/setSelectedBot', bot);

            const tab = new ChatWindowsTabData('Chat');
            const tabs = this.$store.state.windowstabs.Tabs;
            tabs!.push(tab);
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        max-width: 65vw;
        max-height: calc(90%);
        margin: 0 auto;
        padding: 10px 12px 0 12px;
        transition: all 0.3s ease;
    }

    .modal-header {
        margin: -10px -12px 0 -12px;
    }

    .modal-container.sm {
        width: 200px
    }

    .modal-container.md {
        width: 400px
    }

    .modal-container.lg {
        width: 574px
    }

    .modal-container.xlg {
        width: 1000px
    }

    .modal-body {
        margin: 12px 0 12px 0;
    }


    .modal-enter-from {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

        .modal-enter-from .modal-container,
        .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

    .md {
        position: relative;
        top: -30px;
    }

    #close {
        float: right;
        cursor: pointer;
    }

    #help{
        float: right;
        cursor: pointer;
    }
</style>
