import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-19ee549c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  id: "app"
}
const _hoisted_2 = { id: "adnMain" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ChooseTenant = _resolveComponent("ChooseTenant")!
  const _component_Container = _resolveComponent("Container")!
  const _component_ThemeLoader = _resolveComponent("ThemeLoader")!

  return (_openBlock(), _createBlock(_component_ThemeLoader, null, {
    default: _withCtx(() => [
      (_ctx.Username ==='')
        ? (_openBlock(), _createBlock(_component_Login, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Header),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_Transition, {
                name: "page",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_ctx.Tenant === null)
                    ? (_openBlock(), _createBlock(_component_ChooseTenant, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_Container, {
                        key: 1,
                        class: "d-flex"
                      }))
                ]),
                _: 1
              })
            ])
          ]))
    ]),
    _: 1
  }))
}