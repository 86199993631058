import * as api from "./ApiClient";


export class WindowsTabData {
    public static index = 0;
    private static zindex = 0;

    public Key: number;
    public Name: string;
    public ZIndex: number;


    constructor(name: string) {
        this.Key = WindowsTabData.index++;
        this.Name = name;
        this.ZIndex = WindowsTabData.zindex++;
    }

    public BringToFront() {
        this.ZIndex = WindowsTabData.zindex++;
    }
}

export class FsoWindowsTabData extends WindowsTabData {
    public Fso: api.FileSystemObject;

    constructor(name: string, fso: api.FileSystemObject) {
        super(name)
        this.Fso = fso;
    }
}

export class ChatWindowsTabData extends WindowsTabData {

    constructor(name: string) {
        super(name)
    }
}

