import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = ["value", "min", "max"]
const _hoisted_3 = { class: "w-100 mt-3 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: "datepicker",
      type: "date",
      name: "calendar",
      value: _ctx.selectedDate,
      min: _ctx.minDate,
      max: _ctx.maxDate
    }, null, 8, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      class: "mt-3",
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDateInput) = $event)),
      id: "dateInput",
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.InputDateChanged && _ctx.InputDateChanged(...args)))
    }, null, 544), [
      [_vModelText, _ctx.selectedDateInput]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SetItem(true))),
        class: "btnViewer btnViewerDefault"
      }, _toDisplayString(_ctx.UIText.SetDate), 1)
    ])
  ]))
}