import { GetterTree, MutationTree, Module } from 'vuex';

import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import * as ext from "../api/Extensions";

export interface IReportBookStore {
    IsBusy: boolean;
    IsNew: boolean;
    EntryIsNew: boolean;
    DisplayDefinitionDetailsPanel: boolean;
    ReportBook: ext.ReportBookItem | null;
    SelectedEntryIndex: number | null;
    SelectedParameterIndex: number | null;
    NewReportBookParameters: api.BookAndFormParameter[];
}

const ReportBookStore: Module<IReportBookStore, any> = {
    namespaced: true,
    state: {
        IsBusy: false,
        IsNew: false,
        EntryIsNew: false,
        DisplayDefinitionDetailsPanel: false,
        ReportBook: null,
        SelectedEntryIndex: null,
        SelectedParameterIndex: null,
        NewReportBookParameters: []
    },

    mutations: {
        ClearState(state) {
            state.DisplayDefinitionDetailsPanel = state.IsNew = state.IsBusy = false;
            state.ReportBook = state.SelectedEntryIndex = state.SelectedParameterIndex = null;
            state.NewReportBookParameters = [];
        },

        SetIsBusy(state, isBusy: boolean) {
            state.IsBusy = isBusy;
        },

        SetIsNew(state, isNew: boolean) {
            state.IsNew = isNew;
        },

        SetEntryIsNew(state, isNew: boolean) {
            state.EntryIsNew = isNew;
        },

        SetDisplayDefinitionDetailsPanel(state, display: boolean) {
            state.DisplayDefinitionDetailsPanel = display;
        },

        SetReportBook(state, reportBook: ext.ReportBookItem | null) {
            state.ReportBook = reportBook;
        },

        SetSelectedEntryIndex(state, index: number | null) {
            state.SelectedEntryIndex = index;
        },

        SetSelectedParameterIndex(state, index: number | null) {
            state.SelectedParameterIndex = index;
        },

        UpdateDefinition(state, newDefinition: api.BookDefinition) {
            var reportBook = state.ReportBook
            if (reportBook) {
                reportBook.name = newDefinition.name;
                reportBook.compilation = newDefinition.compilation;
                reportBook.output = newDefinition.output;
                reportBook.destination = newDefinition.destination;
                reportBook.password = newDefinition.password;
            }
        },

        UpdateSelectedParameter(state, updated: api.BookAndFormParameter) {
            var reportbook = state.ReportBook;
            if (reportbook !== null && reportbook.parameters) {
                var index = state.SelectedParameterIndex;
                if (index !== null && index >= 0) {
                    reportbook.parameters[index] = updated;
                }
                else {
                    reportbook.parameters.push(updated);
                }
            }
        },

        ClearNewReportBookParameters(state) {
            state.NewReportBookParameters = [];
        },

        AddNewReportBookParameters(state) {
            var reportbook = state.ReportBook;
            var newParameters = state.NewReportBookParameters;
            if (reportbook !== null) {
                if (reportbook.parameters)
                    reportbook.parameters = reportbook.parameters.concat(newParameters);
                else
                    reportbook.parameters = newParameters;
            }
            state.NewReportBookParameters = []; // clear the list
        }
    },

    actions: {
        async NewReportBook(context, { parentId, path, name }: { parentId: number; path: string; name: string }) {
            context.commit("ClearState");
            context.commit("SetIsNew", true);
            context.commit("SetDisplayDefinitionDetailsPanel", true);

            var fso = new api.FileSystemObjectContent();
            fso.parentId = parentId;
            fso.path = path;
            fso.name = name;
            var fullPath = path + "/" + name;
            while (fullPath.startsWith('//')) // fix for parent being the root
                fullPath = fullPath.replace("//", "/");
            fso.fullPath = fullPath;
            var reportBook = new ext.ReportBookItem({
                fileSystemObject: fso,
                name: name,
                password: null,
                output: api.BookOutput.File,
                compilation: api.BookCompilation.Merged,
                destination: fullPath,
                parameters: [],
                entries: []
            });

            context.commit("SetReportBook", reportBook);
        },

        async LoadReportBook(context, fso: api.FileSystemObjectContent) {
            context.commit("SetIsBusy", true);
            context.commit("ClearState");
            try {
                var path = fso.path + "/" + fso.name;
                while (path.startsWith('//')) // fix for parent being the root
                    path = path.replace("//", "/");

                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.ReportBookClient(auth_client);
                var reportBook = await client.load(path);
                // create new object with extension methods
                var parsed = new ext.ReportBookItem(reportBook);
                context.commit("SetReportBook", parsed);
            }
            finally {
                context.commit("SetIsBusy", false);
            }
        },

        async UpdateSelectedEntry(context, updated: api.ReportBookEntry) {
            var state = context.state;
            var reportbook = state.ReportBook;
            if (reportbook !== null && reportbook.entries) {
                var index = state.SelectedEntryIndex;
                if (index !== null && index >= 0) {
                    reportbook.entries[index] = updated;
                }
                else {
                    reportbook.entries.push(updated);
                }

                // Now, we will remove any report book parameters that the user no longer wants.
                var reportBookParameters = reportbook.parameters;
                if (reportBookParameters) {
                    var formParameterList = updated.parameters;
                    if (formParameterList && formParameterList.parameters) {
                        var formParameters = formParameterList.parameters;
                        var followsReportBookParameterNamingConvention: (param: api.FormParameter) => boolean = context.getters["FollowsReportBookParameterNamingConvention"];
                        var anyFormParametersPointToThisRBParameter: (key: string) => boolean = context.getters["AnyFormParametersPointToThisRBParameter"];

                        var updatedFso = updated.fileSystemObject!;
                        var updatedFullPath = updatedFso.path + "/" + updatedFso.name;
                        updatedFullPath = updatedFullPath.replace("//", "/").replace("//", "/");

                        for (var testParam of formParameters) {
                            var reportBookParameterIndex = reportBookParameters.findIndex(p =>
                                p.bookParameter!.sourceName === testParam.name &&
                                p.bookParameter!.sourcePath === updatedFullPath
                            );

                            if (reportBookParameterIndex !== -1 && !followsReportBookParameterNamingConvention(testParam)) {
                                // This form parameter no longer points to its report book parameter.
                                // We will check to see if any other form parameters point to the report book parameter -
                                // if not, then the user probably doesn't want it any more, and it can be removed.
                                var reportBookParameter = reportBookParameters[reportBookParameterIndex];
                                if (!anyFormParametersPointToThisRBParameter(reportBookParameter.bookParameter!.name!)) {
                                    reportBookParameters.splice(reportBookParameterIndex, 1)[0];
                                }
                            }
                        }
                    }
                }
            }
        },

        DeleteEntry(context, index: number) {
            var reportbook = context.state.ReportBook;
            if (reportbook !== null && reportbook.entries) {
                if (index >= 0) {
                    var rbEntry = reportbook.entries.splice(index, 1)[0];

                    // Now, we will remove any report book parameters that were pointing to this entry.
                    var reportBookParameters = reportbook.parameters;
                    if (reportBookParameters) {
                        var formParameterList = rbEntry.parameters;
                        if (formParameterList && formParameterList.parameters) {
                            var formParameters = formParameterList.parameters;
                            var followsReportBookParameterNamingConvention: (param: api.FormParameter) => boolean = context.getters["FollowsReportBookParameterNamingConvention"];
                            var anyFormParametersPointToThisRBParameter: (key: string) => boolean = context.getters["AnyFormParametersPointToThisRBParameter"];

                            var entryFso = rbEntry.fileSystemObject!;
                            var entryFullPath = entryFso.path + "/" + entryFso.name;
                            entryFullPath = entryFullPath.replace("//", "/").replace("//", "/");

                            for (var testParam of formParameters) {
                                if (followsReportBookParameterNamingConvention(testParam)) {
                                    var reportBookParameterIndex = reportBookParameters.findIndex(p =>
                                        p.bookParameter!.sourceName === testParam.name &&
                                        p.bookParameter!.sourcePath === entryFullPath
                                    );

                                    if (reportBookParameterIndex !== -1) {
                                        // We are removing the form parameter that is the origin of this report book parameter.
                                        // We will check to see if any other form parameters point to the report book parameter -
                                        // if not, then the user probably doesn't want it any more, and it can be removed.
                                        var reportBookParameter = reportBookParameters[reportBookParameterIndex];
                                        if (!anyFormParametersPointToThisRBParameter(reportBookParameter.bookParameter!.name!)) {
                                            reportBookParameters.splice(reportBookParameterIndex, 1)[0];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },

        async DeleteParameter(context, index: number) {
            var reportbook = context.state.ReportBook;
            if (reportbook !== null && reportbook.parameters) {
                if (index >= 0) {
                    var rbParam = reportbook.parameters.splice(index, 1)[0];
                    var testKey = (context.getters["ReportBookParameterValue"](rbParam.bookParameter!.name!) as api.FormParameterValue).key;

                    if (reportbook.entries) {
                        for (var entry of reportbook.entries) {
                            if (entry.parameters && entry.parameters.parameters) {
                                var params = entry.parameters.parameters;
                                for (var param of params) {
                                    var currentValue = param.currentValue;
                                    if (currentValue && currentValue.key === testKey)
                                        currentValue.key = currentValue.name = currentValue.caption = "";
                                }
                            }
                        }
                    }
                }
            }
        },

        async SaveReportBook(context) {
            try {
                var state = context.state;
                var reportBook = state.ReportBook;
                if (reportBook) {
                    context.commit("SetIsBusy", true);
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();
                    var client = new api.ReportBookClient(auth_client);

                    await client.saveDefinition(reportBook.fileSystemObject!.fullPath!, reportBook.definition);
                    console.log("Report book " + reportBook.definition.name! + " saved!");
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("ReportBookStore_Report_book_was_saved"),
                        type: api.ToastType.Info
                    }));
                    context.commit("SetIsNew", false);
                }
            }
            catch (err) {
                console.log(context.rootState.Localizer.Localize("ReportBookStore_Report_book_was_not_saved_successfully"));
                this.dispatch("ShowToastError", err);
            }
            finally {
                context.commit("SetIsBusy", false);
            }
        },

        async RunReportBook(context, reportBook: ext.ReportBookItem) {
            try {
                console.log(context.rootState.Localizer.Localize("ReportBookStore_Starting_execution_of_report_book_") + reportBook.definition.name + ".");
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("ReportBookStore_Report_book_execution_in_progress_You_will_receive_a_notification_when_complete"),
                    type: api.ToastType.Info
                }));

                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.ReportBookClient(auth_client);

                var notifications = await client.runReportBook(reportBook.definitionAndParameters);
                for (var notification of notifications) {
                    var notificationHandler = context.rootState.explorer.NotificationHandler;
                    if (notificationHandler) {
                        notificationHandler.HandleNotification(notification);
                    }
                }
                context.commit("ClearState");
            }
            catch (err) {
                this.dispatch("ShowToastError", err);
            }
        }
    },

    getters: {
        CompilationOptions(state): { id: number, value: string }[] {
            // turning the enum api.BookCompilation into an array with all possible options
            var result: { id: number, value: string }[] = [];
            for (const key in Object.keys(api.BookCompilation)) {
                var val = api.BookCompilation[key] as any as api.BookCompilation;
                if (val)
                    result.push({ id: key as any as number, value: val as any as string });
            }
            return result;
        },

        OutputOptions(state): { id: number, value: string }[] {
            // turning the enum api.BookOutput into an array with all possible options
            var result: { id: number, value: string }[] = [];
            for (const key in Object.keys(api.BookOutput)) {
                var val = api.BookOutput[key] as any as api.BookOutput;
                if (val)
                    result.push({ id: key as any as number, value: val as any as string });
            }
            return result;
        },

        ReportBookEntries(state): api.ReportBookEntry[] | null {
            return(state.ReportBook && state.ReportBook.entries) ? state.ReportBook.entries : null;
        },

        ReportBookParameters(state): api.BookAndFormParameter[] | null {
            return (state.ReportBook && state.ReportBook.parameters) ? state.ReportBook.parameters : null;
        },

        SelectedEntry(state): api.ReportBookEntry | null {
            if (state.SelectedEntryIndex !== null && state.ReportBook && state.ReportBook.entries)
                return state.ReportBook.entries[state.SelectedEntryIndex];
            else
                return null;
        },

        SelectedParameter(state): api.BookAndFormParameter | null {
            if (state.SelectedParameterIndex !== null && state.ReportBook && state.ReportBook.parameters)
                return state.ReportBook.parameters[state.SelectedParameterIndex];
            else
                return null;
        },

        ReportBookParameterValue: (state) => (name: string) => {
            var newValue = new api.FormParameterValue();
            newValue.key = newValue.name = newValue.caption = `{BookParameter.${name}.Key}`;
            return newValue;
        },

        FollowsReportBookParameterNamingConvention: (state) => (param: api.FormParameter) => {
            const regex = new RegExp("^{BookParameter\\." + param.name + "\\d*\\.Key}$");
            var name = param.currentValue!.name;
            return name ? regex.test(name!) : false;
        },

        AnyFormParametersPointToThisRBParameter: (state, getters) => (reportBookParameterName: string) => {
            var formParameterValue: api.FormParameterValue = getters["ReportBookParameterValue"](reportBookParameterName);
            var key = formParameterValue.key;

            var entries: api.ReportBookEntry[] = getters["ReportBookEntries"];
            for (var entry of entries) {
                if (entry.parameters && entry.parameters.parameters && entry.parameters.parameters.some(fp => fp.currentValue && fp.currentValue.key === key)) {
                    return true;
                }
            }
            return false;
        }
    }
};

export default ReportBookStore;