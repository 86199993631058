import { GetterTree, MutationTree, Module } from 'vuex';

import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import { ReportBookItem } from '../api/Extensions';

export interface IFormStore {
    SelectedTreeParameterValue: api.FormParameterValue | null;
}

const FormStore: Module<IFormStore, any> = {
    namespaced: true, 
    state: {
        SelectedTreeParameterValue: null,
    },

    mutations: {
        ClearState(state) {
            state.SelectedTreeParameterValue = null;
        },

        SetSelectedTreeParameterValue(state, treeParameterValue: api.FormParameterValue | null) {
            state.SelectedTreeParameterValue = treeParameterValue;
        }
    },

    actions: {

    },

    getters: {

    }
};

export default FormStore;