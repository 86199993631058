<template>
    <div class="context_menu" ref="context_menu">
        <slot name="buttons" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component
    export default class ContextMenuPanel extends Vue {
        public get el(): HTMLDivElement {
            return this.$refs.context_menu as HTMLDivElement;
        }

        public get BoundingClientRect(): DOMRect {
            return this.el.getBoundingClientRect();
        }

        public Open(left: number, top: number) {
            var el = this.el;
            el.style.left = left + "px";
            el.style.top = top + "px";
            el.style.visibility = "visible";
        }

        public get IsVisible(): boolean {
            return this.el.style.visibility == "visible";
        }
    }
</script>

<style>
</style>
