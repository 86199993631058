import IIndexable from "./IIndexable";

export class InteractiveColumnDefinition<T extends IIndexable> {
    title: string;
    property: keyof T;
    sortProperty: keyof T;
    filter: Map<string, boolean> = new Map();
    sortStrings: (a: string, b: string) => number = (a, b) => ((a ?? "").toLocaleLowerCase() > (b ?? "").toLocaleLowerCase()) ? 1 : (((a ?? "").toLocaleLowerCase() < (b ?? "").toLocaleLowerCase()) ? -1 : 0);
    sortNumbers: (a: string, b: string) => number = (a, b) => (parseFloat(a??0) - parseFloat(b??0));
    sortDates: (a: Date | null, b: Date | null) => number = (a, b) => (a ?? new Date(2999, 1, 1)).getTime() - (b ?? new Date(2999, 1, 1)).getTime();
    sortStatus: (a: number, b: number) => number = (a, b) => b - a;


    public constructor(title: string, property: keyof T, sortProperty?: keyof T) {
        this.title = title;
        this.property = property;
        if (sortProperty != null)
            this.sortProperty = sortProperty;
        else
            this.sortProperty = property;
    }

    public RefreshFilter(activities: T[]) {
        var result: [string, any, boolean][] = [];
        var type = typeof "";
        for (const activity of activities) {
            const key = activity[this.property];
            const sortkey = activity[this.sortProperty];
            if (sortkey != null)
                type = typeof (sortkey);
            const key_str = (key as string | number).toString();
            const oldValue = this.filter.get(key_str);
            result.push([key_str, sortkey, oldValue === undefined || oldValue]);
        }
        if (type == 'string') {
            this.filter = new Map(result.sort((x, y) => this.sortStrings(x[1], y[1])).map(x => [x[0], x[2]]));
        }
        else if (type == 'number' && this.sortProperty == "status") {
            this.filter = new Map(result.sort((x, y) => this.sortStatus(x[1], y[1])).map(x => [x[0], x[2]]));
        }
        else if (type == 'number') {
            this.filter = new Map(result.sort((x, y) => this.sortNumbers(x[1], y[1])).map(x => [x[0], x[2]]));
        }
        else {
            this.filter = new Map(result.sort((x, y) => this.sortDates(x[1], y[1])).map(x => [x[0], x[2]]));
        }
    }

    public Filter(activity: T): boolean {
        return this.filter.get(activity[this.property].toString())!;
    }

    public ClearFilter(val: boolean) {
        const result = new Map<string, boolean>(this.filter);
        for (const key of result.keys())
            result.set(key, val);

        this.filter = result;
    }

    public ToggleFilter(key: string) {
        const result = new Map<string, boolean>(this.filter);
        result.set(key, !this.filter.get(key));

        this.filter = result;
    }
}

export class UserInteractiveColumnDefinition<T extends IIndexable> extends InteractiveColumnDefinition<T> {
    public RefreshFilter(activities: T[]) {
        const result = new Map();
        for (const activity of activities) {
            const users = (activity[this.property] as string | number).toString().split(", ");
            for (const user of users) {
                const oldValue = this.filter.get(user);
                result.set(user, oldValue === undefined || oldValue);
            }
        }

        this.filter = new Map([...result].sort((x,y) => this.sortStrings(x[0], y[0])));
    }

    public Filter(activity: T): boolean {
        const users = (activity[this.property] as string | number).toString().split(", ");
        for (const user of users) {
            if (this.filter.get(user))
                return true;
        }
        return false;
    }
}
