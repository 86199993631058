<template>
    <div class="node">
        <div class="node-main" v-bind:class="{selected: IsSelected}" @click.stop="Value_Click()">
            <div class="expander" v-bind:class="{expanded: IsExpanded, collapsed: !IsExpanded, hidden: !HasChildren}" @click.stop="Expander_Click" />
            <span class="label" v-bind:class="{disabled: DisplayOnly}">{{Label}}</span>
        </div>

        <transition name="fso-expanded">
            <div class="children" v-if="IsExpanded">
                <transition-group name="fso-expanded" tag="span">
                    <TreeParameterNode v-for="child, i in Children" v-bind:key="i" :Definition="child" />
                </transition-group>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import { TreeParameterNodeDefinition as NodeDefinition } from "../../../api/Extensions";
    import * as api from "../../../api/ApiClient";
    import * as apiBase from "../../../api/ApiClientBase";

    @Component({ name: "TreeParameterNode" })
    export default class TreeParameterNode extends Vue {
        @Prop() private Definition!: NodeDefinition;
        get Value(): api.FormParameterValue {
            return this.Definition.Value;
        }
        get Label(): string {
            return this.Definition.Label;
        }
        get Parent(): NodeDefinition | null {
            return this.Definition.Parent;
        }
        get Children(): NodeDefinition[] {
            return this.Definition.Children;
        }
        get DisplayOnly(): boolean {
            return this.Value.displayOnly;
        }

        private IsExpanded = false;

        get IsSelected(): boolean {
            return this.Value == this.$store.state.forms.SelectedTreeParameterValue;
        }

        get HasChildren(): boolean {
            return this.Children.length > 0;
        }

        Value_Click() {
            if (!this.DisplayOnly)
                this.$store.commit("forms/SetSelectedTreeParameterValue", this.Value);
        }

        Expander_Click() {
            this.IsExpanded = !this.IsExpanded;
        }
    }
</script>

<style scoped>
    .node {
        padding-bottom: 0px;
    }

    .node-main {
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 28px;
        height: 36px;
        position: relative;
        white-space: nowrap;
    }

        .node-main.selected {
            background-color: var(--accent-color);
            color: var(--accent-foreground);
        }

        .node-main .label {
            overflow: hidden;
            position: absolute;
            left: 56px;
            right: 28px;
            text-overflow: ellipsis;
            cursor: pointer;
            user-select: none;
        }

            .node-main .label.disabled {
                opacity: 0.5;
                cursor: default;
            }

        .expander.hidden {
            visibility: hidden;
        }

    .children {
        padding-left: 20px;
    }
</style>
