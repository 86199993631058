import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-56504cbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "webchat",
  role: "main",
  style: {"width":"100%","height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}