<template>
    <div id="breadcrumb">
        <img src="../../public/icons/home.svg"
             class="breadcrumb-svg"
             style="cursor:pointer; margin-left:4px;"
             @click="goHome">

        <transition-group name="fso" tag="span">
            <span v-for="(item,i) in BreadCrumbItems" :key="item.payload.id" :style="{'--i':i}">
                <img class="arrowLeft breadcrumb-svg">
                <span class="breadcrumb-button" @click="BreadcrumbItem_Click(item)">{{ getTranslation(item.name)  }}</span>
            </span>
        </transition-group>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import RouteNames from '../routing/RouteNames';
    import * as api from '../api/ApiClient';
    import { Localizer } from '@/api/Localizer';
    import * as uiIntegration from '@/api/FluenceIntegration';

    class BreadcrumbItem {

        public name: string;
        public location: any;
        public payload: any | null;

        constructor(name: string, location: any, payload: api.FileSystemObjectContent | string) {
            this.name = name;
            this.location = location;
            if (typeof payload === 'object') {
                this.payload = payload;
            }
            else {
                this.payload = { id: payload };
            }
        }
    }

    @Component
    export default class Breadcrumb extends Vue {
        private Localizer = new Localizer(this.$store);

        getTranslation(toTranslate : string) : string {            
            return this.Localizer.Localize(toTranslate);
        }

        get ModuleSecurityAccess(): api.ModuleAccess[] | null {
            return this.$store.state.security.ModuleSecurity;
        }

        get UserCanAccessModels(): boolean {
            return this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelManagementManage);
        }

        get UserCanAccessModelSecurity(): boolean {
            return this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityMetadataSecurityAssign);
        }

        get UserCanAccessHomepages(): boolean {
            return this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.HomepagesHomepageManagementManage);
        }

        // eslint-disable-next-line complexity
        get BreadCrumbItems(): BreadcrumbItem[] {
            const result: BreadcrumbItem[] = [];
            const route = this.$route.name;

            if (route == RouteNames.ExplorerRoute) {
                const selectedFso = this.$store.state.explorer.SelectedFso as api.FileSystemObjectContent | null;
                if (selectedFso != null && selectedFso.id != null) {
                    const hierarchy = this.$store.getters['explorer/getFsoHierarchyById']
                        (selectedFso.id) as api.FileSystemObjectContent[] | null;

                    if (hierarchy != null) {
                        for (let i = 0; i < hierarchy.length; i++) {
                            const myFso = hierarchy[i];
                            result.push(new BreadcrumbItem(
                                i == 0 ? this.Localizer.Localize('Breadcrumb_Explorer') : myFso.name!,
                                { name: route, query: { selectedFso: myFso.id.toString() } },
                                hierarchy[i]
                            ));
                        }
                    }
                }
            }

            else if (route == RouteNames.SecurityManagementUsersRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security'),
                    { name: RouteNames.SecurityManagementRoute },
                    RouteNames.SecurityManagementRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security_Users'),
                    { name: route },
                    RouteNames.SecurityManagementUsersRoute
                ));
            }

            else if (route == RouteNames.MatchingUI) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Sidebar_Matching'),
                    { name: RouteNames.MatchingUI },
                    RouteNames.MatchingUI
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Bank_To_GL'),
                    { name: RouteNames.MatchingUI },
                    RouteNames.MatchingUI
                ));
            }

            else if (route == RouteNames.SecurityManagementRolesRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security'),
                    { name: RouteNames.SecurityManagementRoute },
                    RouteNames.SecurityManagementRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security_Roles'),
                    { name: route },
                    RouteNames.SecurityManagementRolesRoute
                ));
            }
            else if (route == RouteNames.SecurityManagementModulesRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security'),
                    { name: RouteNames.SecurityManagementRoute },
                    RouteNames.SecurityManagementRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security_Modules'),
                    { name: route },
                    RouteNames.SecurityManagementModulesRoute
                ));
            }
            else if (route == RouteNames.LogManagementAuditTrailRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Logs'),
                    { name: RouteNames.LogsRoute },
                    RouteNames.LogsRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security_Audit_Trail'),
                    { name: route },
                    RouteNames.LogManagementAuditTrailRoute
                ));
            }
            else if (route == RouteNames.LogManagementEtlLogsRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Logs'),
                    { name: RouteNames.LogsRoute },
                    RouteNames.LogsRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security_Import_DrillBack'),
                    { name: route },
                    RouteNames.LogManagementEtlLogsRoute
                ));
            }
            else if (route == RouteNames.SecurityManagementUserReportsRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security'),
                    { name: RouteNames.SecurityManagementRoute },
                    RouteNames.SecurityManagementRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Security_User_Reports'),
                    { name: route },
                    RouteNames.SecurityManagementUserReportsRoute
                ));
            }
            else if (route == RouteNames.DataMapsRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('DataMaps_Title'),
                    { name: route },
                    RouteNames.DataMapsRoute
                ));
            }
            else if (route == RouteNames.MemberMappingsViewRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('DataMaps_Title'),
                    { name: RouteNames.DataMapsRoute },
                    RouteNames.DataMapsRoute
                ));
                const selectedMap = this.$store.state.etl.SelectedDataMap as api.DataMapInfo | null;
                if (selectedMap != null) {
                    result.push(new BreadcrumbItem(
                        selectedMap.sourceName!,
                        { name: route, query: { selectedMap: selectedMap.sourceName! } },
                        selectedMap.sourceName!
                    ));
                }
            }
            else if (route == RouteNames.DataModelMappingsRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('DataImportDefinitions_Title'),
                    { name: route },
                    RouteNames.DataModelMappingsRoute
                ));
            }
            else if (route == RouteNames.ClearDataViewRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('DataImportDefinitions_Title'),
                    { name: RouteNames.DataModelMappingsRoute },
                    RouteNames.DataModelMappingsRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('ClearDataSettings_Title'),
                    { name: route },
                    RouteNames.ClearDataViewRoute
                ));
            }
            else if (route == RouteNames.FieldMappingsViewRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('DataImportDefinitions_Title'),
                    { name: RouteNames.DataModelMappingsRoute },
                    RouteNames.DataModelMappingsRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('FieldMappingView_FieldMapping'),
                    { name: route },
                    RouteNames.FieldMappingsViewRoute
                ));
            }
            else if (route == RouteNames.DataConnectionStringsRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Connection_Strings'),
                    { name: route },
                    RouteNames.DataConnectionStringsRoute
                ));
            }
            else if (route == RouteNames.DataStagingTableRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Staging_Tables'),
                    { name: route },
                    RouteNames.DataStagingTableRoute
                ));
            }
            else if (route == RouteNames.DataStagingTableEditRoute) {
                const selectedTable = this.$store.state.etl.SelectedStagingTable as api.StagingTableInfo | null;
                const tableName = selectedTable ? selectedTable.tableName : null;
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Staging_Tables'),
                    { name: RouteNames.DataStagingTableRoute },
                    RouteNames.DataStagingTableRoute
                ));
                result.push(new BreadcrumbItem(
                    tableName ? tableName : this.Localizer.Localize('StagingTableDetails_New_Table'),
                    { name: route, query: { name: tableName } },
                    RouteNames.DataStagingTableEditRoute
                ));
            }
            else if (route == RouteNames.DataThemesRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Themes_Title'),
                    { name: route },
                    RouteNames.DataThemesRoute
                ));
            }
            else if (route == RouteNames.DataThemeDetailsRoute) {
                const selectedTheme = this.$store.state.theme.ThemeDetails;
                const themeName = selectedTheme ? selectedTheme.themeName : null;
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Configure'),
                    { name: RouteNames.DataRoute },
                    RouteNames.DataRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Themes_Title'),
                    { name: RouteNames.DataThemesRoute },
                    RouteNames.DataThemesRoute
                ));
                result.push(new BreadcrumbItem(
                    themeName ? themeName : this.Localizer.Localize('Theme_Details_New_Theme'),
                    { name: route, query: { name: themeName } },
                    RouteNames.DataThemeDetailsRoute
                ));
            }
            else if (route == RouteNames.ModelViewRoute) {
                if (this.UserCanAccessModels) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Metadata'),
                        { name: RouteNames.ModelsRoute },
                        RouteNames.ModelsRoute
                    ));
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Models'),
                        { name: RouteNames.ModelViewRoute },
                        RouteNames.ModelViewRoute
                    ));
                }
            }
            else if (route == RouteNames.DimensionViewRoute) {
                if (this.UserCanAccessModels) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Metadata'),
                        { name: RouteNames.ModelsRoute },
                        RouteNames.ModelsRoute));
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Dimensions'),
                        { name: RouteNames.DimensionViewRoute },
                        RouteNames.DimensionViewRoute
                    ));
                }
            }
            else if (route == RouteNames.ModelViewsViewRoute) {
                if (this.UserCanAccessModels) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Metadata'),
                        { name: RouteNames.ModelsRoute },
                        RouteNames.ModelsRoute
                    ));
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Model_ModelViews'),
                        { name: RouteNames.ModelViewsViewRoute },
                        RouteNames.ModelViewsViewRoute
                    ));
                }
            }
            else if (route == RouteNames.ModelSubsetsRoute) {
                if (this.UserCanAccessModels) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Metadata'),
                        { name: RouteNames.ModelsRoute },
                        RouteNames.ModelsRoute
                    ));
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Model_Subsets'),
                        { name: RouteNames.ModelSubsetsRoute },
                        RouteNames.ModelSubsetsRoute
                    ));
                }
            }
            else if (route == RouteNames.ModelListsRoute) {
                if (this.UserCanAccessModels) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Metadata'),
                        { name: RouteNames.ModelsRoute },
                        RouteNames.ModelsRoute
                    ));
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Model_Lists'),
                        { name: RouteNames.ModelListsRoute },
                        RouteNames.ModelListsRoute
                    ));
                }
            }
            else if (route == RouteNames.ModelDataMappingsRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Metadata'),
                    { name: RouteNames.ModelsRoute },
                    RouteNames.ModelsRoute
                ));
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Model_Data_Mapping'),
                    { name: RouteNames.ModelDataMappingsRoute },
                    RouteNames.ModelDataMappingsRoute
                ));
            }
            else if (route == RouteNames.ModelDimensionSecurityRoute) {
                if (this.UserCanAccessModels) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Metadata'),
                        { name: RouteNames.ModelsRoute },
                        RouteNames.ModelsRoute
                    ));
                    if (this.UserCanAccessModelSecurity) {
                        result.push(new BreadcrumbItem(
                            this.Localizer.Localize('Breadcrumb_Model_Dimension_Security'),
                            { name: RouteNames.ModelDimensionSecurityRoute },
                            RouteNames.ModelDimensionSecurityRoute
                        ));
                    }
                }
            }
            else if (route == RouteNames.DimensionHierarchyViewRoute) {
                if (this.UserCanAccessModels) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Metadata'),
                        { name: RouteNames.ModelsRoute },
                        RouteNames.ModelsRoute
                    ));

                    const selectedDimension = this.$store.state.models.SelectedDimension as api.DimensionInfo | null;
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Dimensions'),
                        { name: RouteNames.DimensionViewRoute },
                        RouteNames.DimensionViewRoute
                    ));

                    if (selectedDimension != null) {
                        result.push(new BreadcrumbItem(
                            selectedDimension.dimensionName!,
                            { query: { selectedDimension: selectedDimension.dimensionName } },
                            `${RouteNames.DimensionHierarchyViewRoute}_${selectedDimension.dimensionName}`
                        ));
                        const selectedHierarchy = this.$store.state.models.SelectedHierarchy as string | null;
                        if (selectedHierarchy != null) {
                            result.push(new BreadcrumbItem(
                                selectedHierarchy,
                                { query: { selectedDimension: selectedDimension.dimensionName, selectedHierarchy } },
                                `${RouteNames.DimensionHierarchyViewRoute}_${selectedDimension.dimensionName}_${selectedHierarchy}`
                            ));
                        }
                    }
                }
            }
            else if (route == RouteNames.ModelViewEditorRoute) {
                if (this.UserCanAccessModels) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Metadata'),
                        { name: RouteNames.ModelsRoute },
                        RouteNames.ModelsRoute
                    ));

                    const selectedModelView = this.$store.state.models.SelectedModelView as api.ModelViewInfo | null;
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Model_ModelViews'),
                        { name: RouteNames.ModelViewsViewRoute },
                        RouteNames.ModelViewsViewRoute
                    ));

                    if (selectedModelView != null) {
                        result.push(new BreadcrumbItem(
                            selectedModelView.viewName!,
                            { query: { selectedDimension: selectedModelView.viewName } },
                            `${RouteNames.ModelViewEditorRoute}_${selectedModelView.viewName}`
                        ));
                    }
                }
            }
            else if (route == RouteNames.WorkflowRoute) {
                const path = this.$store.state.workflow.WorkflowPath;
                let hierarchy: api.FileSystemObjectContent[] | null = null;
                if (path != null) {
                    hierarchy = this.$store.getters['explorer/getFsoHierarchyByPath'](path);
                }
                if (hierarchy != null) {
                    for (let i = 0; i < hierarchy.length; i++) {
                        const myFso = hierarchy[i];
                        if (myFso.objectType == api.FileSystemType.Folder) {
                            result.push(new BreadcrumbItem(
                                i == 0 ? this.Localizer.Localize('Breadcrumb_Explorer') : myFso.name!,
                                { name: RouteNames.ExplorerRoute, query: { selectedFso: myFso.id.toString() } },
                                hierarchy[i]
                            ));
                        }
                    }
                }

                let id = null;
                if (this.$route.query['id'] != null) {
                    id = this.$route.query['id'];
                }
                const name = this.$store.state.workflow.WorkflowName;
                if (name != null) {
                    result.push(new BreadcrumbItem(
                        name!,
                        { name: route, query: { name, id, t: (Date.now()).toString() } },
                        'process'
                    ));
                }
                const subprocess = this.$store.state.workflow.CurrentSubprocessNodes as any[];
                if (subprocess != null && subprocess.length > 0) {
                    for (let i = 0; i < subprocess.length; i++) {
                        const actionName = subprocess[i].activity.name;
                        const t = Date.now();
                        result.push(new BreadcrumbItem(
                            actionName!,
                            { name: route, query: { name, id, subprocess: actionName, t: t.toString() } },
                            i + actionName
                        ));
                    }
                }
            }

            else if (route == RouteNames.ReportBookRoute) {
                const fso = this.$store.state.reportbook.ReportBook.fileSystemObject;
                const hierarchy: api.FileSystemObjectContent[] | null
                    = this.$store.getters['explorer/getFsoHierarchyByPath'](fso.path);
                if (hierarchy != null) {
                    for (let i = 0; i < hierarchy.length; i++) {
                        const myFso = hierarchy[i];
                        if (myFso.objectType == api.FileSystemType.Folder) {
                            result.push(new BreadcrumbItem(
                                i == 0 ? this.Localizer.Localize('Breadcrumb_Explorer') : myFso.name!,
                                { name: RouteNames.ExplorerRoute, query: { selectedFso: myFso.id.toString() } },
                                hierarchy[i]
                            ));
                        }
                    }
                }

                const name = fso.name;
                if (name) {
                    result.push(new BreadcrumbItem(
                        name,
                        { name: route, query: { id: fso.id } },
                        'reportbook'
                    ));
                }
            }

            else if (route == RouteNames.PowerBIRoute) {
                // @ts-ignore
                const reportPath = parseInt(this.$route.params.pathMatch);
                let fso;
                const hierarchy: api.FileSystemObjectContent[] | null
                    = this.$store.getters['explorer/getFsoHierarchyById'](reportPath);
                if (hierarchy != null) {
                    for (let i = 0; i < hierarchy.length; i++) {
                        const myFso = hierarchy[i];
                        if (myFso.objectType == api.FileSystemType.Folder) {
                            result.push(new BreadcrumbItem(
                                i == 0 ? this.Localizer.Localize('Breadcrumb_Explorer') : myFso.name!,
                                { name: RouteNames.ExplorerRoute, query: { selectedFso: myFso.id.toString() } },
                                hierarchy[i]
                            ));
                        }
                        else {
                            fso = myFso;
                        }
                    }
                }
                if (fso) {
                    const name = fso.name;
                    if (name) {
                        result.push(new BreadcrumbItem(
                            name,
                            { params: this.$route.params, name: route },
                            'report'
                        ));
                    }
                }
            }

            else if (route == RouteNames.WorkflowContributorRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Breadcrumb_Workflow_Contributor'),
                    { name: route },
                    route
                ));
            }

            else if (route == RouteNames.DashboardRoute) {
                const selectedSection = this.$store.state.dashboard.SelectedHomepage as api.DashboardSection | null;
                if (selectedSection) {
                    const localizedName = this.Localizer.ParseLocalizedString(selectedSection.name);
                    if (localizedName) {
                        result.push(new BreadcrumbItem(localizedName,
                            { name: route, query: this.$route.query }, localizedName));
                    }
                }
            }

            else if (route == RouteNames.DataHomepagesRoute) {
                if (this.UserCanAccessHomepages) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Configure'),
                        { name: RouteNames.DataRoute },
                        RouteNames.DataRoute
                    ));
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Homepages_Homepages'),
                        { name: route },
                        route
                    ));
                }
            }

            else if (route == RouteNames.DataHomepageDesignerRoute) {
                if (this.UserCanAccessHomepages) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Breadcrumb_Configure'),
                        { name: RouteNames.DataRoute },
                        RouteNames.DataRoute
                    ));
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Homepages_Homepages'),
                        { name: RouteNames.DataHomepagesRoute },
                        route
                    ));
                    const selectedSection = this.$store.state.dashboard.SelectedHomepage as api.DashboardSection | null;
                    if (selectedSection) {
                        const localizedName = this.Localizer.ParseLocalizedString(selectedSection.name);
                        if (localizedName) {
                            result.push(new BreadcrumbItem(localizedName,
                                { name: route, query: this.$route.query }, localizedName));
                        }
                    }
                }
            }

            else if (route == RouteNames.JournalsRoute || route == RouteNames.JournalsEditRoute
                || route == RouteNames.JournalsSelectRoute || route == RouteNames.JournalsReportingRoute
                || route == RouteNames.JournalsAuditRoute ) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('JournalsEdit_Journals'),
                    { name: RouteNames.JournalsSelectRoute },
                    RouteNames.JournalsSelectRoute
                ));
                if (route != RouteNames.JournalsSelectRoute) {
                    const journal = this.$store.state.journals.SelectedJournal as api.JournalDefinition | null;
                    if (journal) {
                        result.push(new BreadcrumbItem(
                            journal.name!,
                            { name: RouteNames.JournalsEditRoute, query: { journal: journal.name } },
                            journal.name ?? ''
                        ));
                    }
                    if (route == RouteNames.JournalsReportingRoute) {
                        result.push(new BreadcrumbItem(
                            this.Localizer.Localize('JournalsEdit_Reporting'),
                            { name: RouteNames.JournalsReportingRoute },
                            route
                        ));
                    }
                    if (route == RouteNames.JournalsAuditRoute && journal) {
                        result.push(new BreadcrumbItem(
                            this.Localizer.Localize('JournalsEdit_JournalAuditSummary'),
                            { name: RouteNames.JournalsAuditRoute, query: { journal: journal.name }},
                            route
                        ));
                    }
                }
            }

            else if (route == RouteNames.MaintenanceTypeSelectorRoute) {
                result.push(new BreadcrumbItem(
                    this.Localizer.Localize('Sidebar_Maintenance'),
                    { name: RouteNames.MaintenanceRoute },
                    RouteNames.MaintenanceRoute
                ));
            }

            else if (route == RouteNames.MaintenanceFormListSelectorRoute) {
                if (this.UserCanAccessHomepages) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Sidebar_Maintenance'),
                        { name: RouteNames.MaintenanceRoute },
                        RouteNames.MaintenanceRoute
                    ));
                    const type = this.$store.state.maintenance.ListType as string;
                    if (type) {
                        result.push(new BreadcrumbItem(type, { name: route, query: this.$route.query }, type));

                    }
                }
            }

            else if (route == RouteNames.MaintenanceEditRoute) {
                if (this.UserCanAccessHomepages) {
                    result.push(new BreadcrumbItem(
                        this.Localizer.Localize('Sidebar_Maintenance'),
                        { name: RouteNames.MaintenanceRoute },
                        RouteNames.MaintenanceRoute
                    ));
                    const type = this.$store.state.maintenance.ListType as string;
                    if (type) {
                        result.push(new BreadcrumbItem(
                            type,
                            { name: RouteNames.MaintenanceFormListSelectorRoute },
                            type
                        ));
                    }
                    const form = this.$store.state.maintenance.HeaderForm as api.AdminForm;
                    if (form) {
                        result.push(new BreadcrumbItem(this.Localizer.ParseLocalizedString(form.displayName)!,
                            { name: route, query: this.$route.query }, this.Localizer.ParseLocalizedString(form.displayName)!));
                    }

                }
            }
            else if (route == RouteNames.HomeExtensionRoute) {
            var breadcrumbs = this.$store.state.HomeExtensionBreadcrumbs as uiIntegration.FluenceBreadcrumbMessage[];
                if (breadcrumbs) {
                    for (var i = 0; i < breadcrumbs.length; i++) {
                        var myBC = breadcrumbs[i];
                        result.push(new BreadcrumbItem(myBC.caption, { name: RouteNames.HomeExtensionRoute, query: myBC.params }, RouteNames.HomeExtensionRoute));
                    }
                }
            }

            return result;
        }

        BreadcrumbItem_Click(item: BreadcrumbItem) {
            const route = this.$route.name;
            const selectedFso = this.$store.state.explorer.SelectedFso as api.FileSystemObjectContent | null;
            if (selectedFso != null && selectedFso.id != null) {
                if (selectedFso !== item.payload) {
                    this.$store.commit('explorer/SelectFso', item.payload);
                }
            }
            const temp = item.location as any;
            if (route === RouteNames.ModelsRoute) {
                if (temp.query != null) {
                    if (temp.query.selectedHierarchy == null) {
                        this.$store.commit('models/SetSelectedHierarchy',
                            `(${this.Localizer.Localize('default_hierarchy_name')})`);
                    }
                }
                else {
                    this.$store.commit('models/SetSelectedDimension', null);
                    
                }
            }
            else {
                this.$store.commit('models/SetSelectedHierarchy', null);
            } 
            this.$router.push(item.location);
        }

        goHome() {
            if (this.$store.state.security.UserData.hasDashboard) {
                this.$router.push({ name: RouteNames.DashboardRoute });
            }
            else {
                this.$router.push({ name: RouteNames.WorkflowContributorRoute });
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #breadcrumb {
        height: 40px;
        line-height: 40px;
        background-color: var(--background-breadcrumb);
        color: var(--foreground-breadcrumb);
        border-bottom: 1px solid var(--thin-border);
        border-top: 1px solid var(--thin-border);
        font-weight: bold;
        padding-left: 14px;
        width: 100%;
        position: absolute;
        top: 46px;
        right: 0;
        left: 0;
        z-index: 100;
    }

    .arrowLeft {
        content: url("../../public/icons/arrow_right.svg");
        padding: 6px;
    }

    .breadcrumb-button {
        cursor: pointer;
    }

    .fso-leave-active {
    }

    .fso-enter-active {
        transition: all .3s ease;
        transition-delay: calc(.08s * var(--i));
    }

    .fso-enter-from, .fso-leave-to {
        opacity: 0;
    }
</style>
