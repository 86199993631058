import { GetterTree, MutationTree, Module } from 'vuex';

import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import * as ext from "../api/Extensions";
import { ActivityValidator } from "../api/ActivityValidator";

import * as jsPlumbToolkit from 'jsplumbtoolkit';
import { WorkflowNotificationsHandler } from '../api/WorkflowNotificationsHandler';
import router from '../routing/Router';
import Vue from 'vue';
import BaseEditableNode from '../components/pages/workflow/Nodes/BaseEditableNode.vue';



export interface IWorkflowStore {
    IsBusy: boolean;

    SelectedActivity: api.BaseProcessActivity | null;
    SelectedActivityInitialValue: string | null;
    SelectedNode: api.InteractiveProcessNode | null;
    SelectedEdge: jsPlumbToolkit.Edge | null;
    SelectedActivityValidator: ActivityValidator | null;
    Workflow: api.InteractiveProcess | null;
    WorkflowState: api.ProcessActivityExecutionState[] | null;
    SelectedSubprocessRun: string;
    Schedules: api.ProcessSchedule[] | null;
    ParentWorkflow: api.InteractiveProcess[];
    CurrentSubprocessNodes: api.InteractiveProcessNode[];
    ContextMenuInfo: { node: BaseEditableNode; event: MouseEvent } | null;

    AvailableDataSources: api.DataSource[] | null;

    IsEditable: boolean;
    IsNew: boolean;
    ProcessRunId: string | null;
    WorkflowPath: string | null;
    WorkflowName: string | null;

    NotificationHandler: WorkflowNotificationsHandler | null;
    LastReceivedNotification: api.ProcessRunActivity | null;

    ZoomLevel: number;
}

const WorkflowStore: Module<IWorkflowStore, any> = {
    namespaced: true,
    state: {
        IsBusy: false,

        SelectedActivity: null,
        SelectedActivityInitialValue: null,
        SelectedNode: null,
        SelectedEdge: null,
        SelectedActivityValidator: null,
        Workflow: null,
        WorkflowState: null,
        SelectedSubprocessRun: "",
        Schedules: null,
        ParentWorkflow: [],
        CurrentSubprocessNodes: [],
        ContextMenuInfo: null,

        AvailableDataSources: null,

        IsEditable: false,
        IsNew: false,
        ProcessRunId: null,
        WorkflowPath: null,
        WorkflowName: null,

        NotificationHandler: null,
        LastReceivedNotification: null,

        ZoomLevel: 1,
    },

    mutations: {
        ClearWorkflowInstanceVariables(state) {
            state.SelectedActivity = state.SelectedActivityInitialValue = state.SelectedNode = state.SelectedEdge = state.SelectedActivityValidator
                = state.ProcessRunId = null;
            state.ParentWorkflow = [];
            state.CurrentSubprocessNodes = [];
        },

        SelectActivity(state, val: api.BaseProcessActivity | null) {
            state.SelectedActivity = val;
            if (val) {
                state.SelectedActivityInitialValue = JSON.stringify(val);
                state.SelectedActivityValidator = ActivityValidator.For(val);
            }
            else {
                state.SelectedActivityInitialValue = "";
                state.SelectedActivityValidator = null;
            }
        },

        SetSelectedNode(state, node: api.InteractiveProcessNode | null) {
            state.SelectedNode = node;
            if (node != null) {
                let activity = node.activity!;
                state.SelectedActivity = activity;
                if (activity.hasOwnProperty('dueDate')) {
                    if ((<api.InteractiveProcessActivity>activity).dueDate == null) {
                        (<api.InteractiveProcessActivity>activity).dueDate == '';
                    }
                }
                state.SelectedActivityInitialValue = JSON.stringify(activity);
                state.SelectedActivityValidator = ActivityValidator.For(activity);
            }
            else {
                state.SelectedActivity = null;
                state.SelectedActivityInitialValue = "";
                state.SelectedActivityValidator = null;
            }
        },

        SetSubprocess(state, node: api.InteractiveProcessNode | null) {
            state.SelectedNode = node;
            if (node != null) {
                if (node.activity instanceof (api.SubProcessProcessActivity)) {
                    state.ParentWorkflow.push(state.Workflow!);
                    state.CurrentSubprocessNodes.push(node);
                    state.Workflow = (node.activity as api.SubProcessProcessActivity).subProcess!;
                    state.SelectedSubprocessRun = "Definition";
                }
            }
            else {
                state.SelectedActivity = null;
                state.SelectedActivityInitialValue = "";
                state.SelectedActivityValidator = null;
                state.SelectedSubprocessRun = "";

            }
        },

        SetSelectedEdge(state, edge: jsPlumbToolkit.Edge | null) {
            state.SelectedEdge = edge;
        },

        EndEditSubprocess(state, workflow: api.InteractiveProcess) {
            var node = state.CurrentSubprocessNodes.pop();
            (node!.activity as api.SubProcessProcessActivity).subProcess = workflow;
            state.Workflow = state.ParentWorkflow.pop()!;

            state.SelectedActivity = null;
            state.SelectedNode = null;
            state.SelectedActivityInitialValue = "";
            state.SelectedActivityValidator = null;
            if (state.CurrentSubprocessNodes.length > 0)
                state.SelectedSubprocessRun = "Definition";
            else
                state.SelectedSubprocessRun = "";
        },

        SetWorkflow(state, Workflow: api.InteractiveProcess | null) {
            state.Workflow = Workflow;
        },

        SetWorkflowState(state, WorkflowState: api.ProcessActivityExecutionState[] | null) {
            state.WorkflowState = WorkflowState;
        },

        SetWorkflowSchedules(state, schedules: api.ProcessSchedule[] | null) {
            state.Schedules = schedules;
        },

        NewWorkflow(state, path: string) {
            state.SelectedActivity = state.SelectedActivityInitialValue = state.SelectedNode = state.SelectedEdge = state.SelectedActivityValidator
                = state.ProcessRunId = null;
            state.ParentWorkflow = [];
            state.CurrentSubprocessNodes = []

            state.IsNew = true;
            state.WorkflowPath = path;
            var parts = path.split("/");
            state.WorkflowName = parts[parts.length - 1];

            var wf = new api.InteractiveProcess();
            wf.edges = [];
            wf.nodes = [];
            wf.startProperties = new api.InteractiveProcessStartProperties()
            wf.startProperties.dueDate = "";

            wf.nodes.push(new api.InteractiveProcessNode());
            wf.nodes[0].left = 0;
            wf.nodes[0].top = 50;
            wf.nodes[0].type = "start";
            wf.nodes[0].w = 200;
            wf.nodes[0].h = 100;
            wf.nodes[0].text = "START";
            wf.nodes[0].state = "Upcoming";
            wf.nodes[0].id = jsPlumbToolkit.jsPlumbUtil.uuid();
            wf.nodes[0].text = "START";

            var spa = new api.StartProcessActivity();
            spa.disabled = false;
            spa.name = "Start";
            spa.timeout = 0;

            wf.nodes![0].activity = spa;

            state.Workflow = wf
        },

        SetOldWorkflowPath(state, path: string) {
            state.WorkflowPath = path;
            state.IsNew = false;
        },

        SetWorkflowName(state, name: string) {
            state.WorkflowName = name;
        },


        SetNotificationHandler(state, notificationHandler: WorkflowNotificationsHandler) {
            if (state.NotificationHandler != null)
                state.NotificationHandler.stop();
            state.NotificationHandler = notificationHandler;
            state.NotificationHandler.start();
        },

        SetLastReceivedNotification(state, activity: api.ProcessRunActivity) {
            state.LastReceivedNotification = activity;
        },

        SetSelectedSubprocessRun(state, subprocessRun: string) {
            state.SelectedSubprocessRun = subprocessRun;
        },


        SetAvailableDataSources(state, dataSources: api.DataSource[]) {
            state.AvailableDataSources = dataSources;
        },

        SetZoomLevel(state, zoom: number) {
            if (zoom && zoom > 0)
                state.ZoomLevel = zoom;
        },

        SetIsBusy(state, busy: boolean) {
            state.IsBusy = busy;
        },

        SetProcessRunId(state, processRunId: string) {
            state.ProcessRunId = processRunId;
        },

        SetIsNew(state, isnew: boolean) {
            state.IsNew = isnew;
        },

        SetIsEditable(state, isEditable: boolean) {
            state.IsEditable = isEditable;
        },



        FixSubprocessActivity(state, nodeId: string) {
            var subprocessNode = state.Workflow!.nodes!.find(node => node.id == nodeId)!;
            var subprocessActivity = subprocessNode.activity;
            var subprocess = (subprocessActivity as any as api.SubProcessProcessActivity).subProcess;
            var newSubProcess = (subprocessActivity as any as api.SubProcessProcessActivity).subProcess = new api.InteractiveProcess();
            newSubProcess.nodes = [];
            newSubProcess.edges = [];
            for (var j = 0; j < subprocess!.nodes!.length; j++) {
                var node = subprocess!.nodes![j];
                var newNode = new api.InteractiveProcessNode();
                newNode.activity = node.activity;
                newNode.h = node.h;
                newNode.id = node.id;
                newNode.left = node.left;
                newNode.state = node.state;
                newNode.text = node.text;
                newNode.top = node.top;
                newNode.type = node.type;
                newNode.w = node.w;
                newSubProcess.nodes!.push(newNode);
            }
            for (var j = 0; j < subprocess!.edges!.length; j++) {
                var edge = subprocess!.edges![j];
                var newEdge = new api.InteractiveProcessEdge();
                newEdge.data = new api.InteractiveProcessEdgeData();
                newEdge.data.id = edge.data!.id
                newEdge.data.label = edge.data!.label
                newEdge.data.type = edge.data!.type
                newEdge.source = edge.source;
                newEdge.target = edge.target;
                newEdge.geometry = edge.geometry;
                newSubProcess.edges!.push(newEdge);
            }
        },

        FixSubprocessActivityNode(state, subprocessNode: api.InteractiveProcessNode) {
            var subprocessActivity = subprocessNode.activity;
            var subprocess = (subprocessActivity as any as api.SubProcessProcessActivity).subProcess;
            var newSubProcess = (subprocessActivity as any as api.SubProcessProcessActivity).subProcess = new api.InteractiveProcess();
            newSubProcess.nodes = [];
            newSubProcess.edges = [];
            for (var j = 0; j < subprocess!.nodes!.length; j++) {
                var node = subprocess!.nodes![j];
                var newNode = new api.InteractiveProcessNode();
                newNode.activity = node.activity;
                newNode.h = node.h;
                newNode.id = node.id;
                newNode.left = node.left;
                newNode.state = node.state;
                newNode.text = node.text;
                newNode.top = node.top;
                newNode.type = node.type;
                newNode.w = node.w;
                newSubProcess.nodes!.push(newNode);
            }
            for (var j = 0; j < subprocess!.edges!.length; j++) {
                var edge = subprocess!.edges![j];
                var newEdge = new api.InteractiveProcessEdge();
                newEdge.data = new api.InteractiveProcessEdgeData();
                newEdge.data.id = edge.data!.id
                newEdge.data.label = edge.data!.label
                newEdge.data.type = edge.data!.type
                newEdge.source = edge.source;
                newEdge.target = edge.target;
                newEdge.geometry = edge.geometry;
                newSubProcess.edges!.push(newEdge);
            }
        },

        SetContextMenuInfo(state, info: { node: BaseEditableNode; event: MouseEvent } | null) {
            state.ContextMenuInfo = info;
        }
    },

    actions: {
        WorkflowRunFinished(context) {
            this.dispatch("ShowToast", new api.ToastNotification({
                message: context.rootState.Localizer.Localize("WorkflowStore_Workflow_run_finished"),
                type: api.ToastType.Info
            }));
            context.commit("SetProcessRunId", null);
        },

        async LoadWorkflow(context, fso: api.FileSystemObjectContent) {
            context.commit("SetZoomLevel", 1);
            context.commit("SetIsEditable", true);
            while (context.state.IsBusy == true) {
                //avoid loading 2 processes at the same time.
                await new Promise(resolve => setTimeout(resolve, 200));
            }

            context.commit("SetIsBusy", true);
            try {
                var name = fso.path + "/" + fso.name;
                while (name.startsWith('//')) // fix for parent being the root
                    name = name.replace("//", "/");
                context.commit("ClearWorkflowInstanceVariables");
                context.commit("SetOldWorkflowPath", name);
                context.commit("SetWorkflowName", fso.name);

                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.ProcessClient(auth_client);
                var client2 = new api.ProcessExecutionClient(auth_client);

                var processPromise = client.loadInteractive(name);
                var workflowStatePromise = client.getInteractiveProcessExecutionState(name);
                var schedulesPromise = client2.getSchedule(name);

                var process = await processPromise;
                var workflowState = await workflowStatePromise;
                var schedules = await schedulesPromise;

                context.commit("SetProcessRunId", null);
                for (var j = 0; j < workflowState.length; j++) {
                    if (workflowState[j].processRunId != null) {
                        context.commit("SetProcessRunId", workflowState[j].processRunId);
                        break;
                    }
                }

                console.log(workflowState);

                context.commit("SetWorkflowState", workflowState);
                context.commit("SetWorkflow", process);
                context.commit("SetWorkflowSchedules", schedules);

            }
            finally {
                context.commit("SetIsBusy", false);
            }
        },

        async CreateSchedule(context, schedule: api.ProcessSchedule) {

            context.state.Schedules!.push(schedule);
        },

        async SaveWorkflow(context) {
            try {
                context.commit("SetIsBusy", true);
                var auth_client = new AuthClient();
                await auth_client.ensureToken();

                var client = new api.ProcessClient(auth_client);
                await context.dispatch("FixSubprocessActivities");
                await client.saveInteractive(context.state.WorkflowPath!, context.state.Workflow!);

                //var ModuleSecurityAccess = context.state.security.ModuleSecurity;
                //var UserCanScheduleWorkflow = ModuleSecurityAccess != null && ModuleSecurityAccess.includes(api.ModuleAccess.WorkflowManagementManage);

                if (true) {
                    var client2 = new api.ProcessExecutionClient(auth_client);
                    if (context.state.Schedules != null)
                        await client2.setSchedule(context.state.Schedules!, context.state.WorkflowPath!);
                }

                context.commit("SetOldWorkflowPath", context.state.WorkflowPath);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("WorkflowStore_Workflow_saved"),
                    type: api.ToastType.Info
                }));
                console.log("Workflow " + context.state.WorkflowPath! + " saved!");
            }
            finally {
                context.commit("SetIsBusy", false);
            }
        },

        async FixSubprocessActivities(context) {
            var fix = function (workflow: api.InteractiveProcess | null | undefined) {
                if (workflow == null || workflow.nodes == null)
                    return;

                for (var i = 0; i < workflow.edges!.length; i++) {
                    if (workflow.edges![i].geometry != null && !(workflow.edges![i].geometry instanceof (api.InteractiveProcessEdgeGeometryData))) {
                        var geometry = workflow.edges![i].geometry;
                        workflow.edges![i].geometry = new api.InteractiveProcessEdgeGeometryData();
                        workflow.edges![i].geometry!.segments = geometry!.segments;
                        workflow.edges![i].geometry!.source = geometry!.source;
                        workflow.edges![i].geometry!.target = geometry!.target;
                    }
                }

                for (var i = 0; i < workflow.nodes.length; i++) {
                    if (workflow.nodes[i]!.activity instanceof (api.SubProcessProcessActivity)) {
                        context.commit("FixSubprocessActivityNode", workflow.nodes[i]);
                        fix((workflow.nodes[i]!.activity as api.SubProcessProcessActivity).subProcess);
                    }
                }
            }

            fix(context.state.Workflow!)
        },

        async RunWorkflow(context, options: string[][] | null) {
            if (context.state.IsNew)
                return;
            context.commit("SetIsBusy", true);
            try {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.ProcessExecutionClient(auth_client);
                context.commit("SetNotificationHandler", new WorkflowNotificationsHandler(context));
                if (options == undefined || options == null)
                    options = [];
                var id = await client.executeInteractiveFileSystem(context.state.WorkflowPath!, options);
                context.state.NotificationHandler!.processRunId = id;
                context.commit("SetProcessRunId", id);
                this.dispatch("ShowToast", new api.ToastNotification({
                    message: context.rootState.Localizer.Localize("WorkflowStore_Workflow_started"),
                    type: api.ToastType.Info
                }));
                console.log(context.rootState.Localizer.Localize("WorkflowStore_notifications_started"));
            }
            finally {
                context.commit("SetIsBusy", false);
            }
        },

        async StopWorkflow(context) {
            if (context.state.ProcessRunId != null) {
                context.commit("SetIsBusy", true);
                try {
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();
                    var client = new api.ProcessExecutionClient(auth_client);
                    await client.stopInteractiveProcess(context.state.ProcessRunId);
                    if (context.state.NotificationHandler != null)
                        context.state.NotificationHandler.stop();
                    context.commit("SetProcessRunId", null);
                    context.commit("SetWorkflowState", null);

                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: context.rootState.Localizer.Localize("WorkflowStore_Workflow_stopped"),
                        type: api.ToastType.Info
                    }));
                }
                finally {
                    context.commit("SetIsBusy", false);
                }
            }
        },

        async LoadDataSources(context) {
            context.commit("SetIsBusy", true);
            try {
                if (context.state.AvailableDataSources == null) {
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();
                    var client = new api.DataSourceClient(auth_client);
                    var datasources = await client.getAvailableDataSources();
                    context.commit("SetAvailableDataSources", datasources);
                }
            }
            finally {
                context.commit("SetIsBusy", false);
            }
        },

        async GetStartProperties(context): Promise<api.FormParameter[] | null | undefined> {
            context.commit("SetIsBusy", true);

            try {
                var workflow = context.state.Workflow;
                if (context.state.ParentWorkflow != null && context.state.ParentWorkflow.length > 0)
                    workflow = context.state.ParentWorkflow[0];
                if (workflow == null || workflow.startProperties == null || workflow.startProperties!.processPropertiesFormPath == null)
                    return [];

                var formPath = workflow.startProperties!.processPropertiesFormPath;

                if (formPath != null) {
                    var auth_client = new AuthClient();
                    await auth_client.ensureToken();
                    var client = new api.FormClient(auth_client);
                    var formparameters = await client.getFormParameters(formPath);
                    if (formparameters.parameters) {
                        formparameters.parameters.forEach(p => p.currentValue = new api.FormParameterValue({
                            isLeaf: true,
                            displayOnly: true
                        }));
                    }
                    return formparameters.parameters;
                }
            }
            finally {
                context.commit("SetIsBusy", false);
            }
        }
    },

    getters: {
        selectedActivityValidator(state):ActivityValidator | null {
            return state.SelectedActivityValidator;
        },
        getAdvanceRules(state, getters, rootState): any[] {
            var rez = [];
            rez.push({ value: "", text: rootState.Localizer.Localize("WorkflowStore_Every_time_a_predecessor_completes") });
            rez.push({ value: api.AdvanceRule.WhenAllPredecesors, text: rootState.Localizer.Localize("WorkflowStore_All_predecessors_have_completed") });
            rez.push({ value: api.AdvanceRule.WhenFirstPredecesor, text: rootState.Localizer.Localize("WorkflowStore_First_time_a_predecessor_completes") });
            return rez;
        },

        getAllActivities(state): api.InteractiveProcessNode[] {
            var result: api.InteractiveProcessNode[] = [];
            if (state.Workflow) {
                var getAllActivitiesInner = function (process: api.InteractiveProcess) {
                    if (process.nodes)
                        for (var node of process.nodes!) {
                            if (node.activity instanceof (api.SubProcessProcessActivity)) {
                                if (node.activity.subProcess)
                                    getAllActivitiesInner(node.activity.subProcess);
                            }
                            else if (node.activity instanceof (api.NoteProcessActivity) || node.activity instanceof (api.StartProcessActivity))
                                continue;
                            else
                                result.push(node);
                        }
                }
                getAllActivitiesInner(state.Workflow);
            }
            return result;
        },

        getNodeById: (state) => (nodeId: string): api.InteractiveProcessNode | null => {
            var workflow = state.ParentWorkflow[0];
            if (workflow == null)
                workflow = state.Workflow!;

            var getNodeByIdInner = function (workflow: api.InteractiveProcess, nodeId: string): api.InteractiveProcessNode | null {
                if (workflow != null && workflow.nodes != null)
                    for (var i = 0; i < workflow.nodes.length; i++) {
                        var node = workflow.nodes[i];
                        if (node != null && node.id == nodeId)
                            return node;
                        if (node.activity != null && node.activity instanceof (api.SubProcessProcessActivity)) {
                            var proc2 = (node.activity as api.SubProcessProcessActivity).subProcess;
                            if (proc2 != null && proc2.nodes != null) {
                                var result = getNodeByIdInner(proc2, nodeId);
                                if (result != null)
                                    return result;
                            }
                        }
                    }
                return null;
            }

            return getNodeByIdInner(workflow, nodeId);
        },

        getDataSource: (state) => (name: string): api.DataSource | null => {
            if (state.AvailableDataSources != null)
                for (var i = 0; i < state.AvailableDataSources.length; i++) {
                    var ds = state.AvailableDataSources[i]
                    if (ds != null && ds.name == name)
                        return ds;
                }
            return null;
        },

        getDataSources: (state) => (provider: api.DataSourceProvider): string[] => {
            var result: string[] = [];
            if (state.AvailableDataSources != null)
                for (var i = 0; i < state.AvailableDataSources.length; i++) {
                    var ds = state.AvailableDataSources[i]
                    if (ds != null && ds.provider == provider && ds.name != null)
                        result.push(ds.name);
                }
            return result;
        },

        getMoreDataSources: (state) => (providers: api.DataSourceProvider[]): string[] => {
            var result: string[] = [];
            if (state.AvailableDataSources != null)
                for (var i = 0; i < state.AvailableDataSources.length; i++) {
                    var ds = state.AvailableDataSources[i]
                    if (ds != null && ds.name != null && providers.includes(ds.provider))
                        result.push(ds.name);
                }
            return result;
        }
    }
};

export default WorkflowStore;
