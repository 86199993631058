<template>
    <div class="node">
        <div class="node-main" v-bind:class="{selected: IsSelected}" @click.stop="Value_Click()">
            <div class="expander" v-bind:class="{expanded: IsExpanded, collapsed: !IsExpanded, hidden: !HasChildren}" @click.stop="Expander_Click" />
            <span data-toggle="tooltip"
                  data-placement="right"
                  :title="Value.caption"
                  :class="['label', IsExactMatch && Search !== '' ? 'bold' : '']">{{Value[TreeControlLabel.toLowerCase()]}}</span>
        </div>

        <transition name="fso-expanded">
            <div class="children" v-show="IsExpanded">
                <transition-group name="fso-expanded" tag="span">
                    <TreeItem v-for="(child, i) in Children"
                              :key="i"
                              :Definition="child"
                              :Search="Search"
                              v-show="showSearchNodes(child)" />
                </transition-group>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
    import { TreeNodeDefinition as NodeDefinition, TreeNodeValue } from "./form/HierarchicalTreeNode";

    @Component({ name: "TreeItem" })
    export default class TreeItem extends Vue {
        @Prop() private Definition!: NodeDefinition;
        @Prop() private Search!: string;

        get Value(): TreeNodeValue { return this.Definition.Value; }
        get HasChildren(): boolean { return this.Children.length > 0; }
        get Children(): NodeDefinition[] { return this.Definition.Children; }
        get Parent(): NodeDefinition | null { return this.Definition.Parent; }
        get ShowTreeControlName(): boolean { return this.$store.state.excel.ShowTreeControlName; }
        get IsSelected(): boolean { return this.Value == this.$store.state.excel.SelectedTreeNode; }
        get SelectedTreeNode(): TreeNodeValue { return this.$store.state.excel.SelectedTreeNode };
        set SelectedTreeNode(selectedTreeNode: TreeNodeValue) { this.$store.commit("excel/SetSelectedTreeNode", selectedTreeNode); };
        get TreeControlLabel(): string { return this.$store.state.excel.TreeControlLabel };
        set TreeControlLabel(treeControlLabel: string) { this.$store.commit("excel/SetTreeControlLabel", treeControlLabel); };

        IsExpanded: boolean = false;
        IsExactMatch: boolean = false;

        Value_Click() {
            this.$store.commit("excel/SetSelectedTreeNode", this.Value);
        }

        Expander_Click() {
            this.IsExpanded = !this.IsExpanded;
        }

        mounted() {
            this.IsExpanded = this.Definition.IsExpanded;
            let tooltips: any = document.getElementsByTagName('[data-toggle="tooltip"]');
            if (tooltips){
                for (let item of tooltips) {
                    item.tooltip();
                }
            }
            if (this.IsSelected) {
                this.expandParentsSelected(this.Definition);
            }
        }

        @Watch("Search") onSearchChange() {
            this.IsExpanded = this.Definition.IsExpanded;
            this.$nextTick(() => {
                if (this.Search) {
                    this.IsExactMatch = this.Definition.SingleNodeSearch(this.Search);
                    this.IsExpanded = this.Definition.Search(this.Search);
                    if (this.HasChildren) {
                        if (this.Children.find(node => node.IsMatch))
                            this.IsExpanded = true;
                        else
                            this.IsExpanded = false;
                    }
                }
            });
        }
        showSearchNodes(node: NodeDefinition) {
            if (this.Search) {
                return node.IsMatch;
            }
            return true;
        }

        expandParentsSelected(node: NodeDefinition) {
            if (node.Parent) {
                node.Parent.IsExpanded = true;
                this.expandParentsSelected(node.Parent);
            }
        }

    }
</script>

<style scoped>
    .node {
        padding-bottom: 0px;
        cursor: pointer;
        user-select: none;
    }

    .node-main {
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 28px;
        height: 36px;
        position: relative;
        white-space: nowrap;
    }

        .node-main.selected {
            background-color: var(--accent-color);
            color: var(--accent-foreground);
        }

        .node-main .label {
            position: absolute;
            left: 25px;
            right: 28px;
            text-overflow: ellipsis;
        }

    .expander.hidden {
        visibility: hidden;
    }

    .children {
        padding-left: 20px;
    }

    .bold {
        font-weight: bold;
    }
</style>
