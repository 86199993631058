<template>
    <div id="PowerBIContainer" ref="report_container" style="width:100%;height:100%">
        <Spinner v-if="IsBusy"/>
    </div>
</template>

<script lang="ts">
    import * as pbi from 'powerbi-client';
    declare var powerbi: pbi.service.Service;
    import { AuthClient } from "../../api/ApiClientBase";
    import * as api from "../../api/ApiClient";
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import Spinner from "../helpers/Spinner.vue";

    @Component({ components: { Spinner } })
    export default class PowerBI extends Vue {
        IsBusy: boolean = true;

        @Prop()
        private Path!: string | null;
        private get ReportPath(): string {
            if (this.Path != null)
                return this.Path;
            // @ts-ignore
            return this.$route.params.pathMatch;
        }

        async mounted() {
            try {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client1 = new api.PowerBIClient(auth_client);
                var embedInfo = await client1.getEmbedInfo(this.ReportPath);
                //var embedInfo = await client1.getEmbedInfo("/Power BI Test");
                let config: pbi.IEmbedConfiguration = {
                    type: 'report',
                    accessToken: embedInfo.accessToken!,
                    embedUrl: embedInfo.embedUrl!,
                    id: embedInfo.reportId,
                    tokenType: pbi.models.TokenType.Embed,
                    permissions: pbi.models.Permissions.All,
                    viewMode: pbi.models.ViewMode.View,
                };
                powerbi.embed(this.$refs.report_container as any, config);
            }
            catch (err) {
                await this.$store.dispatch("ShowToastError", err);
                this.IsBusy = false;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
