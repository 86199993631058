import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "app",
  style: {"background-color":"var(--background-primary)"}
}
const _hoisted_2 = {
  key: 1,
  style: {"background-color":"var(--background-primary)"}
}
const _hoisted_3 = {
  key: 2,
  id: "app"
}
const _hoisted_4 = {
  id: "main",
  style: {"background-color":"var(--background-primary)"}
}
const _hoisted_5 = { id: "PopupsContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_ChooseServer = _resolveComponent("ChooseServer")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ChooseTenant = _resolveComponent("ChooseTenant")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_WindowsTab = _resolveComponent("WindowsTab")!
  const _component_portal_target = _resolveComponent("portal-target")!
  const _component_ThemeLoader = _resolveComponent("ThemeLoader")!

  return (_openBlock(), _createBlock(_component_ThemeLoader, null, {
    default: _withCtx(() => [
      (_ctx.Username ==='')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Login, { style: {"margin":"0 auto","padding":"50px"} })
          ]))
        : (_ctx.ServerSelectionNeeded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ChooseServer, { style: {"margin":"0 auto","padding":"50px"} })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Header),
              _createVNode(_component_Breadcrumb),
              _createVNode(_component_Sidebar),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.Tenant === null)
                  ? (_openBlock(), _createBlock(_component_ChooseTenant, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Tabs, (tab) => {
                  return (_openBlock(), _createBlock(_component_WindowsTab, {
                    key: tab.Key,
                    TabData: tab
                  }, null, 8, ["TabData"]))
                }), 128))
              ]),
              _createVNode(_component_portal_target, {
                name: "DialogsContainer",
                multiple: ""
              })
            ]))
    ]),
    _: 1
  }))
}