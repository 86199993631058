<template>
    <div v-if="!IsForm" class="adnContainer">
        <Menu></Menu>
    </div>
    <div v-else class="adnContainer">
        <Form></Form>
    </div>
</template>

<script lang="ts">
    /// <reference types="office-js-preview" />

    import { Component, Vue } from 'vue-facing-decorator';

    //Components
    import Menu from './Menu.vue';
    import Form from './Form.vue';
    import * as excelHandler from './form/ExcelHandler'

    @Component({ components: { Menu, Form } })
    export default class Container extends Vue {

        get UIText(): object | null { return this.$store.state.Locale; }
        get TenantOverride(): string | null { return this.$store.state.excel.TenantOverride; }
        set TenantOverride(tenantOverride: string | null) { this.$store.commit("excel/SetTenantOverride", tenantOverride); }
        get IsForm(): boolean { return this.$store.state.excel.IsForm; }
        set IsForm(isForm: boolean) { this.$store.commit("excel/SetIsForm", isForm); }


        async mounted() {
            OfficeExtension.config.extendedErrorLogging = true;
            await this.CheckControlSheet();
            excelHandler.onTaskpaneVisibilityModeChanged();
        }

        private async CheckControlSheet() {
            // check for the control tab
            await Excel.run(async (context) => {
                context.workbook.worksheets.load("items");
                await context.sync();

                for (var sheet in context.workbook.worksheets.items) {
                    var ws: Excel.Worksheet = context.workbook.worksheets.items[sheet];
                    if (ws.name == '_Fluence') {
                        this.IsForm = true;
                    }
                }
            });
        }
    }
</script>

<style scoped>
    .adnContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
</style>
