import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ff426d66"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "rightPanel"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body content" }
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { class: "panelButtons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "header", {}, () => [
                _createTextVNode(" default header ")
              ], true),
              _createElementVNode("img", {
                src: "/icons/workflow/close.svg",
                class: "theme-svg",
                id: "close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }),
              (_ctx.Can_OpenHelp)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: "/icons/help.svg",
                    class: _normalizeClass(["theme-svg", {md:_ctx.size==='md'}]),
                    id: "help",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.OpenHelp && _ctx.OpenHelp(...args)))
                  }, null, 2))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "body", {}, () => [
                _createTextVNode(" default body ")
              ], true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", {
                    class: "btnViewer btnViewerDefault",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
                  }, _toDisplayString(_ctx.Localizer.Localize("Modal_OK")), 1)
                ])
              ], true)
            ]),
            _renderSlot(_ctx.$slots, "modal", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}