import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "PowerBIContainer",
  ref: "report_container",
  style: {"width":"100%","height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.IsBusy)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : _createCommentVNode("", true)
  ], 512))
}