<template>
    <Spinner v-if="isBusy"></Spinner>
    <div v-else>
        <span class="header">Attachment</span>
        <div class="mt-3">
            <label v-if="selectedItem">{{selectedItem}}</label>
            <template v-for="(option, i) in attachmentOptions">
                <div v-if="option.text === 'Add Attachment'">
                    <input type="file" name="add" @change="setFile" />
                    <div class="w-100 mt-3 text-right">
                        <button @click="AttachmentAction(option.action)" class="btnViewer btnViewerDefault" v-bind:class="{disabled: !fileToUpload}">+ Add</button>
                    </div>
                </div>
                <div v-else>
                    <a href="#"
                       class="d-block"
                       @click.stop.prevent="AttachmentAction(option.action)">{{option.text}}</a>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Vue } from 'vue-facing-decorator';
    import * as formControls from './form/FormControls';
    import * as excelHandler from './form/ExcelHandler';
    import * as excelHandlerUtils from './form/ExcelHandler.Utils';
    import * as api from "../../../api/ApiClient";
    import { AuthClient } from '../../../api/ApiClientBase';
    import Spinner from '../../helpers/Spinner.vue';
    import { Localizer } from "../../../api/Localizer";

    @Component({ components: { Spinner } })
    export default class ListControl extends Vue {
        private Localizer = new Localizer(this.$store);
        get ControlRanges(): formControls.GridRange[] | null { return this.$store.state.excel.ControlRanges; }
        get SelectedRange(): Excel.Range | any | null { return this.$store.state.excel.SelectedRange; }
        get SelectedRangeAddress(): string | null { return this.$store.state.excel.SelectedRangeAddress; }
        get FormPath(): string | null { return this.$store.state.excel.FormPathOrId; }
        get Attachments(): excelHandler.AttachmentListItem[] { return this.$store.state.excel.Attachments; }
        set Attachments(attachments: excelHandler.AttachmentListItem[]) { this.$store.commit('excel/SetAttachments', attachments); }

        isBusy: boolean = true;
        selectedItem: string = '';
        attachmentOptions: object[] = [];
        fileToUpload: File | null = null;
        attachments = this.Attachments;

        @Watch("SelectedRangeAddress") async onItemChange() {
            await this.UpdateItem();
            this.attachments = this.Attachments;
        }

        async created() {
            await this.UpdateItem();
        }

        async SetItem(isUpdate: boolean) {
            if (isUpdate) {
            }
        }

        async UpdateItem() {
            var vm = this;
            var editControl: formControls.EditControl | null = new formControls.EditControl();
            await Excel.run(async (context) => {
                let selectedRange = context.workbook.getSelectedRange();
                selectedRange.load('text, worksheet, rowIndex, columnIndex, format/protection/locked');
                await context.sync();
                editControl = await excelHandlerUtils.GetCellEditControl(selectedRange.worksheet, selectedRange.rowIndex, selectedRange.columnIndex, this.ControlRanges!);
                Office.addin.showAsTaskpane();
                await context.sync();
                if (editControl) {
                    if (selectedRange.text[0][0]) {
                        this.selectedItem = selectedRange.text[0][0];
                        this.attachmentOptions = [{
                            action: 'Download',
                            text: this.Localizer.Localize('AttachmentControl_Download_Attachment')
                        }];
                        if (editControl.AllowEdit && !selectedRange.format.protection.locked) {
                            this.attachmentOptions.push({
                                action: 'Remove',
                                text: this.Localizer.Localize('AttachmentControl_Remove_Attachment')
                            });
                        }
                    } else {
                        if (editControl.AllowEdit && !selectedRange.format.protection.locked) {
                            this.attachmentOptions = [{
                                action: 'Add',
                                text: this.Localizer.Localize('AttachmentControl_Add_Attachment')
                            }];
                        }
                    }
                }
            });
            this.isBusy = false;
        }

        async AttachmentAction(action: string, fileName: string) {
            var vm = this;
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            let client = new api.FormClient(auth_client);
            let buildElement: string = '';
            let attachmentParams: Record<string, any> = {};
            let actionFlag: string = '';
            actionFlag = await excelHandler.GetActionFlag(this.SelectedRangeAddress!);
            await Excel.run(async (context) => {
                let ws = context.workbook.worksheets.getActiveWorksheet();
                let target = ws.getRange(this.SelectedRangeAddress!);
                let targetControl = excelHandler.GetCellControlRange(this.SelectedRange, this.ControlRanges!);
                if (targetControl && targetControl.ObjectControl) {
                    if (targetControl.ObjectControl instanceof formControls.TableControl) {
                        buildElement = targetControl.ObjectControl.Name!;
                        let rangeIndex = 0;
                        for (let columnRange of targetControl.ObjectControl.FieldMappings!) {
                            //Get the grid range for this columnRange
                            let controlRange = this.ControlRanges!.find(r => r.ColumnRangeIndex === rangeIndex && r.ObjectControl instanceof formControls.TableControl && r.ObjectControl.Name === buildElement);
                            //Go through each field and get the value if the field is a key
                            let fieldIndex = 0;
                            for (let field of columnRange.Fields!) {
                                if (field.IsKey) {
                                    target.load('rowIndex');
                                    await context.sync();
                                    let cell = ws.getCell(target.rowIndex, controlRange!.Column! + fieldIndex);
                                    cell.load('values');
                                    await context.sync();
                                    if (field.Name) {
                                        attachmentParams[field.Name] = cell.values[0][0];
                                    }
                                    fieldIndex++;
                                }
                                rangeIndex++;
                            }
                        }
                    }
                    //else if (targetControl.ObjectControl is RecordControl recordControl)
                    //{
                    //    buildElement = recordControl.Name;
                    //    foreach(var field in recordControl.Fields)
                    //    {
                    //        if (field.IsKey) {
                    //            string recordRange = string.Format("Record.{0}.{1}", buildElement, field.Name);
                    //            var record = ActiveForm.Workbook.GetNamedRange(recordRange);
                    //            attachmentParams[field.Name] = record.Cells[1, 1].Value2;
                    //        }
                    //    }
                    //}
                    else {
                        //If there is no valid control object
                        return;
                    }
                }
            });
            switch (action) {
                case "Download":
                    await this.$store.dispatch("ShowToast", new api.ToastNotification({
                        type: api.ToastType.Info,
                        message: this.Localizer.Localize('AttachmentControl_Downloading_file')
                    }));
                    try {
                        let res = await client.getAttachment(parseInt(this.FormPath!), buildElement, attachmentParams);
                        if (res != null) {
                            var myBlob = res.data;
                            const link = document.createElement("a");
                            link.href = window.URL.createObjectURL(<File>myBlob);
                            link.download = this.selectedItem;
                            link.click();
                        }
                        await this.$store.dispatch("ShowToast", new api.ToastNotification({
                            type: api.ToastType.Success,
                            message: this.Localizer.Localize('AttachmentControl_Downloading_file')
                        }));
                    } catch (e) {
                        await this.$store.dispatch("ShowToast", new api.ToastNotification({
                            type: api.ToastType.Error,
                            message: this.Localizer.Localize('AttachmentControl_Problem_Downloading_File')
                        }));
                    }
                    break;
                case "Remove":
                    //create attachmentInfo object
                    let target: Excel.Range | null = null;
                    await Excel.run(async (context) => {
                        target = context.workbook.worksheets.getActiveWorksheet().getRange(this.SelectedRangeAddress!).load('worksheet/name, address');
                    })
                    let aInfo: excelHandler.AttachmentInfo = {
                        id: parseInt(this.FormPath!),
                        target: target,
                        attachmentParams: attachmentParams,
                        fileName: '',
                        buildElement: buildElement,
                        action: "remove"
                    }
                    let attachmentItem: excelHandler.AttachmentListItem = {
                        attachmentInfo: aInfo,
                        file: ''
                    }
                    this.attachments.push(attachmentItem);
                    this.Attachments = this.attachments;
                    await Excel.run(async (context) => {
                        let target = context.workbook.worksheets.getActiveWorksheet().getRange(this.SelectedRangeAddress!);
                        target.load('values');
                        await context.sync();
                        target.values = [['']];
                        await context.sync();
                    });
                    break;
                case "Add":
                    if (this.fileToUpload != null) {
                        try {
                            //read attachment file data
                            var reader = new FileReader();
                            var resultString: string = '';
                            reader.onload = (async (event) => {
                                // Remove the metadata before the base64-encoded string.
                                var startIndex = reader!.result!.toString().indexOf("base64,");
                                resultString = reader!.result!.toString().substr(startIndex + 7);
                            });
                            reader.readAsDataURL(this.fileToUpload);

                            //add attachment object to array and save to store for later save
                            let target: Excel.Range | null = null;
                            await Excel.run(async (context) => {
                                target = context.workbook.worksheets.getActiveWorksheet().getRange(this.SelectedRangeAddress!).load('worksheet/name, address');
                            })
                            let aInfo: excelHandler.AttachmentInfo = {
                                id: parseInt(this.FormPath!),
                                target: target,
                                attachmentParams: attachmentParams,
                                fileName: this.fileToUpload!.name,
                                buildElement: buildElement,
                                action: "update"
                            }
                            let attachmentItem: excelHandler.AttachmentListItem = {
                                attachmentInfo: aInfo,
                                file: resultString
                            }

                            // overwrite action to "add" if new row
                            if (actionFlag == "new" || actionFlag == "inserted") {
                                aInfo.action = "add";
                            }
                            this.attachments.push(attachmentItem);
                            this.Attachments = this.attachments;

                            //replace file name inside target cell
                            await Excel.run(async (context) => {
                                let cell = context.workbook.worksheets.getActiveWorksheet().getRange(this.SelectedRangeAddress!).load('values');
                                await context.sync();
                                cell.values = [[aInfo.fileName]];
                                await context.sync();
                            });
                        } catch (e) {
                            await this.$store.dispatch("ShowToast", new api.ToastNotification({
                                type: api.ToastType.Error,
                                message: this.Localizer.Localize('AttachmentControl_Problem_Uploading_File')
                            }));
                        }
                    }
                    break;
                default:
            }
        }

        //helper function for attachment input onChange event
        setFile(event: any) {
            this.fileToUpload = event.target.files[0];
        }
    }
</script>

<style scoped>
    input::-webkit-calendar-picker-indicator {
        display: none;
    }

    .btn---accent-color {
        background-color: var(--accent-color);
        color: #fff;
    }

    .btn.btn---accent-color:focus, .btn.btn---accent-color.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgb(0 175 139 / 25%);
        box-shadow: 0 0 0 0.2rem rgb(0 175 139 / 25%);
    }

    .btn.btn---accent-color:not(:disabled):not(.disabled):active, .btn.btn---accent-color:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: var(--accent-color);
        border-color: var(--accent-color);
    }

    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color: var(--accent-color);
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgb(0 175 139 / 25%);
        box-shadow: 0 0 0 0.2rem rgb(0 175 139 / 25%);
    }

    .header {
        font-size: 22px;
        font-weight: bold;
    }
</style>
