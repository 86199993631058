import { GetterTree, MutationTree, Module } from 'vuex';

import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import * as formControls from '../components/pages/excel/form/FormControls';
import { TreeNodeValue } from "../components/pages/excel/form/HierarchicalTreeNode";
import * as excelHandler from '../components/pages/excel/form/ExcelHandler';
import WorkbookControlVM from '../components/pages/excel/form/WorkbookControlVM';

export interface IExcelStore {
    FormPathOrId: string | null;
    TenantOverride: string | null;
    Password: string | null;
    Parameters: api.FormParameterList | null;
    SelectedParameters: api.FormParameterCurrentValue[] | [];
    SelectedControl: formControls.TableControl | any | null;
    SelectedRangeAddress: string | null;
    SelectedRange: Excel.Range | null;
    SelectedEditControl: formControls.EditControl | null;
    ControlRanges: formControls.GridRange[] | null;
    ParametersSelectionRequested: boolean;
    RefreshFormRequested: boolean;
    TreeControlLabel: string;
    SelectedTreeNode: TreeNodeValue | null;
    Attachments: excelHandler.AttachmentListItem[];
    LogId: string;
    WorkbookControls: WorkbookControlVM[];
    IsForm: boolean
}

const ExcelStore: Module<IExcelStore, any> = {
    namespaced: true,
    state: {
        FormPathOrId: null,
        Password: null,
        TenantOverride: '',
        Parameters: null,
        SelectedParameters: [],
        SelectedControl: null,
        SelectedRangeAddress: null,
        SelectedRange: null,
        SelectedEditControl: null,
        ControlRanges: [],
        ParametersSelectionRequested: false,
        RefreshFormRequested: false,
        TreeControlLabel: "name",
        SelectedTreeNode: null,
        Attachments: [],
        LogId: '',
        WorkbookControls: [],
        IsForm: false
    },
    mutations: {
        SetFormPath(state, formPath: number | null) {
            if (formPath !== null)
                state.FormPathOrId = formPath.toString();
        },

        SetPassword(state, password: string | null) {
            if (password !== null)
                state.Password = password.toString();
        },

        SetTenantOverride(state, tenantOverride: string) {
            if (tenantOverride !== '')
                state.TenantOverride = tenantOverride.toString();
        },

        SetParameters(state, parameters: api.FormParameterList | null) {
            if (parameters !== null)
                state.Parameters = parameters;
        },

        SetSelectedParameters(state, selectedParameters: api.FormParameterCurrentValue[] | null) {
            if (selectedParameters !== null)
                state.SelectedParameters = selectedParameters;
        },

        SetSelectedControl(state, selectedControl: any | null) {
            state.SelectedControl = selectedControl;
        },

        SetSelectedRangeAddress(state, selectedRangeAddress: any) {
            state.SelectedRangeAddress = selectedRangeAddress;
        },

        SetSelectedRange(state, selectedRange: any) {
            state.SelectedRange = selectedRange;
        },

        SetControlRanges(state, controlRanges: formControls.GridRange[]) {
            state.ControlRanges = controlRanges;
        },

        SetParametersSelectionRequested(state, parametersSelectionRequested: boolean | undefined) {
            state.ParametersSelectionRequested = parametersSelectionRequested ? true : false;
        },

        SetRefreshFormRequested(state, refreshFormRequested: boolean | undefined) {
            state.RefreshFormRequested = refreshFormRequested ? true : false;
        },

        SetSelectedEditControl(state, selectedEditControl: formControls.EditControl | null) {
            state.SelectedEditControl = selectedEditControl;
        },

        SetTreeControlLabel(state, treeControlLabel: string) {
            state.TreeControlLabel = treeControlLabel;
        },

        SetSelectedTreeNode(state, selectedTreeNode: TreeNodeValue | null) {
            state.SelectedTreeNode = selectedTreeNode;
        },

        SetAttachments(state, attachments: excelHandler.AttachmentListItem[]) {
            state.Attachments = [...attachments];
        },

        SetLogId(state, logId: string) {
            state.LogId = logId;
        },

        SetWorkbookControls(state, workbookControls: WorkbookControlVM[]) {
            state.WorkbookControls = workbookControls;
        },

        SetIsForm(state, isForm: boolean) {
            state.IsForm = isForm;
        }
    },
    actions: {
        async LoadParameters(context) {
            context.commit("SetParameters", null);
            context.commit("SetSelectedParameters", null);
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.FormClient(auth_client);
            if (context.state.FormPathOrId != null) {
                var params = await client.getFormParameters(context.state.FormPathOrId);
                context.commit("SetParameters", params);
            }
        },
    }
}

export default ExcelStore;