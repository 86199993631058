import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-bar" }
const _hoisted_2 = {
  class: "header",
  style: {"grid-column":"1"}
}
const _hoisted_3 = {
  class: "wbtn-array right-aligned",
  style: {"grid-column":"2"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "right-group")
    ])
  ]))
}