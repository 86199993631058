<template>
    <div style="width: 100%; display: flex; height: 100%; flex-direction: column;">
        <div id="Navbar">
            <div class="wbtn" v-bind:class="{selected: DisplayFluenceView, disabled: HasError }" @click="SetFluenceView">
                <span>Fluence</span>
            </div>
            <!--<div v-if="IsForm" class="wbtn" v-bind:class="{selected: DisplayNavigateView, disabled: HasError }" @click="SetNavigateView">
                <span v-if="DisplayNavigateView">Navigate</span>
                <span v-else>Navigate</span>
            </div>-->
            <div v-if="IsForm" class="wbtn" v-bind:class="{selected: DisplayFormView, disabled: HasError }" @click="SetFormView">
                <span>Form</span>
            </div>
            <div v-if="IsForm" class="wbtn" v-bind:class="{selected: DisplayElementView, disabled: HasError }" @click="SetElementView">
                <span>Element</span>
            </div>
        </div>
        <div v-if="HasError" class="bg"></div>
        <div v-else class="bg">
            <FluenceView v-if="DisplayFluenceView"></FluenceView>
            <NavigateView v-else-if="DisplayNavigateView"></NavigateView>
            <FormView v-else-if="DisplayFormView"></FormView>
            <ElementView v-else-if="DisplayElementView"></ElementView>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import ControlSettings from './ControlSettings.vue';
    import NavigateView from './tabs/NavigateView.vue';
    import ElementView from './tabs/ElementView.vue';
    import FluenceView from './tabs/FluenceView.vue';
    import Spinner from '../../helpers/Spinner.vue';
    import FormView from './tabs/FormView.vue';

    enum TabView {
        Fluence,
        Navigate,
        Form,
        Element,
    }

    @Component({ components: { Spinner, ControlSettings, ElementView, FormView, NavigateView, FluenceView } })

    export default class Menu extends Vue {

        HasError: boolean = false;

        currentView: TabView = TabView.Form;

        get DisplayFluenceView(): boolean { return this.currentView == TabView.Fluence }
        get DisplayNavigateView(): boolean { return this.currentView == TabView.Navigate }
        get DisplayFormView(): boolean { return this.currentView == TabView.Form }
        get DisplayElementView(): boolean { return this.currentView == TabView.Element }
        get IsForm(): boolean { return this.$store.state.excel.IsForm; }
        set IsForm(isForm: boolean) { this.$store.commit("excel/SetIsForm", isForm); }

        mounted() {
            this.currentView = this.IsForm ? TabView.Form : TabView.Fluence;
        }

        SetFluenceView() { this.currentView = TabView.Fluence; }
        SetNavigateView() { this.currentView = TabView.Navigate; }
        SetFormView() { this.currentView = TabView.Form; }
        SetElementView() { this.currentView = TabView.Element; }
    }
</script>

<style scoped>
    #Navbar {
        grid-row: 1;
        background-color: var(--background-toolbar);
        height: 36px;
        border-bottom: 1px solid var(--thin-border);
        display: flex;
    }

        #Navbar .wbtn {
            display: flex;
            cursor: pointer;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
        }

            #Navbar .wbtn.selected {
                border-bottom: 2px solid var(--accent-color);
                color: var(--accent-color);
            }

            #Navbar .wbtn.disabled {
                opacity: 0.5;
                cursor: default;
                pointer-events: none;
                color: var(--theme-color);
            }

    .bg {
        flex-grow: 1;
    }
</style>
