<template>
    <div class="avatar"
         @click="$emit('click', $event)"
         :class="{medavatar: Size==null || Size == 'medium', bigavatar:Size == 'large', smallavatar:Size == 'small'}">

        <div v-if="ImageData==null || ImageData.length<10" class="avatarcontent">{{Initials}}</div>
        <img v-else class="avatarcontent" :src="ImageData" />
    </div>
</template>

<script lang="ts">

    import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
    import * as api from "../../api/ApiClient";
    import * as base from "../../api/ApiClientBase";

    @Component({ components: {}, emits: ["click"] })
    export default class Avatar extends Vue {
        @Prop() UserName!: string;
        @Prop() Initials!: string;
        @Prop() Size!: string;

        get CurrentUserAvatarVersion(): number {
            return this.$store?.state.security.AvatarVersion
        }

        async mounted() {
            if (this.UserName != null)
                this.onUserNameChange();
        }

        @Watch("CurrentUserAvatarVersion")
        async onCurrentUserAvatarVersion() {
            var currentUser = this.$store.state.security.UserName;
            if (currentUser == this.UserName)
                await this.onUserNameChange();
        }

        private ImageData: string | null = null;

        @Watch("UserName")
        async onUserNameChange() {
            var auth_client = new base.AuthClient();
            await auth_client.ensureToken();
            var client = new api.SecurityClient(auth_client);

            var img = await client.getUserImage(this.UserName);
            if (img != null && img.length > 10)
                this.ImageData = "data:image/png;base64, " + img;
            else
                this.ImageData = "";
        }

        //Cache() {
        //    const item = {
        //        value: this.ImageData,
        //        expiry: new Date().getTime() + 60 * 60 * 1000,
        //    }
        //    localStorage.setItem("Avatar_" + this.UserName, JSON.stringify(item))
        //}

        //FromCache(): string | null {
        //    const itemStr = localStorage.getItem("Avatar_" + this.UserName)
        //    if (!itemStr) {
        //        return null
        //    }
        //    const item = JSON.parse(itemStr)
        //    if (new Date().getTime() > item.expiry) {
        //        localStorage.removeItem("Avatar_" + this.UserName)
        //        return null
        //    }
        //    return item.value
        //}
    }

</script>

<style scoped>
    .avatar .avatarcontent {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        color: var(--avatar-foreground);
        background: var(--avatar-background);
        text-align: center;
        justify-content: center;
        top: 0;
    }

    .avatar img.avatarcontent {
        vertical-align: top;
    }

    .medavatar {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 34px;
    }

    .bigavatar {
        display: inline;
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 20px;
    }

    .smallavatar {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 10px;
        position: relative;
        top: -1px;
    }
</style>
