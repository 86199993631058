<template>
    <div id="sidebar">
        <router-link v-if="UserCanAccessWorkflow" :to="{name: `${getDefaultWorkflowRoute()}`}">
            <div class="sidebar-button">
                <img src="../../public/icons/process.svg">
                <div>{{ Localizer.Localize("Sidebar_Workflow") }}</div>
            </div>
        </router-link>
        <router-link v-if="UserCanAccessJournals && FeatureFlagJournals" :to="{name: `${getDefaultJournalEntriesRoute()}`}">
            <div class="sidebar-button">
                <img src="../../public/icons/journals.svg">
                <div>{{ Localizer.Localize("Sidebar_Journals") }}</div>
            </div>
        </router-link>
        <router-link :to="{name: RouteNames.ExplorerRoute}" style="position:relative;">
            <div class="sidebar-button">
                <img src="../../public/icons/explore.svg">
                <div>{{ Localizer.Localize("Sidebar_Explore") }}</div>
                <div v-if="Tabs.length>0" class="counter" @click.prevent.stop="setShowWindowsContextMenu($event)">
                    {{ Tabs.length }}
                </div>
            </div>
        </router-link>
        <router-link v-if="FeatureFlagMatching && UserCanAccessMatching" :to="{name: `${RouteNames.MatchingUI}`}">
            <div class="sidebar-button">
                <img src="../../public/icons/matching/matching.svg" class="size-sm">
                <div>{{ Localizer.Localize("Sidebar_Matching") }}</div>
            </div>
        </router-link>
        <router-link v-if="UserCanAccessModels" :to="{name: `${modelsRoute}`}">
            <div class="sidebar-button">
                <img src="../../public/icons/metadata.svg">
                <div>{{ Localizer.Localize("Sidebar_Models") }}</div>
            </div>
        </router-link>
        <router-link v-if="UserCanAccessMaintenance" :to="{name: `${getDefaultMaintenanceRoute()}`}">
            <div class="sidebar-button">
                <img src="../../public/icons/maintenance.svg">
                <div>{{ Localizer.Localize("Sidebar_Maintenance") }}</div>
            </div>
        </router-link>
        <router-link v-if="UserCanAccessConfiure" :to="{name: `${configureRoute}`}">
            <div class="sidebar-button">
                <img src="../../public/icons/data.svg">
                <div>{{ Localizer.Localize("Sidebar_Configuration") }}</div>
            </div>
        </router-link>
        <router-link v-if="UserCanAccessSecurity" :to="{name: `${securityRoute}`}">
            <div class="sidebar-button">
                <img src="../../public/icons/users.svg">
                <div>{{ Localizer.Localize("Sidebar_Security") }}</div>
            </div>
        </router-link>
        <router-link v-if="UserCanAccessLogs" :to="{name: `${logsRoute}`}">
            <div class="sidebar-button">
                <img src="../../public/icons/log.svg">
                <div>{{ Localizer.Localize("Sidebar_Logs") }}</div>
            </div>
        </router-link>
        <router-link v-for="item,i in HomeExtensions" :to="{name: RouteNames.HomeExtensionRoute, params:{id:item.id }}">
            <div class="sidebar-button">
                <img :src="item.svg" />
                <div>{{item.caption}}</div>
            </div>
        </router-link>
        <ContextMenu v-if="ShowWindowsContextMenu"
                     :TriggerEvent="ShowWindowsContextMenu"
                     @close="ShowWindowsContextMenu=null">
            <template #buttons>
                <div v-for="tab in Tabs"
                     class="context_menu_btn"
                     :Key="tab.Key"
                     @click="Open(tab)">
                    <img class="theme-svg" src="../../public/icons/explorer/context_menu/open.svg">
                    &nbsp;{{ tab.Name }}
                </div>
            </template>
        </ContextMenu>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-facing-decorator';
    import { Localizer } from '@/api/Localizer';
    import RouteNames from '../routing/RouteNames';
    import * as api from '../api/ApiClient';
    import ContextMenu from './helpers/ContextMenu.vue';
    import { WindowsTabData, ChatWindowsTabData } from '@/api/WindowsTabData';
    import { RouteRecordName } from 'vue-router';
    import { AuthClient } from "../api/ApiClientBase";

    @Component({ components: { ContextMenu } })
    export default class Sidebar extends Vue {
        public RouteNames = RouteNames;
        private Localizer = new Localizer(this.$store);

        private configureRoute: string | RouteRecordName | null | undefined = this.getDefaultConfigureRoute;
        private logsRoute: string | RouteRecordName | null | undefined = this.getDefaultLogsRoute;
        private securityRoute: string | RouteRecordName | null | undefined = this.getDefaultSecurityRoute;
        private modelsRoute: string | RouteRecordName | null | undefined = this.getDefaultModelsRoute;
        private extensions: api.HomeExtension[] = [];

        @Watch('$route') onRouteChange() {
            if (this.$route.fullPath.startsWith('/data')) {
                this.configureRoute = this.$route.name;
            }
            else {
                this.configureRoute = this.getDefaultConfigureRoute;
            }

            if (this.$route.fullPath.startsWith('/metadata')) {
                this.modelsRoute = this.$route.name;
            }
            else {
                this.modelsRoute = this.getDefaultModelsRoute;
            }

            if (this.$route.fullPath.startsWith('/security')) {
                this.securityRoute = this.$route.name;
            }
            else {
                this.securityRoute = this.getDefaultSecurityRoute;
            }

            if (this.$route.fullPath.startsWith('/logs')) {
                this.logsRoute = this.$route.name;
            }
            else {
                this.logsRoute = this.getDefaultLogsRoute;
            }
        }

        @Watch('ModuleSecurityAccess') async onModuleSecurityAccessLoad() {
            this.configureRoute = this.getDefaultConfigureRoute;
            this.logsRoute = this.getDefaultLogsRoute;
            this.securityRoute = this.getDefaultSecurityRoute;
            this.modelsRoute = this.getDefaultModelsRoute;

            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.ExtensionClient(auth_client);
            this.extensions = await client.homeExtensions();
        }

        get Tabs(): WindowsTabData[] {
            return this.$store.state.windowstabs.Tabs;
        }

        get FeatureFlagJournals(): boolean {
            const ud = this.$store.state.security.UserData as api.ExtendedUserData;
            if (ud != null && ud.featureFlags != null) {
                for (let i = 0; i < ud.featureFlags!.length; i++) {
                    if (ud.featureFlags[i] == 'Journals') {
                        return true;
                    }
                }
            }
            return false;
        }

        get FeatureFlagMatching(): boolean {
            const ud = this.$store.state.security.UserData as api.ExtendedUserData;
            if (ud != null && ud.featureFlags != null) {
                for (let i = 0; i < ud.featureFlags!.length; i++) {
                    if (ud.featureFlags[i] == 'MatchingUI') {
                        return true;
                    }
                }
            }
            return false;
        }

        async mounted() {

        }

        get HomeExtensions(): any[] {

            return this.extensions;
        }

        get CurrentUser(): api.UserData | null {
            return this.$store?.state.security.UserData;
        }

        get ModuleSecurityAccess(): api.ModuleAccess[] | null {
            return this.$store?.state.security.ModuleSecurity;
        }

        get UserCanAccessWorkflow(): boolean {
            return true;
        }

        get UserCanAccessModels(): boolean {
            return this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelManagementManage)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelViewsManage)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelsView)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelsDelete)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelsCreateUpdate));
        }

        get UserCanAccessSecurity(): boolean {
            return this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityUserManagementCreate)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityUserManagementUpdate)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityUserManagementDelete)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityRoleManagementCreate)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityRoleManagementUpdate)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityRoleManagementDelete)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityRoleManagementAssignAuthorization)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityFileSystemSecurityAssign));
        }

        get UserCanAccessConfiure(): boolean {
            return this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.ConfigurationDataSourcesConnectionsManage)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.ConfigurationStagingTablesManage)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.DataImportsDataImportDefinitionsManage)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.DataImportsMappingsView)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.DataImportsMappingsManage)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.HomepagesHomepageManagementManage)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.ConfigurationJournalEntriesManageSettings)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.ThemeCustomSettingsManage));
        }

        get UserCanAccessJournals(): boolean {
            return this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.JournalEntriesView);
        }

        get UserCanAccessMaintenance(): boolean {
            return this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.ConsolidationMaintain)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.FinancialReportingMaintain)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.AccountReconciliationMaintain));
        }

        get UserCanAccessLogs(): boolean {
            return this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.LogsEtlLogsView)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.LogsMetadataAuditLogView));
        }

        get UserCanAccessMatching(): boolean {
            return this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.MatchingView));
        }

        // eslint-disable-next-line complexity
        get getDefaultConfigureRoute() {
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.ConfigurationDataSourcesConnectionsManage)) {
                return RouteNames.DataConnectionStringsRoute;
            }
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.ConfigurationStagingTablesManage)) {
                return RouteNames.DataStagingTableRoute;
            }
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.DataImportsMappingsView)) {
                return RouteNames.DataMapsRoute;
            }
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.HomepagesHomepageManagementManage)) {
                return RouteNames.DataHomepagesRoute;
            }
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.DataImportsDataImportDefinitionsManage)) {
                return RouteNames.DataModelMappingsRoute;
            }
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.ConfigurationJournalEntriesManageSettings)) {
                return RouteNames.DataJournalsRoute;
            }
            return RouteNames.DashboardRoute;
        }

        get getDefaultModelsRoute() {
            if (this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelsCreateUpdate) ||
                this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelsView) ||
                this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelsDelete)
                )) {
                return RouteNames.ModelViewRoute;
            }
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelManagementManage)) {
                return RouteNames.DimensionViewRoute;
            }
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.MetadataManagementModelViewsManage)) {
                return RouteNames.ModelViewsViewRoute;
            }
            return RouteNames.DashboardRoute;
        }

        get getDefaultSecurityRoute() {
            if (this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityUserManagementCreate)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityUserManagementUpdate)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityUserManagementDelete))) {
                return RouteNames.SecurityManagementUsersRoute;
            }
            if (this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityRoleManagementCreate)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityRoleManagementUpdate)
                    || this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityRoleManagementDelete))) {
                return RouteNames.SecurityManagementRolesRoute;
            }
            if (this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.SecurityRoleManagementAssignAuthorization))) {
                return RouteNames.SecurityManagementModulesRoute;
            }
            return RouteNames.DashboardRoute;
        }

        get getDefaultLogsRoute() {
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.LogsEtlLogsView)) {
                return RouteNames.LogManagementEtlLogsRoute;
            }
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.LogsMetadataAuditLogView)) {
                return RouteNames.LogManagementAuditTrailRoute;
            }
            return RouteNames.DashboardRoute;
        }

        getDefaultWorkflowRoute() {
            return RouteNames.WorkflowContributorRoute;
        }

        getDefaultJournalEntriesRoute() {
            if (this.ModuleSecurityAccess != null
                && this.ModuleSecurityAccess.includes(api.ModuleAccess.JournalEntriesView)) {
                return RouteNames.JournalsRoute;
            }
        }

        getDefaultMaintenanceRoute() {
            if (this.ModuleSecurityAccess != null
                && (this.ModuleSecurityAccess.includes(api.ModuleAccess.ConsolidationMaintain) ||
                    this.ModuleSecurityAccess.includes(api.ModuleAccess.FinancialReportingMaintain) ||
                    this.ModuleSecurityAccess.includes(api.ModuleAccess.AccountReconciliationMaintain))) {
                return RouteNames.MaintenanceRoute;
            }
            //return RouteNames.MaintenanceRoute;
        }

        private ShowWindowsContextMenu: MouseEvent | null = null;
        Open(tab: WindowsTabData) {
            tab.BringToFront();
            this.ShowWindowsContextMenu = null;
        }

        OpenChatPopup() {
            const tab = new ChatWindowsTabData('Chat');
            this.Tabs!.push(tab);
        }

        setShowWindowsContextMenu(e: MouseEvent) {
            this.ShowWindowsContextMenu = e;
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.size-sm {
    width: 24px;
}

    #sidebar {
        width: 60px;
        background-color: var(--sidebar-background);
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        left: 0;
        top: 86px;
        bottom: 0;
        border-right: 1px solid var(--thin-border);
    }

        #sidebar a {
            text-decoration: none;
            color: var(--sidebar-foreground);
        }

            #sidebar a:hover {
                text-decoration: none;
                color: var(--sidebar-foreground-hover);
            }

    .sidebar-button {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        word-break: break-all;
    }

        .sidebar-button .counter {
            background-color: var(--accent-color);
            color: var(--accent-foreground);
            width: 18px;
            height: 18px;
            border-radius: 10px;
            position: absolute;
            right: 4px;
            top: 4px;
        }

        .sidebar-button img {
            filter: var(--sidebar-svg);
        }

        .sidebar-button:hover img {
            filter: var(--sidebar-hover-svg);
        }

    .router-link-active .sidebar-button {
        background-color: var(--accent-color3);
        color: var(--sidebar-foreground-selected);
    }

        .router-link-active .sidebar-button img {
            filter: var(--sidebar-selected-svg);
        }
</style>
