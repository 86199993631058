<template>
    <div class="botContainer">
        <div style="overflow:auto;">
            <div v-for="bot in BotList" v-bind:class="{ selected: SelectedBot===bot }" class="bot" @click="SelectedBot=bot">
                <Avatar :UserName="bot.userName" :Initials="bot.initials" Size="small">
                </Avatar>
                {{bot.name}}
            </div>
        </div>
        <div style="overflow:hidden" v-if="SelectedBot!=null">
            <iframe v-if="SelectedBot.name=='Old Bot'" src='https://webchat.botframework.com/embed/ZenDeskLanguage-bot?s=XaRpeucpTn8.iXqM3Oot5lO1zDBd8YZ2-SBNI5D-jm2vZz3VakNDfME'
                    class="botConversationContainer"></iframe>
            <div v-else-if="SelectedBot.name=='Fluence Bot'" class="botConversationContainer">
                <div class="botConversationHeader">
                    <Avatar :UserName="SelectedBot.userName" :Initials="SelectedBot.initials" Size="large">
                    </Avatar>
                    <div>
                        <div style="font-size:18px; font-weight:bold;margin-bottom:-6px;">{{SelectedBot.name}}</div>
                        <small>online</small>
                    </div>
                </div>
                <BotFrameworkChat></BotFrameworkChat>
            </div>
            <div v-else class="botConversationContainer">
                <div class="botConversationHeader">
                    <Avatar :UserName="SelectedBot.userName" :Initials="SelectedBot.initials" Size="large">
                    </Avatar>
                    <div>
                        <div style="font-size:18px; font-weight:bold;margin-bottom:-6px;">{{SelectedBot.name}}</div>
                        <small>online</small>
                    </div>
                </div>
                <div ref="messages" class="botConversationMessages" style="min-height:30px;">
                    <div v-for="message in SelectedBot.history">
                        <div v-if="message.message == '`~`'" class="chatMessage">
                            <div class="loader"><span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></div>
                        </div>
                        <div v-else v-bind:class="{user: message.user==true, error: message.isError==true}" class="chatMessage" v-html="URLify(message.message)"></div>
                    </div>
                </div>
                <div class="botConversationWrite">
                    <div style="width:30px; height:30px; border-radius:15px; background-color:var(--accent-color); margin-top:6px; cursor:pointer;" @click="Clear">
                        <svg fill="currentColor" width="18" height="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="margin:6px;">
                            <path d="M14.85 1.15c.2.2.2.5 0 .7L10.9 5.81a4.78 4.78 0 0 0-.71-.7l3.96-3.96c.2-.2.5-.2.7 0ZM4.65 6.19l-.39.36 5.2 5.2.4-.4a3.67 3.67 0 1 0-5.2-5.16ZM1.3 8.04l2.1-.95 5.52 5.52-.95 2.1a.5.5 0 0 1-.81.14l-6-6a.5.5 0 0 1 .14-.8Z" fill="white"></path>
                        </svg>
                    </div>
                    <textarea rows="2" type="text" placeholder="Your message" v-model="Message" v-on:keyup.enter="SendMessage" style="resize:none"></textarea>
                    <div style="margin-top: 6px; position: relative; top: 9px; right: 32px; cursor: pointer;" @click="SendMessage">
                        <svg fill="currentColor" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.72 1.05a.5.5 0 0 0-.71.55l1.4 4.85c.06.18.21.32.4.35l5.69.95c.27.06.27.44 0 .5l-5.69.95a.5.5 0 0 0-.4.35l-1.4 4.85a.5.5 0 0 0 .71.55l13-6.5a.5.5 0 0 0 0-.9l-13-6.5Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    require('@/assets/app.css');

    import Avatar from '../helpers/Avatar.vue';
    import { Component, Vue } from 'vue-facing-decorator';

    import { ChatUser, ChatMessage } from './chat/ChatUser';
    import BotFrameworkChat from './chat/BotFrameworkChat.vue';

    import * as api from "../../api/ApiClient";
    import * as base from "../../api/ApiClientBase";

    @Component({ components: { Avatar, BotFrameworkChat } })
    export default class Chat extends Vue {

        private get BotList(): ChatUser[] {
            return this.$store.state.chat.BotList;
        }

        private get SelectedBot(): ChatUser | null {
            return this.$store.state.chat.SelectedBot;
        }

        private set SelectedBot(value: ChatUser | null) {
            this.$store.commit('chat/setSelectedBot', value);
        }

        private Message: string = '';

        async mounted() {
            if (this.BotList.length == 0) {
                var bots = [];
                bots.push(new ChatUser('Fluence Help', 'F', 'fluence'));

                if (this.FeatureFlagChatBot)
                    bots.push(new ChatUser('Legacy bot', 'LB', 'lb'));
                if (this.FeatureFlagChatBot)
                    bots.push(new ChatUser('Fluence Bot', 'FB', 'fb'));
                if (this.FeatureFlagChatBot)
                    bots.push(new ChatUser('FluenceXL Help', 'FX', 'fluencexl'));
                if (this.FeatureFlagChatBot)
                    bots.push(new ChatUser('XlCubed Help', 'X3', 'xl3'));
                if (this.FeatureFlagChatBotAI)
                    bots.push(new ChatUser('Fluence Meetings', 'MT', 'meetings'));
                if (this.FeatureFlagChatBotAI)
                    bots.push(new ChatUser('Fluence FileSystem', 'FS', 'fluence-fs'));
                if (this.FeatureFlagChatBotAI)
                    bots.push(new ChatUser('Fluence Logins', 'FL', 'fluence-logins'));
                if (this.FeatureFlagChatBotAI)
                    bots.push(new ChatUser('Wikipedia Help', 'WH', 'wikipedia'));

                this.$store.commit('chat/ResetBots', bots);

                this.SelectedBot = this.BotList[0];

                if (this.FeatureFlagChatBotAI) {
                    var auth_client = new base.AuthClient();
                    await auth_client.ensureToken();
                    var client = new api.UserClient(auth_client);
                    var users = await client.getUserDetails();
                    users.sort((a, b) => (a.fullName ?? a.userName ?? "").localeCompare(b.fullName ?? b.userName ?? ""));
                    for (var i = 0; i < users.length; i++) {
                        var usr = users[i];
                        this.BotList.push(new ChatUser(usr.fullName ?? usr.userName!, this.getInitials(usr.fullName!), "usr", usr.userName))
                    }
                }

                this.$store.commit('chat/ResetBots', this.BotList);
            }
        }

        private Clear() {
            if (this.SelectedBot != null)
                this.SelectedBot.history = [];
        }

        private async SendMessage() {
            var sb = this.SelectedBot;
            if (sb == null)
                return;

            if (sb.soft != 'usr') {
                this.scrollToBottom();
                sb.history.push(new ChatMessage(this.Message, true));
                sb.history.push(new ChatMessage('`~`', false));

                var authClient = new base.AuthClient();
                authClient.ensureToken();
                var client = new api.AiClient(authClient);
                var message = new api.HelpChatMessage();
                message.soft = sb.soft;
                message.hint = sb.hint;
                message.userMessage = this.Message;
                message.history = [];
                var strUser = "";
                var strBot = "";
                for (var i = 0; i < sb.history.length; i++) {
                    var msg = sb.history[i];
                    if (msg.message != '`~`' && !msg.isError) {
                        if (msg.user)
                            strUser += msg.message + "\n";
                        else {
                            strBot += msg.message + "\n";
                            var chi = new api.HelpChatHistoryItem();
                            chi.question = strUser;
                            chi.answer = strBot;
                            message.history.push(chi);
                        }
                    }
                }

                this.Message = "";

                var isError = false;
                try {
                    message = await client.helpChatTurn(message);
                }
                catch (e: any) {
                    console.log(e);
                    message.answer = e.message;
                    isError = true;
                }

                if (message.answer != null) {
                    sb.history.pop();
                    this.scrollToBottom();
                    var chatMessage = new ChatMessage(message.answer, false);
                    chatMessage.isError = isError;
                    sb.history.push(chatMessage);
                }
            }
        }

        private scrollToBottom() {
            var out = this.$refs["messages"] as HTMLElement;
            var isScrolledToBottom = out.scrollHeight - out.clientHeight <= out.scrollTop + 1;

            if (isScrolledToBottom)
                setTimeout(function () {
                    out.scrollTop = out.scrollHeight - out.clientHeight;
                }, 100);
        }

        UserNameFilterPattern: string = "";

        get ExistingUsers(): api.UserObject[] | null {
            return this.$store.state.usermanagement.Users;
        }

        get Users(): api.UserObject[] {
            if (this.ExistingUsers === null)
                return [];

            var lc_UserNameFilterPattern = this.UserNameFilterPattern.toLowerCase();
            var filteredUsers = this.ExistingUsers.filter(u => u.userName!.toLowerCase().indexOf(lc_UserNameFilterPattern) >= 0 || u.fullName!.toLowerCase().indexOf(lc_UserNameFilterPattern) >= 0 || u.email!.toLowerCase().indexOf(lc_UserNameFilterPattern) >= 0);
            return filteredUsers;
        }

        getInitials(fullname: string) {
            var un = fullname;
            if (un && un.length > 0) {
                var parts = un.trim().split(' ');
                if (parts.length == 1)
                    return parts[0][0].toUpperCase();
                else
                    return (parts[0][0] + parts[1][0]).toUpperCase();
            }
            return "UNK";
        }

        get FeatureFlagChatBotAI(): boolean {
            var ud = this.$store.state.security.UserData as api.ExtendedUserData;
            if (ud != null && ud.featureFlags != null) {
                for (var i = 0; i < ud.featureFlags!.length; i++) {
                    if (ud.featureFlags[i] == "ChatBotAI")
                        return true;
                }
            }
            return false;
        }

        get FeatureFlagChatBot(): boolean {
            var ud = this.$store.state.security.UserData as api.ExtendedUserData;
            if (ud != null && ud.featureFlags != null) {
                for (var i = 0; i < ud.featureFlags!.length; i++) {
                    if (ud.featureFlags[i] == "ChatBot")
                        return true;
                }
            }
            return false;
        }

        URLify(str: string) {
            const urls = str.match(/((((ftp|https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g);
            if (urls) {
                urls.forEach(function (url) {
                    str = str.replace(url, '<a target="_blank" href="' + url + '">' + url + "</a>");
                });
            }
            return str.replace("(", "<br/>(");
        }



        //async startCapture() {
        //    var displayMediaOptions = {
        //        video: {
        //            cursor: "always",
        //            height: 640,
        //            width: 1000
        //        },
        //        audio: true,
        //    };

        //    async function startCapture() {
        //        try {
        //            videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
        //            dumpOptionsInfo();
        //        } catch (err) {
        //            // Handle error
        //            console.error("Error: " + err);
        //        }
        //    }
        //    // Stop the stream
        //    function stopCapture(evt) {
        //        let tracks = videoElem.srcObject.getTracks();
        //        tracks.forEach(track => track.stop());
        //        videoElem.srcObject = null;
        //    }
    }


</script>

<style scoped>
    .botContainer {
        display: grid;
        grid-template-columns: 250px minmax(10px, 1fr);
        align-items: stretch;
        width: 100%;
        max-height: 100%;
        height: 100%;
        background-color: var(--background-primary);
        font-size: 13px;
        color: var(--foreground-primary);
    }

    .bot {
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
        cursor: pointer;
        background-color: var(--background-secondary);
        border: 1px solid var(--thin-border);
        font-size: 14px;
    }

        .bot.selected {
            background-color: var(--accent-color);
            color: var(--accent-foreground);
        }

    .botConversationContainer {
        min-width: 200px;
        width: calc(100%-6px);
        height: 100%;
        min-height: 200px;
        display: grid;
        grid-template-rows: auto minmax(10px, 1fr) auto;
        grid-row-gap: 6px;
        margin: 6px;
    }

    .botConversationHeader {
        background-color: var(--accent-color);
        color: var(--accent-foreground);
        display: grid;
        grid-template-columns: auto minmax(10px, 1fr);
        padding: 6px;
        column-gap: 12px;
    }

    .botConversationMessages {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .botConversationWrite {
        display: grid;
        grid-template-columns: auto minmax(10px, 1fr) 0px;
        column-gap: 6px;
        padding-bottom: 12px;
    }

    .chatMessage {
        max-width: 70%;
        clear: both;
        float: left;
        border-radius: 20px;
        border-bottom-left-radius: 0px;
        padding: 12px;
        margin-bottom: 6px;
        background-color: var(--background-secondary);
        border: 1px solid var(--thin-border);
        margin-left: 6px;
        overflow: auto;
        white-space: pre-wrap;
    }

    .user {
        background-color: var(--accent-color);
        color: var(--accent-foreground);
        float: right;
        margin-right: 6px;
        border-radius: 15px;
        border-bottom-right-radius: 0px;
        border-color: var(--accent-color);
    }

    .error {
        color: var(--foreground-error) !important;
    }

    @keyframes blink {
        50% {
            color: transparent
        }
    }

    .loader__dot {
        font-size: 30px;
        line-height: 6px;
        animation: 1s blink infinite;
    }

        .loader__dot:nth-child(2) {
            animation-delay: 250ms
        }

        .loader__dot:nth-child(3) {
            animation-delay: 500ms
        }
</style>
