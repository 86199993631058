import { GetterTree, MutationTree, Module } from 'vuex';
import { AuthClient } from "../api/ApiClientBase";
import * as ext from "../api/Extensions";
import * as api from "../api/ApiClient";
import { Prop, Model } from 'vue-facing-decorator';

import { ChatUser, ChatMessage } from '../components/pages/chat/ChatUser';

export interface IChatStore {
    BotList: ChatUser[],
    SelectedBot: ChatUser | null,
    HelpArticles: api.HelpArticle[],
}

const ChatStore: Module<IChatStore, any> = {
    namespaced: true,
    state: {
        BotList: [],
        SelectedBot: null,
        HelpArticles: [],
    },
    mutations: {
        ResetBots(state, bots: ChatUser[]) {
            state.BotList = bots;
            if (state.SelectedBot != null && bots.indexOf(state.SelectedBot) < 0)
                state.SelectedBot = null;
        },

        setSelectedBot(state, bot: ChatUser) {
            state.SelectedBot = bot;
        },

        setArticles(state, articles: api.HelpArticle[]) {
            state.HelpArticles = articles;
        }

    },
    actions: {
        async LoadHelpArticles(context) {
            const authClient = new AuthClient();
            await authClient.ensureToken();
            const client = new api.AiClient(authClient);
            var articles = await client.getHelpArticles();
            context.commit("setArticles", articles);
        }
    },

    getters: {
        getHelpArticle: (state) =>
            (key: string): api.HelpArticle | null => {
                if (state.HelpArticles != null)
                    for (var j = 0; j < state.HelpArticles.length; j++)
                        if (state.HelpArticles[j].key!.toLowerCase() == key.toLowerCase()) {
                            return state.HelpArticles[j];
                        }
                return null;
            }
    }
}
export default ChatStore;
