<template>
    <div style="width:100%;">
        <div class="editorContent">
            <input type="text" v-if="param.style == 'Input'" :class="{interpolation: true}" v-model="CurrentValue.name" @change="ParameterText_Change($event)" />
            <input type="date" v-else-if="param.style == 'Date' && (FormatDate(CurrentValue.key)==null || !FormatDate(CurrentValue.key).startsWith('{'))" :class="{interpolation: false}" :value="FormatDate(CurrentValue.key)" @change="ParameterDate_Change($event)" />
            <input type="text" v-else-if="param.style == 'Date'" v-model="CurrentValue.name" @change="ParameterText_Change($event)" />
            <input type="text" v-else-if="param.style == 'Tree'" style="cursor: pointer" @click.stop="SelectedTreeParameterValue = null; TreeParameterModal = true" v-model="CurrentValueLabel" readonly />
            <select v-else-if="param.style == 'List'" v-model="CurrentValue">
                <option v-if="IsUsingAReportBookParameter" v-bind:value="CurrentValue">{{CurrentValueLabel}}</option>
                <option v-for="choice, k in param.values" v-bind:value="choice">{{ValueLabel(choice)}}</option>
            </select>

            <div class="wbtn-array" v-if="ShowButtonArray">
                <div class="wbtn"
                     style="padding: 5px;"
                     @click="PromoteParameter()"
                     :disabled="IsReportBookParameter || null">
                    <img src="../../../../public/icons/reportbook/promote_green.svg" />
                </div>
                <div class="wbtn" style="padding: 5px;"
                     @click="ReportBookParameterModal = true"
                     :disabled="IsReportBookParameter || null">
                    <img class="accent-svg" src="../../../../public/icons/reportbook/parameter.svg" />
                </div>
            </div>
        </div>

        <Modal v-if="TreeParameterModal" @close="TreeParameterModal = false">
            <template v-slot:header>
                <span class="modalTitle">{{param.label}}</span>
            </template>

            <template v-slot:body>
                <div class="modalContent">
                    <transition-group name="fso" tag="span">
                        <TreeParameterNode v-for="node, i in RootNodes" v-bind:key="key(i)" :Definition="node" />
                    </transition-group>
                </div>
            </template>

            <template v-slot:footer>
                <div class="btnViewer" @click="TreeParameterModal = false">
                    {{Localizer.Localize("CANCEL")}}
                </div>
                <div class="btnViewer btnViewerDefault" v-bind:class="{disabled: !CanChooseTreeParameterValue}" @click="ChooseTreeParameterValue()">
                    {{Localizer.Localize("OK")}}
                </div>
            </template>
        </Modal>

        <Modal v-if="ReportBookParameterModal" @close="CancelPickReportBookParameter">
            <template v-slot:header>
                <span class="modalTitle">Pick a report book parameter</span>
            </template>
            <template v-slot:body>
                <div>
                    <div class="modalContent"
                         v-if="ReportBookParameters === null || ReportBookParameters.length === 0">
                        <Empty>No report book parameters</Empty>
                    </div>
                    <div class="modalContent" v-else>
                        <transition-group name="fso" tag="span">
                            <div class="modalItemWrapper"
                                 v-for="(param, i) in ReportBookParameters"
                                 :class="{even: i%2==0}"
                                 :key="key(i)"
                                 @click="SelectReportBookParameter(param)"
                                 :style="{'--i':i/ReportBookParameters.length}">
                                <div class="modalItem">
                                    <span class="label">{{param.bookParameter.label}}</span>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="btnViewer" @click="CancelPickReportBookParameter">
                    {{Localizer.Localize("CANCEL")}}
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts">
    require('@/assets/portal.css');

    import { Localizer } from "../../../api/Localizer";
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import * as api from "../../../api/ApiClient";
    import { TreeParameterNodeDefinition as NodeDefinition } from "../../../api/Extensions"

    import Modal from "../../helpers/Modal.vue";
    import Empty from "../../helpers/Empty.vue";
    import TreeParameterNode from "./TreeParameterNode.vue"

    @Component({ components: { Modal, Empty, TreeParameterNode } })
    export default class FormParameterEditor extends Vue {
        private Localizer = new Localizer(this.$store);

        @Prop() private readonly param!: api.FormParameter;
        @Prop({default: null}) private readonly reportBookEntryPath!: string | null;

        private TreeParameterModal = false;
        private ReportBookParameterModal = false;

        get ShowButtonArray(): boolean {
            return this.reportBookEntryPath !== null;
        }

        beforeMount() {
            if (!this.CurrentValue) {
                this.CurrentValue = new api.FormParameterValue();
            }
        }

        key(i: number): string {
            return i.toString();
        }

        get CurrentValue(): api.FormParameterValue | null | undefined {
            return this.param.currentValue;
        }
        set CurrentValue(val: api.FormParameterValue | null | undefined) {
            this.$emit("update", val);
        }

        ValueLabel(value: api.FormParameterValue | null | undefined): string {
            if (value) {
                if (this.Display == "Key")
                    return value.key!;
                else if (this.Display == "Name")
                    return value.name!;
                else if (this.Display == "Caption")
                    return value.caption!;
            }
            return "";
        }
        get Display(): string | null | undefined {
            return this.param.display;
        }
        get CurrentValueLabel(): string {
            return this.ValueLabel(this.CurrentValue);
        }

        get SelectedTreeParameterValue(): api.FormParameterValue | null {
            return this.$store.state.forms.SelectedTreeParameterValue;
        }
        set SelectedTreeParameterValue(val: api.FormParameterValue | null) {
            this.$store.commit("forms/SetSelectedTreeParameterValue", val);
        }

        async ParameterText_Change(event: Event) {
            // For text input parameters, the inputed text is given to the key, name, and caption.
            var val = (event.target as HTMLInputElement).value;
            this.CurrentValue = new api.FormParameterValue({
                displayOnly: false,
                isLeaf: true,
                caption: val,
                key: val,
                name: val,
                parentKey: ""
            });
        }

        private FormatDate(dt: string) {
            if (dt == null)
                return null;
            if (dt.startsWith('{'))
                return dt;

            var str = dt.substr(0, 4) + "-" + dt.substr(4, 2) + "-" + dt.substr(6);
            console.log(str);
            return str;
        }

        async ParameterDate_Change(event: Event) {
            // For text input parameters, the inputed text is given to the key, name, and caption.
            var val = (event.target as HTMLInputElement).value;
            let yourDate = new Date(Date.parse(val));
            var dt = yourDate.toISOString().split('T')[0].replaceAll('-', '').replaceAll('/', '');
            console.log(dt);
            this.CurrentValue = new api.FormParameterValue({
                displayOnly: false,
                isLeaf: true,
                key: dt,
                parentKey: ""
            });
        }

        get SelectedTreeParameterNodes(): NodeDefinition[] | null {
            if (this.param && this.param.values) {
                var values = this.param.values;
                var nodes = values.map(val => new NodeDefinition(val, this.param.display));

                for (var node of nodes) {
                    var parentNode = nodes.find(n => n.Value.key == node.Value.parentKey);
                    if (parentNode) {
                        node.Parent = parentNode;
                        parentNode.Children.push(node);
                        parentNode.Value.isLeaf = false;
                    }
                }

                return nodes;
            }

            return null;
        }

        get RootNodes(): NodeDefinition[] | null {
            if (this.SelectedTreeParameterNodes)
                return this.SelectedTreeParameterNodes.filter(node => node.IsRoot);
            else
                return null;
        }

        get CanChooseTreeParameterValue(): boolean {
            return this.SelectedTreeParameterValue != null;
        }

        async ChooseTreeParameterValue() {
            this.CurrentValue = this.SelectedTreeParameterValue;
            this.TreeParameterModal = false;
        }

        get OldReportBookParameters(): api.BookAndFormParameter[] | null {
            return this.$store.getters["reportbook/ReportBookParameters"];
        }
        get NewReportBookParameters(): api.BookAndFormParameter[] {
            return this.$store.state.reportbook.NewReportBookParameters;
        }
        get ReportBookParameters(): api.BookAndFormParameter[] {
            return (this.OldReportBookParameters ? this.OldReportBookParameters : []).concat(this.NewReportBookParameters);
        }
        get FormSpecificReportBookParameters(): api.BookAndFormParameter[] {
            return (this.OldReportBookParameters ? this.OldReportBookParameters.filter(p => p.bookParameter!.sourcePath === this.reportBookEntryPath) : []).concat(this.NewReportBookParameters);
        }

        get IsInListOfReportBookParameters(): boolean {
            return this.FormSpecificReportBookParameters.some(p => p.bookParameter!.sourceName === this.param.name);
        }
        get FollowsNamingConvention(): boolean {
            return this.$store.getters["reportbook/FollowsReportBookParameterNamingConvention"](this.param);
        }
        get IsReportBookParameter(): boolean {
            return this.IsInListOfReportBookParameters && this.FollowsNamingConvention;
        }

        get IsUsingAReportBookParameter(): boolean {
            return this.CurrentValue!.name ? this.CurrentValue!.name.startsWith("{BookParameter.") && this.CurrentValue!.name.endsWith(".Key}") : false;
        }

        async PromoteParameter() {
            // if this parameter has been promoted in the past, assign it here
            var formParameterName = this.param.name!;
            var existingRbParameter = this.FormSpecificReportBookParameters.find(p => p.bookParameter!.sourceName === formParameterName);
            var rbParameterName: string;

            if (existingRbParameter) {
                rbParameterName = existingRbParameter.bookParameter!.name!;
            }
            else {
                rbParameterName = await this.AssignReportBookParameterName(formParameterName);
                this.NewReportBookParameters.push(new api.BookAndFormParameter({
                    bookParameter: new api.BookParameter({
                        name: rbParameterName,
                        label: this.param.label ? this.param.label : rbParameterName,
                        sourcePath: this.reportBookEntryPath!,
                        sourceName: this.param.name!
                    }),
                    formParameter: this.param
                }));
            }
            this.CurrentValue = this.$store.getters["reportbook/ReportBookParameterValue"](rbParameterName);
        }

        async AssignReportBookParameterName(name: string): Promise<string> {
            var testName = name;
            var counter = 0;
            while (this.ReportBookParameters!.some(p => p.bookParameter!.name === testName)) {
                testName = name + counter;
                counter++;
            }
            while (this.NewReportBookParameters.some(p => p.bookParameter!.name === testName)) {
                testName = name + counter;
                counter++;
            }
            return testName;
        }

        async CancelPickReportBookParameter() {
            this.ReportBookParameterModal = false;
        }
        async SelectReportBookParameter(param: api.BookAndFormParameter) {
            this.CurrentValue = this.$store.getters["reportbook/ReportBookParameterValue"](param.bookParameter!.name!);
            this.CancelPickReportBookParameter();
        }
    }
</script>

<style scoped>
    input, select, textarea {
        border: 1px solid var(--thin-border);
        border-radius: 3px 3px;
        padding: 5px;
        width: 100%;
    }

        input[type='checkbox'] {
            width: auto;
            margin-left: 10px;
            margin-top: 10px;
        }

    .editorContent {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 5px;
    }

    .buttonArray {
        position: relative;
        top: 50%;
        transform: translateY(-100%);
    }

        .buttonArray button {
            border: none;
            background: none;
            position: relative;
            top: 50%;
        }

            .buttonArray button[disabled] {
                opacity: 0.5;
            }

    .modalTitle {
        font-size: 22px;
        font-weight: bold;
    }

    .modalContent {
        grid-column: auto;
        overflow-y: auto;
        background-color: var(--background-primary);
        border: 1px solid var(--thin-border);
        overflow-x: auto;
        overflow-y: auto;
        height: 300px;
    }

    .modalItemWrapper {
        width: 100%;
        border-bottom: 1px solid var(--thin-border);
        padding-bottom: 0px;
        cursor: pointer;
        user-select: none;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 28px;
        height: 36px;
        position: relative;
        white-space: nowrap;
    }

        .modalItemWrapper.even {
            background-color: var(--background-even);
        }

    .modalItem .label {
        overflow: hidden;
        position: absolute;
        left: 56px;
        right: 28px;
        text-overflow: ellipsis;
    }

</style>
