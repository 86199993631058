<template>
    <Confirm v-if="Show" @close="Cancel">
        <template v-slot:icon>
            <div style="float:left;margin:12px;">
                <img class="icon" :style="{width: '50px', height:'50px', content: (icon ? ('url('+icon+')') : 'url(/icons/info.png)')}" />
            </div>
        </template>

        <template v-slot:body>
            <div style="padding-top:15px; white-space: normal;">{{title}}</div>
            <div v-if="innerHtml" style="max-height: 300px; padding: 20px; overflow: auto">
                <div v-html="innerHtml"></div>
            </div>
        </template>

        <template v-slot:footer>
            <div class="btnViewer" @click="Null" v-if="nullButtonText!=null">
                {{nullButtonText}}
            </div>
            <div class="btnViewer" @click="Cancel"  v-if="hideCancelButton!=true">
                {{cancelButtonText == null ? Localizer.Localize('CANCEL'): cancelButtonText}}
            </div>
            <div class="btnViewer btnViewerDefault" @click="Accept">
                {{acceptButtonText == null ? Localizer.Localize('OK'):acceptButtonText}}
            </div>
        </template>
    </Confirm>
</template>

<script lang="ts">
    import { Localizer } from "../../api/Localizer";
    import Confirm from './Confirm.vue';
    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component({ components: { Confirm } })
    export default class Confirm2 extends Vue {
        private Localizer = new Localizer(this.$store);

        @Prop() title!: string;
        @Prop({ type: String, required: false }) icon!: string | null;
        @Prop() acceptButtonText!: string;
        @Prop() cancelButtonText!: string;
        @Prop() nullButtonText!: string;
        @Prop({ type: String, required: false }) innerHtml!: string | null;
        @Prop() hideCancelButton!: boolean;

        Show: boolean = false;
        private resolve: ((value: boolean | null | PromiseLike<boolean | null>) => void) | null = null;

        Open(): Promise<boolean | null> {
            this.Show = true;

            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        }

        Cancel() {
            this.resolve!(false);
            this.Show = false;
        }

        Accept() {
            this.resolve!(true);
            this.Show = false;
        }

        Null() {
            this.resolve!(null);
            this.Show = false;
        }
    }
</script>

<style scoped>

</style>
