<template>
    <div style="display: flex;flex-direction: column;height: 100%;">
        <div v-if="processesAndDataViewsView" class="mt-2" style="flex-grow: 1;">
            <template v-for="(wc, i) in WorkbookControls">
                <transition-group name="slide" tag="span">
                    <div v-if="dataViews && isDataView(wc)" :key="i" class="listItem" @click="Fluence.Run(wc)">
                        <img src="/icons/excel/form/chart.svg" class="reverse-accent-svg" /><span class="ml-1 text-white">{{wc.FullName}}</span>
                    </div>
                    <template v-if="processes && isProcess(wc)">
                        <div @mouseover="wc.Enabled = true" @mouseleave="wc.Enabled = false" :key="i">
                            <div class="listItem" @click="Fluence.Run(wc)">
                                <div>
                                    <img src="/icons/excel/form/list.svg" class="reverse-accent-svg" /><span class="ml-1 text-white">{{wc.FullName}}</span>
                                </div>
                            </div>
                            <transition name="expanded" mode="out-in">
                                <div v-if="wc.Enabled">
                                    <template v-for="(pr, j) in Fluence.processRuns">
                                        <div v-if="pr.FullName === wc.FullName" :key="j">
                                            <div class="listItem ml-5 mt-1">
                                                <span class="ml-1 text-white">{{pr.Name}}</span>
                                            </div>
                                            <template v-for="(prl, k) in Fluence.processRunLog">
                                                <div v-if="prl.processRunId === pr.Id" class="ml-5 mt-1" :key="prl.activityRunId+prl.time">
                                                    <span class="ml-1">{{Fluence.getEnumKeyFromValue(prl.level)}} - {{prl.message}}</span>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </transition>
                        </div>
                    </template>
                </transition-group>
            </template>
        </div>
        <div v-else-if="scriptView" style="display: flex; flex-direction: column; z-index: 1000; flex-grow: 1">
            <div style="display: flex; background-color: var(--accent-color3); height: 40px;">
                <button type="button" class="scriptNavBtn" @click="scriptView = false; settingsView = true;">
                    <img src="/icons/excel/arrow_left.svg" class="reverse-accent-svg" style="height: 100%;">
                </button>
                <div style="text-align: center; flex-grow: 1">
                    <button type="button" class="scriptNavBtn" @click="runScript()">
                        <span style="margin-right: 5px;vertical-align: text-top;">Run Script</span><img src="/img/run.e9e47f0f.svg" class="reverse-accent-svg">
                    </button>
                </div>
                <button type="button" class="scriptNavBtn" style="padding: 5px" @click="updateCustomXml()">
                    <img src="/icons/excel/save.svg" class="reverse-accent-svg" style="height: 100%;">
                </button>
            </div>
            <div class="textAreaCode d-flex">
                <div style="margin-top:5px">
                    <span class="blink_text"><img src="/icons/arrow_right.svg" class="reverse-accent-svg"></span>
                </div>
                <textarea v-model="textAreaCode" class="textAreaCode"></textarea>
            </div>
        </div>
        <div v-else style="width:100%; flex-grow: 1" class="itemGrid">
            <div v-if="!settingsView">
                <div class="item">
                    <img src="/icons/excel/form/signout_64.png" width="52" height="52">
                    <div>Internal Testing</div>
                </div>
                <div class="item">
                    <img src="/icons/excel/form/select_parameters_64.png" width="52" height="52">
                    <div>Open</div>
                </div>
                <!--<div class="item">
                    <img src="/icons/excel/form/refresh_64.png" width="52" height="52">
                    <div>Edit</div>
                </div>-->
                <div v-if="!IsForm" class="item" @click="processes = true; processesAndDataViewsView = true; settingsView = false">
                    <img src="/icons/excel/form/audit_trail_64.png" width="52" height="52">
                    <div>Processes</div>
                </div>
                <div v-if="!IsForm" class="item" @click="dataViews = true; processesAndDataViewsView = true; settingsView = false">
                    <img src="/icons/excel/form/add_row_64.png" width="52" height="52">
                    <div>Dataviews</div>
                </div>
                <div class="item">
                    <img src="/icons/excel/form/view_log_64.png" width="52" height="52">
                    <div>Show Logs</div>
                </div>
            </div>
            <div v-else>
                <div class="item" @click="Fluence.AddProcessTemplate(SelectedRangeAddress)">
                    <img src="/icons/excel/form/add_row_64.png" width="52" height="52">
                    <div>Process Template</div>
                </div>
                <div class="item" @click="Fluence.AddDataViewTemplate(SelectedRangeAddress)">
                    <img src="/icons/excel/form/add_row_64.png" width="52" height="52">
                    <div>DataView Template</div>
                </div>
                <!--<div class="item" @click="addScriptTemplate(SelectedRangeAddress)">
                    <img src="/icons/excel/form/add_row_64.png" width="52" height="52">
                    <div>Add Script Template</div>
                </div>-->
                <div class="item" @click="scriptView = true; processesAndDataViewsView = false; settingsView = false;">
                    <img src="/icons/excel/form/add_row_64.png" width="52" height="52">
                    <div>Script</div>
                </div>
                <div class="item" @click="Fluence.ScanWorkbook(true, textAreaCode)">
                    <img src="/icons/excel/form/view_log_64.png" width="52" height="52">
                    <div>Rescan Workbook</div>
                </div>
                <div class="item" @click="Fluence.OpenProcessEditor()">
                    <img src="/icons/excel/form/line_item_64.png" width="52" height="52">
                    <div>Open Process Editor</div>
                </div>
                <div class="item" @click="Fluence.StartStopDebugServer()">
                    <img src="/icons/excel/form/audit_trail_64.png" width="52" height="52">
                    <div>Start/Stop Server Debug</div>
                </div>
            </div>
        </div>
        <div v-if="!IsForm" class="bottomBar">
            <div class="iconsLeft">
                <div :class="[processes ? 'icon' : 'h-100']">
                    <img v-if="!processes" src="/icons/excel/form/list.svg" class="h-100" @click="processes = true; processesAndDataViewsView = true; settingsView = false" title="Processes" />
                    <img v-else src="/icons/excel/form/list.svg" class="h-100 reverse-accent-svg" @click="processes = false; processesAndDataViewsView = dataViews" title="Hide Processes" />
                </div>
                <div :class="[dataViews ? 'icon' : 'h-100']">
                    <img v-if="!dataViews" src="/icons/excel/form/chart.svg" class="h-100" @click="dataViews = true; processesAndDataViewsView = true; settingsView = false" title="DataViews" />
                    <img v-else src="/icons/excel/form/chart.svg" class="h-100 reverse-accent-svg" @click="dataViews = false; processesAndDataViewsView = processes" title="Hide DataViews" />
                </div>
            </div>
            <div class="iconsRight">
                <div :class="[settingsView ? 'icon' : 'h-100']">
                    <img v-if="!settingsView" src="/icons/excel/form/parameter.svg" class="h-100" @click="settingsView = true; processesAndDataViewsView = false; processes = false; dataViews = false; scriptView = false" title="Settings" />
                    <img v-else src="/icons/excel/form/parameter.svg" class="h-100 reverse-accent-svg" @click="settingsView = false;" title="Close Settings" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import * as api from "../../../../api/ApiClient";
    import { Localizer } from "../../../../api/Localizer";
    import { Component, Watch, Vue } from 'vue-facing-decorator';
    import ProcessVM from "../vena/ProcessVM";
    import DataViewVM from "../vena/DataViewVM";
    import WorkbookControlVM from "../vena/WorkbookControlVM";
    import VenaHandler from '../vena/VenaHandler'

    @Component
    export default class FluenceView extends Vue {

        isSelectEventEnabled: boolean = true;
        settingsView: boolean = false;
        processes: boolean = false;
        dataViews: boolean = false;
        processesAndDataViewsView: boolean = false;
        textAreaCode: string = '';
        scriptView: boolean = false;
        Fluence: VenaHandler = new VenaHandler(this.$store);
        private Localizer = new Localizer(this.$store);

        get SelectedRange(): Excel.Range | null { return this.$store.state.excel.SelectedRange; }
        set SelectedRange(selectedRange: Excel.Range | null) { this.$store.commit("excel/SetSelectedRange", selectedRange); }
        get SelectedRangeAddress(): string | null { return this.$store.state.excel.SelectedRangeAddress; }
        set SelectedRangeAddress(selectedRangeAddress: string | null) { this.$store.commit("excel/SetSelectedRangeAddress", selectedRangeAddress); }
        get WorkbookControls(): WorkbookControlVM[] { return this.$store.state.excel.WorkbookControls; }
        set WorkbookControls(workbookControls: WorkbookControlVM[]) { this.$store.commit("excel/SetWorkbookControls", workbookControls); }
        get IsForm(): boolean { return this.$store.state.excel.IsForm; }
        set IsForm(isForm: boolean) { this.$store.commit("excel/SetIsForm", isForm); }

        async mounted() {
            await Excel.run(async (context) => {
                context.workbook.worksheets.load("items");
                await context.sync();

                for (var sheet in context.workbook.worksheets.items) {
                    var ws: Excel.Worksheet = context.workbook.worksheets.items[sheet];
                    if (ws.name == '_Fluence') {
                        this.IsForm = true;
                    }
                }
            });
            if (!this.IsForm) {
                await this.registerTableHandlers();
                let scriptString = await this.ScanWorkbook(false);
                if (scriptString)
                    this.textAreaCode = await scriptString;
            }
        }

        isDataView(wc: WorkbookControlVM) {
            return wc instanceof DataViewVM;
        }
        isProcess(wc: WorkbookControlVM) {
            return wc instanceof ProcessVM;
        }

        async registerTableHandlers() {
            await Excel.run(async (context) => {
                var worksheets = context.workbook.worksheets;
                worksheets.load('items/name')
                await context.sync();
                worksheets.items.forEach((sheet) => {
                    // Do not add event handlers if _Fluence worksheet
                    if (sheet.name !== '_Fluence') {
                        sheet.onSelectionChanged.add(this.onWorksheetCollectionSelectionChange);
                    }
                });
                await context.sync()
                console.log("Event handler successfully registered for onSelectionChanged event in the worksheet.");
            }).catch(function (error) {
                console.log("Error: " + error);
                if (error instanceof OfficeExtension.Error) {
                    console.log("Debug info: " + JSON.stringify(error.debugInfo));
                }
            });
        }

        async onWorksheetCollectionSelectionChange(args: Excel.WorksheetSelectionChangedEventArgs) {
            if (this.isSelectEventEnabled) {
                var vm = this;
                await Excel.run(async (context) => {
                    //check ranges, control ranges, and actions
                    var selectedRange = context.workbook.getSelectedRange();
                    var selectedWorksheet = context.workbook.worksheets.getActiveWorksheet();
                    var cellRange = context.workbook.worksheets.getActiveWorksheet().getRange(args.address);
                    cellRange.load('text');
                    selectedRange.load('address, addressLocal, columnIndex, rowIndex, rowCount, columnCount, values, text, formulas');
                    selectedWorksheet.load('name');
                    await context.sync();
                    // save range selected and range address to store
                    this.SelectedRange = selectedRange;
                    this.SelectedRangeAddress = selectedRange.address;
                    await context.sync();
                }).catch(function (error) {
                    console.log("Error: " + error);
                    if (error instanceof OfficeExtension.Error) {
                        console.log("Debug info: " + JSON.stringify(error.debugInfo));
                    }
                });
            }
        }

        async runScript() {
            let vm = this;
            eval(vm.textAreaCode);
        }

        async saveCustomXml() {
            await Excel.run(async (context) => {
                const originalXml = `<FluenceScript>${this.textAreaCode}</FluenceScript>`;
                const customXmlPart = context.workbook.customXmlParts.add(originalXml);
                customXmlPart.load("id");
                const xmlBlob = customXmlPart.getXml();

                await context.sync();

                const readableXml = this.addLineBreaksToXML(xmlBlob.value);
                console.log(readableXml);

                // Store the XML part's ID in a setting.
                const settings = context.workbook.settings;
                settings.add("scriptXmlPartId", customXmlPart.id);

                await context.sync();
            });
        }

        async updateCustomXml() {
            await Excel.run(async (context) => {
                const settings = context.workbook.settings;
                const xmlPartIDSetting = settings.getItemOrNullObject("scriptXmlPartId").load("value");
                await context.sync();

                if (xmlPartIDSetting.value) {
                    let customXmlPart = context.workbook.customXmlParts.getItem(xmlPartIDSetting.value);
                    // The setXml method does a whole-for-whole replacement
                    // of the entire XML.
                    const xmlString: string = `<FluenceScript>${this.textAreaCode}</FluenceScript>`;
                    customXmlPart.setXml(xmlString);
                    const xmlBlob = customXmlPart.getXml();
                    await context.sync();

                    const readableXml = this.addLineBreaksToXML(xmlBlob.value);
                    console.log(readableXml);
                    await context.sync();
                }
                else {
                    this.saveCustomXml()
                }
            });
            await this.$store.dispatch("ShowToast", new api.ToastNotification({
                message: this.Localizer.Localize('FluenceView_Custom_Script_Attached_Save_to_Keep'), 
                type: api.ToastType.Success
            }));
        }

        addLineBreaksToXML(xmlBlob: string): string {
            const replaceValue = new RegExp(">");
            return xmlBlob.replace(/></g, "> <");
        }

        async ScanWorkbook(userTriggered: boolean, script?: string): Promise<void | string> {
            let vm = this;
            let scriptString;
            vm.Fluence.workbookControls.splice(0);
            vm.Fluence.registeredFunctions = [];
            await Excel.run(async (context) => {
                const wb = context.workbook;
                let tempControls: any[] = [];
                vm.Fluence.workbookControls = [];
                wb.load('names/items/name');
                await context.sync();
                let names = wb.names.items;
                for (let i = 0; i < names.length; ++i) {
                    let name = names[i].name.toLowerCase();
                    if (name.startsWith("fluence.process.")) {
                        var range = names[i].getRange().load('text, address');
                        await context.sync();
                        let enabled = range.text[0][1].toLowerCase() === "true";
                        let pr: ProcessVM = new ProcessVM({
                            NamedRange: names[i].name,
                            RangeAddress: range.address,
                            FullName: range.text[0][0],
                            Enabled: enabled,
                            Language: vm.$store.state.Locale
                        })
                        tempControls.push(pr);
                    }
                    else if (name.startsWith("fluence.dataview.")) {
                        var range = names[i].getRange().load('text, address');
                        await context.sync();
                        let enabled = range.text[0][1].toLowerCase() === "true";
                        let dv: DataViewVM = new DataViewVM({
                            NamedRange: names[i].name,
                            RangeAddress: range.address,
                            FullName: range.text[0][0],
                            Enabled: enabled,
                            Language: vm.$store.state.Locale
                        })
                        tempControls.push(dv);
                    }
                }
                tempControls.forEach(p => vm.Fluence.workbookControls.push(p))
                //add to store
                vm.$store.commit("excel/SetWorkbookControls", [...vm.Fluence.workbookControls]);

                if (!userTriggered) {
                    scriptString = await vm.RunCustomXml();
                } else if (script) {
                    eval(script);
                }
            }).catch(function (error) {
                console.log("Error: " + error);
                if (error instanceof OfficeExtension.Error) {
                    console.log("Debug info: " + JSON.stringify(error.debugInfo));
                }
            });
            return scriptString;
        }

        async RunCustomXml(): Promise<void | string> {
            let vm = this;
            let scriptString;
            await Excel.run(async (context) => {
                const settings = context.workbook.settings;
                const xmlPartIDSetting = settings.getItemOrNullObject("scriptXmlPartId").load("value");
                await context.sync();

                if (xmlPartIDSetting.value) {
                    let customXmlPart = context.workbook.customXmlParts.getItem(xmlPartIDSetting.value);
                    const xmlBlob = customXmlPart.getXml();
                    await context.sync();
                    const xmlStr = xmlBlob.value;
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(xmlStr, "application/xml");
                    // print the value of the root element or error message
                    const errorNode = doc.querySelector("parsererror");
                    if (errorNode) {
                        console.log("error while parsing");
                    } else {
                        if (doc.documentElement.nodeName === 'FluenceScript') {
                            if (doc.documentElement.textContent) {
                                eval(doc.documentElement.textContent);
                                scriptString = doc.documentElement.textContent;
                            }
                        }
                        else console.log("empty script");
                    }
                }
            });
            return scriptString;
        }
    }

</script>

<style scoped>
    .itemGrid {
        display: grid;
        grid-column: 1/4;
        grid-row: 2;
        border-bottom: 1px solid var(--thin-border);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .item {
        display: inline-grid;
        align-self: center;
        cursor: pointer;
        -ms-flex-line-pack: center;
        align-content: center;
        justify-items: center;
        padding: 14px;
        width: 100px;
        text-align: center;
    }

        .item.disabled {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
            color: var(--theme-color);
        }

    .bottomBar {
        height: 50px;
        display: flex;
    }

    .iconsLeft {
        display: flex;
        flex: 1;
        cursor: pointer;
    }

    .iconsRight {
        cursor: pointer;
    }

    .icon {
        height: 100%;
        background-color: var(--accent-color);
    }

    .bottomBar img {
        padding: 0.5rem;
    }

    .listItem {
        padding: 10px;
        background-color: var(--accent-color);
        cursor: pointer;
    }

    .expanded-enter-active, .expanded-leave-active {
        transition: all .3s ease;
    }

    .expanded-leave-active {
        position: absolute;
    }

    .expanded-enter-from, .expanded-leave-to {
        transform: translateY(-10px);
        opacity: 0;
    }

    .slide-enter-active, .slide-leave-active {
        transition: all .3s ease;
    }

    .slide-leave-active {
        position: absolute;
    }

    .slide-enter-from, .slide-leave-to {
        transform: translateY(-10px);
        opacity: 0;
    }

    .slide-move {
        transition: transform 0.8s ease;
    }

    .scriptNavBtn {
        border: none;
        display: inline-block;
        cursor: pointer;
        background-color: var(--accent-color3);
        height: 100%;
        color: rgb(255, 255, 255);
        font-size: 16px;
    }

        .scriptNavBtn:hover {
            background-color: var(--foreground-primary);
        }

        .scriptNavBtn:active {
            background-color: var(--accent-color);
        }

    .textAreaCode {
        background-color: rgb(33, 33, 33);
        color: rgb(255,255,255);
        font-size: 16px;
        font-family: 'Roboto';
        height: 100%;
        border: none;
    }

        .textAreaCode:focus-visible {
            outline: none;
        }

    .innerView {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        z-index: 1000;
    }
</style>
