<template>
    <div class="editor m-3">
        <img class="theme-svg" src="/icons/workflow/close.svg" id="close" @click="Cancel" />
        <span class="title">Set parameter values</span>
        <div class="child-editor" v-for="param, i in CurrentParameters.parameters">
            <label>{{param.name}}</label>
            <FormParameterEditor :param="param" @update="param.currentValue = $event;" />
        </div>
        <div style="margin:0 auto;">
            <div class="btnViewer btnViewerDanger" @click="Cancel">CANCEL</div>
            <div class="btnViewer btnViewerDefault" @click="Run">RUN</div>
        </div>
    </div>
</template>

<script lang="ts">
    require('@/assets/portal.css');

    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import Spinner from "../../helpers/Spinner.vue";
    import Empty from "../../helpers/Empty.vue";
    import * as api from "../../../api/ApiClient";

    import FormParameterEditor from "../forms/FormParameterEditor.vue";

    @Component({ components: { Spinner, Empty, FormParameterEditor } })
    export default class FormParameterInput extends Vue {
        @Prop() Parameters!: api.FormParameterList;

        CurrentParameters: api.FormParameterList = new api.FormParameterList;
        SelectedParameters: api.FormParameterCurrentValue[] | null = [];
        beforeMount() {
            var copy = this.Parameters.toJSON();
            this.CurrentParameters = api.FormParameterList.fromJS(copy);
        }

        async mounted() {

        }

        Cancel() {
            this.$nextTick().then(() => {
                this.SelectedParameters = null;
            });
            this.$emit('Cancel');
        }

        Run() {
            this.$nextTick().then(() => {
                if (this.CurrentParameters.parameters) {
                    this.SelectedParameters = this.CurrentParameters.parameters.map(param => new api.FormParameterCurrentValue({
                        name: param.name,
                        currentValue: param.currentValue,
                        isPinned: false
                    }));
                }
                if (this.SelectedParameters && this.SelectedParameters.length > 0) {
                    this.$store.commit("excel/SetSelectedParameters", this.SelectedParameters);
                    this.$emit('Run');
                }
            });
        }
    }
</script>

<style scoped>
    .editor {
        background-color: var(--background-primary);
    }

    label {
        font-weight: bold;
        margin-top: 6px;
    }

    select {
        border-radius: 3px 3px;
        padding: 5px;
    }

    #close {
        float: right;
    }

    .title {
        font-size: 22px;
        font-weight: bold;
    }
</style>
