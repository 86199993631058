import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateControl = _resolveComponent("DateControl")!
  const _component_ListControl = _resolveComponent("ListControl")!
  const _component_TreeControl = _resolveComponent("TreeControl")!
  const _component_AttachmentControl = _resolveComponent("AttachmentControl")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasDateControl)
      ? (_openBlock(), _createBlock(_component_DateControl, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.hasListControl)
      ? (_openBlock(), _createBlock(_component_ListControl, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.hasTreeControl)
      ? (_openBlock(), _createBlock(_component_TreeControl, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.hasAttachmentControl)
      ? (_openBlock(), _createBlock(_component_AttachmentControl, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}