<template>
    <div id="webchat" role="main" style="width:100%; height:100%;"></div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-facing-decorator';
    import Avatar from '../../helpers/Avatar.vue';

    import * as api from "../../../api/ApiClient";
    import * as base from "../../../api/ApiClientBase";

    class Fake {
        type: string | undefined;
        dispatch: any;
    }

    @Component({ components: { Avatar } })
    export default class BotFrameworkChat extends Vue {
        private loadedScripts = 0;
        private Details: any;

        private async LoadCustomScript(url: string) {
            if (this.loadedScripts < 2) {
                console.log(url);
                let webchat = document.createElement('script');
                webchat.setAttribute('crossorigin', "anonymous");
                webchat.setAttribute('src', url);
                webchat.onload = async () => {
                    this.loadedScripts += 1;
                    if (this.loadedScripts >= 2)
                        await this.SetUpUI();
                }
                document.head.appendChild(webchat);
                return true;
            }
            return false;
        }

        private interval: ReturnType<typeof setInterval> | null = null;

        async mounted() {
            this.Details = await this.GetDetails();

            this.interval = setInterval(async () => {
                this.Details = await this.GetDetails();
            }, 1000 * 60 * 2);

            var newScripts = await this.LoadCustomScript('https://cdn.botframework.com/botframework-webchat/latest/webchat.js');
            newScripts = await this.LoadCustomScript('https://unpkg.com/simple-update-in/dist/simple-update-in.production.min.js') || newScripts;

            if (!newScripts)
                await this.SetUpUI();
        }

        beforeUnmount() {
            if (this.interval)
                clearInterval(this.interval);
        }

        private async GetDetails() {
            var authClient = new base.AuthClient();
            await authClient.ensureToken();

            var tenant: string = this.$store.state.security.Tenant.name;
            var userName: string = this.$store.state.security.UserData.userName;
            var token: string | null = base.AuthClient.accessToken
            var details = {
                tenant: tenant,
                userName: userName,
                token: token
            };
            return details;
        }

        private async SetUpUI() {
            var wnd = window as any;

            const styleOptions = {
                accent: 'var(--accent-color)',
                backgroundColor: 'var(--background-primary)',

                bubbleFromUserBackground: 'var(--accent-color)',
                bubbleFromUserBorderColor: 'var(--accent-color)',
                bubbleFromUserTextColor: ' var(--accent-foreground)',
                bubbleBorderRadius: '15px',

                bubbleBackground: 'var(--background-secondary)',
                bubbleBorderColor: 'var(--thin-border)',
                bubbleTextColor: ' var(--foreground-secondary)',

                cardEmphasisBackgroundColor: 'var(--background-secondary)',
                cardPushButtonBackgroundColor: 'var(--accent-color)',
                cardPushButtonTextColor: ' var(--accent-foreground)',

                suggestedActionBorderColor: 'var(--thin-border)',
                suggestedActionBorderColorOnDisabled: 'var(--thin-border)',
            };

            const store = wnd.WebChat.createStore(
                {},
                (x: Fake) => (next: Function) => (action: Fake) => {
                    if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
                        action = wnd.simpleUpdateIn(action, ['payload', 'activity', 'channelData', 'details'], () => this.Details);
                    }
                    else if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
                        x.dispatch({
                            type: 'WEB_CHAT/SEND_EVENT',
                            payload: {
                                name: 'webchat/join',
                                value: { myvalue: "test" }
                            }
                        });
                    }

                    return next(action);
                }
            );

            const adapters = await wnd.WebChat.createDirectLineSpeechAdapters({
                fetchCredentials: {
                    region: 'eastus2',
                    subscriptionKey: '05baaee6099848d5bad8a97ddadde2c1'
                }
            });

            wnd.WebChat.renderWebChat(
                {
                    //directLine: wnd.WebChat.createDirectLine({
                    directLine: wnd.WebChat.createDirectLine({
                        token: 'FIMJcF3isDo.fCtXPA2eD_AXaE64fJmqawQ-QJXlytPUi7x6kyH0CiQ',
                    }),
                    store: store,
                    styleOptions: styleOptions,
                    locale: 'en-US',
                    ...adapters
                },
                document.getElementById('webchat')
            );
        }
    }


</script>

<style scoped>
    .ac-pushButton {
        border-color: var(--thin-border) !important;
    }
</style>
