import { Module } from 'vuex';
import * as api from "../api/ApiClient";

export interface IThemeStore {
    ThemeDetails: api.ThemeInfo | null
    AllThemes: string[]
    PreviewedTheme: string | null
    PreviewedThemeSettings: { [key: string]: string } | null
    ActiveThemeSettings: { [key: string]: string } | null
}

const ThemeStore: Module<IThemeStore, any> = {
    namespaced: true,
    state: {
        ThemeDetails: null,
        AllThemes: [],
        PreviewedTheme: null,
        PreviewedThemeSettings: null,
        ActiveThemeSettings: null
    },
    mutations: {
        SetThemeDetails(state, theme: api.ThemeInfo | null) {
            state.ThemeDetails = theme;
        },
        SetAllThemes(state, themes: string[]) {
            state.AllThemes = themes;
        },
        SetPreviewedTheme(state, themeName: string) {
            state.PreviewedTheme = themeName;
        },
        SetActiveThemeSettings(state, themeSettings: { [key: string]: string }) {
            state.ActiveThemeSettings = themeSettings;
        },
        SetPreviewedThemeSettings(state, themeSettings: { [key: string]: string }) {
            state.PreviewedThemeSettings = themeSettings;
        },
        SetThemeInLocalStorage(state: IThemeStore, themeSettings: { [key: string]: string }) {
            var storedTenantName = localStorage.getItem("tenantName");
            if (storedTenantName) {
                localStorage.setItem(`${storedTenantName}_theme`, JSON.stringify(themeSettings))
            }
        }
    },
};

export default ThemeStore;