<template>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component
    export default class Login extends Vue {
        private async mounted() {
            //not sure why this.$route.query doesn't always contain the proper values.
            var queryString = window.location.search;
            if (queryString != null && queryString.indexOf("apitoken=") > 0) {
                console.log("Api token found.")
                var queryString = queryString.substring(queryString.indexOf("apitoken=") + "apitoken=".length);
                if (queryString.indexOf('&') > 0)
                    queryString = queryString.substring(0, queryString.indexOf('&'));
                var apitoken = queryString;
                this.$store.commit("security/SetApiToken", apitoken);
            }
            var prefferedTenant = this.$route.query["tenant"];
            await this.$store.dispatch("security/Login", prefferedTenant);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
