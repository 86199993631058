<template>
    <FormParameterInput :Parameters="Parameters" SelectedParameters="SelectedParameters" v-if="isFormParameterInputOpened" @Cancel="isFormParameterInputOpened=false; isBusy = false" @Run="LoadFormWithParamsAsync" />
    <div v-else>
        <Spinner v-if="isBusy" />
        <button v-else-if="!isFormLoaded" @click="ShowParametersInput">Load Form</button>
        <div v-else>
            <Menu :isForm="true"></Menu>
            <!--<button @click="hideTaskpane">{{UIText.HidePane}}</button>-->
        </div>
    </div>
</template>

<script lang="ts">
    import * as api from "../../../api/ApiClient";
    import * as excelHandler from './form/ExcelHandler';
    import * as formControls from './form/FormControls';
    import { AuthClient } from '../../../api/ApiClientBase';
    import { Component, Watch, Vue } from 'vue-facing-decorator';

    //Components
    import Menu from './Menu.vue';
    import Spinner from '../../helpers/Spinner.vue';
    import ControlSettings from './ControlSettings.vue';
    import FormParameterInput from './FormParameterInput.vue';
    import ChooseTenant from '../../../components/ChooseTenant.vue';

    @Component({ components: { ChooseTenant, FormParameterInput, Spinner, ControlSettings, Menu } })
    export default class Container extends Vue {
        isDateOpened: boolean = false;

        private isBusy: boolean = true;
        private isFormLoaded: boolean = false;
        private Client: api.FormClient | undefined;
        private isSelectEventEnabled: boolean = true;
        private isControlsInitialized: boolean = false;
        private isFormParameterInputOpened: boolean = false;
        private controlRanges: formControls.GridRange[] = [];
        private tableControls: formControls.TableControl[] = [];
        private editControlRanges: formControls.EditControl[] = [];

        get UIText(): object | null { return this.$store.state.Locale; }
        get FormPath(): string | null { return this.$store.state.excel.FormPathOrId; }
        set FormPath(path: string | null) { this.$store.commit("excel/SetFormPath", path); }
        get Password(): string | null { return this.$store.state.excel.Password; }
        set Password(password: string | null) { this.$store.commit("excel/SetPassword", password); }
        get TenantOverride(): string | null { return this.$store.state.excel.TenantOverride; }
        set TenantOverride(tenantOverride: string | null) { this.$store.commit("excel/SetTenantOverride", tenantOverride); }
        get Parameters(): api.FormParameterList | null { return this.$store.state.excel.Parameters; }
        set Parameters(params: api.FormParameterList | null) { this.$store.commit("excel/SetParameters", params); }
        get SelectedRange(): Excel.Range | null { return this.$store.state.excel.SelectedRange; }
        set SelectedRange(selectedRange: Excel.Range | null) { this.$store.commit("excel/SetSelectedRange", selectedRange); }
        get ControlRanges(): formControls.GridRange[] | null { return this.$store.state.excel.ControlRanges; }
        set ControlRanges(controlRanges: formControls.GridRange[] | null) { this.$store.commit("excel/SetControlRanges", controlRanges); }
        get SelectedRangeAddress(): string | null { return this.$store.state.excel.SelectedRangeAddress; }
        set SelectedRangeAddress(selectedRangeAddress: string | null) { this.$store.commit("excel/SetSelectedRangeAddress", selectedRangeAddress); }
        get SelectedControl(): formControls.TableControl | any | null { return this.$store.state.excel.SelectedControl; }
        set SelectedControl(selectedControl: formControls.TableControl | any | null) { this.$store.commit("excel/SetSelectedControl", selectedControl); }
        get RefreshFormRequested(): boolean | undefined { return this.$store.state.excel.RefreshFormRequested; }
        set RefreshFormRequested(refreshFormRequested: boolean | undefined) { this.$store.commit("excel/SetRefreshFormRequested", refreshFormRequested); }
        get SelectedParameters(): api.FormParameterCurrentValue[] | [] { return this.$store.state.excel.SelectedParameters; }
        set SelectedParameters(selectedParameters: api.FormParameterCurrentValue[] | []) { this.$store.commit("excel/SetSelectedParameters", selectedParameters); }
        get ParametersSelectionRequested(): boolean | undefined { return this.$store.state.excel.ParametersSelectionRequested; }
        set ParametersSelectionRequested(parametersSelectionRequested: boolean | undefined) { this.$store.commit("excel/SetParametersSelectionRequested", parametersSelectionRequested); }

        @Watch("isFormLoaded") async onIsFormLoadedChange() {
            if (this.isFormLoaded) {
                await excelHandler.disableRibbonButton("LoadForm");
                await this.initializeControls();
                if (this.isControlsInitialized) {
                    this.isBusy = false;
                    await Excel.run(async (context) => {
                        const wb = context.workbook;
                        wb.isDirty = false;
                        await context.sync();
                    });
                }
            }
        }

        @Watch("ParametersSelectionRequested") async onParametersSelectionRequested() {
            if (this.ParametersSelectionRequested) {
                this.parametersDialog();
                this.isBusy = true;
                this.ParametersSelectionRequested = false;
            }
        }

        @Watch("RefreshFormRequested") async onRefreshFormRequested() {
            if (this.RefreshFormRequested) {
                this.refreshDialog();
                this.isBusy = true;
                this.RefreshFormRequested = false;
            }
        }

        async mounted() {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            this.Client = new api.FormClient(auth_client);
            await this.CheckControlSheet();
        }

        private async CheckControlSheet() {
            // check for the control tab
            var controlSheet: Excel.Worksheet;
            await Excel.run(async (context) => {
                context.workbook.worksheets.load("items");
                await context.sync();

                for (var sheet in context.workbook.worksheets.items) {
                    var ws: Excel.Worksheet = context.workbook.worksheets.items[sheet];
                    if (ws.name == '_Fluence') {
                        controlSheet = ws;
                    }
                }

                if (controlSheet != null) {
                    // check the controlType, tenantOverride, idOrPath, and removeControlFile
                    var controlRange = controlSheet.getRange("A1:D1");
                    var range = controlSheet.getUsedRange();
                    controlRange.load("values");
                    range.load("values");
                    await context.sync();

                    var controlType = controlRange.values[0][0];
                    if (controlType == "Open") {
                        var tenantOverride = controlRange.values[0][1];
                        this.SetTenantOverride(tenantOverride);

                        var idOrPath = controlRange.values[0][2];
                        var removeControlFile = controlRange.values[0][3] == null || controlRange.values[0][3] == '';
                        this.FormPath = idOrPath;
                        this.TenantOverride = tenantOverride;

                        var parameterMap: { Name: string, Value: string }[] = [];
                        var row = 1;

                        while (true) {
                            if (range.values[row]) {
                                var key = <string>range.values[row][0];
                                var value = <string>range.values[row][1];
                                if (key == "" || key == null || value == "" || value == null)
                                    break;
                                parameterMap.push({ Name: key, Value: value });
                                row++;
                            }
                            break;
                        }

                        this.OpenFormAsync(idOrPath, parameterMap, true, removeControlFile, false, "");
                    }
                    else if (controlType == "Form") {
                        this.isFormLoaded = true;
                    }
                    else {
                        this.isFormLoaded = true; //Remove this line later
                        return;
                    }
                };
            });
        }

        private async LoadFormWithParamsAsync() {
            this.isBusy = true;
            this.isFormLoaded = false;
            this.isFormParameterInputOpened = false;
            for (let i = 0; i < this.Parameters!.parameters!.length; i++) {
                this.Parameters!.parameters![i].currentValue = this.SelectedParameters![i].currentValue;
            }
            if (this.SelectedParameters.length > 0 && this.FormPath != null) {
                try {
                    var res = this.Client ? await this.Client.openForm(this.FormPath, this.SelectedParameters) : null;
                    if (res != null) {
                        var myBlob = res.data;
                        if (await excelHandler.insertSheets(<File>myBlob)) {
                            this.isFormLoaded = true;
                        }
                    }
                    else {
                        this.isFormLoaded = false;
                    }
                } catch (e) {
                    this.isFormLoaded = false;
                }
            }
        }

        private async LoadFormNoParamsAsync() {
            this.isBusy = true;
            this.isFormLoaded = false;
            if (this.FormPath != null) {
                var res = await this.Client!.openFormNoParams(this.FormPath);
                var myBlob = res!.data;
                if (await excelHandler.insertSheets(<File>myBlob)) {
                    this.isFormLoaded = true;
                }
            }
        }

        private async refreshForm() {
            if (this.SelectedParameters.length > 0) {
                await this.LoadFormWithParamsAsync();
            }
            else {
                await this.LoadFormNoParamsAsync();
            }
        }

        private async refreshDialog() {
            var dialog: any = null;
            var proceed: any = null;
            await Office.context.ui.displayDialogAsync(window.location.origin + "/excel/refreshDialog.html", { width: 20, height: 15 },
                // Dialog callback
                (result) => {
                    dialog = result.value;
                    dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                        proceed = arg.message;
                        if (dialog) {
                            dialog.close();
                        }
                        if (proceed === "true") {
                            this.refreshForm();
                        }
                        else this.isBusy = false;
                    });
                    dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
                        if (arg.error === 12006) {
                            dialog.close();
                            this.isBusy = false;
                        }
                    });
                },
            );
        }

        private async parametersDialog() {
            var dialog: any = null;
            var proceed: any = null;
            await Office.context.ui.displayDialogAsync(window.location.origin + "/excel/parametersDialog.html", { width: 20, height: 18 },
                // Dialog callback
                (result) => {
                    dialog = result.value;
                    dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
                        proceed = arg.message;
                        if (dialog) {
                            dialog.close();
                        }
                        if (proceed === "true") {
                            if (this.SelectedParameters.length > 0) {
                                this.isFormParameterInputOpened = true;
                            }
                            else
                                await this.LoadFormNoParamsAsync();
                        }
                        else this.isBusy = false;
                    });
                    dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
                        if (arg.error === 12006) {
                            dialog.close();
                            this.isBusy = false;
                        }
                    });
                },
            );
        }

        private SetTenantOverride(tenant: string) {
            var tenants = this.$store.state.security.Tenants as (api.Tenant[] | null);
            if (tenants == null)
                return; // this shouldn't be possible - after all the user must have logged in
            var current_tenant = this.$store.state.security.Tenant as api.Tenant;
            if (tenant == current_tenant.name)
                return;
            for (var i = 0; i < tenants.length; i++) {
                if (tenants[i].name == tenant) {
                    this.$store.dispatch("security/SelectTenant", tenants[i]);
                    return;
                }
            }
        }

        async OpenFormAsync(idOrPath: string, parameterMap: { Name: string, Value: string }[], closeCurrentWorkbook: boolean, removeCurrentFile: boolean, targetSelf: boolean, targetTab: string) {
            // Loading window
            this.isBusy = true;
            var parameterList: api.FormParameterList;
            try {
                parameterList = await this.Client!.getFormParameters(idOrPath);
            }
            finally {
                this.isBusy = false;
            }
            if (parameterList == null) {
                return;
            }
            var selectedParameters: api.FormParameterCurrentValue[] | null = this.SelectedParameters;
            if (parameterList.parameters) {
                if (parameterMap != null && parameterMap.length > 0) {
                    for (var p in parameterList.parameters) {
                        //JBTODO
                    }
                }
                if (parameterList.parameters.length == this.SelectedParameters.length) {
                    this.LoadFormNoParamsAsync();
                }
                else {
                    this.Parameters = parameterList;
                    this.ShowParametersInput(this.Client!, parameterList, this.SelectedParameters);
                }
            }
        }

        async ShowParametersInput(client?: api.FormClient, parameterList?: api.FormParameterList, currentParameterValues?: api.FormParameterCurrentValue[]) {
            var userConfirmed: boolean = true;
            if (parameterList && parameterList.parameters != null && parameterList.parameters.length > 0) {
                //If currentparameterValues passed in, set the equivalent parameter to the specified current value
                if (currentParameterValues != null && currentParameterValues.some(u => u)) {
                    //JBTODO
                }
                userConfirmed = false;
            }
            Office.addin.showAsTaskpane();
            this.isFormParameterInputOpened = true;
        }

        async registerTableHandlers() {
            await Excel.run(async (context) => {
                var worksheets = context.workbook.worksheets;
                worksheets.load('items/name')
                await context.sync();
                worksheets.items.forEach((sheet) => {
                    // Do not add event handlers if _Fluence worksheet
                    if (sheet.name !== '_Fluence') {
                        sheet.onSelectionChanged.add(this.onWorksheetCollectionSelectionChange);
                        sheet.onChanged.add(this.onWorksheetCollectionChange);
                    }
                });
                excelHandler.onTaskpaneVisibilityModeChanged();
                await context.sync()
                console.log("Event handler successfully registered for onSelectionChanged event in the worksheet.");
            }).catch(function (error) {
                console.log("Error: " + error);
                if (error instanceof OfficeExtension.Error) {
                    console.log("Debug info: " + JSON.stringify(error.debugInfo));
                }
            });
        }

        async onWorksheetCollectionSelectionChange(args: Excel.WorksheetSelectionChangedEventArgs) {
            if (this.isSelectEventEnabled) {
                var vm = this;
                await Excel.run(async (context) => {
                    //check ranges, control ranges, and actions
                    var selectedRange = context.workbook.getSelectedRange();
                    var selectedWorksheet = context.workbook.worksheets.getActiveWorksheet();
                    var cellRange = context.workbook.worksheets.getActiveWorksheet().getRange(args.address);
                    cellRange.load('text');
                    selectedRange.load('address, addressLocal, columnIndex, rowIndex, rowCount, columnCount, values, text, formulas');
                    selectedWorksheet.load('name');
                    await context.sync();
                    // save range selected and range address to store
                    this.SelectedRange = selectedRange;
                    this.SelectedRangeAddress = selectedRange.address.slice(selectedRange.address.indexOf('!') + 1);
                    await this.$nextTick();
                    // check if selected range is within a valid control range
                    var sheetControlRanges = this.controlRanges.filter((range: any) => range.SheetName === selectedWorksheet.name);
                    var controlRange = <formControls.GridRange>excelHandler.GetCellControlRange(selectedRange, sheetControlRanges);
                    if (controlRange) {
                        this.SelectedControl = controlRange.ObjectControl;
                    }
                    else {
                        this.SelectedControl = null;
                        return;
                    };
                    await context.sync();
                }).catch(function (error) {
                    console.log("Error: " + error);
                    if (error instanceof OfficeExtension.Error) {
                        console.log("Debug info: " + JSON.stringify(error.debugInfo));
                    }
                });
            }
        }

        async onWorksheetCollectionChange(event: Excel.WorksheetChangedEventArgs) {
            var vm = this;
            this.isSelectEventEnabled = false;
            //console.log('Excel.WorksheetChangedEventArgs');
            await Excel.run(async (context) => {
                //disable all event handlers
                context.runtime.load("enableEvents");
                context.runtime.enableEvents = false;
                await context.sync();
                //console.log('events disabled');
                let allowChange = true;
                var selectedWorksheet = context.workbook.worksheets.getActiveWorksheet();
                var selectedRange = selectedWorksheet.getRange(event.address);
                selectedRange.load('address, columnIndex, rowIndex, rowCount, columnCount');
                await context.sync();
                for (let i = 0; i < selectedRange.rowCount; ++i) {
                    for (let j = 0; j < selectedRange.columnCount; ++j) {
                        const activeCell = selectedRange.getCell(i, j);
                        activeCell.load('address, columnIndex, rowIndex, rowCount, columnCount, values');
                        await context.sync();
                        const controlRange = excelHandler.GetCellControlRange(activeCell, this.controlRanges);
                        if (controlRange) {
                            if (controlRange.ObjectControl!.FlagAction) {
                                //JBTODO: perform lazy unprotect on the sheet
                                if (controlRange.ObjectControl instanceof formControls.TableControl) {
                                    for (let k = 0; k < controlRange.ObjectControl.FieldMappings!.length; ++k) {
                                        if (controlRange.ObjectControl.FieldMappings![k].Fields!.length >= controlRange.ColumnCount!) {
                                            if (controlRange.ObjectControl.FieldMappings![k].Fields![activeCell.columnIndex - controlRange.Column!].DisableCondition != null) {
                                                //store selected range value before cell is edited and retrieved here in case DisableCondition evaluates to true
                                                const actionCell = activeCell.getOffsetRange(0, -activeCell.columnIndex);
                                                actionCell.select();
                                                actionCell.load('formulas, values, text');
                                                await context.sync()
                                                const actionFlag = actionCell.values[0][0];
                                                actionCell.formulas = [[`=${controlRange.ObjectControl.FieldMappings![k].Fields![activeCell.columnIndex - controlRange.Column!].DisableCondition}`]];
                                                actionCell.setDirty();
                                                context.workbook.application.calculate('Recalculate');
                                                await context.sync();
                                                actionCell.load('values, text');
                                                await context.sync()
                                                // if evaluate returns true, undo the change
                                                // NOTE: does not work when copy paste over different cell
                                                if (actionCell.values[0][0]) {
                                                    activeCell.values = [[vm.SelectedRange!.values[i][j]]];
                                                    //activeCell.format.protection.locked = true;
                                                    allowChange = false;
                                                }
                                                actionCell.formulas = [['']];
                                                actionCell.values = [[actionFlag]];
                                                await context.sync();
                                            }
                                        }
                                    }
                                }
                                await context.sync();
                                if (allowChange) {
                                    // update action flag -> What happens in case the cells have action flag deleted and there is a multiple row paste on top of it?
                                    await excelHandler.UpdateActionFlag(activeCell, excelHandler.UserAction.ChangeRow, context);
                                    // Optimization: no need to check other cells on this row since we already updated the row flag
                                    break;
                                }
                            }
                        }
                    }
                }
                //JBTODO: reprotect the sheet if needed
                // reenable event handlers
                context.runtime.enableEvents = true;
                await context.sync();
                this.isSelectEventEnabled = true;
                context.workbook.worksheets.getActiveWorksheet().getRange(this.SelectedRangeAddress!).select();
            });
        }

        async hideTaskpane() {
            Office.addin.hide();
        }

        async initializeControls() {
            var vm = this;
            this.resetControls();
            await Excel.run(async (context) => {
                const controlSheet = context.workbook.worksheets.getItem('_Fluence');
                const range = controlSheet.getUsedRange();
                range.load('values');
                await context.sync();

                let row = 1;
                while (true) {
                    if (range.values[row]) {
                        var key = <string>range.values[row][0];
                        var value = <string>range.values[row][1];
                        switch (key) {
                            case 'Password':
                                // Protection password -- client needs to know it in order to manipulate the grid
                                this.Password = value;
                                break;
                            case 'Table':
                                //code block
                                let newTableControl = new formControls.TableControl(JSON.parse(value));
                                if (newTableControl.EditControls) {
                                    this.tableControls.push(newTableControl);
                                }
                                break;
                            default:
                                break;
                        }
                        if (key == "" || key == null || value == "" || value == null)
                            break;
                        row++;
                    }
                    else break;
                }
            })
                .catch(function (error) {
                    console.log("Error: " + error);
                    if (error instanceof OfficeExtension.Error) {
                        console.log("Debug info: " + JSON.stringify(error.debugInfo));
                    }
                });
            var isControlRangesLoaded = await this.setControlRanges();
            console.log();
            if (await isControlRangesLoaded) {
                await this.registerTableHandlers();
                this.isControlsInitialized = true;
            }
        }

        async setControlRanges(): Promise<boolean> {
            var vm = this;
            let dataRowRange: Excel.Range | undefined;
            await Excel.run(async (context) => {
                const ws = context.workbook.worksheets;
                ws.load('items/name');
                await context.sync();
                for (let i = 0; i < this.tableControls.length; i++) {
                    if (this.tableControls[i].DataRowRange) {
                        var worksheet: Excel.Worksheet;
                        for (let j = 0; j < ws.items.length; j++) {
                            worksheet = context.workbook.worksheets.getItem(ws.items[j].name);
                            try {
                                dataRowRange = worksheet.getRange(this.tableControls[i].DataRowRange ? this.tableControls[i].DataRowRange! : '');
                                await context.sync();
                            } catch (e) {
                                console.log(e);
                                break;
                            }
                            if (dataRowRange) {
                                dataRowRange.load('worksheet, rowIndex, rowCount');
                                await context.sync();
                                console.log('datarowrange props loaded');
                                await this.addFieldMappings(this.tableControls[i], dataRowRange, context);
                                console.log('addFieldMappings done');
                                await this.setEditControlRanges(this.tableControls[i], dataRowRange, context, worksheet);
                                console.log('setEditControlRanges done');
                                break;
                            }
                        }
                    }
                }
            })
                .catch(function (error) {
                    console.log("Error: " + error);
                    if (error instanceof OfficeExtension.Error) {
                        console.log("Debug info: " + JSON.stringify(error.debugInfo));
                    }
                });
            if (this.controlRanges.length > 0) {
                this.ControlRanges = this.controlRanges;
            }
            return this.controlRanges.length > 0 && true;
        }

        async addFieldMappings(tableControl: formControls.TableControl, dataRowRange: Excel.Range, context: Excel.RequestContext) {
            var vm = this;
            if (tableControl.FieldMappings) {
                let fmArr = tableControl.FieldMappings;
                dataRowRange.worksheet.load('name');
                for (var i = 0; i < fmArr.length; i++) {
                    let drRange = dataRowRange.worksheet.getRange(fmArr[i].ColumnRangeName);
                    drRange.load('columnIndex');
                    drRange.load('columnCount');
                    await context.sync();
                    let controlRange = new formControls.GridRange();
                    controlRange.init({
                        SheetName: dataRowRange.worksheet.name,
                        Name: fmArr[i].ColumnRangeName,
                        Row: dataRowRange.rowIndex,
                        RowCount: dataRowRange.rowCount,
                        ColumnRangeIndex: i,
                        DataRowRange: tableControl.DataRowRange,
                        ObjectControl: tableControl,
                        Column: drRange.columnIndex,
                        ColumnCount: drRange.columnCount,
                    });
                    this.controlRanges.push(controlRange);
                }
            }
        }

        async setEditControlRanges(tableControl: formControls.TableControl, dataRowRange: Excel.Range, context: Excel.RequestContext, worksheet: Excel.Worksheet) {
            var vm = this;
            if (tableControl.EditControls) {
                let ecArr = tableControl.EditControls!;
                for (let i = 0; i < this.controlRanges!.length; i++) {
                    for (let j = 0; j < ecArr.length; j++) {
                        if (ecArr[j].RangeIndex === this.controlRanges[i].ColumnRangeIndex) {
                            this.editControlRanges.push(ecArr[j]);
                            await context.sync();
                        }
                    }
                }
            }
        }

        async resetControls() {
            this.editControlRanges = [];
            this.controlRanges = [];
            this.tableControls = [];
        }

        /*async EnsureUserLoggedIn(allowInteractive: boolean, forceLogin: boolean = false): Promise<string> {
            var result = "";
            if (this.Tenants == null || forceLogin) {
                try {
                    result = await this.$store.dispatch("security/Login", this.Tenant);
                    if (result != null) {
                        // What to do here?
                        //var hwnd = Application.Hwnd;
                        //tenant = await loginApp.EnsureTenant(hwnd, allowInteractive);
                        //Ribbon.ToggleConnectButton(true);
                    }
                }
                catch (ex) {
                    throw ex;
                }
            }
            return result;
        }*/
    }
</script>

<style scoped>
</style>
