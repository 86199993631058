<template>
    <Spinner v-if="isBusy"></Spinner>
    <div v-else class="text-center">
        <label for="input-with-list"></label>
        <b-form-input list="input-list" id="input-with-list" v-model="selectedItem"></b-form-input>
        <b-form-datalist id="input-list" :options="dropdownList"></b-form-datalist>
        <div class="w-100 mt-3 text-right">
            <button @click="SetItem(true)" class="btnViewer btnViewerDefault">Select</button>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Vue } from 'vue-facing-decorator';
    import * as formControls from './form/FormControls';
    import * as excelHandler from './form/ExcelHandler';
    import * as excelHandlerUtils from './form/ExcelHandler.Utils';
    import * as api from "../../../api/ApiClient";
    import { AuthClient } from '../../../api/ApiClientBase';
    import SubstitutionDictionary from './form/SubstitutionDictionary';
    import Spinner from '../../helpers/Spinner.vue';

    @Component({components: { Spinner }})
    export default class ListControl extends Vue {

        get ControlRanges(): formControls.GridRange[] | null { return this.$store.state.excel.ControlRanges; }
        get SelectedRange(): Excel.Range | any | null { return this.$store.state.excel.SelectedRange; }
        get SelectedRangeAddress(): string | null { return this.$store.state.excel.SelectedRangeAddress; }
        get FormPath(): string | null { return this.$store.state.excel.FormPathOrId; }

        selectedItem: string = "";
        itemsList: ListItem[] = [];
        dropdownList: string[] = [];
        isBusy: boolean = true;

        @Watch("SelectedRangeAddress") async onItemChange() {
            await this.UpdateItem();
        }

        async created() {
            await this.UpdateItem();
        }

        async SetItem(isUpdate: boolean) {
            if (isUpdate) {
                await excelHandler.SetItem(this.itemsList.find(item => item.displayField === this.selectedItem)!.keyField, this.SelectedRangeAddress!);
            }
        }

        async UpdateItem() {
            this.selectedItem = '';
            var editControl: formControls.EditControl | null = new formControls.EditControl();
            var dictionary: SubstitutionDictionary<string> | null = null;
            dictionary = await excelHandlerUtils.GetSubstitutionDictionaryFromRange(null, this.ControlRanges!);
            await Excel.run(async (context) => {
                let selectedRange = context.workbook.getSelectedRange();
                selectedRange.load('text, worksheet, rowIndex, columnIndex');
                await context.sync();
                editControl = await excelHandlerUtils.GetCellEditControl(selectedRange.worksheet, selectedRange.rowIndex, selectedRange.columnIndex, this.ControlRanges!);
                Office.addin.showAsTaskpane();
                await context.sync();
            });
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            let client = new api.FormClient(auth_client);
            if (editControl) {
                var keyField = editControl.KeyField;
                var displayField = editControl.DisplayField || keyField;
                let response: api.QueryResult[] = [];
                try {
                    if (editControl.SourceType === "DimensionQuery")
                        response = await client.runDimensionQuery(parseInt(this.FormPath!), editControl.SourceName!, dictionary!.ToStringArray()!);
                    if (editControl.SourceType === "RelationalQuery") {
                        response = await client.runRelationalQuery(parseInt(this.FormPath!), editControl.SourceName!, dictionary!.ToStringArray()!);
                    }
                    this.itemsList = response.map(item => {
                        return {
                            keyField: item.hasOwnProperty(keyField ? keyField : '') ? item[<string>keyField] : '',
                            displayField: item.hasOwnProperty(displayField ? displayField : '') ? item[<string>displayField] : ''
                        }
                    });
                    this.dropdownList = this.itemsList.map(item => item.displayField);
                } catch (e) {
                    console.log(e);
                }
            }
            this.isBusy = false;
        }
    }

    class ListItem {
        keyField: string = '';
        displayField: string = '';
    }
</script>

<style scoped>
    input::-webkit-calendar-picker-indicator {
        display: none;
    }

    .btn---accent-color {
        background-color: var(--accent-color);
        color: #fff;
    }

    .btn.btn---accent-color:focus, .btn.btn---accent-color.focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgb(0 175 139 / 25%);
        box-shadow: 0 0 0 0.2rem rgb(0 175 139 / 25%);
    }

    .btn.btn---accent-color:not(:disabled):not(.disabled):active, .btn.btn---accent-color:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: var(--accent-color);
        border-color: var(--accent-color);
    }

    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color: var(--accent-color);
        outline: 0;
        -webkit-box-shadow: 0 0 0 0.2rem rgb(0 175 139 / 25%);
        box-shadow: 0 0 0 0.2rem rgb(0 175 139 / 25%);
    }
</style>
