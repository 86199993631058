<template>
    <div id="main">
        <br />
        <br />
        <div style="margin:0 auto;text-align:center;">
            <h1>Choose Your Setup</h1>
            <br />
            <div style="margin: 0 auto; width: fit-content;">
                <div class="tenant_btn" @click="PerformLoginOnProd()">Production</div>
                <div class="tenant_btn" @click="PerformLoginOnDev()">Development</div>
            </div>
            <div v-if="working" style="clear:both;">
                <Spinner />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-facing-decorator';
    import { AuthClient, Configuration } from "../api/ApiClientBase";
    import Spinner from './helpers/Spinner.vue';

    @Component({ components: { Spinner } })
    export default class ChooseServer extends Vue {
        private working = false;
        get Username(): string { return this.$store.state.security.UserName; }

        private mounted() {
            //not sure why this.$route.query doesn't always contain the proper values.
            console.log("Choose server: Username is " + this.Username);
            if (AuthClient.configuration.apiBaseUrl_dev == null || AuthClient.configuration.apiBaseUrl_dev == "")
                this.$store.commit("security/SetServerSelectionNeeded", false);
        }

        private PerformLoginOnProd() {
            console.log(AuthClient.configuration.apiBaseUrl);
            this.$store.commit("security/SetServerSelectionNeeded", false);
        }

        private async PerformLoginOnDev() {
            AuthClient.configuration.apiBaseUrl = AuthClient.configuration.apiBaseUrl_dev;
            this.working = true;
            await this.$store.dispatch("security/LoadUserTenants");
            this.$store.commit("security/SetServerSelectionNeeded", false);
            this.working = false;
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .tenant_btn {
        width: 200px;
        height: 200px;
        color: var(--accent-foreground);
        background-color: var(--accent-color);
        cursor: pointer;
        margin: 10px;
        float: left;
        justify-content: center; /* align horizontal */
        align-items: center; /* align vertical */
        text-align: center;
        font-size: 25px;
        display: flex;
        flex-direction: column;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    }
</style>
