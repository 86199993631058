import { GetterTree, MutationTree, Module } from 'vuex';

import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import * as ext from "../api/Extensions";
import { ActivityValidator } from "../api/ActivityValidator";
import ActivityExecutionState from "../components/pages/contributor/ActivityExecutionState";
import { InteractiveColumnDefinition, UserInteractiveColumnDefinition } from "../api/InteractiveColumns/InteractiveColumnDefinition";

import * as jsPlumbToolkit from 'jsplumbtoolkit';
//import { WorkflowNotificationsHandler } from '../api/WorkflowNotificationsHandler';

export interface IContributorStore {
    MyActivities: api.ProcessActivityExecutionState[] | null;
    MyActivitiesTimestamp: Date | null;
    SelectedActivity: api.ProcessActivityExecutionState | null;
    SelectedActivityDetails: api.BaseProcessActivity | null;
    IsBusy: boolean;
    SortConfiguration: { property: keyof ActivityExecutionState, descending: boolean } | null;
    GroupByProperty: keyof ActivityExecutionState | null;
    DisplayedWorkflowActivity: api.ProcessActivityExecutionState | null;
    TaskListColumns: (InteractiveColumnDefinition<ActivityExecutionState> | null)[];
}

const ContributorStore: Module<IContributorStore, any> = {
    namespaced: true,
    state: {
        MyActivitiesTimestamp: null,
        MyActivities: null,
        SelectedActivity: null,
        SelectedActivityDetails: null,
        IsBusy: false,
        SortConfiguration: null,
        GroupByProperty: null,
        DisplayedWorkflowActivity: null,
        TaskListColumns: [
            new InteractiveColumnDefinition<ActivityExecutionState>("Contributor_Name", "activityName"),
            new InteractiveColumnDefinition<ActivityExecutionState>("Contributor_Workflow", "processName"),
            new InteractiveColumnDefinition<ActivityExecutionState>("Contributor_Type", "type"),
            new UserInteractiveColumnDefinition<ActivityExecutionState>("Contributor_Owner", "owners"),
            new UserInteractiveColumnDefinition<ActivityExecutionState>("Contributor_Contributors", "contributors"),
            new UserInteractiveColumnDefinition<ActivityExecutionState>("Contributor_Supervisors", "supervisors"),
            new InteractiveColumnDefinition<ActivityExecutionState>("Contributor_Due_Date", "dueDateString", "dueDate"),
            new InteractiveColumnDefinition<ActivityExecutionState>("Contributor_Status", "statusString", "status"),
            new InteractiveColumnDefinition<ActivityExecutionState>("Contributor_Percent_Complete", "percentComplete")
        ],
    },

    mutations: {
        SetMyActivitiesTimestamp(state, val: Date) {
            state.MyActivitiesTimestamp = val;
        },

        SetMyActivities(state, myActivities: api.ProcessActivityExecutionState[] | null) {
            state.MyActivitiesTimestamp = new Date(Date.now());
            if (myActivities !== null)
                myActivities.sort((a, b) => {
                    let x = b.calculatedStatus - a.calculatedStatus;
                    if (x == 0) {
                        if (a.calculatedDueDate == null)
                            return 1;
                        if (b.calculatedDueDate == null)
                            return -1;

                        x = (<any>a.calculatedDueDate! - <any>b.calculatedDueDate!);
                    }
                    return x;
                });
            state.MyActivities = myActivities;
            if (state.SelectedActivity != null) {
                var found = false;
                for (var i = 0; i < state.MyActivities!.length; i++) {
                    if (state.MyActivities![i].processRunId == state.SelectedActivity!.processRunId)
                        if (state.MyActivities![i].activityName == state.SelectedActivity!.activityName)
                            if (state.MyActivities![i].nodeId == state.SelectedActivity!.nodeId) {
                                state.SelectedActivity = state.MyActivities![i];
                                found = true;
                            }
                }
                if (!found)
                {
                    console.log("Activity not found");
                    state.SelectedActivity = null;
                    state.SelectedActivityDetails = null;
                }
            }
        },

        SetSelectedActivity(state, activity: api.ProcessActivityExecutionState | null) {
            state.SelectedActivity = activity;
        },

        SetSelectedActivityDetails(state, activity: api.BaseProcessActivity | null) {
            state.SelectedActivityDetails = activity;
        },

        SetIsBusy(state, busy: boolean) {
            state.IsBusy = busy;
        },

        SetSortConfiguration(state, val: { property: keyof ActivityExecutionState, descending: boolean } | null) {
            state.SortConfiguration = val;
        },

        SetGroupByProperty(state, val: keyof ActivityExecutionState) {
            state.GroupByProperty = val;
        },

        SetDisplayedWorkflowActivity(state, activity: api.ProcessActivityExecutionState  | null) {
            state.DisplayedWorkflowActivity = activity;
        },
    },

    actions: {
        async LoadMyActivities(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.ProcessClient(auth_client);
            var activities = await client.getMyActivities();
            context.commit("SetMyActivities", activities);
        },

        async LoadActivities(context, path: string) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.ProcessClient(auth_client);
            var activities = await client.getActivities(path);
            context.commit("SetMyActivities", activities);
        },

        async ReLoadMyActivities(context, maxAge: number | undefined) {
            if (maxAge && context.state.MyActivitiesTimestamp) {
                var dif = Math.abs((new Date().getTime() - context.state.MyActivitiesTimestamp.getTime()) / 1000);
                if (dif < maxAge) {
                    console.log("Skipping activities reload - maxage is good.");
                    return;
                }
                else {
                    console.log("MyActivitiesTimestamp." + context.state.MyActivitiesTimestamp.toLocaleTimeString());
                }
            }

            console.log("Reloading activities.");
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.ProcessClient(auth_client);
            var activities = await client.getMyActivities();
            context.commit("SetMyActivities", activities);
        },

        async LoadSelectedActivityDetails(context, activity: api.ProcessActivityExecutionState | null) {
            context.commit("SetSelectedActivityDetails", null);
            context.commit("SetSelectedActivity", activity);
            if (activity != null && activity.nodeId != null && activity.processPath != null) {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.ProcessClient(auth_client);
                var details = await client.getActivityDetails(activity.processPath, activity.nodeId, activity.processRunId);
                context.commit("SetSelectedActivityDetails", details);
            }
        },
    },

    getters: {

    }
};

export default ContributorStore;