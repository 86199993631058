import { GetterTree, MutationTree, Module } from 'vuex';
import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import * as ext from "../api/Extensions";

export interface IDashboardStore {
    CachedFsos: api.FileSystemObjectContent[],
    CurrentWorkflow: api.FileSystemObjectContent | null,
    SelectedHomepage: api.DashboardSection | null,
    SelectedControl: api.DashboardControl | null
}

const DashboardStore: Module<IDashboardStore, any> = {
    namespaced: true,
    state: {
        CachedFsos: [],
        CurrentWorkflow: null,
        SelectedHomepage: null,
        SelectedControl: null
    },

    mutations: {
        SetCurrentWorkflow(state, wf: api.FileSystemObjectContent | null) {
            state.CurrentWorkflow = wf;
        },
        SetSelectedHomepage(state, val: api.DashboardSection | null) {
            state.SelectedHomepage = val;
        },
        SetSelectedControl(state, val: api.DashboardControl | null) {
            state.SelectedControl = val;
        }
    },

    actions: {
        async GetCachedFso(context, dashboardControl: api.DashboardControl) {
            var path = dashboardControl.fileSystemPath!;
            if (path == null || path == "" || path == "*")
                return null;

            for (var i = 0; i < context.state.CachedFsos.length; i++) {
                if (context.state.CachedFsos[i].fullPath!.toLowerCase() == path.toLowerCase()) {
                    context.rootState
                    console.log(context.rootState.Localizer.Localize("DashboardStore_Dashboard_Control_returned_from_cache"));
                    return context.state.CachedFsos[i];
                }
            }

            console.log("retrieving dashboard fso." + path);
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var fsClient = new api.FileSystemClient(auth_client);
            var fso = await fsClient.getFile(path, "binary", false);
            context.state.CachedFsos.push(fso);
            return fso;
        }
    },

    getters: {

    }
};

export default DashboardStore;