import * as Msal from "msal";
import * as api from "./ApiClient";
import LogHelper from './LogHelper';

export interface $configInterface {
    apiBaseUrl: string;
    apiBaseUrl_dev: string;
    appInsights_Id: string | null | undefined;
    appInsights_Prefix: string;
    environment: string;
    hideWhatsNew: boolean;
    hideFluenceHelp: boolean;
    portalTitle: string;
}

export class Configuration {
    public static environment: string = "default";

    public apiBaseUrl: string | null | undefined;
    public apiBaseUrl_dev: string | null | undefined;
    public uiBaseUrl: string | null | undefined;
    public tenantName: string | null | undefined;
    public tenantBgColor: string | null | undefined;

    public $config: $configInterface | null;

    constructor() {
        this.apiBaseUrl = null;
        this.uiBaseUrl = window.location.protocol + "//" + window.location.host;

        this.tenantName = null;

        this.$config = null;
    }
}

//var graphConfig = {
//    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
//    graphPhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo"
//};

export class AuthClient {

    static configuration: Configuration = new Configuration();
    static accessToken: string | null = null;
    static apiToken: string | null = null;
    static tenantId: string | null = null;
    static account: Msal.Account | null = null;

    state!: string;

    private myMSALObj: Msal.UserAgentApplication;
    private requestObj: Msal.AuthenticationParameters;

    public constructor() {
        if (this.isMultitenant()) {
            this.requestObj = {
                scopes: ["api://610d5baa-d751-4a60-82e1-98a02e1bedb3/ApiAccess"]
            };
            var msalConfig: Msal.Configuration =
            {
                auth: {
                    clientId: "610d5baa-d751-4a60-82e1-98a02e1bedb3",
                    authority: AuthClient.tenantId == null ? "https://login.microsoftonline.com/organizations/" : "https://login.microsoftonline.com/" + AuthClient.tenantId + "/",
                    redirectUri: AuthClient.configuration.uiBaseUrl!,
                    postLogoutRedirectUri: AuthClient.configuration.uiBaseUrl + "?finalised=true",
                    navigateToLoginRequestUrl: true,

                },
                cache: {
                    cacheLocation: ("localStorage" as "localStorage"),
                    storeAuthStateInCookie: true
                }
            }

            this.myMSALObj = new Msal.UserAgentApplication(msalConfig);
        }
        else {
            this.requestObj = {
                scopes: ["api://d3c5ed7b-901e-4d39-9e87-e456c6be3d2b/ApiAccess"]
            };

            var msalConfig: Msal.Configuration =
            {
                auth: {
                    clientId: "d3c5ed7b-901e-4d39-9e87-e456c6be3d2b",
                    authority: "https://login.microsoftonline.com/9483ff9b-08db-425d-9857-96ba1fd9beed/",
                    redirectUri: AuthClient.configuration.uiBaseUrl!,
                    postLogoutRedirectUri: AuthClient.configuration.uiBaseUrl + "?finalised=true",
                    navigateToLoginRequestUrl: true,

                },
                cache: {
                    cacheLocation: ("localStorage" as "localStorage"),
                    storeAuthStateInCookie: true
                }
            }

            this.myMSALObj = new Msal.UserAgentApplication(msalConfig);
        }



        var self = this as AuthClient;
        this.myMSALObj.handleRedirectCallback((error: Msal.AuthError, response: Msal.AuthResponse | undefined) => {
            if (error) {
                console.error(error);
            } else if (response) {
                self.state = localStorage.getItem("FluenceLoginState")!;

                AuthClient.account = response.account;
                AuthClient.accessToken = response!.accessToken;
                AuthClient.tenantId = response.tenantId;
            }
        });
    }


    static setTenant = async (tenant: api.Tenant | null) => {
        if (tenant != null) {
            AuthClient.configuration.tenantName = tenant.name;
            AuthClient.configuration.tenantBgColor = tenant.bgColor;
            AuthClient.configuration.apiBaseUrl = tenant.webApiUrl;
        }
        else {
            AuthClient.configuration.tenantName = null;
            AuthClient.configuration.tenantBgColor = undefined;
            AuthClient.configuration.apiBaseUrl = undefined;
        }
    }

    ensureToken = async (): Promise<void> => {
        if (AuthClient.apiToken != null)
            return;

        var self = this as AuthClient;

        try {
            var idTokenResponse = await self.myMSALObj.acquireTokenSilent(self.requestObj);
            if (idTokenResponse.accessToken) {
                if (AuthClient.accessToken != idTokenResponse.accessToken && AuthClient.accessToken != null)
                    console.log("Token has been refreshed silently." + AuthClient.accessToken + " <> " + idTokenResponse.accessToken);
                AuthClient.accessToken = idTokenResponse.accessToken;
                AuthClient.account = idTokenResponse.account;
            }
            else {
                console.log("Token could not be refresh silently.");
            }

            //let options_ = <RequestInit>{
            //    method: "GET",
            //    mode: "no-cors",
            //    headers: {
            //        "Accept": "application/json",
            //        "Authorization": "Bearer " + self.token
            //    },
            //};
            //var response = await fetch("https://graph.microsoft.com/v1.0/me/photo", options_)
            //console.log(response);
        }
        catch (error) {
            // Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
            if (this.requiresInteraction(error.errorCode)) {
                if (window.localStorage.getItem("Excel") == "Excel") {
                    Office.context.ui.displayDialogAsync(
                        <string>AuthClient.configuration.uiBaseUrl,
                        { width: 30, height: 40 },
                        (result: Office.AsyncResult<Office.Dialog>) => {
                            console.log('success');
                        });
                }
                else {
                    self.myMSALObj.acquireTokenRedirect(self.requestObj);
                }
            }
            console.log(error);
        }
    }

    forceLogin = async () => {
        var requestObj: Msal.AuthenticationParameters = JSON.parse(JSON.stringify(this.requestObj));
        requestObj.prompt = "select_account";
        this.myMSALObj.loginRedirect(requestObj);
    };

    login = async (loginState: string) => {
        if (AuthClient.apiToken != null)
            return;
        if (loginState == null)
            loginState = "";

        localStorage.setItem("FluenceLoginState", loginState);

        if (AuthClient.accessToken == null) {
            this.myMSALObj.loginRedirect(this.requestObj);
        }
    }

    logout = async () => {
        AuthClient.apiToken = null;
        this.myMSALObj.logout();
        localStorage.removeItem("tenantWebApiUrl");
        localStorage.removeItem("tenantName");

        //var correlationIdParam = "client-request-id=" + msalApp.requestCorrelationId;
        //var postLogoutQueryParam = msalApp.getPostLogoutRedirectUri()
        //    ? "&post_logout_redirect_uri=" + encodeURIComponent(msalApp.getPostLogoutRedirectUri())
        //    : "";
        //var urlNavigate = msalApp.authorityInstance.EndSessionEndpoint
        //    ? msalApp.authorityInstance.EndSessionEndpoint + "?" + correlationIdParam + postLogoutQueryParam
        //    : msalApp.authority + "oauth2/v2.0/logout?" + correlationIdParam + postLogoutQueryParam;

        //return urlNavigate;
    }

    requiresInteraction = (errorCode: any) => {
        if (!errorCode || !errorCode.length) {
            return false;
        }
        return errorCode === "consent_required" ||
            errorCode === "interaction_required" ||
            errorCode === "user_login_error" ||
            errorCode === "login_required";
    }

    isMultitenant = () => {
        var url = new URL(window.location.href.toLowerCase());
        if (url.hostname == "portal-ca.fluence.app")
            return true;
        if (url.hostname == "portal-us.fluence.app")
            return true;
        if (url.hostname == "portal-eu.fluence.app")
            return true;
        if (url.hostname == "portal-ca-1.fluence.app")
            return true;
        if (url.hostname == "portal-us-1.fluence.app")
            return true;
        if (url.hostname == "portal-eu-1.fluence.app")
            return true;
        if (url.hostname == "portal.fl.avantcorp.app")
            return true;
        if (url.hostname == "portal-eu-beta.fluence.app")
            return true;

        return false;
    }
}


export class FluenceApiClientBase {
    private authClient: AuthClient;

    constructor(authClient: AuthClient) {
        this.authClient = authClient;
    }

    protected async transformOptions(options: RequestInit): Promise<RequestInit> {
        await this.authClient.ensureToken();

        if (AuthClient.apiToken == null)
            options.headers = {
                ...options.headers,
                Authorization: "Bearer " + AuthClient.accessToken,
                Tenant: AuthClient.configuration.tenantName as string,
                env: Configuration.environment as string,
            };
        else
            options.headers = {
                ...options.headers,
                "x-api-token": AuthClient.apiToken,
                Tenant: AuthClient.configuration.tenantName as string,
                env: Configuration.environment as string,
            };
        if (LogHelper.LogId != null) {
            options.headers = {
                ...options.headers,
                LogId: LogHelper.LogId as string,
            };
        }

        return options
    }

    protected transformResult(url: string, response: Response, processor: (response: Response) => any) {
        var result = processor(response);
        return result;
    }

    protected getBaseUrl(arg0: string, baseUrl: string | undefined): string {
        var url = AuthClient.configuration.apiBaseUrl
        if (url != null)
            return url;
        return new Configuration().apiBaseUrl as string;
    }
}
