<template>

    <ThemeLoader>
        <Login v-if="Username ===''" />
        <div id="app" v-else>
            <Header />

            <div id="adnMain">
                <transition name="page" mode="out-in">
                    <ChooseTenant v-if="Tenant === null" />
                    <Container v-else class="d-flex"></Container>
                </transition>
            </div>
        </div>
    </ThemeLoader>
</template>
<script lang="ts">
    /// <reference types="office-js-preview" />

    import { Component, Vue, Watch } from 'vue-facing-decorator';
    import * as api from "./api/ApiClient";
    import Login from './components/Login.vue';
    import ChooseTenant from './components/ChooseTenant.vue';
    import Header from './components/Header.vue';
    import Container from './components/pages/excel/Container.vue';
    import ThemeLoader from './components/ThemeLoader.vue';

    @Component({ components: { Login, ChooseTenant, Header, Container, ThemeLoader } })

    export default class AppJS extends Vue {

        get Username(): string { return this.$store.state.security.UserName; }
        get Tenants(): api.Tenant[] | undefined {
            return this.$store.state.security.Tenants;
        }
        get Tenant(): api.Tenant | undefined { return this.$store.state.security.Tenant; }
        set Tenant(tenant: api.Tenant | undefined) { this.$store.commit("security/SetTenant", tenant); }
        @Watch("Tenants") async OnTenantsChange(val: api.Tenant[] | undefined) {
            await this.InitializeTenant();
        }

        async InitializeTenant() {
            var storedTenantName = localStorage.getItem("tenantName");
            if (storedTenantName !== null && this.Tenants) {
                var tenant = this.Tenants.find(t => t.name === storedTenantName);
                if (tenant)
                    await this.$store.dispatch("security/SelectTenant", tenant);
            }
        }

        @Watch("Tenant") OnTenantChange(val: api.Tenant | undefined) {
            if (val && val.name)
                localStorage.setItem("tenantName", val.name);
            else
                localStorage.setItem("tenantName", "");
        }

        mounted() {
            this.$store.dispatch("SetLocalizationLanguage", Office.context.displayLanguage);
        }
    }
</script>
<style scoped>
    #app {
        font-family: Roboto;
        font-size: 14px;
        color: #555;
        padding: 0px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    #adnMain {
        padding-top: 46px;
        height: 100vh;
        /*position: absolute;
        top: 46px;
        right: 0px;
        bottom: 0;
        left: 0px;
        overflow-y: auto;
        scroll-behavior: smooth;
        width: 100%;*/
    }

    .page-enter-active, .page-leave-active {
        transition: all .1s ease;
    }

    .page-leave-to {
        transform: translateX(-10px);
        opacity: 0;
    }

    .page-enter-from {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
