import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0cdaefc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "adnContainer"
}
const _hoisted_2 = {
  key: 1,
  class: "adnContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Form = _resolveComponent("Form")!

  return (!_ctx.IsForm)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Menu)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_Form)
      ]))
}