<template>
    <div :style="Theme">
        <slot>
        </slot>
    </div>
</template>

<script lang="ts">
import Themes from "../api/Themes";
import {defineComponent} from 'vue';
export default defineComponent({
    computed: {
        Theme() {
            if (this.$store.state.Theme == null) {
                var storedTenantName: string | null = localStorage.getItem("tenantName");
                var storedTenantTheme: string | null = storedTenantName ? localStorage.getItem(`${storedTenantName}_theme`) : null;
                if (storedTenantTheme) {
                    this.$store.commit("SetTheme", JSON.parse(storedTenantTheme));
                } else {
                    this.$store.commit("SetTheme", Themes.ThemeLight);
                }
            }

            return this.$store.state.Theme;
        }
    },
    watch: {
        '$store.state.Theme': function () {
            this.appendThemeCssAsLinkToHead();
        }
    },
    methods: {
        appendThemeCssAsLinkToHead() {
            // We need to duplicate theme styles appending to global head to make sure modals and dropdowns
            // that was appended to body outside the #app root element have theme styles
            if (!this.Theme) {
                return;
            }
            const themeLink =  document.head.querySelector('#theme');
            let css = 'body {';
            const themeStr = (
                this.Theme && Object.entries(this.Theme).map(([k, v]) => `${k}:${v}`).join(';')
            );
            css += themeStr;
            css += '}'
            if (!themeLink) {
                const head = document.head || document.getElementsByTagName('head')[0];
                const style = document.createElement('style');
                style.id = 'theme';
                head.appendChild(style);
                style.appendChild(document.createTextNode(css));
            }
            else {
                themeLink.innerHTML = '';
                themeLink.appendChild(document.createTextNode(css));
            }
        }
    }
})
</script>

<style scoped>
</style>
