<template>
    <div @click="Click" class="checkbox" v-bind:class="{ disabled: disabled }">
        <img v-if="(modelValue && !reversed) || (!modelValue && reversed)" src="../../../public/icons/users/yes.svg" class="theme-svg yesno" />
        <img v-else src="../../../public/icons/users/no.svg" class="theme-svg yesno" />
    </div>
</template>

<script lang="ts">
    require('../../assets/portal.css');

    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component({ emits: [ 'update:modelValue', 'input', 'change' ] })
    export default class Checkbox extends Vue {
        @Prop() modelValue!: boolean;
        @Prop({ default: false }) disabled!: boolean;
        @Prop({ default: false }) reversed!: boolean;

        Click() {
            if (!this.disabled) {
                this.$emit('input', !this.modelValue);
                this.$emit('change', !this.modelValue);
                this.$emit('update:modelValue', !this.modelValue);
            }
        }
    }
</script>

<style scoped>
    .checkbox {
        display: flex;
        height: 20px;
        width: 20px;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: default;
    }

    .yesno {
        height: 100%;
        width: 100%;
        cursor: pointer;
    }

    .error > .theme-svg {
        filter: invert(25%) sepia(100%) saturate(5712%) hue-rotate(360deg) brightness(96%) contrast(133%) !important;
    }
</style>
