<template>
    <div class="header-bar">
        <h1 class="header" style="grid-column: 1;">{{title}}</h1>
        <div class="wbtn-array right-aligned" style="grid-column: 2;">
            <slot name="right-group">

            </slot>
        </div>
    </div>
</template>

<script lang="ts">
    require('@/assets/portal.css');
    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component
    export default class TitleBar extends Vue {
        @Prop() title!: string;
    }
</script>

<style scoped>

</style>
