import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr'
import { AuthClient } from '../../../../api/ApiClientBase';
import WorkbookControlVM from "./WorkbookControlVM"
import LogHelper from '../../../../api/LogHelper'
import * as api from "../../../../api/ApiClient";
import ProcessRun from "./ProcessRun";
import DataViewVM from "./DataViewVM"
import ProcessVM from "./ProcessVM"
import { Store } from 'vuex';

export default class VenaHandler {
    workbookControls: WorkbookControlVM[] = [];
    store: any = '';
    connection: HubConnection | null = null;
    processRuns: ProcessRun[] = [];
    processRunLog: api.ProcessRunLog[] = [];
    registeredFunctions: RegisteredFunction[] = [];

    constructor(store: Store<any>) {
        this.store = store;
    }

    async Run(wc: string | WorkbookControlVM) {
        let control = this.store.state.excel.WorkbookControls.find((control: WorkbookControlVM) => control.Name === wc || control === wc);
        if (control) {
            wc = control;
            try {
                wc instanceof DataViewVM ? await this.RunDataView(<DataViewVM>wc) : await this.RunProcess(<ProcessVM>wc);
            } catch (e) {

            }
        }
        else {
            await this.store.dispatch("ShowToast", new api.ToastNotification({
                type: api.ToastType.Error,
                message: `Error running process or dataview.`
            }));
        }
    }

    async RunDataView(wc: DataViewVM) {
        await this.store.dispatch("ShowToast", new api.ToastNotification({
            type: api.ToastType.Info,
            message: `Running dataview ${wc.NamedRange}.`
        }));
        if (!(await wc.ValidateRange(wc.NamedRange))) {
            await this.store.dispatch("ShowToast", new api.ToastNotification({
                type: api.ToastType.Warning,
                message: `Range for ${wc.NamedRange} has been modified. Rescan workbook or fix range.`
            }));
            return;
        }

        let parameters = await wc.ReadVariables();
        let switches: string[][] = await wc.ReadSwitches();

        let tasks: any[] = [];

        try {
            let spname: string = "";
            let dataSource: string = "";
            let destinationRange: string = "";
            let header = true;
            let style = true;
            let autoExpandRange = true;

            for (var i = 0; i < switches.length; i++) {
                var key = switches[i][0];
                if (key != null) {
                    key = key.toLowerCase().trim();
                    if (key == "/spname" || key == "\\spname")
                        spname = switches[i][1];
                    if (key == "/datasource" || key == "\\datasource")
                        dataSource = switches[i][1];
                    if (key == "/range" || key == "\\range")
                        destinationRange = switches[i][1];
                    if (key == "/header" || key == "\\header")
                        header = switches[i][1].toUpperCase() == "TRUE";
                    if (key == "/style" || key == "\\style")
                        style = switches[i][1].toUpperCase() == "TRUE";
                    if (key == "/autoexpandrange" || key == "\\autoexpandrange")
                        autoExpandRange = switches[i][1].toUpperCase() == "TRUE";
                }
            }

            if (!spname) {
                await this.store.dispatch("ShowToast", new api.ToastNotification({
                    type: api.ToastType.Error,
                    message: "Data View does not specify the name of the stored procedure to execute (spname)."
                }));
                return;
            }
            if (!destinationRange) {
                await this.store.dispatch("ShowToast", new api.ToastNotification({
                    type: api.ToastType.Error,
                    message: "Data View does not specify the name of the data destination range (range)."
                }));
                return;
            }

            let auth_client = new AuthClient();
            await auth_client.ensureToken();
            let client1 = new api.SpDataClient(auth_client);
            let tableData = await client1.getDataViewData(spname, parameters, dataSource);
            if (tableData.metadata && tableData.data) {
                let columnData = tableData.metadata.columns;
                let values = tableData.data;
                if (!columnData || columnData.length == 0) {
                    await this.store.dispatch("ShowToast", new api.ToastNotification({
                        type: api.ToastType.Warning,
                        message: "Data View did not return any data."
                    }));
                    return;
                }
                let range = await wc.GetRangeByName(destinationRange);
                range.clear("Contents");
                if (style) {
                    range.clear('Formats');
                }
                await range.context.sync();

                if (header) {
                    let activeCell = range.getCell(0, 0);
                    for (var i = 0; i < columnData.length; i++) {
                        activeCell.values = [[columnData[i].columnCaption || ""]];
                        activeCell = activeCell.getOffsetRange(0, 1);
                    }
                    await range.context.sync();
                }
                if (values.length > 0) {
                    let activeRange = range.getRow(0);
                    activeRange = activeRange.getRowsBelow(values.length);
                    for (let row = 0; row < values.length; row++) {
                        let rowValues = values[row];
                        for (let column = 0; column < columnData.length; column++) {
                            let activeCell = activeRange.getCell(row, column);
                            activeCell.values = [[rowValues[column]]];
                        }
                    }
                    range.context.trackedObjects.remove(range);
                    await range.context.sync();
                }
                let resizedRangeAddress = '';
                await Excel.run(async (context) => {
                    let name = context.workbook.names.getItem(destinationRange);
                    let range = name.getRange();
                    let resizedRange: Excel.Range | null = null;
                    if (header) {
                        resizedRange = range.getAbsoluteResizedRange(values.length + 1, columnData!.length);
                    }
                    else {
                        if (values.length > 0)
                            resizedRange = range.getRow(1).getAbsoluteResizedRange(values.length, columnData!.length);
                    }
                    if (resizedRange) {
                        resizedRange.load('address');
                        await context.sync();
                        resizedRangeAddress = resizedRange.address;
                    }
                }).catch(function (error) {
                    if (error instanceof OfficeExtension.Error) {
                        console.log("Excel error: " + JSON.stringify(error.debugInfo));
                        throw "Excel error: " + JSON.stringify(error.debugInfo)
                    }
                });
                if (autoExpandRange) {
                    wc.ResizeNamedRange(destinationRange, resizedRangeAddress);
                }
                await Excel.run(async (context) => {
                    let name = context.workbook.names.getItem(destinationRange);
                    range = name.getRange();
                    if (style) {
                        range.format.fill.color = "#F5F5F5";
                        range.format.autofitColumns();

                        if (header) {
                            let headerRange = range.getRow(0);
                            headerRange.format.fill.color = "#1e90ff";
                            headerRange.format.font.color = "#ffffff";
                            headerRange.format.font.bold = true;
                        }
                        await context.sync();
                    }
                }).catch(function (error) {
                    if (error instanceof OfficeExtension.Error) {
                        console.log("Excel error: " + JSON.stringify(error.debugInfo));
                    }
                    throw "Excel error: " + JSON.stringify(error.debugInfo);
                });
                for (var i = 0; i < switches.length; i++) {
                    var key = switches[i][0];
                    if (key != null) {
                        key = key.toLowerCase().trim();
                        if (key == "/oncomplete" || key == "\\oncomplete")
                            tasks.push(switches[i][1]);
                        if (key == "/onsuccess" || key == "\\onsuccess")
                            tasks.push(switches[i][1]);
                    }
                }
            }
        } catch (e) {
            let message = e;
            if (e.response) {
                message = JSON.parse(e.response).deepMessage;
            }
            if (e instanceof OfficeExtension.Error) {
                message = "Debug info: " + JSON.stringify(e.debugInfo);
            }
            await this.store.dispatch("ShowToast", new api.ToastNotification({
                type: api.ToastType.Error,
                message: message
            }));

            for (var i = 0; i < switches.length; i++) {
                var key = switches[i][0];
                if (key != null) {
                    key = key.toLowerCase().trim();
                    if (key == "/oncomplete" || key == "\\oncomplete")
                        tasks.push(switches[i][1]);
                    if (key == "/onfailure" || key == "\\onfailure")
                        tasks.push(switches[i][1]);
                }
            }
        }
        finally {
            for (let task of tasks)
                await this.RunWorkbookControl(task, this.workbookControls);
        }
    }

    async RunProcess(wc: ProcessVM) {
        await this.store.dispatch("ShowToast", new api.ToastNotification({
            type: api.ToastType.Info,
            message: `Running process ${wc.NamedRange}.`
        }));
        if (!(await wc.ValidateRange(wc.NamedRange))) {
            return await this.store.dispatch("ShowToast", new api.ToastNotification({
                type: api.ToastType.Warning,
                message: `Range for ${wc.NamedRange} has been modified. Rescan workbook or fix range.`
            }));
        }

        let parameters = await wc.ReadVariables();
        let switches = await wc.ReadSwitches();
        var auth_client = new AuthClient();
        await auth_client.ensureToken();
        var client = new api.ProcessExecutionClient(auth_client);

        var client1 = new api.SignalRClient(auth_client);
        var data = await client1.getSignalRConfig();
        var id = await client.executeFileSystem(wc.FullName, parameters);
        await this.store.dispatch("ShowToast", new api.ToastNotification({
            message: "Process started",
            type: api.ToastType.Info
        }));
        this.connection = new HubConnectionBuilder()
            .withUrl(data.url!, { "accessTokenFactory": () => data.token! })
            .build();

        let runVM = new ProcessRun({
            Id: id,
            Name: wc.Name + " - " + new Date().toLocaleTimeString(),
            FullName: wc.Name,
        });
        this.processRuns.push(runVM);

        this.connection.on('ProcessEvent', (messageReceived, data: string) => { this.HandleProcessNotification(JSON.parse(data), switches, runVM) });
        this.connection.start();

        this.connection.onclose(() => { if (this.connection != null) this.connection.start() });
        return this.processRuns;
    }

    async AddProcessTemplate(activeCellAddress: string) {
        //ShowNotification(LocalizationProvider.GetLocalizedValue<string>("CreateProcessTemplate"), LogLevel.Info);
        await Excel.run(async (context) => {
            let comments = context.workbook.comments;
            let range = context.workbook.getSelectedRange();
            range = range.getAbsoluteResizedRange(6, 2);
            range.load('values, format/fill/color');
            let activeCell: Excel.Range;
            await context.sync();

            let templateContents = [
                { text: ["<FileSystemName>", "<TRUE|FALSE>"], comment: ["This is the file-system name of the process. You can use a localizable string (ie 'Fallback Name|Language Code 1|Process Name 1|Language Code 2|Process Name 2' ex: 'Data Import|en|Data Import|ro|Import Date')", "Use this field to enable or disable the process in the ui."] },
                { text: ["/onComplete", "<Name>"], comment: ["Set the cell next to this one to the name of a process, dataview or macro that will be triggered regardeless of whether the process succeeds or fails. For the first 2 use the fallback name, for a macro use the macro name; if the macro has one boolean parameter, will be filled with the status (true for success, false for error). You can use this switch multiple times.", null] },
                { text: ["/onSuccess", "<Name>"], comment: ["Set the cell next to this one to the name of a process, dataview or macro that will be called only if the process succeeds. For the first 2 use the fallback name. You can use this switch multiple times.", null] },
                { text: ["/onFailure", "<Name>"], comment: ["Set the cell next to this one to the name of a process, dataview or macro that will be called only if the process fails. For the first 2 use the fallback name. You can use this switch multiple times.", null] },
                { text: ["<Key 1>", "<Value 1>"], comment: ["Each of the se rows will define a key value pair that is going to be sent to process execution context. In the process you can use this values by simply setting activity parameter values to {Key 1} if they support interpolation.", null] },
                { text: ["<Key 2>", "<Value 2>"], comment: ["Each of the se rows will define a key value pair that is going to be sent to process execution context. In the process you can use this values by simply setting activity parameter values to {Key 2} if they support interpolation.", null] },
            ];

            //JBTODO: Check if enough empty space and no comments before adding template
            for (let i = 0; i < templateContents.length; ++i) {
                for (let j = 0; j < 2; ++j) {
                    activeCell = range.getCell(i, j);
                    activeCell.load('values, format/fill/color, address')
                    await context.sync();
                    let commentString = templateContents[i].comment[j];
                    if (commentString) {
                        let comment = comments.add(activeCell.address, commentString);
                        comment.set({ resolved: true });
                    }
                    activeCell.values = [[templateContents[i].text[j]]];
                    await context.sync();
                }
            }
            range.format.fill.color = "Yellow";
            range.format.autofitColumns();
            await context.sync();

            //last comment cell
            activeCell = range.getRowsBelow(1).getCell(0, 0);
            activeCell.load('values, address, format/fill/color')
            await context.sync();
            let comment = comments.add(activeCell.address, "Make sure you create a named range (format 'Fluence.Process.<CrtNo>') for the area that configures the process.");
            activeCell.values = [["*"]];
            comment.set({ resolved: true });
            activeCell.format.fill.clear();
            await context.sync();
        }).catch(async (error) => {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
            await this.store.dispatch("ShowToast", new api.ToastNotification({
                type: api.ToastType.Error,
                message: "Error creating dataview, please allow enough space for data view to be created."
            }));
        });
    }

    async AddDataViewTemplate(activeCellAddress: string) {
        //ShowNotification(LocalizationProvider.GetLocalizedValue<string>("CreateProcessConfigTemplate"), LogLevel.Info);
        await Excel.run(async (context) => {
            let comments = context.workbook.comments;
            let range = context.workbook.getSelectedRange();
            range = range.getAbsoluteResizedRange(12, 2);
            range.load('values, format/fill/color');
            let activeCell: Excel.Range;
            await context.sync();

            let templateContents = [
                { text: ["<DisplayName>", "<TRUE|FALSE>"], comment: ["This is the name of the data view. You can use a localizable string (ie 'Fallback Name|Language Code 1|Data View Name 1|Language Code 2|Data View Name 2' ex: 'Data Import|en|Data Import|ro|Import Date')", "Use this field to enable or disable the data view in the ui."] },
                { text: ["/datasource", "<DataSource>"], comment: ["Place the name of the data source where the data is retrieved from in the cell next to this one.", null] },
                { text: ["/spname", "<SP_Name>"], comment: ["Place the name of the stored procedure that retrieves the data in the cell next to this one.", null] },
                { text: ["/range", "<RangeName>"], comment: ["Destination range. This is where the data returned from the stored procedure will be inserted in the workbook.", null] },
                { text: ["/header", "<TRUE|FALSE>"], comment: ["Set the cell next to this one to FALSE, to skip the generation of the table header for the data.", null] },
                { text: ["/style", "<TRUE|FALSE>"], comment: ["Set the cell next to this one to FALSE, to skip formatting the the table.", null] },
                { text: ["/onComplete", "<Name>"], comment: ["Set the cell next to this one to the name of a process, dataview or macro that will be triggered regardeless of whether the dataview succeeds or fails. For the first 2 use the fallback name, for a macro use the macro name; if the macro has one boolean parameter, will be filled with the status (true for success, false for error). You can use this switch multiple times.", null] },
                { text: ["/onSuccess", "<Name>"], comment: ["Set the cell next to this one to the name of a process, dataview or macro that will be called only if the dataview succeeds. For the first 2 use the fallback name. You can use this switch multiple times.", null] },
                { text: ["/onFailure", "<Name>"], comment: ["Set the cell next to this one to the name of a process, dataview or macro that will be called only if the dataview fails. For the first 2 use the fallback name. You can use this switch multiple times.", null] },
                { text: ["/autoExpandRange", "<TRUE|FALSE>"], comment: ["Set the cell next to this one to TRUE, to have /range value epanded to contain all the data.", null] },
                { text: ["<Key 1>", "<Value 1>"], comment: ["Each of the se rows will define a key value pair that is going to be sent to process execution context. In the process you can use this values by simply setting activity parameter values to {Key 1} if they support interpolation.", null] },
                { text: ["<Key 2>", "<Value 2>"], comment: ["Each of the se rows will define a key value pair that is going to be sent to process execution context. In the process you can use this values by simply setting activity parameter values to {Key 2} if they support interpolation.", null] },
            ];

            //JBTODO: Check if enough empty space and no comments before adding template
            for (let i = 0; i < templateContents.length; ++i) {
                for (let j = 0; j < 2; ++j) {
                    activeCell = range.getCell(i, j);
                    activeCell.load('values, format/fill/color, address')
                    await context.sync();
                    let commentString = templateContents[i].comment[j];
                    if (commentString) {
                        let comment = comments.add(activeCell.address, commentString);
                        comment.set({ resolved: true });
                    }
                    activeCell.values = [[templateContents[i].text[j]]];
                    await context.sync();
                }
            }
            range.format.fill.color = "Yellow";
            range.format.autofitColumns();
            await context.sync();

            //last comment cell
            activeCell = range.getRowsBelow(1).getCell(0, 0);
            activeCell.load('values, address, format/fill/color')
            await context.sync();
            let comment = comments.add(activeCell.address, "Make sure you create a named range (format 'Fluence.DataView.<CrtNo>') for the area that configures the dataview.");
            activeCell.values = [["*"]];
            comment.set({ resolved: true });
            activeCell.format.fill.clear();
            await context.sync();
        })
            .catch(async (error) => {
                console.log("Error: " + error);
                if (error instanceof OfficeExtension.Error) {
                    console.log("Debug info: " + JSON.stringify(error.debugInfo));
                }
                await this.store.dispatch("ShowToast", new api.ToastNotification({
                    type: api.ToastType.Error,
                    message: "Error creating dataview, please allow enough space for data view to be created."
                }));
            });
    }

    async StartStopDebugServer() {
        let vm = this;
        if (!this.store.state.excel.LogId) {
            localStorage.removeItem("log");
            this.store.state.excel.LogId = LogHelper.startLog();
            let auth_client = new AuthClient();
            await auth_client.ensureToken();
            await this.store.dispatch("ShowToast", new api.ToastNotification({
                message: "Server Debug Started",
                type: api.ToastType.Info
            }));
        }
        else {
            this.store.state.excel.LogId = '';
            let auth_client = new AuthClient();
            await auth_client.ensureToken();
            let client = new api.ServerDebugClient(auth_client);
            let logs = await client.getServerDebugLogs();
            var dt = new Date().toLocaleDateString();
            LogHelper.stopLog();
            var dialog: any = null;
            var proceed: any = null;
            if (logs) {
                localStorage.setItem("log", logs);
                await Office.context.ui.displayDialogAsync(window.location.origin + "/excel/LogDialog.html", { width: 30, height: 30 },
                    // Dialog callback
                    (result) => {
                        dialog = result.value;
                        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                            proceed = arg.message;
                            if (dialog) {
                                dialog.close();
                            }
                            if (proceed === "true") {
                                vm.store.dispatch("ShowToast", new api.ToastNotification({
                                    message: "Debug log copied to clipboard.",
                                    type: api.ToastType.Success
                                }));
                            }
                        });
                        dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
                            if (arg.error === 12006) {
                                dialog.close();
                            }
                        });
                    },
                );
            }
            else {
                await this.store.dispatch("ShowToast", new api.ToastNotification({
                    message: "Log is empty.",
                    type: api.ToastType.Info
                }));
            }
        }
    }

    private async HandleProcessNotification(notification: any, switches: any[], runVM: ProcessRun) {
        if (runVM.Id != '' && notification.processRunId != runVM.Id)
            return;

        if (notification.status != undefined) {
            var runNotification = notification as api.ProcessRun;
            if (notification.status == "stopped" || notification.status == "Stopped") {
                await this.store.dispatch("ShowToast", new api.ToastNotification({
                    message: "Process failed",
                    type: api.ToastType.Error
                }));
                await this.OnProcessRunComplete(switches, 'onfailure');
            }
            if (notification.status == "success" || notification.status == "Success") {
                await this.store.dispatch("ShowToast", new api.ToastNotification({
                    message: "Process successful",
                    type: api.ToastType.Success
                }));
                await this.OnProcessRunComplete(switches, 'onsuccess');
            }
        }
        else {
            var runlogNotification = notification as api.ProcessRunLog;
            this.processRunLog.push(notification);
        }
    }

    private async OnProcessRunComplete(switches: any[][], status: string) {
        let tasks: string[] = [];
        for (var i = 0; i < switches.length; i++) {
            var key = switches[i][0];
            if (key != null) {
                key = key.toLowerCase().trim();
                if (key == "/oncomplete" || key == "\\oncomplete")
                    tasks.push(switches[i][1]);
                if (key == `/${status}` || key == `\\${status}`)
                    tasks.push(switches[i][1]);
            }
        }
        for (let task of tasks)
            await this.RunWorkbookControl(task, this.workbookControls);
        if (this.connection != null) {
            var con = this.connection;
            this.connection = null;
            con.stop();
        }
    }

    async RunWorkbookControl(name: string, workbookControls: WorkbookControlVM[]) {
        this.workbookControls = workbookControls;
        var found = false;
        name = name.toLowerCase();
        if (!found) {
            for (var wc of workbookControls) {
                if (wc.FallBackName.toLowerCase() === name) {
                    found = true;
                    await this.RunDataView(wc);
                    return;
                }
            }
            for (var func of this.registeredFunctions) {
                if (func.name === name) {
                    found = true;
                    await func.fn();
                    return;
                }
            }
        }
    }

    OpenProcessEditor() {
        let url = 'https://fluenceportal.azurewebsites.net/process';
        let params = '?tenant=';
        let tenant: api.Tenant | undefined = this.store.state.security.Tenant;
        if (tenant) {
            url += params + tenant.name!.replace(' ', '%20');
            window.open(url, '_blank');
        }
    }

    RegisterFunction(name: string, fn: any) {
        this.registeredFunctions.push(new RegisteredFunction(name, fn));
    }

    RunRegisteredFunction(name: string) {
        let runFunction = this.registeredFunctions.find(func => func.name === name);
        if (runFunction) {
            runFunction.fn();
        }
    }

    DeleteRegisteredFunction(name: string) {
        let fnIndex = -1;
        for (let i = 0; i < this.registeredFunctions.length; ++i) {
            if (this.registeredFunctions[i].name === name) {
                 fnIndex = i;
            }
        }
        if (fnIndex >= 0) {
            this.registeredFunctions.splice(fnIndex, 1);
            console.log(this.registeredFunctions);
        }
    }

    private getEnumKeyFromValue(val: number) {
        let keyPair = Object.entries(api.LogLevel).find(x => x[1] === val);
        return keyPair![0];
    }
}

class RegisteredFunction {
    name: string = '';
    fn: any = ''

    constructor(name: string, fn: any) {
        this.name = name;
        this.fn = fn;
    }
}

