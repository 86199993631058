class WorkbookControlVM {
    Name: string = '';
    FallBackName: string = '';
    NamedRangeSortable: string = '';
    NamedRange: string = '';
    /*Due to officejs context properties, the range reference will change everytime Excel.run is called, 
     * therefore we change Range from an Excel.Range object to the address of the range in order to keep track of it.*/
    RangeAddress: string = '';
    FullName: string = '';
    Enabled: boolean = false;
    Language: string = '';

    constructor(data?: any) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
            if (this.FullName) {
                var value = this.FullName;
                var parts = value.split('|');
                var prName = [];
                var code = '';

                //0 - exact match
                //1 - language match (no country code)
                //2 - language match (other country code)
                //3 - global name
                for (var idx = 0; idx < parts.length; idx++) {
                    var part = parts[idx];
                    if (idx == 0)
                        prName[3] = part;
                    else if (idx % 2 == 1)
                        code = part;
                    else {
                        if (code == this.Language)
                            prName[0] = part;
                        else if (this.Language.startsWith(code))
                            prName[1] = part;
                        else if (this.Language.substring(0, 2) == code.substring(0, 2))
                            prName[2] = part;
                    }
                }
                this.FallBackName = prName[3];
                for (var i = 0; i < 4; i++) {
                    if (prName[i] != null) {
                        this.Name = prName[i];
                        break;
                    }
                }
            }
            if (this.NamedRange) {
                this.NamedRangeSortable = this.NamedRange.split('.', 3)[2];
            }
        }
    }

    async ReadVariables() {
        return this.ReadParametersInternal(false, true);
    }

    async ReadSwitches() {
        return this.ReadParametersInternal(true, false);
    }

    async ReadParametersInternal(includeSwitches: boolean, includeVariables: boolean) {
        let vm = this;
        let values: any[] = [];
        await Excel.run(async (context) => {
            let wb = context.workbook;
            wb.load('names/items/name');
            await context.sync();
            let names = wb.names.items;
            let name = names.find(item => item.name === this.NamedRange);
            if (name) {
                let range = name.getRange();
                range.load('rowCount, columnCount');
                await context.sync();
                let rono = range.rowCount;
                let cono = range.columnCount;

                for (let i = 0; i < rono; ++i) {
                    if (i == 0)
                        continue;
                    var row: string[] = [];
                    for (let j = 0; j < cono; ++j) {
                        let activeCell = range.getCell(i, j).load('text');
                        await context.sync();
                        let txt = activeCell.text[0][0];
                        if (j == 0) {
                            let isCommand = j == 0 && (txt.startsWith("\\") || txt.startsWith("/"));
                            if (isCommand && !includeSwitches)
                                break;
                            if (!isCommand && !includeVariables)
                                break;
                        }
                        row.push(txt);
                    }
                    if (row.length >= 2)
                        values.push(row);
                }
            }
            await context.sync();
        }).catch(function (error) {
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });
        return values;
    }

    async GetRangeByName(namedRange: string): Promise<Excel.Range> {
        return Excel.run(async (context) => {
            let wb = context.workbook;
            wb.load('names/items/name');
            await context.sync();
            let names = wb.names.items;
            let name = names.find(item => item.name === namedRange);
            if (name) {
                let range = name.getRange();
                context.trackedObjects.add(range);
                await context.sync();
                return range;
            }
            else {
                throw `Range: ${namedRange} not found.`
            }
        }).catch(function (error) {
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
            throw error;
        });
    }

    async ResizeNamedRange(destinationRange: string, resizedRangeAddress: string) {
        await Excel.run(async (context) => {
            let name = context.workbook.names.getItem(destinationRange);
            let ws = context.workbook.worksheets.getActiveWorksheet();
            if (name) {
                let resizedRange = ws.getRange(resizedRangeAddress);
                name.delete();
                context.workbook.names.add(destinationRange, resizedRange);
            }
            await context.sync();
        }).catch(function (error) {
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });
    }

    async ValidateRange(namedRange: string) {
        var newRange = await this.GetRangeByName(namedRange);
        newRange.load('address');
        await newRange.context.sync();
        let isValid = newRange.address !== this.RangeAddress ? false : true;
        newRange.context.trackedObjects.remove(newRange);
        return isValid;
    }
}

export default WorkbookControlVM