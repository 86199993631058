const RouteNames = {
    HomeRoute: 'Home',
    FirewallRoute: 'Firewall',
    DevOpsRoute: 'DevOps',
    ExplorerRoute: 'ExplorerRoute',
    SecurityManagementRoute: 'SecurityManagementRoute',
    SecurityManagementUsersRoute: 'SecurityManagementUsersRoute',
    SecurityManagementApiTokenRoute: 'SecurityManagementApiTokenRoute',
    SecurityManagementUserRolesRoute: 'SecurityManagementUserRolesRoute',
    SecurityManagementRolesRoute: 'SecurityManagementRolesRoute',
    SecurityManagementModulesRoute: 'SecurityManagementModulesRoute',
    SecurityManagementUserReportsRoute: 'SecurityManagementUserReportsRoute',
    DataRoute: 'DataRoute',
    LogsRoute: 'LogsRoute',
    TestGridRoute: 'TestGridRoute',
    DataModelMappingsRoute: 'DataModelMappingsRoute',
    ClearDataViewRoute: 'ClearDataViewRoute',
    FieldMappingsViewRoute: 'FieldMappingsViewRoute',
    DataMapsRoute: 'DataMapsRoute',
    MemberMappingsViewRoute: 'MemberMappingsViewRoute',
    DataConnectionStringsRoute: 'DataConnectionStringsRoute',
    DataStagingTableRoute: 'DataStagingTableRoute',
    DataStagingTableEditRoute: 'DataStagingTableEditRoute',
    DataHomepagesRoute: 'DataHomepagesRoute',
    DataHomepageDesignerRoute: 'DataHomepageDesignerRoute',
    DataJournalsRoute: 'DataJournalsRoute',
    DataThemesRoute: 'DataThemesRoute',
    DataThemeDetailsRoute: 'DataThemeDetailsRoute',
    WorkflowContributorRoute: 'ContributorRoute',
    WorkflowRoute: 'WorkflowRoute',
    ModelsRoute: 'ModelsRoute',
    ModelViewRoute: 'ModelViewRoute',
    DimensionViewRoute: 'DimensionViewRoute',
    //DimensionListEditorRoute: 'DimensionListEditorRoute',
    DimensionHierarchyViewRoute: 'DimensionHierarchyViewRoute',
    ModelViewsViewRoute: 'ModelViewsViewRoute',
    ModelViewEditorRoute: 'ModelViewEditorRoute',
    ModelSubsetsRoute: 'ModelSubsetsRoute',
    ModelListsRoute: 'ModelListsRoute',
    ModelDataMappingsRoute: 'ModelDataMappingsRoute',
    ModelDimensionSecurityRoute: 'ModelDimensionSecurityRoute',
    PowerBIRoute: 'PowerBIRoute',
    XL3Route: 'XL3Route',
    MainFallback: 'MainFallback',
    DashboardRoute: 'DashboardRoute',
    ReportBookRoute: 'ReportBookRoute',
    NotificationRoute: 'NotificationRoute',
    JournalsRoute: 'JournalsRoute',
    JournalsEditRoute: 'JournalsEditRoute',
    JournalsSelectRoute: 'JournalsSelectRoute',
    JournalsReportingRoute: 'JournalsReportingRoute',
    JournalsAuditRoute: 'JournalsAuditRoute',
    MaintenanceRoute: 'MaintenanceRoute',
    MaintenanceEditRoute: 'MaintenanceEditRoute',
    LogManagementAuditTrailRoute: 'LogManagementAuditTrailRoute',
    MaintenanceFormListSelectorRoute: 'FormListSelector',
    MaintenanceTypeSelectorRoute: 'MaintenanceTypeSelector',
    LogManagementEtlLogsRoute: 'LogManagementEtlLogsRoute',
    WhatIsNewEditor: 'WhatIsNewEditor',
    MatchingUI: 'MatchingUI',
    HomeExtensionRoute: 'HomeExtension'
};

export default RouteNames;
