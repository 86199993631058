export interface ITableControl {
    FlagAction: boolean;
    AddRows?: boolean;
    DeleteRows?: boolean;
    FieldMappings?: TableFieldMapping[];
    MetaRowRange?: string | null;
    DataRowRange?: string | null;
    NewRowFormatRange?: string | null;
    EditControls?: EditControl[];
    Name?: string | null;
}

export class TableControl implements ITableControl {
    public FlagAction: boolean = true;
    public AddRows?: boolean;
    public DeleteRows?: boolean;
    public FieldMappings?: TableFieldMapping[];
    public MetaRowRange?: string | null;
    public DataRowRange?: string | null;
    public NewRowFormatRange?: string | null;
    public EditControls?: EditControl[];
    public Name?: string | null;

    constructor(data?: ITableControl) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(data?: any) {
        if (data) {
            this.FlagAction = data["FlagAction"] !== undefined ? data["FlagAction"] : true;
            this.AddRows = data["AddRows"] !== undefined ? data["AddRows"] : false;
            this.DeleteRows = data["DeleteRows"] !== undefined ? data["DeleteRows"] : false;
            if (Array.isArray(data["FieldMappings"])) {
                this.FieldMappings = [] as any;
                for (let item in data["FieldMappings"])
                    this.FieldMappings!.push(data["FieldMappings"][item]);
            }
            this.MetaRowRange = data["MetaRowRange"] !== undefined ? data["MetaRowRange"] : <any>null;
            this.DataRowRange = data["DataRowRange"] !== undefined ? data["DataRowRange"] : <any>null;
            this.NewRowFormatRange = data["NewRowFormatRange"] !== undefined ? data["NewRowFormatRange"] : <any>null;
            if (Array.isArray(data["EditControls"])) {
                this.EditControls = [] as any;
                for (let item in data["EditControls"])
                    this.EditControls!.push(data["EditControls"][item]);
            }
            this.Name = data["Name"] !== undefined ? data["Name"] : <any>null;
        }
    }

    static fromJS(data: any): TableControl {
        data = typeof data === 'object' ? data : {};
        let result = new TableControl();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["FlagAction"] = this.FlagAction !== undefined ? this.FlagAction : false;
        data["AddRows"] = this.AddRows !== undefined ? this.AddRows : false;
        data["DeleteRows"] = this.DeleteRows !== undefined ? this.DeleteRows : false;
        if (Array.isArray(this.FieldMappings)) {
            data["FieldMappings"] = [];
            for (let item in this.FieldMappings)
                data["FieldMappings"].push(this.FieldMappings[item]);
        }
        data["MetaRowRange"] = this.MetaRowRange !== undefined ? this.MetaRowRange : <any>null;
        data["DataRowRange"] = this.DataRowRange !== undefined ? this.DataRowRange : <any>null;
        data["NewRowFormatRange"] = this.NewRowFormatRange !== undefined ? this.NewRowFormatRange : <any>null;
        if (Array.isArray(this.EditControls)) {
            data["EditControls"] = [];
            for (let item in this.EditControls)
                data["EditControls"].push(this.EditControls[item]);
        }
        data["Name"] = this.Name !== undefined ? this.Name : <any>null;
        return data;
    }

    clone(): TableControl {
        const json = this.toJSON();
        let result = new TableControl();
        result.init(json);
        return result;
    }
}

export class TableFieldMapping {
    public ColumnRangeName?: string;
    public PreserveFormulas?: boolean;
    public Fields?: Field[];
}

export class ObjectControl {
    public Name?: string | null;            // name of the control
    public FlagAction: boolean = false;     // set to true if action flag should be set when changes are made in this control
}

export class Field extends ObjectControl
{
    public IsKey?: boolean;
    public DefaultValue?: string;
    public DisableCondition?: string;
    public DefaultWhenDisabled?: boolean;
}

export interface IGridControl {
    SheetName?: string;             // worksheet name
    Name?: string;                  // column range name
    Row?: number;                   // top row
    RowCount?: number;              // # of rows
    LastRow?: number;               // last row
    Column?: number;                // leftmost column
    ColumnCount?: number;           // # of columns
    LastColumn?: number;            // rightmost column
    ObjectControl?: ObjectControl;  // associated object control
    ColumnRangeIndex?: number;      // column range index inside the control (i.e. column range # in a TableControl)
    RowRangeIndex?: number;         // row range index inside the control (only applicable to Matrix)
    DataRowRange?: string | null;   // associated data row range
}

export class GridRange implements IGridControl {
    public SheetName?: string;
    public Name?: string | undefined;
    public Row?: number;
    public RowCount?: number;
    public LastRow?: number;
    public Column?: number;
    public ColumnCount?: number;
    public LastColumn?: number;
    public ObjectControl?: ObjectControl;
    public ColumnRangeIndex?: number;
    public RowRangeIndex?: number;
    public DataRowRange?: string | null;

    constructor(data?: IGridControl) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(data?: any) {
        if (data) {
            this.SheetName = data["SheetName"] !== undefined ? data["SheetName"] : <any>null;
            this.Name = data["SheetName"] !== undefined ? data["SheetName"] : <any>null;
            this.Row = data["Row"] !== undefined ? data["Row"] : <any>null;
            this.RowCount = data["RowCount"] !== undefined ? data["RowCount"] : <any>null;
            if (this.Row && this.RowCount) {
                this.LastRow = data["LastRow"] !== undefined ? data["LastRow"] : this.Row + this.RowCount - 1;
            } else
                this.LastRow = data["LastRow"] !== undefined ? data["LastRow"] : <any>null;
            this.Column = data["Column"] !== undefined ? data["Column"] : <any>null;
            this.ColumnCount = data["ColumnCount"] !== undefined ? data["ColumnCount"] : <any>null;
            if (this.Column && this.ColumnCount) {
                this.LastColumn = data["LastColumn"] !== undefined ? data["LastColumn"] : this.Column + this.ColumnCount - 1;
            } else
                this.LastColumn = data["LastColumn"] !== undefined ? data["LastColumn"] : <any>null;
            this.ObjectControl = data["ObjectControl"] !== undefined ? data["ObjectControl"] : <any>null;
            this.ColumnRangeIndex = data["ColumnRangeIndex"] !== undefined ? data["ColumnRangeIndex"] : <any>null;
            this.RowRangeIndex = data["RowRangeIndex"] !== undefined ? data["RowRangeIndex"] : <any>null;
            this.DataRowRange = data["DataRowRange"] !== undefined ? data["DataRowRange"] : <any>null;
        }
    }

    static fromJS(data: any): GridRange {
        data = typeof data === 'object' ? data : {};
        let result = new GridRange();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["SheetName"] = this.SheetName !== undefined ? this.SheetName : <any>null;
        data["Name"] = this.SheetName !== undefined ? this.SheetName : <any>null;
        data["Row"] = this.Row !== undefined ? this.Row : <any>null;
        data["RowCount"] = this.RowCount !== undefined ? this.RowCount : <any>null;
        data["LastRow"] = this.Row && this.RowCount ? this.Row + this.RowCount - 1 : <any>null;
        data["Column"] = this.Column !== undefined ? this.Column : <any>null;
        data["ColumnCount"] = this.ColumnCount !== undefined ? this.ColumnCount : <any>null;
        data["LastColumn"] = this.Column && this.ColumnCount ? this.Column + this.ColumnCount - 1 : <any>null;
        data["ObjectControl"] = this.ObjectControl !== undefined ? this.ObjectControl : <any>null;
        data["ColumnRangeIndex"] = this.ColumnRangeIndex !== undefined ? this.ColumnRangeIndex : <any>null;
        data["RowRangeIndex"] = this.RowRangeIndex !== undefined ? this.RowRangeIndex : <any>null;
        data["DataRowRange"] = this.DataRowRange !== undefined ? this.DataRowRange : <any>null;
        return data;
    }

    clone(): GridRange {
        const json = this.toJSON();
        let result = new GridRange();
        result.init(json);
        return result;
    }
}

export class EditControl {
    // field identification
    // we don't use the field name as in theory a field can appear multiple times in an object with potentially different edit controls
    public RangeIndex: number = 0; // column range in which field is present
    public FieldIndex: number = 0; // field index in range
    // Control information
    public ControlType?: string; // type of edit control (List, Tree, Date etc)
    // List specific fields
    public SourceType?: string; // type of source for the list (GridRange, RelationalQuery, DimensionQuery)
    public SourceName?: string; // name of the grid range or name of the query with data for the list
    // GridRange List specific fields
    public SkipBlanks: boolean = false; // if true, empty values are skipped
    public KeyIndex: number = 0; // zero-based index of the column in range to use as key value for the list
    public DisplayIndex: number = 0; // zero-based index of the column in range to use as display value for the list
    public MatchIndex: number = 0; // zero-based index of the column in range to filter by
    public MatchString?: string; // string value to match filter column with (may contain substitution values)
    // Query List specific fields
    public KeyField?: string; // name of the field to use for key value (i.e. the value that will actually be stored on the grid)
    public DisplayField?: string; // name of the field to use for display value (i.e. the value that will be displayed for selection)
    public RequiredVariables?: string[]; // list of form variables that are required for the query to run on the server
    // Tree specific fields
    public FullTreeView: boolean = false;
    public RootElementKey?: string;
    public RootElementName?: string;
    // Date specific fields
    public MinDate?: string; // minimum date in YYYYMMDD format (substitutions allowed)
    public MaxDate?: string; // maximum date in YYYYMMDD format (substitutions allowed)
    // Attachment specific fields
    public AllowEdit: boolean = false; // attachement is editable (can attach new file or remove/change existing file)
    public Store?: string; // Which component from the selected value from a dimensional query should be stored in the cell (Key, Name, Caption). Optional, default is Name
    public StoreSelectedKeyIn?: string; // where to (additionaly) store the key value; can be another field name or a variable
}