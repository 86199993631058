<template>
    <portal to="DialogsContainer">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div :class="['modal-container', containerStyle]">
                        <div class="modal-header">
                            <div style="padding-top: 10px; padding-right: 12px; padding-bottom: 6px; padding-left: 12px; width: 100%; height: 41px; ">
                                <img src="/icons/workflow/close.svg" class="theme-svg" id="close" @click="$emit('close')" />
                            </div>
                        </div>

                        <div class="modal-body" style="text-align: center;">
                            <div class="center">
                                <img :src="IconUrl" />
                            </div>
                            <div class="center" style="font-weight:bold; font-size:22px;">
                                {{ title }}
                            </div>
                            <div v-if="!IsHtml" class="center" style="margin-top:40px;">
                                {{ message }}
                            </div>
                            <div v-else class="dialoghtml">
                                <div v-html="$sanitize(message)" style="padding:12px; overflow-y: auto; text-align: left;"></div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <slot name="footer">
                                <div class="btnViewer btnViewerDefault" @click="$emit('close')">
                                    OK
                                </div>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </portal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

import { Portal, PortalTarget } from 'portal-vue';

    @Component({ components: { Portal } })
    export default class Dialog extends Vue {
        @Prop() private size!: string;
        @Prop() private icon!: string;
        @Prop() private title!: string;
        @Prop() private message!: string;
        @Prop() private ishtml!: boolean;

        get containerStyle() {
            return this.size;
        }

        get IconUrl() {
            if (this.icon == null) {
                return '/icons/dialog/info.svg';
            }
            return `/icons/dialog/${this.icon}.svg`;
        }

        get IsHtml() {
            return this.ishtml ?? false;
        }

        mounted() {

        }
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 50%;
        margin: 0px auto;
        margin-top: 50px;
        padding: 10px 12px 0px 12px;
        transition: all 0.3s ease;
        background-color: var(--background-primary);
        box-sizing: border-box;
    }

    .modal-header {
        margin: -10px -12px 0px -12px;
        border-bottom-width: 0px;
    }

    .modal-container.sm {
        width: 800px;
        max-width: 35%;
    }

    .modal-container.md {
        width: 800px;
        max-width: 40%;
    }

    .modal-container.lg {
        width: 800px;
        max-width: 50%;
    }

    .modal-body {
        margin: 12px 40px 0px 40px;
    }

    .modal-footer {
        border-top-width: 0px;
    }

    .modal-enter-from {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

        .modal-enter-from .modal-container,
        .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

    #close {
        float: right;
        cursor: pointer;
    }

    .center {
        margin: 0 auto;
    }
</style>
<style>
    .dialoghtml {
        max-height: calc(100vh - 385px);
        overflow-y: auto;
        padding-top: 24px;
    }

    .dialoghtml .bordered {
        border: 0 !important;
    }
</style>
