import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenuPanel = _resolveComponent("ContextMenuPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "context_menu_curtain",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.Close && _ctx.Close(...args))),
      onContextmenu: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Close && _ctx.Close(...args)), ["prevent","stop"]))
    }, null, 32),
    _createVNode(_component_ContextMenuPanel, { ref: "context_menu" }, {
      buttons: _withCtx(() => [
        _renderSlot(_ctx.$slots, "buttons")
      ]),
      _: 3
    }, 512)
  ]))
}