import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6ae04963"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { style: {"padding-top":"10px","padding-right":"12px","padding-bottom":"6px","padding-left":"12px","width":"100%","height":"41px"} }
const _hoisted_5 = {
  class: "modal-body",
  style: {"text-align":"center"}
}
const _hoisted_6 = { class: "center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  class: "center",
  style: {"font-weight":"bold","font-size":"22px"}
}
const _hoisted_9 = {
  key: 0,
  class: "center",
  style: {"margin-top":"40px"}
}
const _hoisted_10 = {
  key: 1,
  class: "dialoghtml"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portal = _resolveComponent("portal")!

  return (_openBlock(), _createBlock(_component_portal, { to: "DialogsContainer" }, {
    default: _withCtx(() => [
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(['modal-container', _ctx.containerStyle])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: "/icons/workflow/close.svg",
                      class: "theme-svg",
                      id: "close",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("img", { src: _ctx.IconUrl }, null, 8, _hoisted_7)
                  ]),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.title), 1),
                  (!_ctx.IsHtml)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.message), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("div", {
                          innerHTML: _ctx.$sanitize(_ctx.message),
                          style: {"padding":"12px","overflow-y":"auto","text-align":"left"}
                        }, null, 8, _hoisted_11)
                      ]))
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _renderSlot(_ctx.$slots, "footer", {}, () => [
                    _createElementVNode("div", {
                      class: "btnViewer btnViewerDefault",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                    }, " OK ")
                  ], true)
                ])
              ], 2)
            ])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}