import { GetterTree, MutationTree, Module } from 'vuex';
import { AuthClient } from "../api/ApiClientBase";
import * as ext from "../api/Extensions";
import * as api from "../api/ApiClient";

export interface IWhatsNewStore {
    Articles: api.WhatsNewArticle[] | null;
}

const UserManagementStore: Module<IWhatsNewStore, any> = {
    namespaced: true,
    state: {
        Articles: null,
    },
    mutations: {
        SetArticles(state, articles) {
            state.Articles = articles;
        }
    },
    actions: {
        async GetArticles(context, filter: boolean, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }
            var client = new api.WhatsNewClient(auth_client);
            var articles = await client.getArticles(filter);
            console.log("articles: ", articles);
            context.commit("SetArticles", articles);

        },
        
    },
    getters: {

    }
};

export default UserManagementStore;