import { GetterTree, MutationTree, Module } from 'vuex';
import { WindowsTabData } from '../api/WindowsTabData';

export interface IWindowsTabsStore {
    Tabs: WindowsTabData[];
}

const WindowsTabsStore: Module<IWindowsTabsStore, any> = {
    namespaced: true,

    state: {
        Tabs: [],
    },

    mutations: {
    },

    actions: {
        async Login(context, prefferedTenant: string) {
            context.state.Tabs.splice(0, context.state.Tabs.length);
        }
    },

    getters: {

    }
};

export default WindowsTabsStore;