import { GetterTree, MutationTree, Module } from 'vuex';

import * as api from "../api/ApiClient";
import * as ext from "../api/Extensions";
import { AuthClient } from "../api/ApiClientBase";
import * as apiBase from "../api/ApiClientBase"

export interface IMaintenanceState {
    HeaderForm: api.AdminForm | null;
    DetailForm: api.AdminForm | null;
    TableName: string | null;
    ListType: string | null;
    Forms: api.AdminForm[] | null;
    Categories: api.AdminCategory[] | null;
}

const MaintenanceStore: Module<IMaintenanceState, any> = {
    namespaced: true,
    state: {
        HeaderForm: null,
        DetailForm: null,
        ListType: null,
        TableName: null,
        Forms: null,
        Categories: null,
    },

    mutations: {
        SetHeaderForm(state, headerForm) {
            state.HeaderForm = headerForm;
        },
        SetDetailForm(state, detailForm) {
            state.DetailForm = detailForm;
        },
        SetTableName(state, tableName) {
            state.TableName = tableName;
        },
        ClearMaintenanceInstanceVariables(state) {
            state.HeaderForm = state.DetailForm = null;
        },
        SetListType(state, ListType) {
            console.log(ListType);
            state.ListType = ListType;
        },
        SetAllForms(state, Forms: api.AdminForm[]) {
            state.Forms = Forms;
        },
        SetCategories(state, categories) {
            state.Categories = categories;
        }
    },

    actions: {
        async SetForms(context, tableName) {
            const auth_client = new apiBase.AuthClient();
            await auth_client.ensureToken();
            var client = new api.MaintenanceClient(auth_client);
            var forms = await client.getFormsByTableName(tableName);
            console.log("forms: ", forms);
            context.commit("SetTableName", tableName);
            if (forms) {
                for (var form of forms) {
                    if (form.headerId) {
                        context.commit("SetDetailForm", form);
                    }
                    else {
                        context.commit("SetHeaderForm", form);
                    }
                }
            } 
        },

        async SetTableName(context, tableName) {
            const auth_client = new apiBase.AuthClient();
            await auth_client.ensureToken();
            context.commit("SetTableName", tableName);
        },

        async SetHeaderForm(context, form) {
            context.commit("SetHeaderForm", form);
        },

        async SetDetailForm(context, form) {
            context.commit("SetDetailForm", form);
        },
        async SetCategories(context, categories) {
            context.commit("SetCategories", categories);
        },

        async GetAllForms(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }
            var client = new api.MaintenanceClient(auth_client);
            var forms = await client.getForms();
            context.commit("SetAllForms", forms);
        },

        async GetCategories(context, auth_client: AuthClient | null = null) {
            if (auth_client == null) {
                auth_client = new AuthClient();
                await auth_client.ensureToken();
            }
            var client = new api.MaintenanceClient(auth_client);
            var categories = await client.getAllCategories();
            context.commit("SetCategories", categories);
        }
    },

    getters: {

    }
};

export default MaintenanceStore;