import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-73d2ce28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "node" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "children" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeItem = _resolveComponent("TreeItem", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["node-main", {selected: _ctx.IsSelected}]),
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.Value_Click()), ["stop"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["expander", {expanded: _ctx.IsExpanded, collapsed: !_ctx.IsExpanded, hidden: !_ctx.HasChildren}]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Expander_Click && _ctx.Expander_Click(...args)), ["stop"]))
      }, null, 2),
      _createElementVNode("span", {
        "data-toggle": "tooltip",
        "data-placement": "right",
        title: _ctx.Value.caption,
        class: _normalizeClass(['label', _ctx.IsExactMatch && _ctx.Search !== '' ? 'bold' : ''])
      }, _toDisplayString(_ctx.Value[_ctx.TreeControlLabel.toLowerCase()]), 11, _hoisted_2)
    ], 2),
    _createVNode(_Transition, { name: "fso-expanded" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(_TransitionGroup, {
            name: "fso-expanded",
            tag: "span"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Children, (child, i) => {
                return _withDirectives((_openBlock(), _createBlock(_component_TreeItem, {
                  key: i,
                  Definition: child,
                  Search: _ctx.Search
                }, null, 8, ["Definition", "Search"])), [
                  [_vShow, _ctx.showSearchNodes(child)]
                ])
              }), 128))
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, _ctx.IsExpanded]
        ])
      ]),
      _: 1
    })
  ]))
}