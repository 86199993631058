export interface IHierarchicalTreeNode {
    Key: string | undefined;
    Name?: string | null;
    Caption?: string | null;
    ParentKey?: string | null;
    DisplayOnly?: boolean;
    IsLeaf?: boolean;
    IsParent?: boolean;
    IsMatch?: boolean;
    Expanded?: boolean;
    IsSelected?: boolean;
    Children?: HierarchicalTreeNode[];
    Parent?: HierarchicalTreeNode;
}

export default class HierarchicalTreeNode implements IHierarchicalTreeNode {
    Key: string | undefined;
    Name?: string | null;
    Caption?: string | null;
    ParentKey?: string | null;
    DisplayOnly?: boolean;
    IsLeaf?: boolean;
    IsParent?: boolean;
    IsMatch?: boolean;
    Expanded?: boolean;
    IsSelected?: boolean;
    Children?: HierarchicalTreeNode[];

    constructor(data?: IHierarchicalTreeNode) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(data?: any) {
        if (data) {
            this.Key = data["Key"] !== undefined ? data["Key"] : <any>null;
            this.Name = data["Name"] !== undefined ? data["Name"] : <any>null;
            this.Caption = data["Caption"] !== undefined ? data["Caption"] : <any>null;
            this.ParentKey = data["ParentKey"] !== undefined ? data["ParentKey"] : <any>null;
            this.DisplayOnly = data["DisplayOnly"] !== undefined ? data["DisplayOnly"] : null;
            this.IsLeaf = data["IsLeaf"] !== undefined ? data["IsLeaf"] : null;
            this.IsParent = data["IsParent"] !== undefined ? data["IsParent"] : null;
            this.IsMatch = data["IsMatch"] !== undefined ? data["IsMatch"] : null;
            if (data["IsLeaf"]) {
                this.IsParent = !(this.IsLeaf = data["IsLeaf"]);
            }
            if (data["IsParent"]) {
                this.IsLeaf = !(this.IsParent = data["IsParent"])
            }
            this.Expanded = data["Expanded"] !== undefined ? data["Expanded"] : null;
            this.IsSelected = data["IsSelected"] !== undefined ? data["IsSelected"] : null;
            if (data["DisplayOnly"]) {
                this.IsSelected = false;
            }
            if (Array.isArray(data["Children"])) {
                this.Children = [] as any;
                for (let item in data["Children"])
                    this.Children!.push(data["Children"][item]);
            }
        }
    }

    static fromJS(data: any): HierarchicalTreeNode {
        data = typeof data === 'object' ? data : {};
        let result = new HierarchicalTreeNode();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["Key"] = this.Key !== undefined ? this.Key : <any>null;
        data["Name"] = this.Name !== undefined ? this.Name : <any>null;
        data["Caption"] = this.Caption !== undefined ? this.Caption : <any>null;
        if (Array.isArray(this.Children)) {
            data["Children"] = [];
            for (let item in this.Children)
                data["Children"].push(this.Children[item]);
        }
        data["ParentKey"] = this.ParentKey !== undefined ? this.ParentKey : <any>null;
        data["DisplayOnly"] = this.DisplayOnly !== undefined ? this.DisplayOnly : <any>null;
        data["IsLeaf"] = this.IsLeaf !== undefined ? this.IsLeaf : null;
        data["IsParent"] = this.IsParent !== undefined ? this.IsParent : null;
        data["IsMatch"] = this.IsMatch !== undefined ? this.IsMatch : null;
        data["Expanded"] = this.Expanded !== undefined ? this.Expanded : null;
        data["IsSelected"] = this.IsSelected !== undefined ? this.IsSelected : null;
        return data;
    }

    clone(): HierarchicalTreeNode {
        const json = this.toJSON();
        let result = new HierarchicalTreeNode();
        result.init(json);
        return result;
    }

    AddChild(child: HierarchicalTreeNode): void {
        if (!this.Children) {
            this.Children = [child];
        } else this.Children.push(child); 
    }

    SetParent(parent: HierarchicalTreeNode): void {
        //JBTODO: this.Parent = parent;
    }
}

export class TreeNodeDefinition {
    Value: TreeNodeValue;
    Parent: TreeNodeDefinition | null = null;
    Children: TreeNodeDefinition[] = [];
    IsMatch: boolean = true;
    IsExpanded: boolean = false;

    constructor(val: any) {
        let tempVal = new TreeNodeValue();
        tempVal.init(val);
        this.Value = tempVal;
        this.Value.isLeaf = true;  // guilty until proven innocent (during the hierarchy sorting)
    }

    get IsLeaf(): boolean {
        return this.Value.isLeaf;
    }

    get IsRoot(): boolean {
        return this.Parent == null;
    }

    SingleNodeSearch(searchText: string) {
        this.IsMatch = this.NameAndCaptionMatch(searchText);
        return this.IsMatch;
    }

    Search(searchText: string) {
        this.IsMatch = this.NameAndCaptionMatch(searchText);
        for (var child of this.Children)
        {
            child.Search(searchText);
        }
        if (this.IsMatch && this.Parent != null) {
            this.Parent.AncestorSearchMatched();
        }

        return this.IsMatch;
    }

    AncestorSearchMatched() {
        this.IsMatch = true;
        if (this.Parent != null) {
            this.Parent.AncestorSearchMatched();
        }
    }

    private NameAndCaptionMatch(searchText: string): boolean {
        searchText = searchText.toLowerCase();
        var match = true;
        if (!searchText) {
            return true;
        }
        if (this.Value.name!.toLowerCase().indexOf(searchText) >= 0 || this.Value.caption!.toLowerCase().indexOf(searchText) >= 0) {
            match = true;
        }
        else {
            match = false;
        }
        return match;
    }

    AddChild(child: TreeNodeDefinition): void {
        if (!this.Children) {
            this.Children = [child];
        } else this.Children.push(child);
    }

    SetParent(parent: TreeNodeDefinition): void {
        this.Parent = parent;
    }
}

export class TreeNodeValue implements ITreeNodeValue {
    key?: string | null;
    name?: string | null;
    caption?: string | null;
    parentKey?: string | null;
    isLeaf!: boolean;
    displayOnly!: boolean;

    constructor(data?: ITreeNodeValue) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(data?: any) {
        if (data) {
            this.key = data["Key"] !== undefined ? data["Key"] : <any>null;
            this.name = data["Name"] !== undefined ? data["Name"] : <any>null;
            this.caption = data["Caption"] !== undefined ? data["Caption"] : <any>null;
            this.parentKey = data["ParentKey"] !== undefined ? data["ParentKey"] : <any>null;
            this.isLeaf = data["IsLeaf"] !== undefined ? data["IsLeaf"] : <any>null;
            this.displayOnly = data["DisplayOnly"] !== undefined ? data["DisplayOnly"] : <any>null;
        }
    }

    static fromJS(data: any): TreeNodeValue {
        data = typeof data === 'object' ? data : {};
        let result = new TreeNodeValue();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["key"] = this.key !== undefined ? this.key : <any>null;
        data["name"] = this.name !== undefined ? this.name : <any>null;
        data["caption"] = this.caption !== undefined ? this.caption : <any>null;
        data["parentKey"] = this.parentKey !== undefined ? this.parentKey : <any>null;
        data["isLeaf"] = this.isLeaf !== undefined ? this.isLeaf : <any>null;
        data["displayOnly"] = this.displayOnly !== undefined ? this.displayOnly : <any>null;
        return data;
    }

    clone(): TreeNodeValue {
        const json = this.toJSON();
        let result = new TreeNodeValue();
        result.init(json);
        return result;
    }
}

export interface ITreeNodeValue {
    key?: string | null;
    name?: string | null;
    caption?: string | null;
    parentKey?: string | null;
    isLeaf: boolean;
    displayOnly: boolean;
}