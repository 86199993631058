<template>
    <transition name="modal">
        <div class="rightPanel" v-if="isVisible">
            <div class="header">
                <slot name="header">
                    default header
                </slot>
                <img src="/icons/workflow/close.svg" class="theme-svg" id="close" @click="$emit('close')" />
                <img src="/icons/help.svg" v-if="Can_OpenHelp" class="theme-svg" id="help" v-bind:class="{md:size==='md'}" @click="OpenHelp" />

            </div>

            <div class="body content">
                <slot name="body">
                    default body
                </slot>
            </div>

            <div class="footer">
                <slot name="footer">
                    <div class="panelButtons">
                        <button class="btnViewer btnViewerDefault" @click="$emit('close')">
                            {{Localizer.Localize("Modal_OK")}}
                        </button>
                    </div>
                </slot>
            </div>

            <slot name="modal">
            </slot>
        </div>
    </transition>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import { Localizer } from '../../api/Localizer';

    import { ChatUser, ChatMessage } from '../pages/chat/ChatUser';
    import { ChatWindowsTabData } from '../../api/WindowsTabData';

    @Component
    export default class RightPanel extends Vue {
        @Prop() private HelpKey: string | undefined;
        @Prop() private HelpBotName: string | undefined;

        private Localizer = new Localizer(this.$store);

        public isVisible = false;

        private get Can_OpenHelp(): boolean {
            if (this.HelpKey != null) {
                const article: object | null = this.$store.getters['chat/getHelpArticle'](this.HelpKey);
                return article != null;
            }
            return false;
        }

        private async OpenHelp() {
            var article: any | null = this.$store.getters['chat/getHelpArticle'](this.HelpKey);
            if (article == null)
                return;

            const botList = this.$store.state.chat.BotList as ChatUser[];
            const newBot = new ChatUser(this.HelpBotName + ' Help', (this.HelpBotName + 'H').substring(0, 1).toUpperCase(), 'fluence');
            newBot.hint = 'https://support.fluencetech.com/api/v2/help_center/en-us/articles/' + article.zenDeskArticleId + '.json';
            newBot.history.push(
                new ChatMessage('Help Url: https://support.fluencetech.com/hc/en-us/articles/' + article.zenDeskArticleId, false)
            )
            let bot = botList.find(b => b.name == newBot.name);
            if (bot == null) {
                botList.push(newBot);
                this.$store.commit('chat/ResetBots', botList);
                bot = newBot;
            }
            this.$store.commit('chat/setSelectedBot', bot);

            const tab = new ChatWindowsTabData('Chat');
            const tabs = this.$store.state.windowstabs.Tabs;
            tabs!.push(tab);
        }

        mounted() {
            // Assign isVisible true on mounted as part of vue3 migration
            // Transition root https://v3-migration.vuejs.org/breaking-changes/transition-as-root.html
            this.isVisible = true;
        }
    }
</script>

<style scoped>
    .rightPanel .header :deep(.title) {
        font-size: 22px;
        font-weight: bold;
    }

    .rightPanel #close {
        float: right;
        margin: -20px -26px;
        cursor: pointer;
    }

    .rightPanel #help {
        float: right;
        margin: -20px -2px;
        cursor: pointer;
    }

    .rightPanel .body {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 21px;
        justify-items: stretch;
        align-items: start;
    }

        .rightPanel .body :deep(.bodyContents) {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: 21px;
            grid-row-gap: 21px;
            justify-items: stretch;
            align-items: baseline;
            padding-right: 12px;
        }

            .rightPanel .body :deep(.bodyContents .title) {
                font-size: 14px;
                color: var(--accent-color);
                text-transform: uppercase;
                grid-column-start: 1;
                grid-column-end: 3;
            }
            .rightPanel .body :deep(.bodyContents label) {
                font-weight: bold;
                margin-bottom: 0;
            }

            .rightPanel .body :deep(.bodyContents .checkbox) {
                align-self: end;
            }

    .rightPanel .footer {
        justify-content: center;
    }

        .rightPanel .footer .panelButtons, .rightPanel .footer :deep(.panelButtons) {
            display: flex;
            justify-content: center;
        }
</style>
