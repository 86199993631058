import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-16d03e76"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-mask"
}
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "header", {}, () => [
                    _createTextVNode(" Information ")
                  ], true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "icon", {}, () => [
                    _createTextVNode(" default icon ")
                  ], true),
                  _renderSlot(_ctx.$slots, "body", {}, () => [
                    _createTextVNode(" default body ")
                  ], true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "footer", {}, () => [
                    _createTextVNode(" default footer "),
                    _createElementVNode("button", {
                      class: "modal-default-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    }, " OK ")
                  ], true)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}