import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../public/icons/users/yes.svg'
import _imports_1 from '../../../public/icons/users/no.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-207350f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  class: "theme-svg yesno"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  class: "theme-svg yesno"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.Click && _ctx.Click(...args))),
    class: _normalizeClass(["checkbox", { disabled: _ctx.disabled }])
  }, [
    ((_ctx.modelValue && !_ctx.reversed) || (!_ctx.modelValue && _ctx.reversed))
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2))
  ], 2))
}