import { Module, Store } from 'vuex';
import * as api from '@/api/ApiClient';
import { AuthClient } from '@/api/ApiClientBase';
import {
    ConfidenceFilter,
    DataRow,
    Filter,
    MatchFilter,
    MatchInfo,
    MatchTypeFilter,
    SupportFilter,
    SupportingItemInfo,
    SupportMatchFilter
} from '@/api/ApiClient';
import {
    AsideFilterInterface,
    DatasetFilterData,
    DatasetsFiltersInterface,
    DatasetType,
    MatchGroupType,
    ModalFilterInterface,
    MatchingStorageInterface,
    TenantType,
    UserNameType,
    GridDataInterface,
    GridStateInterface
} from '@/components/pages/matching/MatchingInterfaces';
import {LOCAL_STORAGE_KEYS} from '@/utils/Constants';
import {FModal} from 'uicomponents';

const DEFAULT_ASIDE_FILTER: AsideFilterInterface = {
    matchFilter: MatchFilter.All,
    matchTypeFilter: MatchTypeFilter.All,
    confidenceFilter: ConfidenceFilter.All,
    varianceLow: null,
    varianceHigh: null
}

interface MatchingUIInterface {
    matchingGroups: api.MatchGroupInfo[],
    leftTransactions: api.TransactionData | null,
    rightTransactions: api.TransactionData | null,
    leftDataset: DatasetType | null,
    rightDataset: DatasetType | null,
    leftRowSelected: DataRow[] | null,
    rightRowSelected: DataRow[] | null,
    dimTables: { [key: string]: string; } | null,
    datasetsFilters: DatasetsFiltersInterface,
    asideFilter: AsideFilterInterface,
    matchGroup: MatchGroupType,
    matchingStorage: MatchingStorageInterface,
    gridState: GridDataInterface
}

const MatchingUIStore: Module<MatchingUIInterface, any> = {
    namespaced: true,
    state: {
        matchingGroups: [],
        matchGroup: '',
        leftTransactions: null,
        rightTransactions: null,
        leftDataset: null,
        rightDataset: null,
        dimTables: null,
        leftRowSelected: [],
        rightRowSelected: [],
        datasetsFilters: {},
        asideFilter: DEFAULT_ASIDE_FILTER,
        matchingStorage: {},
        gridState: {}
    },
    getters: {
        getMatchById:(state:MatchingUIInterface, getters) => (matchId:string): MatchInfo | null => {
            if (!matchId) {
                return null;
            }
            let matchInfo:MatchInfo | null = null ;
            getters.leftTransactions?.matches.some((match:MatchInfo) => {
                if (matchId.toString() === match.matchId?.toString()) {
                    matchInfo = match;
                    return true;
                }
                return false;
            });
            if (matchInfo) {
                return matchInfo;
            }
            getters.rightTransactions?.matches.some((match:MatchInfo) => {
                if (matchId.toString() === match.matchId?.toString()) {
                    matchInfo = match;
                    return true;
                }
                return false;
            });
            return matchInfo;
        },
        manualMatchMaxVariance: (store: MatchingUIInterface) => {
            let variance:number = 0;
            store.matchingGroups.forEach((matchGroupInfo:api.MatchGroupInfo) => {
                if (matchGroupInfo.matchGroup === store.matchGroup) {
                    variance = matchGroupInfo.manualMatchMaxVariance;
                }
            });
            return variance;
        },
        asideFilter: (store: MatchingUIInterface) => store.asideFilter,
        gridState: (store: MatchingUIInterface) => store.gridState,
        getGridState: (state:MatchingUIInterface) => (matchGroup: MatchGroupType, dataset: DatasetType):GridStateInterface | undefined => {
            if (!state.gridState || !state.gridState[matchGroup]) {
                return undefined;
            }
            if (!state.gridState[matchGroup][dataset]) {
                return undefined;
            }
            return state.gridState[matchGroup][dataset];
        },
        getDatasetFilter: (state:MatchingUIInterface) => (matchGroup: MatchGroupType, dataset: DatasetType):DatasetFilterData | null => {
            if (!state.datasetsFilters[matchGroup]) {
                return null;
            }
            if (!state.datasetsFilters[matchGroup][dataset]) {
                return null;
            }
            return state.datasetsFilters[matchGroup][dataset] as DatasetFilterData;
        },
        matchingGroups: (state: MatchingUIInterface) => state.matchingGroups,
        leftTransactions: (state: MatchingUIInterface) => state.leftTransactions,
        rightTransactions: (state: MatchingUIInterface) => state.rightTransactions,
        dimTables: (state: MatchingUIInterface) => state.dimTables,
        leftRowSelected: (state: MatchingUIInterface) => state.leftRowSelected,
        rightRowSelected: (state: MatchingUIInterface) => state.rightRowSelected,
        matchGroup: (state: MatchingUIInterface) => state.matchGroup,
        leftDataset: (state: MatchingUIInterface) => state.leftDataset,
        rightDataset: (state: MatchingUIInterface) => state.rightDataset,
        defaultTransactionsFilter: () => {
             const defaultTransactionsFilter: ModalFilterInterface = {
                supportFilter: SupportFilter.All,
                supportMatchFilter: SupportMatchFilter.All,
                fieldFilters: [],
                freeClauseFilter: null,
            }
            return defaultTransactionsFilter;
        },
        defaultAsideFilter:() => {
            return DEFAULT_ASIDE_FILTER
        },
        matchingStorage: state => state.matchingStorage
    },
    mutations: {
        setAsideFilter(state: MatchingUIInterface, payload: AsideFilterInterface) {
            state.asideFilter = payload;
        },
        setMatchGroup(state: MatchingUIInterface, value: MatchGroupType) {
            state.matchGroup = value;
        },
        setMatchingGroups(state: MatchingUIInterface, properties: api.MatchGroupInfo[]) {
            state.matchingGroups = properties;
        },
        setLeftTransactions(state: MatchingUIInterface, properties: api.TransactionData) {
            state.leftTransactions = properties;
        },
        setRightTransactions(state: MatchingUIInterface, properties: api.TransactionData) {
            state.rightTransactions = properties;
        },
        setLeftDataset(state: MatchingUIInterface, value: DatasetType) {
            state.leftDataset = value;
        },
        setRightDataset(state: MatchingUIInterface, value: DatasetType) {
            state.rightDataset = value;
        },
        resetTransactions(state: MatchingUIInterface) {
            state.leftTransactions = null;
            state.rightTransactions = null;
        },
        setDimTables(state: MatchingUIInterface, value: any) {
            state.dimTables = value;
        },
        setRightRowSelected(state: MatchingUIInterface, value: DataRow[] | null) {
            state.rightRowSelected = value;
        },
        setLeftRowSelected(state: MatchingUIInterface, value: DataRow[] | null) {
            state.leftRowSelected = value;
        },
        setGridState(state: MatchingUIInterface, payload: {
            group: MatchGroupType;
            dataset: DatasetType;
            gridState: GridStateInterface
        }) {
            const {
                group,
                dataset,
                gridState
            } = payload;
            if (!state.gridState) {
                state.gridState = {}
            }
            if (!state.gridState[group]) {
                state.gridState[group] = {};
            }
            state.gridState[group][dataset] = gridState;
        },
        setFullGridState(state: MatchingUIInterface, payload: GridDataInterface) {
            state.gridState = payload;
        },
        setDatasetsFilters(
            state: MatchingUIInterface,
            payload: {
                group: MatchGroupType;
                dataset: DatasetType;
                filter: ModalFilterInterface,
                fields: string[],
                amountIndex: number | null | undefined,
                dateIndex: number | null | undefined,
                frequentlyUsedFilters: Filter[]
            }) {
            const {
                group,
                dataset,
                filter,
                amountIndex,
                fields,
                dateIndex,
                frequentlyUsedFilters = []
            } = payload;

            if (!state.datasetsFilters[group]) {
                state.datasetsFilters[group] = {};
            }
            const previousFrequentlyUsedFilters =
                state.datasetsFilters[group][dataset]?.filterData?.frequentlyUsedFilters || [];
            state.datasetsFilters[group][dataset] = {
                transactionFilter: filter,
                filterData: {
                    amountIndex: amountIndex,
                    dateIndex: dateIndex,
                    fields: fields,
                    frequentlyUsedFilters: frequentlyUsedFilters.concat(previousFrequentlyUsedFilters).slice(0, 3)
                }
            };
        },
        setMatchingStorage(state: MatchingUIInterface,
            payload: {
                tenant: TenantType,
                userName: UserNameType,
                matchGroup: MatchGroupType,
                leftDataset: DatasetType,
                rightDataset: DatasetType,
                gridState: GridDataInterface
            }) {
            const {
                tenant,
                userName,
                matchGroup,
                leftDataset,
                rightDataset,
                gridState
            } = payload;
            if (!state.matchingStorage[tenant]) { // need to initialize first
                state.matchingStorage[tenant] = {};
            }
            state.matchingStorage[tenant][userName] = {
                matchGroup: matchGroup,
                leftDataset: leftDataset,
                rightDataset: rightDataset,
                gridState
            };
        },
    },
    actions: {
        async getDimensionTables(context) {
            if (context.getters.dimTables) {
                return;
            }
            const authClient = new AuthClient();
            await authClient.ensureToken();
            const client = new api.MatchingClient(authClient);
            const dimTables =  await client.getDimensionTables();
            context.commit('setDimTables', dimTables);
        },
        async getTransactions(context, payload) {
            try {
                const { matchGroup, dataset, filter, left } = payload;
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);

                const filterData = new api.TransactionFilter();
                filterData.init(filter);
                const dimTables = context.getters.dimTables;
                const transactions = await client.getTransactions(matchGroup, dataset, filterData, dimTables['Account'], dimTables['Entity']);
                if (transactions.errorMessage) {
                    FModal.error({
                        content: `${context.rootState.Localizer.Localize('Matching_Error_Retrieving_Data')}! ${transactions.errorMessage}`
                    })
                    return;
                }
                if (left) {
                    context.commit('setLeftTransactions', transactions);
                }
                else {
                    context.commit('setRightTransactions', transactions);
                }
            }
            catch (e) {
                this.dispatch('ShowToastError', 'Failed to get matching transactions');
            }
        },
        async getMatchingGroup(context, group) {
            const authClient = new AuthClient();
            await authClient.ensureToken();
            const client = new api.MatchingClient(authClient);
            try {
                const matchingGroups = await client.getMatchingGroup(group);
                context.commit('setMatchingGroups', matchingGroups);
            }
            catch (e) {
                this.dispatch('ShowToastError', 'Failed to get matching group');
            }
        },
        async getMatchingGroups(context) {
            const authClient = new AuthClient();
            await authClient.ensureToken();
            const client = new api.MatchingClient(authClient);
            try {
                const matchingGroups = await client.getMatchingGroups();
                context.commit('setMatchingGroups', matchingGroups);
            }
            catch (e) {
                this.dispatch('ShowToastError', 'Failed to get matching groups');
            }
        },
        async applyManualMatch(context, payload) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                return await client.createMatch(payload);
            } catch (e) {
                this.dispatch('ShowToastError', `Failed manual matching: ${e}`);
            }
        },
        async applyManualUnmatch(context, payload) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                return await client.deleteMatches(payload);
            } catch (e) {
                this.dispatch('ShowToastError', `Failed manual unmatch: ${e}`);
            }
        },
        async updateMatch(context, payload: MatchInfo) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                return await client.updateMatch(payload);
            }
            catch (e) {
                this.dispatch('ShowToastError', `Failed update match: ${e}`);
            }
        },
        async getAttachment(context, attachmentId) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                return await client.getAttachment(attachmentId);
            }
            catch (e) {
                this.dispatch('ShowToastError', `Failed file download`);
            }
        },
        async updateMatchingStorage(context, payload) {
            const {
                leftDataset,
                rightDataset,
                tenant,
                userName,
                gridState
            } = payload;
            await context.commit('setMatchingStorage', {
                tenant,
                userName,
                matchGroup: context.getters.matchGroup,
                leftDataset,
                rightDataset,
                gridState
            });

            try {
                localStorage.setItem('matchingStorage', JSON.stringify(context.getters.matchingStorage));
            }
            catch (e) {
                this.dispatch('ShowToastError', 'Failed to set local storage for Matching');
            }
        },
        async loadDatasetsFromStorage(context, payload) {
            const { tenant, userName } = payload;
            const matchingStorage = context.getters.matchingStorage[tenant] && context.getters.matchingStorage[tenant][userName];
            if (matchingStorage) {
                context.commit('setMatchGroup', matchingStorage.matchGroup);
                context.commit('setLeftDataset', matchingStorage.leftDataset);
                context.commit('setRightDataset', matchingStorage.rightDataset);
                context.commit('setFullGridState', matchingStorage.gridState);
                return;
            }
            const matchingStorageJson = localStorage.getItem(LOCAL_STORAGE_KEYS.MATCHING_STORAGE);
            if (!matchingStorageJson) {
                return;
            }
            try {
                const matchingStorageParsed: MatchingStorageInterface = JSON.parse(matchingStorageJson);
                const matchingStorageSpecific = matchingStorageParsed[tenant] && matchingStorageParsed[tenant][userName];
                if (!matchingStorageSpecific) {
                    return;
                }
                context.commit('setMatchGroup', matchingStorageSpecific.matchGroup);
                context.commit('setLeftDataset', matchingStorageSpecific.leftDataset);
                context.commit('setRightDataset', matchingStorageSpecific.rightDataset);
                context.commit('setFullGridState', matchingStorageSpecific.gridState);
            } catch (e) {
                console.error(e)
                this.dispatch('ShowToastError', 'Failed to get local storage for Matching');
            }
        },
        async getReconciliationGroupList(context,accountName) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                const dimTables = context.getters.dimTables;
                return await client.getReconciliationGroupList(dimTables['Account'], accountName);
            } catch (e) {
                this.dispatch('ShowToastError', e);
            }
        },
        async getAccountList(context, payload:string) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);

                const dimTables = context.getters.dimTables;
                return await client.getAccountList(payload, dimTables['Account']);
            } catch (e) {
                this.dispatch('ShowToastError', e);
            }
        },
        async getEntityList(context) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);

                const dimTables = context.getters.dimTables;
                return await client.getEntityList(dimTables['Entity']);
            } catch (e) {
                this.dispatch('ShowToastError', e);
            }
        },
        async getSubtypeList(context, payload:string){
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);

                return await client.getSupportingItemSubtypeList(payload);
            }
            catch (e) {
                this.dispatch('ShowToastError', e);
            }
        },
        async getClassList() {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                return await client.getSupportingItemClassList()
            }
            catch (e) {
                this.dispatch('ShowToastError', e);
            }
        },
        async createSupportingItem(context, payload:SupportingItemInfo) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                const dimTables = context.getters.dimTables;
                const matchGroup = context.getters.matchGroup;
                return await client.createSupportingItem(matchGroup, payload, dimTables['Account'], dimTables['Entity']);
            } catch (e) {
                context.dispatch('ShowToastError', e, { root: true });
            }
        },
        async updateSupportingItem(context, payload:SupportingItemInfo) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                const dimTables = context.getters.dimTables;
                const matchGroup = context.getters.matchGroup;
                return await client.updateSupportingItem(matchGroup, payload, dimTables['Entity']);
            } catch (e) {
                context.dispatch('ShowToastError', e, { root: true });
            }
        },
        async deleteSupport(context, payload:SupportingItemInfo) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                const dimTables = context.getters.dimTables;
                return await client.deleteSupportingItem(payload, dimTables['Entity']);
            } catch (e) {
                context.dispatch('ShowToastError', e, { root: true });
            }
        },
        async newSupportingItemValidation(context, payload:SupportingItemInfo) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                const dimTables = context.getters.dimTables;
                return await client.newSupportingItemValidation(payload, dimTables['Entity'], dimTables['Time']);
            } catch (e) {
                context.dispatch('ShowToastError', e, { root: true });
            }
        },
        async existingSupportingItemValidation(context, payload:SupportingItemInfo) {
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                const dimTables = context.getters.dimTables;
                return await client.existingSupportingItemValidation(payload, dimTables['Entity'], dimTables['Time']);
            } catch (e) {
                context.dispatch('ShowToastError', e, { root: true });
            }
        },
        async deleteTransactions(context, payload) {
            try {
                const { dataset, transactionIds } = payload;
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.MatchingClient(authClient);
                return await client.deleteTransactions(context.getters.matchGroup, dataset, transactionIds)
            }
            catch (e) {
                context.dispatch('ShowToastError', e, { root: true });
            }
        },
        async preloadSupportingItemValidation(context, payload) {
            try {
                const { itemInfo } = payload;
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const dimTables = context.getters.dimTables;
                const client = new api.MatchingClient(authClient);
                return await client.preloadSupportingItemValidation(itemInfo, dimTables['Entity'], dimTables['Time'])
            } catch (e) {
                context.dispatch('ShowToastError', e, { root: true });
            }
        }
    }
}

export default MatchingUIStore;
