<template>
    <div class="windows-tab" v-bind:style="style" ref="windowstab" @mouseleave="maximizeOptionsVisible=false" @mousedown="BringToFront">
        <div class="tab-content">
            <div class="topnavbar-tab" @mousedown="MouseDown($event)">
                <div class="nome-tab" v-if="TabData!=null">{{TabData.Name}}</div>
                <div class="top-left-menu-tab">
                    <div id="min-icon" @click="Minimize">
                        <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"><path fill="currentColor" d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"></path></svg>
                    </div>
                    <div id="max-icon" @click="Maximize" @mouseover="maximizeOptionsVisible=true">
                        <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"><path fill="currentColor" d="M384 31.1H64c-35.35 0-64 28.65-64 63.1v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64v-320C448 60.65 419.3 31.1 384 31.1zM400 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h320c8.822 0 16 7.178 16 16V416z"></path></svg>
                    </div>
                    <div id="close-icon" @click="Close">
                        <svg aria-hidden="true" focusable="false" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path></svg>
                    </div>
                </div>
            </div>
            <div class="window-content">
                <div v-if="maximizeOptionsVisible" class="maximizeOptions" @mouseleave="maximizeOptionsVisible=false">
                    <div>
                        <div @click="LeftTab" style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;"></div>
                        <div @click="RightTab" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;"></div>
                    </div>
                    <div>
                        <div @click="LeftTab(false)" style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;"></div>
                        <div @click="RightTab(false)" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;"></div>
                    </div>
                    <div>
                        <div style="border-top-left-radius: 4px; border-bottom-left-radius: 4px; grid-row: span 2;"></div>
                        <div @click="CornerTab('TR')" style="border-top-right-radius: 4px; "></div>
                        <div @click="CornerTab('BR')" style="border-bottom-right-radius: 4px; grid-column:2;"></div>
                    </div>
                    <div>
                        <div @click="CornerTab('TL')" style="border-top-left-radius: 4px;"></div>
                        <div @click="CornerTab('TR')" style="border-top-right-radius: 4px;"></div>
                        <div @click="CornerTab('BL')" style="border-bottom-left-radius: 4px;"></div>
                        <div @click="CornerTab('BR')" style="border-bottom-right-radius: 4px;"></div>
                    </div>
                    <div>
                        <div @click="TopHalfTab()" style="grid-column: span 2; border-top-left-radius: 4px; border-top-right-radius: 4px; "></div>
                        <div @click="BottomHalfTab()" style="grid-column: span 2; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; "></div>
                    </div>
                    <div>
                        <div @click="TopHalfTab()" style="grid-column: span 2; border-top-left-radius: 4px; border-top-right-radius: 4px; "></div>
                        <div @click="CornerTab('BL')" style="border-bottom-left-radius: 4px;"></div>
                        <div @click="CornerTab('BR')" style="border-bottom-right-radius: 4px;"></div>
                    </div>
                </div>
                <XL3 v-if="Type == 'FsoWindowsTabData' && TabData.Fso.objectType==FileSystemType.XL3" :Path="TabData.Fso.id"></XL3>
                <PowerBI v-else-if="Type == 'FsoWindowsTabData' && TabData.Fso.objectType==FileSystemType.PowerBI" :Path="TabData.Fso.id"></PowerBI>
                <Chat v-else-if="Type == 'ChatWindowsTabData'"></Chat>
            </div>
        </div>
        <div class="resizable-r" @mousedown="handleResizeDown($event,'r')"></div>
        <div class="resizable-b" @mousedown="handleResizeDown($event,'b')"></div>
        <div class="resizable-t" @mousedown="handleResizeDown($event,'t')"></div>
        <div class="resizable-l" @mousedown="handleResizeDown($event,'l')"></div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';

    import { WindowsTabData, FsoWindowsTabData, ChatWindowsTabData } from '../../api/WindowsTabData';
    import * as api from "../../api/ApiClient";
    import XL3 from '../pages/XL3.vue';
    import PowerBI from '../pages/PowerBI.vue';
    import Chat from '../pages/Chat.vue';

    @Component({ components: { XL3, PowerBI, Chat } })
    export default class WindowsTab extends Vue {
        @Prop() TabData!: WindowsTabData;

        private FileSystemType = api.FileSystemType;

        private get ZIndex() { return this.TabData.ZIndex; }
        @Watch("ZIndex")
        private OnZIndexChanged() {
            this.style.display = "grid";
            this.style['z-index'] = this.TabData.ZIndex.toString();
        }

        private get Type() {
            if (this.TabData instanceof FsoWindowsTabData)
                return "FsoWindowsTabData";
            if (this.TabData instanceof ChatWindowsTabData)
                return "ChatWindowsTabData";
            return "Unknown";
        }

        private maximizeOptionsVisible = false;

        private style = {
            "display": 'grid',
            "transitionDuration": "0s",
            "left": "0px",
            "right": "0px",
            "top": "0px",
            "width": "200px",
            "height": "200px",
            "transform": "",
            "z-index": "0"
        }

        BringToFront() {
            this.TabData.BringToFront()
        }

        MouseDown(event: MouseEvent) {
            this.BringToFront();

            var container = document.getElementById('PopupsContainer')!;
            var windowstab = this.$refs.windowstab! as any;

            var MaxWidth = container.scrollWidth;

            var cx = container.offsetLeft;
            var cy = container.offsetTop;

            var ox = windowstab.offsetLeft;
            var oy = windowstab.offsetTop;

            var ow = windowstab.offsetWidth;
            var oh = windowstab.offsetHeight;

            var sx = event.clientX;
            var sy = event.clientY;

            var that = this;
            document.onmousemove = function (e) {
                var x = ox + e.clientX - sx;
                var y = oy + e.clientY - sy;

                if (e.clientX - cx <= 0) {
                    that.LeftTab();
                } else if (e.clientY - cy <= 0) {
                    that.TopTab();
                } else if (e.clientX - cx >= container.offsetWidth - 10) {
                    that.RightTab();
                } else {
                    that.style.transitionDuration = "0s";
                    that.style.left = x + "px";
                    if (e.clientY - cy >= container.offsetHeight - 10)
                        y = container.offsetHeight - oh;
                    that.style.top = y + "px";
                    that.style.transform = "";
                    that.style.width = ow + "px";
                    that.style.height = oh + "px";
                }
            }

            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
            };

        };

        Close() {
            var allTabs = this.$store.state.windowstabs.Tabs as WindowsTabData[];
            var idx = allTabs.indexOf(this.TabData);
            allTabs.splice(idx, 1);
        }

        Minimize() {
            this.style.display = "none";
        };

        Maximize() {
            this.TopTab();
        };

        CornerTab(corner: string) {
            this.style.left = 0 + "px";
            this.style.top = 0 + "px";
            this.style.right = "";
            if (corner[0] == "B" && corner[1] == "R")
                this.style.transform = "translate(100%, 100%)";
            else if (corner[0] == "B")
                this.style.transform = "translateY(100%)";
            else if (corner[1] == "R")
                this.style.transform = "translateX(100%)";
            else
                this.style.transform = "";
            this.style.width = "50%";
            this.style.height = "50%";
            this.style.transitionDuration = "0.25s";
        }

        LeftTab(simetric: boolean = true) {
            this.style.left = 0 + "px";
            this.style.top = 0 + "px";
            this.style.right = "";
            this.style.transform = "";
            if (simetric)
                this.style.width = "50%";
            else
                this.style.width = "66%";
            this.style.height = "100%";
            this.style.transitionDuration = "0.25s";
        }

        TopTab() {
            this.style.left = 0 + "px";
            this.style.top = 0 + "px";
            this.style.right = "";
            this.style.transform = "";
            this.style.width = "100%";
            this.style.height = "100%";
            this.style.transitionDuration = "0.25s";
        }

        RightTab(simetric: boolean = true) {
            this.style.left = 0 + "px";
            this.style.top = 0 + "px";
            this.style.right = "";
            if (simetric) {
                this.style.transform = "translateX(99%)";
                this.style.width = "50%";
            }
            else {
                this.style.transform = "translateX(200%)";
                this.style.width = "33%";
            }
            this.style.height = "100%";
            this.style.transitionDuration = "0.25s";
        }

        TopHalfTab() {
            this.style.left = 0 + "px";
            this.style.top = 0 + "px";
            this.style.right = "";
            this.style.transform = "";
            this.style.width = "100%";
            this.style.height = "50%";
            this.style.transitionDuration = "0.25s";
        }

        BottomHalfTab() {
            this.style.left = 0 + "px";
            this.style.top = 0 + "px";
            this.style.right = "";
            this.style.transform = "";
            this.style.width = "100%";
            this.style.height = "50%";
            this.style.transform = "translateY(100%)";
            this.style.transitionDuration = "0.25s";
        }

        private resizeState = ' ';
        private dragState = false;
        private mouseX = 0;
        private mouseY = 0;

        private handleResizeDown(event: MouseEvent, direction: string) {
            this.BringToFront();

            var container = document.getElementById('PopupsContainer')!;
            var windowstab = this.$refs.windowstab! as any;

            var MaxWidth = container.scrollWidth;

            var cx = container.offsetLeft;
            var cy = container.offsetTop;

            var ox = windowstab.offsetLeft;
            var oy = windowstab.offsetTop;

            var ow = windowstab.offsetWidth;
            var oh = windowstab.offsetHeight;

            var sx = event.clientX;
            var sy = event.clientY;

            var that = this;
            document.onmousemove = function (e) {
                var x = e.clientX - sx;
                var y = e.clientY - sy;

                that.style.transitionDuration = "0s";
                that.style.transform = "";

                if (direction == 'r')
                    that.style.width = (ow + x) + "px";

                if (direction == 'b')
                    that.style.height = (oh + y) + "px";

                if (direction == 'l') {
                    that.style.left = (ox + x) + "px";
                    that.style.width = (ow - x) + "px";
                }
                if (direction == 't') {
                    that.style.top = (oy + y) + "px";
                    that.style.height = (oh - y) + "px";
                }
            }

            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        }

    }
</script>

<style scoped>
    .windows-tab {
        border: 2px solid rgba(255, 255, 255, 0.37);
        min-height: 350px;
        height: 400px;
        backdrop-filter: blur(3px);
        min-width: 500px;
        width: 500px;
        position: absolute;
        display: none;
        border-radius: 0.5em;
        top: 1px;
        left: 1px;
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        height: 100%;
        width: 100%;
        grid-template-columns: minmax(10px, 1fr);
        grid-template-rows: minmax(10px, 1fr);
    }

    .tab-content {
        margin-bottom: 5px;
        min-height: 0px;
        min-width: 0px;
    }

    .topnavbar-tab {
        height: 2em;
        width: 100%;
        background: #3a3b45;
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-template-columns: minmax(0, 1fr) auto;
        color: white;
    }

    .top-left-menu-tab {
        display: grid;
        place-items: center;
        grid-auto-flow: column;
    }

        .top-left-menu-tab svg {
            height: 1em;
            aspect-ratio: 1;
            opacity: 0.5;
            transition-duration: 0.5s;
        }

            .top-left-menu-tab svg:hover {
                transition-duration: 0.3s;
                opacity: 1 !important;
                filter: drop-shadow(0 0 0.1em black);
            }

        .top-left-menu-tab div {
            height: 2em;
            aspect-ratio: 1;
            display: grid;
            place-items: center;
        }

            .top-left-menu-tab div:hover {
                background-color: var(--glass-white-color);
            }

            .top-left-menu-tab div:nth-child(3):hover {
                background-color: rgb(255, 0, 25);
            }

    .nome-tab {
        padding-left: 6px;
        user-select: none;
    }

    .window-content {
        place-self: center;
        place-items: center;
        font-size: 1.5em;
        animation: opacity 2s ease-in-out;
        color: white;
        /*center this div */
        height: calc(100% - 2em);
        transition-duration: 0.01s;
        width: 100%;
        height: calc(100% - 20px);
        overflow: hidden;
    }

    .maximizeOptions {
        display: grid;
        width: 240px;
        height: 240px;
        background-color: #212425;
        border: 1px solid #BABABA;
        border-radius: 10px;
        row-gap: 12px;
        column-gap: 12px;
        padding: 12px;
        grid-template-columns: 1fr 1fr;
        position: absolute;
        top: 30px;
        right: 3px;
        z-index: 1000;
        animation: opacity 2s ease-in-out;
    }

        .maximizeOptions > div {
            display: grid;
            row-gap: 6px;
            column-gap: 6px;
            grid-template-columns: 1fr 1fr;
        }

            .maximizeOptions > div:nth-child(2) {
                grid-template-columns: 3fr 2fr;
            }

            .maximizeOptions > div > div {
                background-color: #424647;
                border: 1px solid #7E7F80;
            }

                .maximizeOptions > div > div:hover {
                    background-color: #B0C5D5;
                }


    .resizable-r {
        padding: 5px;
        display: block;
        position: absolute;
        z-index: 999;
        touch-action: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: e-resize;
        width: 6px;
        right: -6px;
        top: 0;
        height: 100%;
    }

    .resizable-b {
        padding: 5px;
        display: block;
        position: absolute;
        z-index: 999;
        touch-action: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: s-resize;
        height: 6px;
        bottom: -6px;
        width: 100%;
        left: 0;
    }

    .resizable-t {
        padding: 5px;
        display: block;
        position: absolute;
        z-index: 90;
        touch-action: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: n-resize;
        height: 6px;
        top: -6px;
        width: 100%;
        left: 0;
    }

    .resizable-l {
        padding: 5px;
        display: block;
        position: absolute;
        z-index: 90;
        touch-action: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: w-resize;
        width: 6px;
        left: -6px;
        height: 100%;
        top: 0;
    }
</style>
