import { GetterTree, MutationTree, Module } from 'vuex';

import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import * as ext from "../api/Extensions";
import JournalsEdit from '../components/pages/journals/JournalsEdit.vue';

export interface IJournalsStore {
    SelectedJournal: api.JournalDefinition | null;
    ExtJournalHeaders: ext.JournalHeader[];
    ExtJournalDetails: ext.JournalDetail[];
    SelectedJournalEntry: ext.JournalHeader | null;
    SelectedQuery: any;
}

const JournalsStore: Module<IJournalsStore, any> = {
    namespaced: true,
    state: {
        SelectedJournal: null,
        ExtJournalHeaders: [],
        ExtJournalDetails: [],
        SelectedJournalEntry: null,
        SelectedQuery: {}
    },

    mutations: {
        SetSelectedJournal(state, selectedJournal) {
            state.SelectedJournal = selectedJournal;
        },

        SetExtJournalHeaders(state, extJournalHeaders) {
            state.ExtJournalHeaders = extJournalHeaders;
        },

        SetExtJournalDetails(state, extJournalDetails) {
            state.ExtJournalDetails = extJournalDetails;
        },

        SetSelectedJournalEntry(state, selectedJournalEntry) {
            state.SelectedJournalEntry = selectedJournalEntry;
        },

        SetSelectedQuery(state, query) {
            state.SelectedQuery = query;
        }
    },

    actions: {
    
    },

    getters: {

    }
};

export default JournalsStore;