<template>
    <h2>Navigate View</h2>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-facing-decorator';

    @Component
    export default class NavigateView extends Vue {

    }
</script>

<style scoped>
</style>
